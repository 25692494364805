import React from "react"

const DairyIconActive = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="29"
      height="28"
      viewBox="0 0 29 28"
      fill="none"
    >
      <path
        d="M10 24C9.03333 24 8.20833 23.6583 7.525 22.975C6.84167 22.2917 6.5 21.4667 6.5 20.5V7.5C6.5 6.53333 6.84167 5.70833 7.525 5.025C8.20833 4.34167 9.03333 4 10 4H20.5C21.05 4 21.5208 4.19583 21.9125 4.5875C22.3042 4.97917 22.5 5.45 22.5 6V18.525C22.5 18.6583 22.4458 18.7792 22.3375 18.8875C22.2292 18.9958 22.0333 19.1167 21.75 19.25C21.5167 19.3667 21.3333 19.5333 21.2 19.75C21.0667 19.9667 21 20.2167 21 20.5C21 20.7833 21.0667 21.0375 21.2 21.2625C21.3333 21.4875 21.5167 21.65 21.75 21.75C21.9833 21.85 22.1667 21.9875 22.3 22.1625C22.4333 22.3375 22.5 22.525 22.5 22.725V22.975C22.5 23.2583 22.4042 23.5 22.2125 23.7C22.0208 23.9 21.7833 24 21.5 24H10ZM10 22H19.325C19.225 21.7667 19.1458 21.5292 19.0875 21.2875C19.0292 21.0458 19 20.7833 19 20.5C19 20.2333 19.025 19.975 19.075 19.725C19.125 19.475 19.2083 19.2333 19.325 19H10C9.56667 19 9.20833 19.1458 8.925 19.4375C8.64167 19.7292 8.5 20.0833 8.5 20.5C8.5 20.9333 8.64167 21.2917 8.925 21.575C9.20833 21.8583 9.56667 22 10 22Z"
        fill="#044257"
      />
    </svg>
  )
}

export default DairyIconActive
