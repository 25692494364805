import requester from "api/requester"
import {
  IMetricType,
  IOnboardingAPI,
  IOnboardingPayload,
  IOnboardingServerPayload,
} from "./types"
import { kgToGram } from "utils"
import { MAINTAIN_WEIGHT_TYPES } from "config"

const convertToServerOnboardingPayload = (
  payload: IOnboardingPayload,
  metricType: IMetricType,
): IOnboardingServerPayload => {
  return {
    type_name: payload.typeName,
    activity_level: payload.active,
    weight_change_per_week:
      metricType === "METRIC"
        ? kgToGram(payload.weeklyGoal)
        : payload.weeklyGoal,
    target_weight:
      metricType === "METRIC"
        ? kgToGram(payload.targetWeight)
        : payload.targetWeight,
  }
}

const onboardingAPI: IOnboardingAPI = {
  onboarding(payload, metricType) {
    const model: any = convertToServerOnboardingPayload(payload, metricType)
    if (MAINTAIN_WEIGHT_TYPES.includes(model.type_name)) {
      delete model.weight_change_per_week
      delete model.target_weight
    }

    return requester.post("/goals", model)
  },

  getGoal() {
    return requester.get("/goal")
  },

  getUser() {
    return requester.get("/users/info")
  },

  updateUser(payload) {
    return requester.patch("/users/info", {
      weight: payload.weight,
      height: payload.height,
      measurement_system: payload.metricType,
    })
  },
}

export default onboardingAPI
