import { Popup } from "antd-mobile";
import styled from "styled-components";

export const StyledPopup = styled(Popup)`
  @media screen and (min-width: 500px) {
    .adm-mask,
    .adm-popup-body {
      max-width: 500px;
      left: 50%;
      transform: translateX(-50%) !important;
    }
  }

  .adm-popup-body {
    background: var(--Schemes-Surface-Container-Lowest, #fff);
    padding: var(--spacing_m, 12px) var(--spacing_l, 16px);
    display: flex;
    justify-content: center;
  }
`
