import {
  FoodDetailsContent,
  FoodDetailsHeader,
  FoodDetailsInfo,
  FoodName,
  StyledFoodDetails,
} from "./styled"
import { BackButton } from "components/Common/BackButton"
import ChevronLeft from "components/Icons/ChevronLeft"
import { IAPIFood, IIngredientFood } from "features/food/foodTypes"
import NutrientInformation from "components/NutrientInformation"
import Button from "components/Buttons/Button"
import CtaBox from "components/Common/CtaBox"
import PortionUnitPickerFormItem from "components/PortionFormItemUnitPicker"
import { useUpdateApiFood } from "features/food/hooks/useUpdateApiFood"
import TrashIcon from "components/Icons/TrashIcon"
import FoodImage from "components/FoodImage"
import { useState } from "react"
import { useBackToHome, useLogMeal } from "features/meal/hooks/hooks"

interface IFoodDetailsProps {
  onBack: () => void
  food: IAPIFood | IIngredientFood
  onAdd?: (food: IAPIFood) => void
  onEdit?: (food: IIngredientFood) => void
  onDelete?: (index: number) => void
  mode: "edit" | "add" | "log"
  foodIndex?: number
  editOnly?: boolean
}

const APIFoodDetails = (props: IFoodDetailsProps) => {
  const { onBack, food, onAdd, onEdit, onDelete, mode, foodIndex, editOnly } =
    props
  const {
    food: localFood,
    onPortionChange,
    onUnitChange,
  } = useUpdateApiFood(food)

  const [isPortionValid, setIsPortionValid] = useState(true)

  const onPortionChangeHandler = (value: number) => {
    onPortionChange(value)
    setIsPortionValid(true)
  }
  const backToHome = useBackToHome()
  const { handleLogMealByFood, logMealLoading } = useLogMeal(backToHome)

  return (
    <StyledFoodDetails>
      <FoodDetailsContent>
        <FoodImage previewImageUrl={food.previewImageUrl}>
          <FoodDetailsHeader>
            <BackButton onClick={onBack}>
              <ChevronLeft />
            </BackButton>
          </FoodDetailsHeader>
        </FoodImage>

        <FoodDetailsInfo>
          <FoodName>{localFood.name}</FoodName>

          <PortionUnitPickerFormItem
            unitValue={localFood.unit}
            portionValue={localFood.portion}
            onPortionChange={onPortionChangeHandler}
            onUnitChange={onUnitChange}
            unitOptions={localFood.units}
            setIsPortionValid={setIsPortionValid}
          />

          <NutrientInformation
            calorie={localFood.calorie}
            fat={localFood.fat}
            protein={localFood.protein}
            carbohydrate={localFood.carbohydrate}
          />
        </FoodDetailsInfo>

        {mode === "edit" ? (
          <CtaBox
            style={{ marginTop: "auto", paddingTop: 12, flexDirection: "row" }}
          >
            {!editOnly && (
              <Button
                onClick={() => onDelete?.(foodIndex ?? -1)}
                $type="default"
                $isDeleteBtn
                style={{
                  padding: "var(--spacing_m, 12px) var(--spacing_l, 16px)",
                }}
              >
                <TrashIcon color="#FF4921" />
              </Button>
            )}

            <Button
              disabled={!isPortionValid}
              style={{ width: "100%" }}
              $type="primary"
              onClick={() => {
                onEdit?.(localFood as IIngredientFood)
                onBack()
              }}
            >
              Save
            </Button>
          </CtaBox>
        ) : mode === "log" ? (
          <CtaBox style={{ marginTop: "auto", paddingTop: 12 }}>
            <Button
              onClick={() => handleLogMealByFood(localFood)}
              style={{ width: "100%" }}
              $type="primary"
              disabled={!isPortionValid}
              loading={logMealLoading}
            >
              Log this meal
            </Button>
          </CtaBox>
        ) : (
          <CtaBox style={{ marginTop: "auto", paddingTop: 12 }}>
            <Button
              onClick={() => {
                onAdd?.(localFood as IAPIFood)
                onBack()
              }}
              disabled={!isPortionValid}
              style={{ width: "100%" }}
              $type="primary"
            >
              Add this ingredient
            </Button>
          </CtaBox>
        )}
      </FoodDetailsContent>
    </StyledFoodDetails>
  )
}

export default APIFoodDetails
