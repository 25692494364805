import { FlexBox } from "components/Common/FlexBox"
import styled from "styled-components"

export const YourGoalContent = styled.div`
  --cta-height: 80px;
  height: calc(100% - var(--cta-height) - var(--header-height));
  padding: 24px 16px 0 16px;
  background: var(--Schemes-Surface-Container-Low, #f9fbfc);
  display: flex;
  flex-direction: column;
  gap: var(--spacing_l, 16px);
  overflow-y: auto;
`

export const StyledGoalOverview = styled.div`
  display: flex;
  padding: var(--spacing_l, 16px);
  flex-direction: column;
  align-items: center;
  gap: 8px;
`

export const PlanKg = styled.div`
  font-size: 32px;
  font-weight: 700;
  line-height: 120%;
`

export const YourGoalOverview = styled.div`
  display: flex;
  padding: var(--spacing_l, 16px);
  flex-direction: column;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 0.8px solid var(--Schemes-Outline-Variant, rgba(0, 38, 58, 0.14));
  background: var(--Schemes-Surface-Container-Lowest, #fff);

  svg {
    transform: rotate(180deg);
  }
`

export const PlanStartTime = styled.p`
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
  text-align: center;
`

export const DailyNutrition = styled.div`
  display: flex;
  padding: var(--spacing_l, 16px) var(--spacing_xl, 24px);
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  border-radius: 8px;
  border: 0.8px solid var(--Schemes-Outline-Variant, rgba(0, 38, 58, 0.14));
  background: var(--Schemes-Surface-Container-Lowest, #fff);

  svg {
    transform: rotate(180deg);
  }
`

export const PlanDescription = styled.p`
  font-size: 16px;
  text-align: center;
  width: 345px;
`

export const PlanDescriptionStrong = styled.span`
  font-size: 16px;
  font-weight: 500;
  line-height: 120%;
`

export const DailyNutritionTitle = styled.div`
  font-size: 16px;
  font-weight: 800;
  line-height: 140%;
  text-transform: uppercase;
`

export const DailyText = styled.div`
  font-size: 14px;
`

export const NutritionBox = styled(FlexBox)`
  padding: 10px 0;
  gap: 12px;
  flex-direction: column;
  width: 100%;
`

export const StyledNutrition = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`
export const NutritionValue = styled.div`
  font-size: 14px;
  font-weight: 700;
`

export const NutritionPercent = styled.div<{
  $percent: number
  $color: string
}>`
  position: relative;
  width: 100%;
  height: 6px;
  border-radius: 99px;
  background-color: var(--Schemes-Outline-Variant, rgba(0, 38, 58, 0.14));

  &::after {
    content: "";
    position: absolute;
    left: -0.5px;
    height: 100%;
    width: ${({ $percent }) => $percent + 0.5}%;
    background-color: ${({ $color }) => $color};
    border-radius: 99px;
  }
`

export const YourGoalBmr = styled.div`
  display: flex;
  flex-direction: column;
  padding: var(--spacing_l, 16px);
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 0.8px solid var(--Schemes-Outline-Variant, rgba(0, 38, 58, 0.14));
  background: var(--White, #fff);
  gap: 4px;
`

export const BmrTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
`

export const BmrValue = styled.div`
  font-size: 18px;
  font-weight: 700;
  line-height: 140%;
`

export const BmrDescription = styled.div`
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  text-align: center;
`
