import logo from "../assets/images/logo.jpg"

const KagamiAvatar = ({ size = "normal" }: { size?: string }) => {
  const containerWidth = size === "normal" ? 24 : 40
  const imageWidth = size === "normal" ? 20 : 33
  const imageHeight = size === "normal" ? 20 : 31
  return (
    <div
      style={{
        width: containerWidth,
        height: containerWidth,
        borderRadius: "50%",
        overflow: "hidden",
        background: "#fff",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexShrink: 0,
    
      }}
    >
      <img
        src={logo}
        style={{
          width: imageWidth,
          height: imageHeight,
        }}
      />
    </div>
  )
}

export default KagamiAvatar
