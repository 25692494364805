import { FOOD_DETAILS_BODY_ID } from "config"
import { useEffect, useRef, useState } from "react"

const IMAGE_HEIGHT = 190
const HEADER_HEIGHT = 44

export const useFoodTopNavPosition = (forceReset: boolean) => {
  const [position, setPosition] = useState<"fixed" | "absolute">("fixed")
  const [width, setWidth] = useState<string>("100%")
  const ticking = useRef<any>()

  useEffect(() => {
    const bodyElement = document.getElementById(FOOD_DETAILS_BODY_ID)

    if (!bodyElement) return

    setWidth(`${bodyElement.clientWidth}px`)

    const handleScroll = () => {
      if (!ticking.current) {
        window.requestAnimationFrame(() => {
          ticking.current = false

          const isOverHeader =
            bodyElement.scrollTop > IMAGE_HEIGHT - HEADER_HEIGHT

          if (isOverHeader) {
            position === "fixed" && setPosition("absolute")
          } else {
            position === "absolute" && setPosition("fixed")
          }
        })
      }

      ticking.current = true
    }

    bodyElement.addEventListener("scroll", handleScroll)

    return () => {
      bodyElement.removeEventListener("scroll", handleScroll)
    }
  }, [position, forceReset])

  return { position, width }
}

