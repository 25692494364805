import styled from "styled-components"

export const FoodImageOverlay = styled.div`
  background: linear-gradient(180deg, rgba(27, 20, 1, 0) 0%, #1b1401 100%);
  position: absolute;
  inset: 0px;
`
export const CloseReviewBtn = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 100000;
`
