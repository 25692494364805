import styled from "styled-components"

export const StyledCreateFoodForm = styled.div`
  height: calc(100% - var(--header-height) - var(--cta-box-height));
  padding: 12px 16px 0px 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow-y: auto;
`

export const FormItemLabel = styled.div`
  font-size: 14px;
  font-weight: 700;
  line-height: 160%;
  margin-bottom: 12px;

  span {
    font-weight: 400;
  }
`
