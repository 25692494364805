import { createSlice } from "@reduxjs/toolkit"
import onboardingAPI from "./onboardingAPI"
import { createAppAsyncThunk } from "app/hooks"
import {
  IOnboardingState,
  IOnboardingPayload,
  IUser,
  IGoal,
  IUserResponse,
  IUpdateUserPayload,
} from "./types"
import {
  formatBirthedOn,
  getFtToDisplay,
  getKgToDisplay,
  titleCase,
  mmToCm,
  gramToKg,
  getCmToDisplay,
  getPoundToDisplay,
  is404Response,
} from "utils"
import { resetStoreAction } from "config"

const convertResponseToClientGoal = (responseGoal: any): IGoal => {
  return {
    caloriesPerDay: responseGoal.caloriePerDay,
    protein: responseGoal.pfcBalance.protein,
    fat: responseGoal.pfcBalance.fat,
    carb: responseGoal.pfcBalance.carb,
    targetWeight: responseGoal.targetWeight,
    weightChangePerWeek: responseGoal.weightChangePerWeek,
    typeName: responseGoal.typeName,
    reachedOn: responseGoal.reachedOn,
    bmr: responseGoal.bmr,
    startDate: responseGoal.createdAt,

    proteinGram: responseGoal.pfc.protein,
    fatGram: responseGoal.pfc.fat,
    carbsGram: responseGoal.pfc.carb,
  }
}

const convertUserResponseToClientUser = (response: IUserResponse): IUser => {
  const {
    gender,
    birthedOn,
    height,
    weight,
    measurementSystem: metricType,
    showTutorial: isShowTutorial,
  } = response

  const clientHeight = metricType === "METRIC" ? mmToCm(height) : height
  const clientWeight = metricType === "METRIC" ? gramToKg(weight) : weight
  const user: IUser = {
    gender: titleCase(gender),
    birthedOn: formatBirthedOn(birthedOn),
    height: clientHeight,
    weight: clientWeight,
    metricType: metricType ?? "IMPERIAL",
    cmHeight: getCmToDisplay(clientHeight, metricType) + " cm",
    feetHeight: getFtToDisplay(clientHeight, metricType) + " ft",
    kgWeight: getKgToDisplay(clientWeight, metricType) + " kg",
    poundWeight: getPoundToDisplay(clientWeight, metricType) + " lb",
    isShowTutorial,
  }

  return user
}

export const getUser = createAppAsyncThunk(
  "onboarding/getUser",
  async (isRevalidated: boolean, { rejectWithValue }) => {
    try {
      const response = await onboardingAPI.getUser()
      const user = convertUserResponseToClientUser(response)
      return {
        user,
        isRevalidated,
      }
    } catch (err: any) {
      return rejectWithValue(err)
    }
  },
)

export const updateUser = createAppAsyncThunk(
  "onboarding/updateUser",
  async (payload: IUpdateUserPayload, { rejectWithValue }) => {
    try {
      const response = await onboardingAPI.updateUser(payload)
      return convertUserResponseToClientUser(response)
    } catch (err: any) {
      return rejectWithValue(err)
    }
  },
)

export const getGoal = createAppAsyncThunk(
  "onboarding/getGoal",
  async (isRetried: boolean, { rejectWithValue, dispatch }) => {
    try {
      const response = await onboardingAPI.getGoal()
      return response ? convertResponseToClientGoal(response) : null
    } catch (err: any) {
      if (!isRetried && !is404Response(err)) {
        setTimeout(() => {
          dispatch(getGoal(true))
        }, 1000)
      } else {
        return rejectWithValue({ ...err, isRetried: true })
      }
    }
  },
)

export const onboarding = createAppAsyncThunk(
  "onboarding/onboarding",
  async (payload: IOnboardingPayload, { rejectWithValue, getState }) => {
    try {
      const metricType = getState().onboarding.metricType
      const response = await onboardingAPI.onboarding(payload, metricType)
      return response ? convertResponseToClientGoal(response) : null
    } catch (err: any) {
      return rejectWithValue(err)
    }
  },
)

const initialState: IOnboardingState = {
  onboardingLoading: false,
  onboardingFailed: null,
  onboardingSuccess: null,

  getGoalLoading: false,
  goal: undefined,
  getGoalFailed: null,

  getUserLoading: false,
  user: undefined,
  getUserFailed: null,

  metricType: "METRIC",

  updateUserLoading: false,
  updateUserFailed: null,
  updateUserSuccess: null,

  isRevalidatedShouldShowTutorial: false,

  isTutorialShowed: false,
}

export const onboardingSlice = createSlice({
  name: "onboarding",
  initialState,
  reducers: {
    resetOnboardingState: (state) => {
      state.onboardingSuccess = undefined
      state.onboardingFailed = undefined
    },

    updateMetricType: (state, { payload }) => {
      state.metricType = payload
    },

    setIsTutorialShowed: (state, { payload }) => {
      state.isTutorialShowed = payload
    },
  },
  extraReducers(builder) {
    builder
      .addCase(onboarding.pending, (state) => {
        state.onboardingLoading = true
      })
      .addCase(onboarding.fulfilled, (state, { payload }) => {
        state.onboardingLoading = false
        state.onboardingSuccess = payload
        state.goal = payload
      })
      .addCase(onboarding.rejected, (state, { payload }) => {
        state.onboardingLoading = false
        state.onboardingFailed = payload
      })

      .addCase(getGoal.pending, (state) => {
        state.getGoalLoading = true
      })
      .addCase(getGoal.fulfilled, (state, { payload }) => {
        if (payload === undefined) {
          return
        }

        state.getGoalLoading = false
        state.goal = payload ?? null
      })
      .addCase(getGoal.rejected, (state, { payload }: any) => {
        if (!payload.isRetried) {
          return
        }

        state.getGoalLoading = false
        state.getGoalFailed = payload
        state.goal = is404Response(payload) ? null : undefined
      })

      .addCase(getUser.pending, (state) => {
        state.getUserLoading = true
        state.getGoalFailed = undefined
      })
      .addCase(getUser.fulfilled, (state, { payload }) => {
        state.getUserLoading = false
        state.user = payload.user
        state.metricType = payload.user.metricType
        if (payload.isRevalidated) {
          state.isRevalidatedShouldShowTutorial = true
        }
      })
      .addCase(getUser.rejected, (state, { payload }) => {
        state.getUserLoading = false
        state.user = {
          height: 0,
          weight: 0,
          cmHeight: "0 cm",
          kgWeight: "0 kg",
          feetHeight: "0 ft",
          poundWeight: "0 lb",
          gender: "",
          birthedOn: "",
          metricType: "METRIC",
          isShowTutorial: true,
        }
      })

      .addCase(updateUser.pending, (state) => {
        state.updateUserLoading = true
      })
      .addCase(updateUser.fulfilled, (state, { payload }) => {
        state.updateUserLoading = false
        state.updateUserSuccess = payload
        state.user = payload
      })
      .addCase(updateUser.rejected, (state, { payload }) => {
        state.updateUserLoading = false
        state.updateUserFailed = payload
      })

      .addCase(resetStoreAction, () => {
        return initialState
      })
  },
})

export const { resetOnboardingState, updateMetricType, setIsTutorialShowed } =
  onboardingSlice.actions

export default onboardingSlice.reducer
