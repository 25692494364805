import React from "react"

const ProfileActiveIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="29"
      height="28"
      viewBox="0 0 29 28"
      fill="none"
    >
      <g id="icon/Tab Bar">
        <path
          id="Union"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.4999 6C12.1823 6 10.3035 7.87881 10.3035 10.1964C10.3035 12.5141 12.1823 14.3929 14.4999 14.3929C16.8176 14.3929 18.6964 12.5141 18.6964 10.1964C18.6964 7.87881 16.8176 6 14.4999 6ZM7.5 21.9812C7.5 22.4508 7.84972 22.8387 8.30294 22.8988C8.33377 22.9045 8.36556 22.9075 8.39805 22.9075H20.606C20.643 22.9075 20.6788 22.9037 20.7133 22.8965C21.1586 22.8295 21.5 22.4452 21.5 21.9812C21.5 18.1152 18.366 14.9812 14.5 14.9812C10.634 14.9812 7.5 18.1152 7.5 21.9812Z"
          fill="#044257"
        />
      </g>
    </svg>
  )
}

export default ProfileActiveIcon
