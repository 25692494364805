import { ReactComponentElement, ReactNode } from "react"
import {
  StyledOnboardingOption,
  OnboardingOptionTitle,
  OnboardingOptionDescription,
  ActiveList,
} from "./styled"

export interface IOnboardingOption {
  label: string
  description: string
  value: string
  Icon: any
}

const OnboardingOption = ({
  option,
  isSelected = false,
  onChange,
}: {
  option: IOnboardingOption
  isSelected?: boolean
  onChange: (value: string) => void
}) => {
  const Icon = option.Icon
  return (
    <StyledOnboardingOption
      $active={isSelected}
      onClick={() => onChange(option.value)}
    >
      <Icon isSelected={isSelected} />

      <div style={{ color: isSelected ? "#fff" : "inherit" }}>
        <OnboardingOptionTitle>{option.label}</OnboardingOptionTitle>
        <OnboardingOptionDescription>
          {option.description}
        </OnboardingOptionDescription>
      </div>
    </StyledOnboardingOption>
  )
}

const OnboardingOptions = ({
  options,
  value,
  onChange,
}: {
  options: IOnboardingOption[]
  value: string
  onChange: (value: string) => void
}) => {
  return (
    <ActiveList>
      {options.map((option) => (
        <OnboardingOption
          key={option.value}
          option={option}
          onChange={onChange}
          isSelected={option.value === value}
        />
      ))}
    </ActiveList>
  )
}

export default OnboardingOptions
