import styled from "styled-components"

export type IJustify =
  | "flex-start"
  | "center"
  | "flex-end"
  | "space-between"
  | "space-around"
  | "space-evenly"

export type IAlignItems =
  | "stretch"
  | "center"
  | "flex-start"
  | "flex-end"
  | "baseline"

export type IDirection = "row" | "column"

export const FlexBox = styled.div<{
  $gap?: number
  $justify?: IJustify
  $alignItems?: IAlignItems
  $direction?: IDirection
}>`
  display: flex;
  gap: ${({ $gap }) => $gap ?? 0}px;
  justify-content: ${({ $justify }) => $justify ?? "flex-start"};
  align-items: ${({ $alignItems }) => $alignItems ?? "stretch"};
  flex-direction: ${({ $direction }) => $direction ?? "row"};
`
