import styled from "styled-components"

export const SuggestedTextsWrapper = styled.div`
  margin-top: 8px;
`

export const SuggestedTextsTitle = styled.div`
  font-size: 14px;
  font-weight: 700;
  line-height: 160%;
  padding: 0 8px;
`

export const SuggestedTextList = styled.div`
  padding: 0 16px;
`

export const SuggestedTextItem = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 16px;

  color: #000;
  font-size: 14px;
  line-height: 160%;
  cursor: default;

  .high-light-text {
    background-color: #fff;
    font-weight: 600;
  }
`

export const SuggestedSearchIconBox = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: var(--Schemes-Surface-Container-Low, #f5f7f7);
`
