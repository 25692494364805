import PATHS from "router/paths"
import {
  FoodResultsWrapper,
  FoodResultsHeader,
  CreateNewMealLink,
  FoodResultsTitle,
  FoodResultsBox,
  CreateNotFoundFoodLink,
} from "./styled"
import { IFood } from "features/food/foodTypes"
import FoodListItem from "components/FoodListItem"
import { FoodListDivider } from "components/FoodListItem/styled"
import { DotLoading, InfiniteScroll } from "antd-mobile"
import { FlexBox } from "components/Common/FlexBox"
import { Fragment } from "react"
import NoFoodFound from "./NoFoodFound"
import PlusIconLight from "components/Icons/PlusIconLight"
import Highlighter from "react-highlight-words"

interface IFoodResultsProps {
  foods: IFood[]
  onLoad: () => any
  loading: boolean
  hasMore: boolean
  keyword: string
  hasCreateLink?: boolean
  onSelectFood?: (food: IFood) => void
  onAddFood?: (food: IFood) => void
  noSearchedFoodFounded: boolean
  noCreateLinkIfNoFoodFounded?: boolean
}

const SearchFoodResults = (props: IFoodResultsProps) => {
  const {
    noSearchedFoodFounded,
    noCreateLinkIfNoFoodFounded,
    foods,
    onLoad,
    loading,
    hasMore,
    keyword,
    hasCreateLink,
    onSelectFood,
    onAddFood,
  } = props

  const isFirstLoading = !foods.length && loading

  return (
    <FoodResultsWrapper>
      {isFirstLoading ? (
        <FoodResultsHeader>
          <FoodResultsTitle style={{ fontWeight: 500 }}>
            Searching...
          </FoodResultsTitle>
        </FoodResultsHeader>
      ) : noSearchedFoodFounded ? (
        <FlexBox $direction="column" $alignItems="center" $gap={14}>
          <NoFoodFound />
          {!noCreateLinkIfNoFoodFounded && (
            <CreateNotFoundFoodLink
              to={`${PATHS.app.food.create}?name=${keyword}`}
            >
              <PlusIconLight />
              <Highlighter
                highlightClassName="no-food-high-light-text"
                searchWords={keyword.split(" ")}
                textToHighlight={`Create "${keyword}" as a custom meal`}
              ></Highlighter>
            </CreateNotFoundFoodLink>
          )}
        </FlexBox>
      ) : (
        <Fragment>
          <FoodResultsHeader>
            <FoodResultsTitle>Results</FoodResultsTitle>
            {hasCreateLink && (
              <CreateNewMealLink to={PATHS.app.food.create}>
                Create New Meal
              </CreateNewMealLink>
            )}
          </FoodResultsHeader>

          <FoodResultsBox>
            {foods.map((food, index) => (
              <div key={food.id}>
                <FoodListItem
                  food={food}
                  keyword={keyword}
                  onSelect={onSelectFood}
                  onAdd={onAddFood}
                />
                {index !== foods.length - 1 && <FoodListDivider />}
              </div>
            ))}

            {loading && (
              <FlexBox
                style={{ height: 40, width: "100%" }}
                $justify="center"
                $alignItems="center"
              >
                {" "}
                <span>Loading more food</span>
                <DotLoading />
              </FlexBox>
            )}
            <InfiniteScroll loadMore={onLoad} hasMore={hasMore}>
              {!foods.length ? null : hasMore ? null : "All foods founded"}
            </InfiniteScroll>
          </FoodResultsBox>
        </Fragment>
      )}
    </FoodResultsWrapper>
  )
}

export default SearchFoodResults
