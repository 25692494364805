import { Fragment, ReactNode } from "react"

import { StepTitle } from "../styled"

import LightlyActive from "./Icons/LightlyActive"
import ActiveIcon from "./Icons/ActiveIcon"
import VeryActive from "./Icons/VeryActive"
import OnboardingOptions, {
  IOnboardingOption,
} from "components/OnboardingOptions"
import { Title24 } from "components/Typo"
import { ActiveWrapper } from "./styled"
import { TitleBox } from "features/onboarding/PersonalInfo/styled"
import { IActive } from "features/onboarding/types"

const options: IOnboardingOption[] = [
  {
    label: "Lightly Active",
    description: "Sedentary lifestyle",
    value: "LIGHTLY_ACTIVE",
    Icon: LightlyActive,
  },
  {
    label: "Active",
    description: "Occasionally active",
    value: "ACTIVE",
    Icon: ActiveIcon,
  },
  {
    label: "Very Active",
    description: "Exercise almost every day",
    value: "VERY_ACTIVE",
    Icon: VeryActive,
  },
]

const Active = ({
  value,
  onChange,
}: {
  value: IActive
  onChange: (value: IActive) => void
}) => {
  return (
    <ActiveWrapper>
      <TitleBox>
        <Title24>What is your current level of activity?</Title24>
      </TitleBox>

      <OnboardingOptions
        options={options}
        onChange={(val) => onChange(val as IActive)}
        value={value}
      />
    </ActiveWrapper>
  )
}

export default Active
