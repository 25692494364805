import { Tabs } from "antd-mobile"
import { IMeal } from "features/meal/types"
import styled from "styled-components"
import { getDisplayCalories } from "utils"
import { IMostMeal, INutritionType } from "../types"

const MostMealsWrapper = styled.div`
  margin-top: 16px;
  background: #fff;

  .adm-tabs-tab-wrapper {
    padding: 0px;
  }

  .adm-tabs-tab {
    margin: 0 10px;
    font-size: 14px;
    font-weight: 600;
    line-height: 160%;
    padding-top: 12px;
    padding-bottom: 12px;
    color: var(--Light-Text-Secondary, rgba(76, 78, 100, 0.68));
  }

  .adm-tabs-tab.adm-tabs-tab-active {
    color: #0fbdd4;
  }

  .adm-tabs-content {
    padding: 0;
  }
`

const Title = styled.div`
  display: flex;
  height: 48px;
  align-items: center;
  border-bottom: 1px solid var(--Schemes-Outline-Variant, rgba(0, 38, 58, 0.14));

  padding: 0 6px;
`

const MealWrapper = styled.div`
  --order-width: 37px;
  --calories-width: 85px;

  display: flex;
  align-items: center;
  padding-left: 4px;
  padding-right: 8px;
  height: 48px;
  padding: 4px;
  border-bottom: 1px solid var(--Schemes-Outline-Variant, rgba(0, 38, 58, 0.14));
`

const MealOrder = styled.div`
  width: 37px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  padding: 8px;
  font-size: 14px;
`

const MealName = styled.div`
  font-size: 14px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: calc(100% - var(--order-width) - var(--calories-width));
`
const MealCalories = styled.div`
  width: var(--calories-width);
  font-size: 14px;
  text-align: right;
  padding: 8px;
  flex-shrink: 0;
`

export const NoDataText = styled.div`
  height: 46px;
  padding: 8px var(--spacing_xxs, 4px) 8px var(--spacing_m, 12px);
  display: flex;
  align-items: center;
  font-size: 14px;
`

const getDisplayNutritionValue = (
  meal: IMeal,
  nutritionType: INutritionType,
) => {
  switch (nutritionType) {
    case "calories":
      return `${getDisplayCalories(meal.calorie)} cal`

    case "protein":
      return `${getDisplayCalories(meal.pfc.protein)} g`

    case "carbs":
      return `${getDisplayCalories(meal.pfc.carb)} g`

    case "fat":
      return `${getDisplayCalories(meal.pfc.fat)} g`

    default:
      return ""
  }
}

const MealItem = ({
  order,
  meal,
  nutritionType,
}: {
  order: number
  meal: IMeal
  nutritionType: INutritionType
}) => {
  return (
    <MealWrapper>
      <MealOrder>{order}</MealOrder>
      <MealName>{meal.name}</MealName>
      <MealCalories>
        {getDisplayNutritionValue(meal, nutritionType)}
      </MealCalories>
    </MealWrapper>
  )
}

const MostCalories = ({ mostMeals }: { mostMeals: IMostMeal[] }) => {
  return (
    <MostMealsWrapper>
      <Tabs
        defaultActiveKey="0"
        style={{
          "--active-title-color": "#0FBDD4",
          "--active-line-color": "#0FBDD4",
        }}
      >
        {mostMeals.map((mostMeal, index) => (
          <Tabs.Tab title={mostMeal.title} key={index}>
            {mostMeal.meals.map((meal, index) => (
              <MealItem
                key={index}
                order={index + 1}
                meal={meal}
                nutritionType={mostMeal.nutritionType}
              />
            ))}
            {mostMeal.meals.length === 0 && (
              <NoDataText>No data yet</NoDataText>
            )}
          </Tabs.Tab>
        ))}
      </Tabs>
    </MostMealsWrapper>
  )
}

export default MostCalories
