import {
  FoodDetailsBody,
  FoodDetailsContent,
  FoodDetailsHeader,
  FoodDetailsInfo,
  FoodName,
  IngredientList,
  StyledFoodDetails,
} from "./styled"
import { BackButton } from "components/Common/BackButton"
import ChevronLeft from "components/Icons/ChevronLeft"
import { IMyFood } from "features/food/foodTypes"
import NutrientInformation from "components/NutrientInformation"
import Button from "components/Buttons/Button"
import CtaBox from "components/Common/CtaBox"
import PortionUnitPickerFormItem from "components/PortionFormItemUnitPicker"
import { useUpdateFood } from "features/food/hooks/useUpdateApiFood"
import TrashIcon from "components/Icons/TrashIcon"
import FoodListItem from "components/FoodListItem"
import { FoodListDivider, FoodNameBox } from "components/FoodListItem/styled"
import FoodImage from "components/FoodImage"
import { TopNavWrapper } from "components/TopNavWrapper"
import { FOOD_DETAILS_BODY_ID } from "config"
import { isMyFood } from "utils"
import MyFoodIcon from "components/Icons/MyFood"
import { useState } from "react"
import { useBackToHome, useLogMeal } from "features/meal/hooks/hooks"

interface IFoodDetailsProps {
  onBack: () => void
  food: IMyFood
  onAdd?: (food: IMyFood) => void
  onEdit?: (food: IMyFood) => void
  onDelete?: (index: number) => void
  mode: "edit" | "add" | "log"
  foodIndex?: number
  editOnly?: boolean
}

const MyFoodDetails = (props: IFoodDetailsProps) => {
  const { onBack, food, onAdd, onEdit, onDelete, mode, foodIndex, editOnly } =
    props

  const backToHome = useBackToHome()
  const { handleLogMealByFood, logMealLoading } = useLogMeal(backToHome)
  const { food: localFood, onPortionChange } = useUpdateFood(food)

  const [isPortionValid, setIsPortionValid] = useState(true)

  const onPortionChangeHandler = (value: number) => {
    onPortionChange(value)
    setIsPortionValid(true)
  }

  if (!localFood) {
    return null
  }

  return (
    <StyledFoodDetails>
      <FoodDetailsContent>
        <FoodDetailsBody id={FOOD_DETAILS_BODY_ID}>
          <FoodImage previewImageUrl={localFood.previewImageUrl}>
            <TopNavWrapper>
              <FoodDetailsHeader>
                <BackButton
                  $disabled={logMealLoading}
                  onClick={() => {
                    if (!logMealLoading) {
                      onBack()
                    }
                  }}
                >
                  <ChevronLeft />
                </BackButton>
              </FoodDetailsHeader>
            </TopNavWrapper>
          </FoodImage>
          <FoodDetailsInfo>
            <FoodNameBox>
              <FoodName>{food.name}</FoodName>
              {isMyFood(food) ? <MyFoodIcon /> : null}
            </FoodNameBox>

            <PortionUnitPickerFormItem
              unitValue={localFood.unit}
              portionValue={localFood.portion}
              onPortionChange={onPortionChangeHandler}
              onUnitChange={() => {}}
              unitOptions={localFood.units}
              setIsPortionValid={setIsPortionValid}
            />

            <NutrientInformation
              calorie={localFood.calorie}
              fat={localFood.fat}
              protein={localFood.protein}
              carbohydrate={localFood.carbohydrate}
            />

            {localFood.ingredients?.length ? (
              <IngredientList>
                {" "}
                {localFood.ingredients.map((ingredient, index) => (
                  <div key={index}>
                    <FoodListItem
                      food={ingredient}
                      hideAddBtn
                      style={{ padding: "0 8px" }}
                    />
                    {index !== (food.ingredients?.length ?? 1) - 1 && (
                      <FoodListDivider />
                    )}
                  </div>
                ))}
              </IngredientList>
            ) : undefined}
          </FoodDetailsInfo>
        </FoodDetailsBody>
        {mode === "edit" ? (
          <CtaBox
            style={{ marginTop: "auto", paddingTop: 12, flexDirection: "row" }}
          >
            {!editOnly && (
              <Button
                onClick={() => onDelete?.(foodIndex ?? -1)}
                $type="default"
                $isDeleteBtn
                style={{
                  padding: "var(--spacing_m, 12px) var(--spacing_l, 16px)",
                }}
              >
                <TrashIcon color="#FF4921" />
              </Button>
            )}

            <Button
              style={{ width: "100%" }}
              $type="primary"
              onClick={() => {
                if (localFood) {
                  onEdit?.({
                    ...localFood,
                  } as IMyFood)
                  onBack()
                }
              }}
              disabled={!isPortionValid}
            >
              Save
            </Button>
          </CtaBox>
        ) : mode === "log" ? (
          <CtaBox style={{ marginTop: "auto", paddingTop: 12 }}>
            <Button
              onClick={() => handleLogMealByFood(localFood)}
              style={{ width: "100%" }}
              $type="primary"
              disabled={!isPortionValid}
              loading={logMealLoading}
            >
              Log this meal
            </Button>
          </CtaBox>
        ) : (
          <CtaBox style={{ marginTop: "auto", paddingTop: 12 }}>
            <Button
              onClick={() => {
                onAdd?.(localFood as IMyFood)
                onBack()
              }}
              style={{ width: "100%" }}
              $type="primary"
              disabled={!isPortionValid}
              loading={logMealLoading}
            >
              Add this Food
            </Button>
          </CtaBox>
        )}
      </FoodDetailsContent>
    </StyledFoodDetails>
  )
}

export default MyFoodDetails
