import { IAPIFood, IFood, IIngredientFood } from "features/food/foodTypes"
import { IMeal } from "features/meal/types"

export const getNutrientInformation = (ingredients: IFood[]) => {
  const calorie = ingredients.reduce((acc, i) => acc + i.calorie, 0)
  const fat = ingredients.reduce((acc, i) => acc + i.fat, 0)
  const protein = ingredients.reduce((acc, i) => acc + i.protein, 0)
  const carbohydrate = ingredients.reduce((acc, i) => acc + i.carbohydrate, 0)

  return { calorie, fat, protein, carbohydrate }
}

export const updateNutrientWhenUnitChange = (
  food: IAPIFood | IIngredientFood | IFood,
  unitLabel: string,
) => {
  const currentUnit = food.units?.find((unit) => unit.label === food.unit)
  const nextUnit = food.units?.find((unit) => unit.label === unitLabel)

  if (!nextUnit || !currentUnit) {
    return food
  }

  const percent = nextUnit.weight / currentUnit.weight
  const fat = food.fat * percent
  const protein = food.protein * percent
  const carbohydrate = food.carbohydrate * percent
  const calorie = food.calorie * percent

  return { ...food, calorie, fat, protein, carbohydrate, unit: unitLabel }
}

export const updateNutrientWhenPortionChange = (
  food: IFood | IAPIFood,
  portion: number,
) => {
  const percent = portion / food.portion
  const fat = food.fat * percent
  const protein = food.protein * percent
  const carbohydrate = food.carbohydrate * percent
  const calorie = food.calorie * percent

  return { calorie, fat, protein, carbohydrate, portion }
}

export const updateMealNutrientWhenUnitChange = (
  meal: IMeal,
  unitLabel: string,
) => {
  const currentUnit = meal.units?.find((unit) => unit.label === meal.unit)
  const nextUnit = meal.units?.find((unit) => unit.label === unitLabel)

  if (!nextUnit || !currentUnit) {
    return meal
  }

  const percent = nextUnit.weight / currentUnit.weight
  const { pfc } = meal

  const fat = pfc.fat * percent
  const protein = pfc.protein * percent
  const carb = pfc.carb * percent
  const calorie = meal.calorie * percent

  const nextIngredients = meal.ingredients?.map((ingredient) => {
    const nextIngredient = {
      ...ingredient,
    }

    nextIngredient.calorie = ingredient.calorie * percent
    nextIngredient.fat = ingredient.fat * percent
    nextIngredient.protein = ingredient.protein * percent
    nextIngredient.carbohydrate = ingredient.carbohydrate * percent
    nextIngredient.portion = ingredient.portion * percent

    return nextIngredient
  })

  return {
    ...meal,
    ingredients: nextIngredients,
    calorie,
    unit: unitLabel,
    pfc: {
      fat,
      protein,
      carb,
    },
  }
}

export const updateMealNutrientWhenPortionChange = (
  meal: IMeal,
  portion: number,
) => {
  const { pfc } = meal
  const percent = portion / meal.portion
  const fat = pfc.fat * percent
  const protein = pfc.protein * percent
  const carb = pfc.carb * percent
  const calorie = meal.calorie * percent

  return {
    calorie,
    portion,
    pfc: {
      fat,
      protein,
      carb,
    },
  }
}
