import styled from "styled-components"

export const StepTitle = styled.h1`
  font-family: proxima-nova, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  margin: 0;
  padding: 6px 0;
`

export const StepTitleStrong = styled.span`
  color: var(--color-primary-dark);
  font-size: 24px;
  font-weight: 700;
  line-height: 120%;
`

export const StepContent = styled.div`
  padding-top: 12px;
  display: flex;
  flex-direction: column;
  height: 100%;
`

export const VerticalFormSection = styled.div`
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  gap: 12px;
`

export const WeightErrorBox = styled.div`
  display: flex;
  padding: var(--spacing_m, 12px) var(--spacing_l, 16px);
  flex-direction: column;
  justify-content: center;

  border-radius: var(--spacing_s, 8px);
  background: var(--Schemes-Error-Container, #fbcac7);
  margin-top: auto;
  margin-bottom: 8px;
`

export const WeightErrorTitle = styled.div`
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 160%;
  color: var(--Schemes-Error, #b3261e);
`

export const WeightErrorText = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
`
