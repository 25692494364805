import styled from "styled-components"

export const StyledCreateMealLoader = styled.div`
  z-index: 100;
  width: 100%;
  background: #fff;
  position: absolute;
  bottom: 0;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 16px;
`
export const ScanningText = styled.div`
  color: var(--Schemes-On-Primary-Container, #044257);
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 160%; /* 25.6px */
`
