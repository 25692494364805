import { useAppSelector } from "app/hooks"
import { Fragment, useEffect } from "react"
import { Navigate, Outlet, useNavigate } from "react-router-dom"
import PATHS from "router/paths"

const AuthRoute = () => {
  const token = useAppSelector((state) => state.auth.token)

  const navigate = useNavigate()

  useEffect(() => {
    if (token) {
      navigate(PATHS.app.root)
    }
  }, [token])

  if (token) {
    return <Navigate to={PATHS.app.root} />
  }

  return (
    <Fragment>
      <Outlet />
    </Fragment>
  )
}

export default AuthRoute
