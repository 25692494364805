import React from "react"

const SmallChevronRight = ({ fill = "#1C1F21" }: { fill?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.28057 17.7599C8.93029 17.417 8.90448 16.8332 9.22291 16.456L12.9845 12L9.22291 7.54401C8.90448 7.16679 8.93029 6.58299 9.28057 6.24006C9.63085 5.89713 10.1729 5.92493 10.4914 6.30215L14.7771 11.3791C15.0743 11.7312 15.0743 12.2688 14.7771 12.6209L10.4914 17.6978C10.1729 18.0751 9.63085 18.1029 9.28057 17.7599Z"
        fill={fill}
      />
    </svg>
  )
}

export default SmallChevronRight
