import React, { useEffect, useRef, useState } from "react"

const animatedTime = 15

const getInitStyle = () => {
  return {
    opacity: 0.2,
    scale: 0.4,
  }
}
export const useAddedFoodAnimation = () => {
  const animatedCount = useRef(animatedTime)
  const [style, setStyle] = useState(getInitStyle())

  useEffect(() => {
    const animate = () => {
      animatedCount.current -= 1
      setStyle((style) => ({
        opacity: style.opacity + 0.8 / animatedTime,
        scale: style.scale + 0.8 / animatedTime,
      }))

      if (animatedCount.current > 0) {
        requestAnimationFrame(animate)
      }
    }

    animate()
  }, [])

  return style
}
