import { PasscodeInput } from "antd-mobile"
import styled from "styled-components"

export const MfaBody = styled.div`
  padding: 24px 16px 64px 16px;
  color: var(--color-navy);
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const MfaTitle = styled.div`
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
  line-height: 120%;
`

export const MfaDescription = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 160%;

  span {
    font-weight: 700;
  }
`

export const PassCode = styled(PasscodeInput)`
  border: none !important;

  &.adm-passcode-input-focused {
    outline: none !important;
    box-shadow: none !important;
  }

  .adm-passcode-input-cell-container {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 4px;

    .adm-passcode-input-cell {
      display: flex;
      width: 42px;
      padding: 10px 16px;

      justify-content: center;
      align-items: center;
      gap: 2px;
      align-self: stretch;

      border-radius: 8px;
      background: #fff;

      font-size: 16px;
      font-weight: 700;
      line-height: 160%;
      height: 48px;
      border: 1px solid transparent;

      &.adm-passcode-input-cell-focused {
        border: 1px solid var(--color-primary);
      }

      &.adm-passcode-input-cell-caret::after {
        content: "";
        width: 1px;
        height: 18px;
        margin-left: 1px;
        background: #69757d;
        animation: 1s linear infinite adm-caret-blink;
      }
    }
  }
`
