import styled from "styled-components"

export const StyledPortionFormItem = styled.div`
  display: flex;
  padding: var(--spacing_s, 8px) var(--spacing_m, 12px);
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-radius: 8px;
  background-color: #fff;

  outline: 0.5px solid var(--Schemes-Outline, rgba(0, 38, 58, 0.32));

  @supports (-webkit-touch-callout: none) {
    outline: 1px solid var(--Schemes-Outline, rgba(0, 38, 58, 0.32)) !important;
  }
`

export const PortionLabel = styled.div`
  font-size: 16px;
`

export const StyledPortionInput = styled.input`
  width: 104px;
  padding: 10px var(--spacing_l, 16px);
  text-align: center;
  font-size: 14px;
  height: 40px;

  border-radius: var(--spacing_s, 8px);
  border: 1px solid var(--Schemes-Outline-Variant, rgba(0, 38, 58, 0.14));
  background: var(--Schemes-Surface-Container-Lowest, #fff);
  color: var(--Schemes-Primary, #0da3ba);

  &,
  &:focus,
  &:visited,
  &:active {
    outline: none;
    box-shadow: none;
  }

  &::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: var(--color-navy);
    opacity: 0.36;
    font-family: proxima-nova;
  }
  &::-moz-placeholder {
    /* Firefox 19+ */
    color: var(--color-navy);
    opacity: 0.36;
    font-family: proxima-nova;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type="number"] {
    -moz-appearance: textfield;
  }
`
