import styled, { createGlobalStyle } from "styled-components"

export const StyledPortionFormItem = styled.div`
  display: flex;
  padding: var(--spacing_s, 8px) var(--spacing_m, 12px);
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-radius: 8px;
  background-color: #fff;

  outline: 0.5px solid var(--Schemes-Outline, rgba(0, 38, 58, 0.32));

  @supports (-webkit-touch-callout: none) {
    outline: 1px solid var(--Schemes-Outline, rgba(0, 38, 58, 0.32)) !important;
  }
`

export const PortionLabel = styled.div`
  font-size: 16px;
`

export const StyledPortionInput = styled.input`
  width: 104px;
  padding: 10px var(--spacing_l, 16px);
  text-align: center;
  font-size: 14px;
  height: 40px;

  border-radius: var(--spacing_s, 8px);
  border: 1px solid var(--Schemes-Outline-Variant, rgba(0, 38, 58, 0.14));
  background: var(--Schemes-Surface-Container-Lowest, #fff);
  color: var(--Schemes-Primary, #0da3ba);

  &,
  &:focus,
  &:visited,
  &:active {
    outline: none;
    box-shadow: none;
  }

  &::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: var(--color-navy);
    opacity: 0.36;
    font-family: proxima-nova;
  }
  &::-moz-placeholder {
    /* Firefox 19+ */
    color: var(--color-navy);
    opacity: 0.36;
    font-family: proxima-nova;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type="number"] {
    -moz-appearance: textfield;
  }
`

export const PickerPopupStyle = createGlobalStyle`

 @media screen and (min-width: 500px) {
 .picker-popup {
   .adm-mask,
   .adm-popup-body {
     max-width: 500px;
     left: 50%;
     transform: translateX(-50%) !important;
     opacity: !important;
   };
 }
 };
 
 .picker-popup {
   .adm-picker-header-button {
   font-size: 14px;
   font-style: normal;
   font-weight: 700;
   line-height: 14px; /* 100% */
   letter-spacing: 0.14px;
   color: var(--Schemes-Primary, #0DA3BA);
   padding-top:14px;
   }
 
   .adm-picker-view-column-item {
     font-size: 16px;
     font-style: normal;
     font-weight: 600;
     line-height: 160%;
     color: var(--Schemes-On-Primary-Container, #044257);
   }
 
   .adm-picker-view-column-item-active {
     font-size: 20px;
     font-style: normal;
     font-weight: 800;
     line-height: 120%;
     color: var(--Schemes-On-Surface, #1C1F21);
   }
 }
 `
export const PickerHeader = styled.div`
  position: absolute;
  top: 6px;
  width: 36px;
  height: var(--spacing_xxs, 4px);
  border-radius: var(--spacing_l, 16px);
  background: var(--Schemes-Outline-Variant, rgba(0, 38, 58, 0.14));
  transform: translateX(-50%);
  left: 50%;
`

export const DisabledUnitText = styled.div`
  color: var(--Schemes-On-Surface-Variant, #69757d);
`
