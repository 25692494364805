import FullPageLoading from "components/Layouts/FullPageLoading"
import { useEffect } from "react"
import { isInWebView, useLoginNativeApp } from "./hooks"
import styled from "styled-components"
import { isIOSWebView } from "utils"
import { Navigate } from "react-router-dom"

const PageWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const PageTitle = styled.div`
  font-size: 24px;
  font-weight: 500;
`

const LoginInNativeApp = () => {
  const { handleLoginInNativeApp, loginFromAppFailed } = useLoginNativeApp()

  useEffect(() => {
    if (isIOSWebView()) {
      ;(window as any).handleLoginInNativeApp = handleLoginInNativeApp
    }
  }, [])

  if (!isIOSWebView()) {
    return <Navigate to="/auth" />
  }

  if (loginFromAppFailed) {
    return (
      <PageWrapper>
        <PageTitle>Invalid Token</PageTitle>
      </PageWrapper>
    )
  }

  return <FullPageLoading />
}

export default LoginInNativeApp
