import requester from "api/requester"
import { IDailyScanProgress, IMeal } from "./types"
import { ILogMealPayload, IScanMealPayload } from "./mealTypes"
import { IFood } from "features/food/foodTypes"

interface IMealAPI {
  scan: (payload: IScanMealPayload) => Promise<IFood[]>
  logMeal: (payload: ILogMealPayload) => Promise<IMeal>
  processImage: (file: File) => Promise<any>
  getMealDetails: (id: string) => Promise<IMeal>
  getSuggestedMeals: () => Promise<IMeal[]>
  getDailyScanProgress: () => Promise<IDailyScanProgress>
  updateMeal: (payload: ILogMealPayload) => Promise<IMeal>
}

const getRequestLogMealPayload = (payload: ILogMealPayload) => {
  const requestPayload: any = {
    ...payload,
  }

  if (requestPayload.ingredients) {
    requestPayload.ingredients = requestPayload.ingredients.map(
      (ingredient: ILogMealPayload) => {
        const requestIngredient: any = {
          ...ingredient,
        }

        if (requestIngredient.externalId) {
          requestIngredient.external_food_id = requestIngredient.externalId
          delete requestIngredient.externalId
        }

        return requestIngredient
      },
    )
  }

  return requestPayload
}

const mealAPI: IMealAPI = {
  logMeal(payload) {
    return requester.post("/meals", getRequestLogMealPayload(payload))
  },

  updateMeal(payload) {
    const { id } = payload ?? ""
    delete payload.id
    return requester.put(`/meals/${id}`, getRequestLogMealPayload(payload))
  },

  scan(payload) {
    const formData = new FormData()
    if (payload.image) {
      formData.append("image", payload.image)
    } else {
      formData.append("image_id", payload.imageId ?? "")
    }

    return requester.post("/scans", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
  },

  processImage(file) {
    return requester.post(
      "/images/process",
      { image: file },
      { headers: { "Content-Type": "multipart/form-data" } },
    )
  },

  getMealDetails(id) {
    return requester.get(`/meals/${id}`)
  },

  async getDailyScanProgress() {
    const user = await requester.get("/users/info")

    return {
      remainingScan: user.startedScanLeft,
    }
  },

  getSuggestedMeals() {
    return requester.get("/meals/suggestions")
  },
}

export default mealAPI
