import React from "react"

const ZozoFitIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="31"
      height="30"
      viewBox="0 0 31 30"
      fill="none"
    >
      <g clipPath="url(#clip0_415_1315)">
        <rect x="0.5" width="30" height="30" rx="4" fill="#1C1F21" />
        <g filter="url(#filter0_d_415_1315)">
          <path
            d="M24.3697 21.8289C21.402 21.8289 18.9857 19.4354 18.9857 16.4958V9.46657H21.6127V16.4958C21.6127 18.0012 22.85 19.2267 24.3697 19.2267V21.8289Z"
            fill="white"
          />
          <path
            d="M12.5257 7.62378C9.558 7.62378 7.14168 10.0172 7.14168 12.9568V21.4514H9.7687V12.9568C9.7687 11.4515 11.006 10.2259 12.5257 10.2259V7.62378Z"
            fill="white"
          />
          <path
            d="M17.1387 13.1433H14.5117V21.4514H17.1387V13.1433Z"
            fill="white"
          />
          <path
            d="M11.5798 15.9408C12.3622 15.9408 12.9964 15.3126 12.9964 14.5376C12.9964 13.7626 12.3622 13.1344 11.5798 13.1344C10.7974 13.1344 10.1632 13.7626 10.1632 14.5376C10.1632 15.3126 10.7974 15.9408 11.5798 15.9408Z"
            fill="#EF4D96"
          />
          <path
            d="M15.8297 12.2152C16.612 12.2152 17.2463 11.587 17.2463 10.812C17.2463 10.0371 16.612 9.40885 15.8297 9.40885C15.0473 9.40885 14.413 10.0371 14.413 10.812C14.413 11.587 15.0473 12.2152 15.8297 12.2152Z"
            fill="#FA4614"
          />
          <path
            d="M23.4597 15.9408C24.242 15.9408 24.8763 15.3126 24.8763 14.5376C24.8763 13.7626 24.242 13.1344 23.4597 13.1344C22.6773 13.1344 22.043 13.7626 22.043 14.5376C22.043 15.3126 22.6773 15.9408 23.4597 15.9408Z"
            fill="#2CCCD3"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_415_1315"
          x="-53"
          y="7.5"
          width="138"
          height="134.5"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="60" />
          <feGaussianBlur stdDeviation="30" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_415_1315"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_415_1315"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_415_1315">
          <rect x="0.5" width="30" height="30" rx="4" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default ZozoFitIcon
