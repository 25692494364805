import { createSlice } from "@reduxjs/toolkit"
import { INotificationState } from "./hooks"
import { resetStoreAction } from "config"

const initialState: INotificationState = {
  snackbarInfos: [],
}

export const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    showSnackbar(state, { payload }) {
      state.snackbarInfos.unshift(payload)
    },

    hideSnackbar(state, { payload: id }) {
      const snackbar = state.snackbarInfos.find(
        (snackbar) => snackbar.id === id,
      )
      if (snackbar) {
        snackbar.status = "inactive"
      }
    },

    removeSnackbar(state, { payload: id }) {
      state.snackbarInfos = state.snackbarInfos.filter(
        (snackbar) => snackbar.id !== id,
      )
    },
  },

  extraReducers(builder) {
    builder.addCase(resetStoreAction, () => {
      return initialState
    })
  },
})

export const { showSnackbar, hideSnackbar, removeSnackbar } =
  notificationSlice.actions

export default notificationSlice.reducer
