import React from "react"
import { getSvgFill } from "utils"

const ActiveIcon = ({ isSelected }: { isSelected: boolean }) => {
  const { backgroundFill, pathFill } = getSvgFill(isSelected)
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
    >
      <g clipPath="url(#clip0_370_1541)">
        <g filter="url(#filter0_b_370_1541)">
          <path
            d="M0 30C0 13.4315 13.4315 0 30 0C46.5685 0 60 13.4315 60 30C60 46.5685 46.5685 60 30 60C13.4315 60 0 46.5685 0 30Z"
            fill={backgroundFill}
          />
        </g>
        <g clipPath="url(#clip1_370_1541)">
          <path
            d="M31.5625 20.5625C33.075 20.5625 34.3125 19.325 34.3125 17.8125C34.3125 16.3 33.075 15.0625 31.5625 15.0625C30.05 15.0625 28.8125 16.3 28.8125 17.8125C28.8125 19.325 30.05 20.5625 31.5625 20.5625ZM26.475 25.2375L22.955 42.9887C22.7762 43.8275 23.4363 44.625 24.3025 44.625H24.4125C25.0588 44.625 25.6087 44.185 25.76 43.5525L27.9875 33.625L30.875 36.375V43.25C30.875 44.0063 31.4937 44.625 32.25 44.625C33.0063 44.625 33.625 44.0063 33.625 43.25V35.495C33.625 34.7388 33.3225 34.0238 32.7725 33.5012L30.7375 31.5625L31.5625 27.4375C33.0338 29.1425 35.165 30.3663 37.5575 30.7512C38.3825 30.875 39.125 30.215 39.125 29.3762C39.125 28.7025 38.63 28.1388 37.9562 28.0288C35.8662 27.685 34.1337 26.4475 33.2125 24.825L31.8375 22.625C31.0675 21.4012 29.5275 20.9062 28.1938 21.47L22.9275 23.6975C21.91 24.1375 21.25 25.1275 21.25 26.2413V29.5C21.25 30.2563 21.8687 30.875 22.625 30.875C23.3812 30.875 24 30.2563 24 29.5V26.2L26.475 25.2375Z"
            fill={pathFill}
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_b_370_1541"
          x="-120"
          y="-120"
          width="300"
          height="300"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="60" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_370_1541"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_370_1541"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_370_1541">
          <rect width="60" height="60" rx="30" fill="white" />
        </clipPath>
        <clipPath id="clip1_370_1541">
          <rect
            width="33"
            height="33"
            fill="white"
            transform="translate(13 13)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default ActiveIcon
