import styled from "styled-components"

export const TutorialWrapper = styled.div`
  height: 100%;
  background: #fff;
  display: flex;
  flex-direction: column;
`

export const TutorialImageBox = styled.div`
  height: 0;
  overflow: hidden;
  padding-top: 83.4%;

  background: white;
  position: relative;
`

export const TutorialImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  position: absolute;
  top: 0;
`

export const TutorialDescription = styled.div`
  font-size: 18px;
  font-weight: 500;
  line-height: 140%;
  text-align: center;
  padding: var(--spacing_xl, 24px) 0px;
  max-width: 317px;
  margin: 0 auto;
`

export const StepperBarItem = styled.div<{ $active: boolean }>`
  width: ${(props) => (props.$active ? 29 : 12.5)}px;
  height: var(--spacing_xs, 6px);
  border-radius: var(--spacing_s, 8px);
  background: ${(props) =>
    props.$active
      ? "var(--Schemes-Primary, #0da3ba)"
      : "var(--Schemes-Primary-Container-Variant, #CCEDED)"};
  flex-shrink: 0;

  transition: width 0.2s;
`
