import { Mask } from "antd-mobile"
import styled from "styled-components"

export const SearchFoodOverlayWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding-top: 8px;
`

export const StyledMask = styled(Mask)`
  position: fixed;
  bottom: 0;
  height: calc(100% - var(--header-height));
  background: #fff !important;
  top: var(--header-height);
  max-width: 500px;
  transform: translateX(-50%);
  left: 50%;  

  .adm-mask-content {
    height: 100%;
  }
`

export const SearchInputBox = styled.div`
  padding: 0 16px;
`
