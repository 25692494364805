import React from "react"
import {
  ManualButton,
  NotFoundContent,
  NotFoundDescription,
  NotFoundTitle,
  ReTry,
  StyledNotFound,
} from "./styled"
import TransparentHeader from "components/Common/TransparentHeader"
import { useLogMealStep, useSetMealTypeParams } from "features/meal/hooks/hooks"

import NotFoundIcon from "components/Icons/NotFoundIcon"
import { Link, useNavigate } from "react-router-dom"
import PATHS from "router/paths"
import { useAppSelector } from "app/hooks"
import { getUserUploadedImageUrl } from "utils"
import { USER_UPLOADED_IMAGE_PREFIX } from "config"

const NotFound = () => {
  const messages = useAppSelector((state) => state.meal.messages)
  const mealImageMessage = messages.find(
    (message) =>
      message.texts.length === 1 &&
      message.texts[0].includes(USER_UPLOADED_IMAGE_PREFIX),
  )

  const userUploadedImageUrl = mealImageMessage
    ? getUserUploadedImageUrl(mealImageMessage.texts[0])
    : ""

  const setStep = useLogMealStep()
  const { mealType } = useSetMealTypeParams()

  return (
    <StyledNotFound>
      <div>
        <TransparentHeader onBack={() => setStep("init")} />
        <img
          src={userUploadedImageUrl}
          alt="food-image"
          style={{
            width: "100%",
            height: "190px",
            objectFit: "cover",
          }}
        />
      </div>

      <NotFoundContent>
        <NotFoundIcon />
        <NotFoundTitle>Not found</NotFoundTitle>
        <NotFoundDescription>
          We couldn't detect any food or nutritional details in the photo.
          Please try again with a clearer image of your meal or nutrition label
        </NotFoundDescription>

        <ReTry onClick={() => setStep("init")}>Retry</ReTry>

        <ManualButton to={`${PATHS.app.food.create}?mealType=${mealType}`}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            viewBox="0 0 25 25"
            fill="none"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M2.75 12.3897C2.75 7.00678 7.11704 2.63974 12.5 2.63974C17.883 2.63974 22.25 7.00678 22.25 12.3897C22.25 17.7727 17.883 22.1397 12.5 22.1397C7.11704 22.1397 2.75 17.7727 2.75 12.3897ZM12.5 4.13974C7.94546 4.13974 4.25 7.8352 4.25 12.3897C4.25 16.9443 7.94546 20.6397 12.5 20.6397C17.0545 20.6397 20.75 16.9443 20.75 12.3897C20.75 7.8352 17.0545 4.13974 12.5 4.13974ZM12.5 7.88974C12.9142 7.88974 13.25 8.22553 13.25 8.63974V11.6397H16.25C16.6642 11.6397 17 11.9755 17 12.3897C17 12.804 16.6642 13.1397 16.25 13.1397H13.25V16.1397C13.25 16.554 12.9142 16.8897 12.5 16.8897C12.0858 16.8897 11.75 16.554 11.75 16.1397V13.1397H8.75C8.33579 13.1397 8 12.804 8 12.3897C8 11.9755 8.33579 11.6397 8.75 11.6397H11.75V8.63974C11.75 8.22553 12.0858 7.88974 12.5 7.88974Z"
              fill="#044257"
            />
          </svg>

          <span>Create new meal</span>
        </ManualButton>
      </NotFoundContent>
    </StyledNotFound>
  )
}

export default NotFound
