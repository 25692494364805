import { Fragment } from "react"
import { FoodListDivider } from "components/FoodListItem/styled"
import FoodListItem from "components/FoodListItem"
import styled from "styled-components"
import { Link } from "react-router-dom"
import PATHS from "router/paths"
import PlusIcon from "components/Icons/PlusIcon"
import { useMyFoods } from "features/food/hooks/index"
import { useLogMeal, useSetMealTypeParams } from "features/meal/hooks/hooks"
import { useGoToFoodDetails } from "features/food/hooks/useFoodDetails"
import { useSetPageTitle } from "libs/ga"

const CreateMealLink = styled(Link)`
  display: flex;
  padding: 7px 24px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 1px solid var(--Schemes-Primary, #0da3ba);
  background: var(--White, #fff);
  font-size: 14px;
  font-weight: 700;
  line-height: 14px; /* 100% */
  letter-spacing: 0.14px;
  color: var(--Schemes-Primary, #0da3ba);
  text-decoration: none;
  gap: 8px;
  height: 40px;
  margin-bottom: 8px;
`

const MyFoods = () => {
  useSetPageTitle("My Foods")
  const { myFoods } = useMyFoods()
  const { mealType } = useSetMealTypeParams()
  const onGoToFoodDetails = useGoToFoodDetails()
  const { handleLogMealByFood } = useLogMeal()

  return (
    <Fragment>
      <CreateMealLink to={`${PATHS.app.food.create}?mealType=${mealType}`}>
        <PlusIcon /> Create new meal
      </CreateMealLink>

      {myFoods.map((food, index) => (
        <div key={food.id}>
          <FoodListItem
            food={food}
            onAdd={() => {
              handleLogMealByFood(food)
            }}
            onSelect={() => {
              onGoToFoodDetails(food)
            }}
          />
          {index !== myFoods.length - 1 && <FoodListDivider />}
        </div>
      ))}
    </Fragment>
  )
}

export default MyFoods
