import { createSlice } from "@reduxjs/toolkit"
import homeAPI from "./homeAPI"
import { createAppAsyncThunk } from "app/hooks"
import { IGetMealsPayload, IHomeState } from "./types"
import dayjs from "dayjs"
import { resetStoreAction } from "config"

let getMealAbortController: any = undefined

export const getMeals = createAppAsyncThunk(
  "home/getMeals",
  async (payload: IGetMealsPayload, { rejectWithValue }) => {
    try {
      if (getMealAbortController) {
        getMealAbortController.abort()
      }

      getMealAbortController = new AbortController()
      const response = await homeAPI.getMeals(
        payload,
        getMealAbortController.signal,
      )
      return response
    } catch (err: any) {
      return rejectWithValue(err)
    } finally {
      getMealAbortController = undefined
    }
  },
)

let getDailyProgressAbortController: any = undefined
export const getDailyProgress = createAppAsyncThunk(
  "home/getDailyProgress",
  async (date: string, { rejectWithValue }) => {
    try {
      if (getDailyProgressAbortController) {
        getDailyProgressAbortController.abort()
      }

      getDailyProgressAbortController = new AbortController()
      const response = await homeAPI.getDailyProgress(
        date,
        getDailyProgressAbortController.signal,
      )

      console.log('daily progress', response)

      return response
    } catch (err: any) {
      return rejectWithValue(err)
    } finally {
      getDailyProgressAbortController = undefined
    }
  },
)

let getMonthlyProgressAbortController: any = undefined
export const getMonthlyProgress = createAppAsyncThunk(
  "home/getMonthlyProgress",
  async ({ from, to }: { from: string; to: string }, { rejectWithValue }) => {
    try {
      if (getMonthlyProgressAbortController) {
        getMonthlyProgressAbortController.abort()
      }

      getMonthlyProgressAbortController = new AbortController()
      const response = await homeAPI.getMonthlyProgress(
        from,
        to,
        getMonthlyProgressAbortController.signal,
      )

      return response
    } catch (err: any) {
      return rejectWithValue(err)
    } finally {
      getMonthlyProgressAbortController = undefined
    }
  },
)

export const deleteMeal = createAppAsyncThunk(
  "home/deleteMeal",
  async (mealId: string, { rejectWithValue, dispatch }) => {
    try {
      await homeAPI.deleteMeal(mealId)
      return mealId
    } catch (err: any) {
      return rejectWithValue(err)
    }
  },
)

const initialState: IHomeState = {
  meals: [],
  getMealsLoading: false,
  getMealsFailed: null,

  dailyProgress: undefined,
  getDailyProgressLoading: false,
  getDailyProgressFailed: undefined,

  monthlyProgress: undefined,
  getMonthlyProgressLoading: false,
  getMonthlyProgressFailed: undefined,

  selectedDate: dayjs(),

  isNoMeals: false,
  isNoRecentMeals: false,

  deleteMealLoading: false,
  deleteMealFailed: null,
  deleteMealSuccess: "",
}

export const homeSlice = createSlice({
  name: "home",
  initialState,
  reducers: {
    setSelectedDate: (state, { payload }) => {
      state.selectedDate = payload
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getMeals.pending, (state) => {
        state.getMealsLoading = true
        state.meals = []
      })
      .addCase(getMeals.fulfilled, (state, { payload }) => {
        state.getMealsLoading = false
        state.isNoMeals = payload.length === 0
        state.meals = payload
      })
      .addCase(getMeals.rejected, (state, { payload }) => {
        state.getMealsLoading = false
        state.getMealsFailed = payload
      })

      .addCase(getDailyProgress.pending, (state) => {
        state.getDailyProgressLoading = true
        state.dailyProgress = undefined
      })
      .addCase(getDailyProgress.fulfilled, (state, { payload }) => {
        state.getDailyProgressLoading = false

        state.dailyProgress = payload
      })
      .addCase(getDailyProgress.rejected, (state, { payload }) => {
        state.getDailyProgressLoading = false
        state.getDailyProgressFailed = payload
      })

      .addCase(getMonthlyProgress.pending, (state) => {
        state.getMonthlyProgressLoading = true
        state.monthlyProgress = undefined
      })
      .addCase(getMonthlyProgress.fulfilled, (state, { payload }) => {
        state.getMonthlyProgressLoading = false
        state.monthlyProgress = payload
      })
      .addCase(getMonthlyProgress.rejected, (state, { payload }) => {
        state.getMonthlyProgressLoading = false
        state.getMonthlyProgressFailed = payload
      })

      .addCase(deleteMeal.pending, (state) => {
        state.deleteMealLoading = true
      })
      .addCase(deleteMeal.fulfilled, (state, { payload }) => {
        state.deleteMealLoading = false
        state.meals = state.meals.filter((meal) => meal.id !== payload)
        state.deleteMealSuccess = payload

        if (state.meals.length === 0) {
          state.isNoMeals = true
        }
      })
      .addCase(deleteMeal.rejected, (state, { payload }) => {
        state.deleteMealLoading = false
        state.deleteMealFailed = payload
      })

      .addCase(resetStoreAction, () => {
        return initialState
      })
  },
})

export const { setSelectedDate } = homeSlice.actions

export default homeSlice.reducer
