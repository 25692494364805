import { useEffect } from "react"
import { Wrapper } from "./Styled"
import { useResetMealState, useSetMealTypeParams } from "../hooks/hooks"
import { useAppSelector } from "app/hooks"
import { useSetPageTitle } from "libs/ga"
import { useNavigate } from "react-router-dom"
import {
  useCheckShouldShowTutorial,
  useIsTutorialShowed,
} from "features/onboarding/hooks"
import PATHS from "router/paths"
import BeforeScanning from "./BeforeScaning"
import ScanningResult from "./ScaningResult"

const ScanMeal = () => {
  useSetPageTitle("Food Detector")
  useResetMealState()

  const { mealType } = useSetMealTypeParams()
  const navigate = useNavigate()
  const { isTutorialShowed } = useIsTutorialShowed()

  const {
    isShowTutorial,
    checkShowTutorialLoading,
    isRevalidatedShouldShowTutorial,
  } = useCheckShouldShowTutorial()

  useEffect(() => {
    const shouldRedirectToTutorial =
      !isTutorialShowed &&
      !checkShowTutorialLoading &&
      isRevalidatedShouldShowTutorial &&
      isShowTutorial === true

    if (shouldRedirectToTutorial) {
      navigate(`${PATHS.app.meal.tutorial}?mealType=${mealType}`)
    }
  }, [
    isShowTutorial,
    checkShowTutorialLoading,
    isRevalidatedShouldShowTutorial,
  ])

  const step = useAppSelector((state) => state.meal.step)

  return (
    <Wrapper>
      {step === "not-found" || step === "scan-result" ? (
        <ScanningResult />
      ) : (
        <BeforeScanning />
      )}
    </Wrapper>
  )
}

export default ScanMeal
