import { Link } from "react-router-dom"
import styled from "styled-components"

export const Wrapper = styled.div`
  height: 100%;
  background: #fff;
  --footer-height: 68px;
`

export const RecentMealMain = styled.div`
  --header-height: 44px;

  height: calc(100% - var(--header-height));
  display: flex;
  flex-direction: column;
  gap: var(--spacing_l, 16px);
  padding-top: 8px;
`

export const AddFoodScanBox = styled.div`
  display: flex;
  padding: 10px;
  justify-content: center;
  align-items: flex-start;
  border-radius: var(--spacing_s, 8px);
  background: var(--Schemes-Primary-Container-Variant, #cceded);
`

export const AddFoodScanBoxInner = styled.div<{ $loading: boolean }>`
  border-radius: var(--spacing_s, 8px);
  background: var(--Schemes-Surface-Container-Lowest, #fff);
  display: flex;
  padding: 12px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--spacing_xxs, 4px);
  flex: 1 1 0;
  cursor: ${({ $loading }) => ($loading ? "progress" : "pointer")};
`

export const TryFoodScanText = styled.div`
  font-size: 14px;
  line-height: 160%;
  color: var(--Schemes-On-Primary-Container, #044257);
  text-align: center;
`

export const RecentFoodsWrapper = styled.div`
  --search-height: 35px;
  --scan-box-height: 96.4px;
  --padding-top: 8px;
  --gap: 16px;

  height: calc(
    100% - var(--scan-box-height) - var(--padding-top) - var(--gap) - var(--gap)
  );
  overflow-y: auto;
  padding: 0 16px;
`

export const RecentFoodsTabBtn = styled(Link)<{ $isActive: boolean }>`
  display: flex;
  padding: var(--spacing_xxs, 4px) 15px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${(props) =>
    props.$isActive
      ? "var(--Schemes-Primary-Container-Variant-2, #0FBDD4)"
      : "var(--Schemes-Surface-Container, #EBEDF0)"};

  color: ${(props) =>
    props.$isActive
      ? "#fff"
      : "var(--Light-Text-Secondary, rgba(76, 78, 100, 0.68))"};

  font-size: 14px;
  font-weight: 600;
  line-height: 160%;
  border-radius: 20px;
  cursor: pointer;
  text-decoration: none;
`

export const FoodList = styled.div`
  margin-top: var(--spacing_l, 16px);
`
