import { useEffect, useState } from "react"
import {
  updateNutrientWhenPortionChange,
  updateNutrientWhenUnitChange,
} from "helper/nutrient"
import { updateIngredientsWhenPortionChange } from "helper/ingredient"
import { IAPIFood, IFood, IIngredientFood } from "../foodTypes"

export const useUpdateApiFood = (initFood: IAPIFood | IIngredientFood) => {
  const [food, setFood] = useState(initFood)


  const onPortionChange = (portion: number) => {
    const nutrient = updateNutrientWhenPortionChange(food, portion)
    setFood({
      ...food,
      ...nutrient,
    })
  }

  const onUnitChange = (unitLabel: string) => {
    const nextFood = updateNutrientWhenUnitChange(food, unitLabel)
    setFood(nextFood as IAPIFood | IIngredientFood)
  }

  return {
    food,
    onPortionChange,
    onUnitChange,
  }
}

export const useUpdateFood = (initFood?: IFood) => {
  const [food, setFood] = useState(initFood)

  const onPortionChange = (portion: number) => {
    if (!food) return
    const ingredients = updateIngredientsWhenPortionChange(
      food,
      portion,
    ) as IIngredientFood[]
    const nutrient = updateNutrientWhenPortionChange(food, portion)
    setFood({ ...food, ...nutrient, ingredients })
  }

  const onUnitChange = (unitLabel: string) => {
    if (!food) return
    const nextFood = updateNutrientWhenUnitChange(food, unitLabel)
    setFood(nextFood)
  }

  useEffect(() => {
    if (initFood) {
      setFood(initFood)
    }
  }, [initFood])

  return {
    food,
    onPortionChange,
    onUnitChange,
  }
}
