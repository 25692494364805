import styled, { css } from "styled-components"

export const StyledDatePicker = styled.div`
  display: flex;
  width: 361px;
  padding: var(--spacing_xs, 6px) 0px;
  flex-direction: column;
`

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  padding: 9px 16px;
`

export const CurrentMonth = styled.div`
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
`

export const NavigateBox = styled.div`
  display: flex;
  gap: 8px;
`

export const NavigateButton = styled.button`
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: none;
  width: 24px;
  height: 24px;
  padding: 0px;

  &:hover {
    background: rgba(0, 38, 58, 0.14);
  }
`

export const TodayButton = styled.button`
  display: flex;
  height: var(--spacing_xl, 24px);

  padding: 0px var(--spacing_m, 12px);
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: var(--spacing_xxs, 4px);
  border: 0.5px solid var(--Schemes-Outline-Variant, rgba(0, 38, 58, 0.14));

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  color: var(--Schemes-On-Surface-Variant, #69757d);

  background: transparent;
  line-height: normal;

  &:hover {
    background: rgba(0, 38, 58, 0.14);
  }
`

const getDateBackground = (props: {
  $isSelected: boolean
  $isToday: boolean
  $available: boolean
  $isInActiveWeek: boolean
}) => {
  if (props.$isSelected) {
    return "var(--Schemes-Primary, #0DA3BA)"
  }
  if (props.$isToday) {
    if (props.$isInActiveWeek) {
      return "var(--Schemes-Primary-Container-Variant, #CCEDED)"
    }

    return "var(--Schemes-Surface-Container, #EBEDF0)"
  }
  return "transparent"
}

const getHoveredDateBackground = (props: {
  $isSelected: boolean
  $available: boolean
  $isInActiveWeek: boolean
}) => {
  if (props.$isSelected) {
    return "var(--Schemes-Primary, #0DA3BA)"
  }

  if (props.$isInActiveWeek) {
    return "var(--Schemes-Primary-Container-Variant, #CCEDED)"
  }

  return "var(--Schemes-Surface-Container, #EBEDF0)"
}

export const DateButton = styled.button<{
  $available: boolean
  $isSelected: boolean
  $isToday: boolean
  $isInActiveWeek: boolean
}>`
  position: relative;
  font-size: 14px;
  line-height: 14px;
  width: 36px;
  height: 36px;
  color: var(--ratio);
  pointer-events: auto;
  opacity: 1;
  cursor: ${(props) => (props.$available ? "pointer" : "pointer")};
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background: ${(props) => getHoveredDateBackground(props)};

    .date_btn__dot {
      background: ${(props) =>
        props.$isSelected && props.$available
          ? "#fff"
          : "var(--Schemes-Primary, #0DA3BA)"};
    }
  }

  border-radius: 50%;
  background: ${(props) => getDateBackground(props)};
`

const getDayTextColor = (props: {
  $isSelected: boolean
  $available: boolean
  $isToday: boolean
  $isInActiveWeek: boolean
}) => {
  if (props.$isSelected) {
    return "#fff"
  }

  if (props.$isToday) {
    if (props.$isInActiveWeek) {
      return "var(--Schemes-On-Surface, #1C1F21)"
    }

    return "var(--Schemes-Primary, #0DA3BA)"
  }

  if (props.$available) {
    return "#000"
  }

  return "var(--Schemes-On-Surface, #1C1F21)"
}

export const DateButtonText = styled.div<{
  $available: boolean
  $isSelected: boolean
  $isToday: boolean
  $isInActiveWeek: boolean
}>`
  position: relative;
  z-index: 11;
  font-weight: ${(props) => (props.$isSelected ? 700 : 400)};
  color: ${(props) => getDayTextColor(props)};
  opacity: ${(props) => (props.$available ? "1" : "0.16")};
`

export const TodayButtonDot = styled.div<{
  $isSelected: boolean
  $isInDifferentMonth: boolean
}>`
  position: absolute;
  z-index: 12;
  bottom: 4px;
  left: 50%;
  transform: translateX(-50%);
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: ${(props) =>
    props.$isSelected
      ? "#fff"
      : props.$isInDifferentMonth
      ? "var(--Schemes-Outline-Variant, rgba(0, 38, 58, 0.14))"
      : "var(--Schemes-Primary, #0DA3BA)"};
`

export const StyledThR = styled.tr`
  padding: 0px var(--spacing_l, 16px);
  display: flex;
  justify-content: space-between;
  width: 100%;

  &:not(:first-child) {
    margin-top: 4px;
  }
`

export const StyledTh = styled.th`
  display: flex;
  width: 44px;
  height: 18px;
  flex-direction: column;
  justify-content: center;

  color: var(--Schemes-On-Surface-Variant, #69757d);
  text-align: center;

  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
`

export const StyledTr = styled.tr<{ $isActive: boolean }>`
  display: flex;
  padding: 0px var(--spacing_l, 16px);
  align-self: stretch;
  position: relative;
  margin-top: 4px;

  ${(props) =>
    props.$isActive &&
    css`
      &::before {
        content: "";
        position: absolute;
        width: 320px;
        height: 40px;
        background: #ebedef;
        transform: translateX(-50%);
        left: 50%;
        bottom: -2px;
        border-radius: 30px;
      }
    `}
`

export const StyledTd = styled.td`
  display: flex;
  height: 36px;
  width: 36px;
  padding: 0px;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
`
