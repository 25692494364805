
import React, { Fragment } from 'react';
import Snackbar from './SnackbarItem';
import { useAppSelector } from 'app/hooks';

const SnackbarHub = () => {
  const snackbarInfos = useAppSelector(
    state => state.notification.snackbarInfos,
  );

  return (
    <Fragment>
      {snackbarInfos.map((snackbarInfo, index) => (
        <Snackbar
          snackbarInfo={snackbarInfo}
          key={snackbarInfo.id}
          index={index}
        />
      ))}
    </Fragment>
  );
};

export default SnackbarHub;
