import {
  MealSectionCalories,
  MealSectionContent,
  MealSectionFooter,
  MealSectionHeader,
  MealSectionTitle,
  StyledMealSection,
} from "./styled"
import PlusIcon from "components/Icons/PlusIcon"
import { IMeal } from "features/meal/types"
import { useNavigate } from "react-router-dom"
import PATHS from "router/paths"
import { getDisplayCalories } from "utils"
import MealItem from "./MealItem"

const MealSection = ({
  meals,
  mealType,
  swipedId,
  setSwipedId,
}: {
  meals: IMeal[]
  mealType: string
  swipedId: string | null
  setSwipedId: (id: string | null) => void
}) => {
  const navigate = useNavigate()
  const calories = meals.reduce((acc, meal) => acc + (meal as any).calorie, 0)

  return (
    <StyledMealSection>
      <MealSectionHeader>
        <MealSectionTitle>{mealType.toLocaleLowerCase()}</MealSectionTitle>
        <MealSectionCalories>
          {getDisplayCalories(calories)}
          <span style={{ fontWeight: 400 }}> cal</span>
        </MealSectionCalories>
      </MealSectionHeader>

      <MealSectionContent>
        {meals.map((meal) => (
          <MealItem
            meal={meal}
            key={meal.id}
            swipedId={swipedId}
            setSwipedId={setSwipedId}
          />
        ))}
      </MealSectionContent>
      <MealSectionFooter
        onClick={() =>
          navigate(
            `${PATHS.app.meal.add.root}?mealType=${mealType.toLocaleLowerCase()}`,
          )
        }
      >
        <PlusIcon />
      </MealSectionFooter>
    </StyledMealSection>
  )
}

export default MealSection
