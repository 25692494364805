import styled from "styled-components"

export const UploadedImage = styled.img`
  border-radius: 24px;
  border: 6px solid var(--Schemes-Primary-Container-Variant-2, #0fbdd4);
  object-fit: cover;

  width: 100%;
  height: 100%;
`

export const UploadedPhotoWrapper = styled.div`
  position: absolute;
  bottom: 80px;
  left: 0px;
  width: 100%;
  padding: 24px;
  padding-bottom: 0px;
  background-color: var(--Schemes-Surface-Container-Low, #f5f7f7);
`

export const StyledScannedPhoto = styled.div`
  position: relative;
  overflow: hidden;
  height: 350px;

  --overlay-height: 178px;

  @keyframes scan-to-bottom {
    0% {
      transform: translatey(-178px);
    }

    100% {
      transform: translatey(700px);
    }
  }

  @keyframes scan-to-top {
    0% {
      transform: translatey(778px);
    }

    100% {
      transform: translatey(-100px);
    }
  }

  .scan-to-bottom {
    width: 100%;
    height: 178px;
    border-bottom: 2px solid var(--Schemes-Primary-Container, #4bd9de);
    background: linear-gradient(
      179deg,
      rgba(255, 255, 255, 0) 0.64%,
      rgba(13, 163, 186, 0.54) 99.4%
    );

    animation: scan-to-bottom 5s linear infinite;
    position: absolute;
    top: 0;
  }

  .scan-to-top {
    width: 100%;
    height: 178px;
    // background: linear-gradient(cyan, 10%, transparent);
    border-top: 2px solid var(--Schemes-Primary-Container, #4bd9de);
    background: linear-gradient(
      to top,

      rgba(255, 255, 255, 0) 0.64%,
      rgba(13, 163, 186, 0.54) 99.4%
    );
    animation: scan-to-top 5s linear infinite;
    position: absolute;
    top: 0;
  }
`

export const ReviewPhoto = styled.img`
  width: 100%;
  height: 350px;
  object-fit: cover;
`

export const CtaBox = styled.div`
  flex-grow: 0;
  padding: 12px 16px 12px 16px;
`
