import { Link } from "react-router-dom"
import styled from "styled-components"

export const FoodResultsWrapper = styled.div`
  height: 100%;
  overflow: hidden;

  .no-food-high-light-text {
    background-color: #fff;
    font-weight: 500;
    color: var(--Schemes-Primary, #0da3ba);
  }
`

export const FoodResultsHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 24px;
  justify-content: space-between;
`

export const FoodResultsTitle = styled.div`
  font-size: 14px;
  line-height: 160%;
  cursor: default;
`

export const CreateNewMealLink = styled(Link)`
  font-size: 14px;
  line-height: 160%;
  color: var(--Schemes-Primary, #0da3ba);
  text-decoration: none;
  cursor: pointer;
`

export const FoodResultsBox = styled.div`
  --result-header-height: 70px;

  height: calc(100% - var(--result-header-height));
  overflow-y: auto;
  padding: 0 16px;
`

export const CreateNotFoundFoodLink = styled(CreateNewMealLink)`
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
`
