import { FlexBox } from "components/Common/FlexBox"
import { StyledSearchIngredients } from "./styled"
import SearchAutocomplete from "components/SearchAutocomplete"
import {
  useExternalSuggestedSearchTexts,
  useSearchExternalFoods,
} from "features/food/hooks/index"
import { useState } from "react"
import SuggestedSearchTexts from "components/SuggestedSearchTexts"
import SearchFoodResults from "components/SearchFoodResults"
import { IAPIFood, IIngredientFood } from "features/food/foodTypes"
import IngredientFoodDetails from "components/APIFoodDetails"

enum IDisplayContent {
  SuggestedSearchText = "Suggested-search-text",
  SearchResults = "Search-results",
  FoodDetails = "Food-details",
}

const SearchIngredients = ({
  setIsHideHeader,
  onAddIngredient,
}: {
  setIsHideHeader: (isHidden: boolean) => void
  onAddIngredient: (food: IAPIFood) => void
}) => {
  const {
    externalKeyword,
    externalSuggestedSearchTexts,
    handleExternalKeywordChange,
    isNoExternalSuggestedSearchTexts,
  } = useExternalSuggestedSearchTexts()

  const {
    searchExternalFoodsResults,
    handleSearchExternalFoods,
    searchExternalFoodsLoading,
    externalSearchSession,
    noSearchedExternalFoodFounded,
  } = useSearchExternalFoods()

  const [displayContent, setDisplayContent] = useState<IDisplayContent>(
    searchExternalFoodsResults.length !== 0
      ? IDisplayContent.SearchResults
      : IDisplayContent.SuggestedSearchText,
  )

  const [foodDetails, setFoodDetails] = useState<IIngredientFood | null>(null)

  const onSelectFood = (food: IIngredientFood) => {
    setFoodDetails(food)
    setIsHideHeader(true)
    setDisplayContent(IDisplayContent.FoodDetails)
  }

  const onDeselectFood = () => {
    setFoodDetails(null)
    setIsHideHeader(false)
    setDisplayContent(IDisplayContent.SearchResults)
  }

  return (
    <StyledSearchIngredients>
      {displayContent !== IDisplayContent.FoodDetails && (
        <FlexBox style={{ padding: "0 16px" }} $direction="column" $gap={16}>
          <SearchAutocomplete
            onFocus={() => {
              if (!externalKeyword) {
                setDisplayContent(IDisplayContent.SuggestedSearchText)
              }
            }}
            placeholder="Search"
            value={externalKeyword}
            onChange={(keyword) => {
              handleExternalKeywordChange(keyword)
              if (displayContent !== IDisplayContent.SuggestedSearchText) {
                setDisplayContent(IDisplayContent.SuggestedSearchText)
              }
            }}
            onClose={() => {
              handleExternalKeywordChange("")
            }}
            onBlur={() => {}}
            onSearch={() => {
              const forceReset = true
              handleSearchExternalFoods({
                nextKeyword: externalKeyword,
                forceReset,
              })
              setDisplayContent(IDisplayContent.SearchResults)
            }}
            visible={!!externalKeyword}
          />
        </FlexBox>
      )}

      {displayContent === IDisplayContent.SuggestedSearchText && (
        <div style={{ padding: "0 16px", marginTop: 20 }}>
          <SuggestedSearchTexts
            isNoSuggestedSearchTexts={isNoExternalSuggestedSearchTexts}
            suggestedTexts={externalSuggestedSearchTexts}
            keyword={externalKeyword}
            onSelect={(keyword) => {
              const forceReset = true
              handleSearchExternalFoods({
                nextKeyword: keyword,
                forceReset,
              })
              setDisplayContent(IDisplayContent.SearchResults)
            }}
          />
        </div>
      )}
      {displayContent === IDisplayContent.SearchResults && (
        <div style={{ height: "calc(100% - var(--header-height) - var(--input-height))" }}>
          {" "}
          <SearchFoodResults
            noSearchedFoodFounded={noSearchedExternalFoodFounded}
            foods={searchExternalFoodsResults}
            onLoad={() =>
              handleSearchExternalFoods({ nextKeyword: externalKeyword })
            }
            loading={searchExternalFoodsLoading}
            hasMore={!!externalSearchSession}
            keyword={externalKeyword}
            onSelectFood={(food) => onSelectFood(food as IIngredientFood)}
            onAddFood={(food) => onAddIngredient(food as IAPIFood)}
            noCreateLinkIfNoFoodFounded={true}
          />
        </div>
      )}

      {displayContent === IDisplayContent.FoodDetails && foodDetails && (
        <IngredientFoodDetails
          onBack={onDeselectFood}
          food={foodDetails}
          onAdd={onAddIngredient}
          mode="add"
        />
      )}
    </StyledSearchIngredients>
  )
}

export default SearchIngredients
