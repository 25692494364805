import { IWeeklyChartDataItem } from "../../types"
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Text,
  ReferenceLine,
} from "recharts"
import CustomTooltip from "./Tooltip"
import { useLayoutEffect, useRef, useState } from "react"
import { getDisplayCalories } from "utils"

function ReferenceLabel(props: any) {
  const { fill, value, viewBox, prefix } = props

  const y = viewBox.y - 6

  const prefixRef = useRef<any>(null)
  const valueRef = useRef<any>(null)

  const [shouldRender, setShouldRender] = useState(false)
  const [prefixWidth, setPrefixWidth] = useState(0)
  const [rectX, setRectX] = useState(0)
  const [rectWidth, setRectWidth] = useState(0)

  const GAP = 5
  const HORIZONTAL_PADDING = 3

  useLayoutEffect(() => {
    if (prefixRef.current && valueRef.current) {
      const prefixWidth = prefixRef.current.getBBox().width
      const valueWidth = valueRef.current.getBBox().width
      setPrefixWidth(prefixWidth)
      setShouldRender(true)

      const rectWidth = prefixWidth + valueWidth + HORIZONTAL_PADDING * 2 + GAP
      const rectX = viewBox.width - rectWidth + viewBox.x

      setRectWidth(rectWidth)
      setRectX(rectX)
    }
  }, [])

  return (
    <g style={{ opacity: shouldRender ? 1 : 0 }}>
      <rect
        fill="rgba(255, 255, 255, 0.60)"
        rx={2}
        ry={2}
        x={rectX}
        y={y - 13.5}
        width={rectWidth}
        height={19}
      ></rect>

      <text
        ref={prefixRef}
        x={rectX + HORIZONTAL_PADDING}
        y={y}
        fill={fill}
        fontSize={12}
      >
        {prefix}
      </text>
      <text
        ref={valueRef}
        fill={fill}
        fontWeight={600}
        x={rectX + HORIZONTAL_PADDING + GAP + prefixWidth}
        y={y}
        fontSize={12}
      >
        {" "}
        {value}
      </text>
    </g>
  )
}

const roundUp = (caloriesPerDay: number) => {
  const step = 500
  for (let index = 1; index < 100; index++) {
    if (caloriesPerDay < step * index) {
      return step * index
    }
  }

  return 0
}

const roundDown = (caloriesPerDay: number) => {
  const step = 500
  for (let index = 1; index < 100; index++) {
    if (caloriesPerDay < step * index) {
      return step * (index - 1)
    }
  }

  return 0
}

const getMaxCaloriesPerDay = (data: IWeeklyChartDataItem[]) => {
  let max = 0
  data.forEach((item) => {
    const total = item.breakfast + item.lunch + item.dinner + item.snack
    if (total > max) {
      max = total
    }
  })
  return max
}

const getMinCaloriesPerDay = (data: IWeeklyChartDataItem[]) => {
  let min = 1000000
  data.forEach((item) => {
    const total = item.breakfast + item.lunch + item.dinner + item.snack
    if (total < min) {
      min = total
    }
  })
  return min
}

const getYDomain = (
  data: IWeeklyChartDataItem[],
  goal: number,
  averageGainedCaloriesPerDay: number,
) => {
  const max =
    averageGainedCaloriesPerDay > goal ? averageGainedCaloriesPerDay : goal
  const min =
    averageGainedCaloriesPerDay > goal ? goal : averageGainedCaloriesPerDay

  const maxPerDay = getMaxCaloriesPerDay(data)
  const minPerDay = getMinCaloriesPerDay(data)

  const maxYValue = max > maxPerDay ? max : maxPerDay
  const maxYRounded = roundUp(maxYValue)

  const minYValue = min > minPerDay ? minPerDay : min
  const minYRounded = roundDown(minYValue)

  return [minYRounded, maxYRounded]
}
const WeeklyBarChart = ({
  data,
  averageGainedCaloriesPerDay,
}: {
  data: IWeeklyChartDataItem[]
  averageGainedCaloriesPerDay: number
}) => {

  const goalCalories = data[data.length - 1].goalCalories

  const [minYAxis, maxYAxis] = getYDomain(
    data,
    goalCalories,
    averageGainedCaloriesPerDay,
  )

  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 25,
          right: 12,
          left: 12,
          bottom: 0,
        }}
        barSize={19.3}
      >
        <XAxis
          height={17}
          tickLine={false}
          scale="point"
          dataKey="name"
          padding={{ left: 10, right: 10 }}
          tick={(tickObject) => {
            return (
              <Text
                {...tickObject}
                fill="#1C1F21"
                fontWeight={500}
                fontSize={10}
              >
                {tickObject.payload.value}
              </Text>
            )
          }}
        />

        <YAxis
          domain={[0, maxYAxis]}
          tickMargin={-2}
          axisLine={false}
          label={{
            angle: 0,
            position: "insideTop",
            value: "Calories",
            offset: -22,
          }}
          width={32}
          stroke=""
          tick={(tickObject) => {
            return (
              <Text {...tickObject} fill="#69757D" width={26} fontSize={10}>
                {tickObject.payload.value}
              </Text>
            )
          }}
        />

        <Tooltip content={<CustomTooltip />} />

        <CartesianGrid
          strokeDasharray="2.56 2.56"
          vertical={false}
          stroke="rgba(0, 38, 58, 0.14)"
        />
        <Bar dataKey="breakfast" stackId="a" fill="#722ED1" />
        <Bar dataKey="lunch" stackId="a" fill="#165DFF" />
        <Bar dataKey="dinner" stackId="a" fill="#14C9C9" />
        <Bar dataKey="snack" stackId="a" fill="#F7BA1E" />

        <ReferenceLine
          y={goalCalories}
          label={
            <ReferenceLabel
              value={`${getDisplayCalories(goalCalories)} cal`}
              fill="#FF4921"
              prefix="Goal"
            />
          }
          stroke="red"
          strokeDasharray="6 6"
        />

        <ReferenceLine
          y={averageGainedCaloriesPerDay}
          label={
            <ReferenceLabel
              value={`${getDisplayCalories(averageGainedCaloriesPerDay)} cal`}
              fill="#044257"
              prefix="AVG"
            />
          }
          stroke="#044257"
          strokeDasharray="6 6"
        />
      </BarChart>
    </ResponsiveContainer>
  )
}

export default WeeklyBarChart
