import PlusIcon from "components/Icons/PlusIcon"
import {
  AddFoodButton,
  FoodImageBox,
  FoodInfoBox,
  FoodLeft,
  FoodName,
  FoodNameBox,
  FoodNutrient,
  StyledMealListItem,
} from "./styled"
import Highlighter from "react-highlight-words"
import { getDisplayCalories } from "utils"
import { IMeal } from "features/meal/types"
import React, { useState } from "react"
import { useAddedFoodAnimation } from "features/food/hooks/animation"
import SmallFoodImage from "components/SmallFoodImage"
import MyFoodIcon from "components/Icons/MyFood"

const AddedButton = () => {
  const style = useAddedFoodAnimation()

  return (
    <AddFoodButton style={{ cursor: "default" }}>
      <svg
        style={{ transform: `scale(${style.scale})`, opacity: style.opacity }}
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
      >
        <rect width="32" height="32" rx="16" fill="#0FBDD4" />
        <path
          d="M24 10L13 21L8 16"
          stroke="white"
          strokeWidth="3.42857"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </AddFoodButton>
  )
}

const MealListItem = ({
  meal,
  keyword,
  onAdd,
  onSelect,
}: {
  meal: IMeal
  keyword?: string
  onAdd: (meal: IMeal) => void
  onSelect?: (meal: IMeal) => void
}) => {
  const {
    name,
    imageUrl,
    calorie,
    pfc: { carb: carbohydrate, protein, fat },
  } = meal
  const gram = carbohydrate + protein + fat

  const [showAddedIcon, setShowAddedIcon] = useState(false)

  const handleAddMeal = () => {
    onAdd(meal)
    setShowAddedIcon(true)
    setTimeout(() => {
      setShowAddedIcon(false)
    }, 1000)
  }
  return (
    <StyledMealListItem>
      <FoodLeft
        onClick={() => {
          onSelect?.(meal)
        }}
      >
        <FoodImageBox>
          <SmallFoodImage src={imageUrl} />
        </FoodImageBox>
        <FoodInfoBox>
          <FoodNameBox>
            <FoodName>
              {" "}
              {keyword ? (
                <Highlighter
                  highlightClassName="high-light-text"
                  searchWords={keyword.split(" ")}
                  textToHighlight={name}
                />
              ) : (
                name
              )}
            </FoodName>
            {meal.foodId && <MyFoodIcon />}{" "}
          </FoodNameBox>

          <FoodNutrient>
            {getDisplayCalories(calorie)} cal, {getDisplayCalories(gram)} gram
          </FoodNutrient>
        </FoodInfoBox>
      </FoodLeft>

      {showAddedIcon ? (
        <AddedButton />
      ) : (
        <AddFoodButton onClick={handleAddMeal}>
          <PlusIcon />
        </AddFoodButton>
      )}
    </StyledMealListItem>
  )
}

export default MealListItem
