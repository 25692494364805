import { FlexBox } from "components/Common/FlexBox"
import styled from "styled-components"
import { StepTitleStrong } from "../styled"

export const PlanKg = styled.div`
  font-size: 32px;
  font-weight: 700;
  line-height: 120%;
`

export const PlanInfoBox = styled(FlexBox)`
  background-color: var(--color-white-60);
  border-radius: 8px;
  padding: 10px 16px;

  svg {
    transform: rotate(180deg);
  }
`

export const PlanDescription = styled.div`
  font-size: 16px;

  text-align: center;
`

export const PlanDescriptionStrong = styled(StepTitleStrong)`
  font-size: 16px;
`

export const DailyNutritionTitle = styled.div`
  font-size: 16px;
  font-weight: 800;
  line-height: 140%;
  text-transform: uppercase;
`

export const DailyText = styled.div`
  font-size: 14px;
`

export const NutritionBox = styled(FlexBox)`
  padding: 10px 0;
  gap: 12px;
  flex-direction: column;
`

export const StyledNutrition = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`
export const NutritionValue = styled.div`
  font-size: 14px;
  font-weight: 700;
`

export const NutritionPercent = styled.div<{
  $percent: number
  $color: string
}>`
  position: relative;
  width: 100%;
  height: 6px;
  border-radius: 99px;
   background-color: var(--Schemes-Outline-Variant, rgba(0, 38, 58, 0.14));

  &::after {
    content: "";
    position: absolute;
    left: -0.5px;
    height: 100%;
    width: ${({ $percent }) => $percent + 0.5}%;
    background-color: ${({ $color }) => $color};
    border-radius: 99px;
  }
`

export const Percent = styled.div``
