import { getSvgFill } from "utils"

const GainWeight = ({ isSelected }: { isSelected: boolean }) => {
  const { backgroundFill, pathFill } = getSvgFill(isSelected)

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
    >
      <g clipPath="url(#clip0_395_386)">
        <g filter="url(#filter0_b_395_386)">
          <path
            d="M0 30C0 13.4315 13.4315 0 30 0C46.5685 0 60 13.4315 60 30C60 46.5685 46.5685 60 30 60C13.4315 60 0 46.5685 0 30Z"
            fill={backgroundFill}
          />
        </g>
        <g clipPath="url(#clip1_395_386)">
          <path
            d="M39.7154 34.8071C39.6689 34.6939 39.7045 34.5375 39.7291 34.4081C40.3289 31.2186 39.1759 28.4363 37.6423 25.751C37.3054 25.1632 37.0234 24.6079 37.0069 23.8934C36.9659 22.2973 36.0786 21.1811 34.5997 20.6446C32.9483 20.0488 31.2614 19.4853 29.5442 19.1294C26.6003 18.5201 24.9078 20.1404 25.2063 23.1115C25.3843 24.8802 27.1535 25.7779 28.6843 24.8559C29.6976 24.2466 30.9793 24.6806 31.9926 23.9581C32.2527 23.7721 32.6663 24.3167 32.8306 24.6833C33.0387 25.1444 32.9976 25.6243 32.91 26.1257C32.617 27.7973 31.8994 29.3691 31.7543 31.3373C30.2864 30.1537 28.7939 29.4446 27.0959 29.8571C26.1785 30.0782 25.6116 29.9865 24.8667 29.3853C23.7548 28.4902 22.2924 28.323 20.9916 28.8407C19.7538 29.3314 20.0276 30.6309 20.0221 31.6851C20.0084 33.9686 20.0194 36.2522 20.0221 38.5358C20.0221 40.5471 20.2631 40.6657 22.3609 40.4797C23.5495 40.3745 24.6641 39.8056 25.9594 40.2181C29.9934 41.5069 33.8083 41.2885 37.5273 38.889C39.217 37.7998 40.6301 37.0422 39.7182 34.8071H39.7154Z"
            fill={pathFill}
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_b_395_386"
          x="-120"
          y="-120"
          width="300"
          height="300"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="60" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_395_386"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_395_386"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_395_386">
          <rect width="60" height="60" rx="30" fill="white" />
        </clipPath>
        <clipPath id="clip1_395_386">
          <rect
            width="20"
            height="22"
            fill="white"
            transform="translate(20 19)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default GainWeight
