import { FlexBox } from "components/Common/FlexBox"
import {
  StepperBarItem,
  TutorialDescription,
  TutorialImage,
  TutorialImageBox,
  TutorialWrapper,
} from "./styled"
import { Link, useNavigate } from "react-router-dom"
import { BackButton } from "components/Common/BackButton"
import ChevronLeft from "components/Icons/ChevronLeft"
import { PageTitle } from "components/Common/PageTitle"
import { useSetMealTypeParams } from "../hooks/hooks"
import PATHS from "router/paths"
import TutorialImage1 from "../../../assets/images/tutorial-1.png"
import TutorialImage2 from "../../../assets/images/tutorial-2.png"
import TutorialImage3 from "../../../assets/images/tutorial-3.png"
import TutorialImage4 from "../../../assets/images/tutorial-4.png"
import { useEffect, useState } from "react"
import Button from "components/Buttons/Button"
import ArrowLeft from "components/Icons/ArrowLeft"
import {
  useCheckShouldShowTutorial,
  useIsTutorialShowed,
} from "features/onboarding/hooks"

const TutorialStep = ({
  imageUrl,
  description,
  isShow,
}: {
  imageUrl: string
  description: string
  isShow: boolean
}) => {
  return (
    <div style={{ display: isShow ? "block" : "none" }}>
      <TutorialImageBox>
        <TutorialImage src={imageUrl} />
      </TutorialImageBox>

      <TutorialDescription>{description}</TutorialDescription>
    </div>
  )
}

const steps = [
  {
    imageUrl: TutorialImage1,
    description:
      "Snap a photo of your meal to start tracking your calories and nutrients!",
  },
  {
    imageUrl: TutorialImage2,
    description:
      "You can also upload a picture of the packaging label or nutrition facts!",
  },
  {
    imageUrl: TutorialImage3,
    description:
      "If we missed any ingredients, make sure to manually add them.",
  },
  {
    imageUrl: TutorialImage4,
    description:
      "Once you start logging your food, we will provide feedback to help you achieve your goals!",
  },
]

const StepperBar = ({
  totalSteps,
  activeStep,
}: {
  totalSteps: number
  activeStep: number
}) => {
  return (
    <FlexBox $justify="center" $alignItems="center" $gap={5}>
      {Array.from(Array(totalSteps)).map((_, index) => (
        <StepperBarItem $active={index === activeStep} key={index} />
      ))}
    </FlexBox>
  )
}

const Tutorial = () => {
  const { mealType } = useSetMealTypeParams()
  const [activeStep, setActiveStep] = useState(0)
  const { handleTutorialShowedChange } = useIsTutorialShowed()
  const navigate = useNavigate()

  const onGoToScanMeal = () => {
    handleTutorialShowedChange(true)
    navigate(`${PATHS.app.meal.scan}?mealType=${mealType}`)
  }

  const { isShowTutorial } = useCheckShouldShowTutorial()

  useEffect(() => {
    if (!isShowTutorial) {
      navigate(`${PATHS.app.meal.scan}?mealType=${mealType}`)
    }
  }, [])

  return (
    <TutorialWrapper>
      <div style={{ height: 6 }}></div>
      <FlexBox
        style={{ height: 44, padding: "9px 12px" }}
        $justify="space-between"
        $alignItems="center"
      >
        <Link to={`${PATHS.app.meal.add.root}?mealType=${mealType}`}>
          <BackButton>
            <ChevronLeft />
          </BackButton>
        </Link>

        <PageTitle>{}</PageTitle>
        <div></div>
      </FlexBox>

      <div>
        {steps.map((step, index) => (
          <TutorialStep
            isShow={index === activeStep}
            key={index}
            imageUrl={step.imageUrl}
            description={step.description}
          />
        ))}

        <StepperBar totalSteps={steps.length} activeStep={activeStep} />
      </div>

      <FlexBox
        style={{
          padding: "12px 24px 24px 24px",
          marginTop: "auto",
        }}
        $justify="space-between"
        $alignItems="center"
      >
        {activeStep !== 0 ? (
          <Button
            $type="primary"
            style={{ width: "100px" }}
            onClick={() => {
              if (activeStep > 0) {
                setActiveStep(activeStep - 1)
              }
            }}
          >
            <ArrowLeft />
          </Button>
        ) : (
          <div />
        )}

        <Button
          $type="primary"
          style={{ width: "100px" }}
          onClick={() => {
            if (activeStep < steps.length - 1) {
              setActiveStep(activeStep + 1)
            } else {
              onGoToScanMeal()
            }
          }}
        >
          <ArrowLeft style={{ rotate: "-180deg" }} />
        </Button>
      </FlexBox>
    </TutorialWrapper>
  )
}

export default Tutorial
