import { Picker } from "antd-mobile"
import {
  PickerHeader,
  PickerPopupStyle,
  StyledInput,
  StyledLabel,
  StyledTextFieldFormItem,
} from "./styled"
import { useEffect, useState } from "react"

interface IProps {
  value: string
  onChange: (value: string) => void
  disabled?: boolean
}

const columns = ["Breakfast", "Lunch", "Dinner", "Snack"].map((item) => ({
  label: item,
  value: item.toUpperCase(),
}))

const MealTypePickerFormItem = (props: IProps) => {
  const { disabled = false, value, onChange } = props
  const [pickerVisible, setPickerVisible] = useState(false)

  const [localValue, setLocalValue] = useState(value)

  useEffect(() => {
    if (value !== localValue) {
      setLocalValue(value)
    }
  }, [value])

  return (
    <StyledTextFieldFormItem>
      <StyledLabel>Meal</StyledLabel>
      <StyledInput
        readOnly
        onFocus={() => {
          if (!disabled) setPickerVisible(true)
        }}
        $isError={false}
        value={props.value ?? ""}
        autoComplete="new-password"
        style={{ paddingRight: "16px" }}
      />

      <PickerPopupStyle />
      <Picker
        cancelText=""
        confirmText="Done"
        popupClassName="picker-popup"
        title={<PickerHeader />}
        visible={pickerVisible}
        onClose={() => {
          setPickerVisible(false)
        }}
        columns={[columns]}
        onConfirm={(value) => {
          onChange(value[0] as string)
        }}
        value={[value.toUpperCase()]}
        onSelect={(value) => {
          setLocalValue(value[0] as string)
        }}
      />
    </StyledTextFieldFormItem>
  )
}

export default MealTypePickerFormItem
