import { useAppDispatch, useAppSelector } from "app/hooks"
import { useEffect, useRef, useState } from "react"
import {
  createFood,
  deleteFood,
  getExternalSuggestedSearchTexts,
  getMyFoods,
  getSuggestedSearchTexts,
  onExternalKeywordChange,
  onKeywordChange,
  resetSearchedExternalFoods,
  resetSearchedFoods,
  searchExternalFoods,
  searchFoods,
  setSearchInAddMealPageVisible as setSearchInAddMealPageVisibleAction,
  setSearchFoodOverlayDisplaySection as setSearchFoodOverlayDisplaySectionAction,
  updateFood,
} from "../foodSlice"
import { useFailed, useSuccess } from "features/notification/hooks"
import { ICreateFoodPayload, ISearchFoodsPayload, IUpdateFoodPayload } from "../foodTypes"
import { useGoToFoodDetails } from "./useFoodDetails"
import PATHS from "router/paths"

export const useSearchFoodOverlayDisplaySection = () => {
  const searchFoodOverlayDisplaySection = useAppSelector(
    (state) => state.food.searchFoodOverlayDisplaySection,
  )

  const dispatch = useAppDispatch()

  const setSearchFoodOverlayDisplaySection = (section: string) => {
    dispatch(setSearchFoodOverlayDisplaySectionAction(section))
  }

  return {
    searchFoodOverlayDisplaySection,
    setSearchFoodOverlayDisplaySection,
  }
}

export const useSearchVisibleInAddMealPage = () => {
  const dispatch = useAppDispatch()
  const isSearchInAddMealPageVisible = useAppSelector(
    (state) => state.food.isSearchInAddMealPageVisible,
  )

  const setSearchInAddMealPageVisible = (visible: boolean) => {
    dispatch(setSearchInAddMealPageVisibleAction(visible))
  }

  return {
    isSearchInAddMealPageVisible,
    setSearchInAddMealPageVisible,
  }
}

export const useMyFoods = () => {
  const myFoods = useAppSelector((state) => state.food.myFoods)

  const getMyFoodsFailed = useAppSelector(
    (state) => state.food.getMyFoodsFailed,
  )

  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(getMyFoods())
  }, [dispatch])

  useFailed(getMyFoodsFailed)

  return {
    myFoods,
  }
}

export const useSuggestedSearchTexts = () => {
  const keyword = useAppSelector((state) => state.food.keyword)
  const getSuggestedSearchTextsLoading = useAppSelector(
    (state) => state.food.getSuggestedSearchTextsLoading,
  )
  const getSuggestedSearchTextsFailed = useAppSelector(
    (state) => state.food.getSuggestedSearchTextsFailed,
  )
  const suggestedSearchTexts = useAppSelector(
    (state) => state.food.suggestedSearchTexts,
  )

  const isNoSuggestedSearchTexts = useAppSelector(
    (state) => state.food.isNoSuggestedSearchTexts,
  )

  const dispatch = useAppDispatch()
  const timer = useRef<any>(null)

  const handleKeywordChange = (text: string) => {
    clearTimeout(timer.current)

    dispatch(onKeywordChange(text))

    timer.current = setTimeout(() => {
      dispatch(getSuggestedSearchTexts(text))
    }, 500)
  }

  useFailed(getSuggestedSearchTextsFailed)

  return {
    keyword,
    getSuggestedSearchTextsLoading,
    suggestedSearchTexts,
    handleKeywordChange,
    isNoSuggestedSearchTexts,
  }
}

export const useSearchExternalFoods = () => {
  const searchExternalFoodsResults = useAppSelector(
    (state) => state.food.searchExternalFoodsResults,
  )
  const searchExternalFoodsLoading = useAppSelector(
    (state) => state.food.searchExternalFoodsLoading,
  )
  const searchExternalFoodsFailed = useAppSelector(
    (state) => state.food.searchExternalFoodsFailed,
  )

  const noSearchedExternalFoodFounded = useAppSelector(
    (state) => state.food.noSearchedExternalFoodFounded,
  )

  const externalKeyword = useAppSelector((state) => state.food.externalKeyword)
  const externalSearchSession = useAppSelector(
    (state) => state.food.externalSearchSession,
  )

  useFailed(searchExternalFoodsFailed)

  const dispatch = useAppDispatch()

  const handleSearchExternalFoods = async ({
    nextKeyword,
    forceReset,
  }: {
    nextKeyword: string
    forceReset?: boolean
  }) => {
    if (!nextKeyword) return

    const payload: ISearchFoodsPayload = {
      keyword: nextKeyword,
      session: "",
    }

    if (nextKeyword === externalKeyword && !forceReset) {
      payload.session = externalSearchSession
    }

    if (forceReset) {
      dispatch(resetSearchedExternalFoods())
    }

    return await dispatch(searchExternalFoods(payload))
  }

  return {
    noSearchedExternalFoodFounded,
    externalSearchSession,
    searchExternalFoodsResults,
    searchExternalFoodsLoading,
    handleSearchExternalFoods,
  }
}

export const useExternalSuggestedSearchTexts = () => {
  const externalKeyword = useAppSelector((state) => state.food.externalKeyword)
  const getExternalSuggestedSearchTextsLoading = useAppSelector(
    (state) => state.food.getExternalSuggestedSearchTextsLoading,
  )
  const getExternalSuggestedSearchTextsFailed = useAppSelector(
    (state) => state.food.getExternalSuggestedSearchTextsFailed,
  )
  const externalSuggestedSearchTexts = useAppSelector(
    (state) => state.food.externalSuggestedSearchTexts,
  )

  const isNoExternalSuggestedSearchTexts = useAppSelector(
    (state) => state.food.isNoExternalSuggestedSearchTexts,
  )

  const dispatch = useAppDispatch()
  const timer = useRef<any>(null)

  const handleExternalKeywordChange = (text: string) => {
    clearTimeout(timer.current)

    dispatch(onExternalKeywordChange(text))

    timer.current = setTimeout(() => {
      dispatch(getExternalSuggestedSearchTexts(text))
    }, 500)
  }

  useFailed(getExternalSuggestedSearchTextsFailed)

  return {
    externalKeyword,
    getExternalSuggestedSearchTextsLoading,
    externalSuggestedSearchTexts,
    handleExternalKeywordChange,
    isNoExternalSuggestedSearchTexts,
  }
}

export const useSearchFoods = () => {
  const searchFoodsResults = useAppSelector(
    (state) => state.food.searchFoodsResults,
  )
  const searchFoodsLoading = useAppSelector(
    (state) => state.food.searchFoodsLoading,
  )
  const searchFoodsFailed = useAppSelector(
    (state) => state.food.searchFoodsFailed,
  )

  const noSearchedFoodFounded = useAppSelector(
    (state) => state.food.noSearchedFoodFounded,
  )

  const keyword = useAppSelector((state) => state.food.keyword)
  const searchSession = useAppSelector((state) => state.food.searchSession)

  useFailed(searchFoodsFailed)

  const dispatch = useAppDispatch()

  const handleSearchFoods = async ({
    nextKeyword,
    forceReset,
  }: {
    nextKeyword: string
    forceReset?: boolean
  }) => {
    if (!nextKeyword) return

    const payload: ISearchFoodsPayload = {
      keyword: nextKeyword,
      session: "",
    }

    if (nextKeyword === keyword && !forceReset) {
      payload.session = searchSession
    }

    if (forceReset) {
      dispatch(resetSearchedFoods())
    }

    return await dispatch(searchFoods(payload))
  }

  return {
    noSearchedFoodFounded,
    searchSession,
    searchFoodsResults,
    searchFoodsLoading,
    handleSearchFoods,
  }
}

export const useCreateFood = () => {
  const createFoodLoading = useAppSelector(
    (state) => state.food.createFoodLoading,
  )
  const createFoodFailed = useAppSelector(
    (state) => state.food.createFoodFailed,
  )
  const createFoodSuccess = useAppSelector(
    (state) => state.food.createFoodSuccess,
  )

  const dispatch = useAppDispatch()

  const handleCreateFood = (food: ICreateFoodPayload) => {
    dispatch(createFood(food))
  }

  useFailed(createFoodFailed)

  const navigateToFoodDetails = useGoToFoodDetails()
  const { setSearchInAddMealPageVisible } = useSearchVisibleInAddMealPage()

  useSuccess(createFoodSuccess, { message: "" }, () => {
    if (createFoodSuccess) {
      setSearchInAddMealPageVisible(false)
      navigateToFoodDetails(createFoodSuccess, PATHS.app.meal.add.myFoods)
    }
  })

  return {
    createFoodLoading,
    createFoodSuccess,
    handleCreateFood,
  }
}

export const useDeleteFood = (callback?: () => void) => {
  const deleteFoodLoading = useAppSelector(
    (state) => state.food.deleteFoodLoading,
  )
  const deleteFoodSuccess = useAppSelector(
    (state) => state.food.deleteFoodSuccess,
  )

  const deleteFoodFailed = useAppSelector(
    (state) => state.food.deleteFoodFailed,
  )

  const dispatch = useAppDispatch()

  const handleDeleteFood = (id: string) => {
    dispatch(deleteFood(id))
  }

  useFailed(deleteFoodFailed)

  useSuccess(deleteFoodSuccess, { message: "" }, callback)

  return {
    deleteFoodLoading,
    handleDeleteFood,
  }
}

export const useUpdateFood = () => {
  const updateFoodLoading = useAppSelector(
    (state) => state.food.updateFoodLoading,
  )
  const updateFoodSuccess = useAppSelector(
    (state) => state.food.updateFoodSuccess,
  )

  const updateFoodFailed = useAppSelector(
    (state) => state.food.updateFoodFailed,
  )

  const dispatch = useAppDispatch()

  const handleUpdateFood = (food: IUpdateFoodPayload) => {
    dispatch(updateFood(food))
  }

  useFailed(updateFoodFailed)
  const navigateToFoodDetails = useGoToFoodDetails()

  useSuccess(updateFoodSuccess, { message: "" }, () => {
    if (updateFoodSuccess) {
      navigateToFoodDetails(updateFoodSuccess, PATHS.app.meal.add.myFoods)
    }
  })

  useSuccess(updateFoodSuccess, { message: "" })

  return {
    updateFoodLoading,
    updateFoodSuccess,
    handleUpdateFood,
  }
}
