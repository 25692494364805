const PATHS = {
  app: {
    root: "/",
    setGoal: "/set-goal",
    personalInfo: "/personal-info",
    trend: "/trend",
    settings: "/settings",
    profile: "/profile",
    overview: "/overview",
    metric: "/metric",
    yourGoal: "/your-goal",
    updateGoal: "/update-goal",

    food: {
      create: "/food/create",
      details: "/food/:id/details",
      edit: "/food/:id/edit",
    },

    meal: {
      tutorial: "/tutorial",
      details: "/meal/:id/details",
      edit: "/meal/:id/edit",
      scan: "/meal/scan",
      add: {
        root: "/meal/add",
        myFoods: "/meal/add/my-foods",
      },
    },
  },
  auth: {
    root: "/auth",
    loginWithEmail: "/auth/login-with-email",
    termOfUse: "/term-of-use",
    loginInNativeApp: "/auth/login-in-native-app",
  },
}

export default PATHS
