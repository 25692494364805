import styled from "styled-components"

export const StyledWeeklyOption = styled.div<{ $active: boolean }>`
  display: flex;
  padding: 11px 25px;
  align-items: center;
  border-radius: 8px;
  background: var(--color-white-60);
  cursor: default;
  line-height: 160%;

  font-weight: ${({ $active }) => ($active ? "600" : "400")};

  color: ${({ $active }) =>
    $active
      ? "var(--Schemes-On-Primary-Container, #044257)"
      : "var(--Schemes-On-Surface, #1C1F21)"};

  outline: ${({ $active }) =>
    $active
      ? "1px solid var(--Schemes-Primary, #0DA3BA)"
      : "0.5px solid var(--Schemes-Outline, rgba(0, 38, 58, 0.14))"};

  @supports (-webkit-touch-callout: none) {
    outline: ${({ $active }) =>
      $active
        ? "1px solid var(--Schemes-Primary, #0DA3BA)"
        : "0.8px solid var(--Schemes-Outline, rgba(0, 38, 58, 0.14))"};
  }
`
