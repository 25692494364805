import React from "react"
import styled from "styled-components"

const NoDataWrapper = styled.div`
  padding: 16px 0;
  background: #fff;
  display: flex;
  flex-direction: column;
  gap: 11px;
  align-items: center;
`
const NoDataTitle = styled.div`
  font-size: 14px;
  text-align: center;
  width: 291px;
  margin: 0 auto;
`

const NoData = () => {
  return (
    <NoDataWrapper>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="251"
        height="200"
        viewBox="0 0 251 200"
        fill="none"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M207.5 66C211.366 66 214.5 69.134 214.5 73C214.5 76.866 211.366 80 207.5 80H167.5C171.366 80 174.5 83.134 174.5 87C174.5 90.866 171.366 94 167.5 94H189.5C193.366 94 196.5 97.134 196.5 101C196.5 104.866 193.366 108 189.5 108H179.326C174.452 108 170.5 111.134 170.5 115C170.5 117.577 172.5 119.911 176.5 122C180.366 122 183.5 125.134 183.5 129C183.5 132.866 180.366 136 176.5 136H93.5C89.634 136 86.5 132.866 86.5 129C86.5 125.134 89.634 122 93.5 122H54.5C50.634 122 47.5 118.866 47.5 115C47.5 111.134 50.634 108 54.5 108H94.5C98.366 108 101.5 104.866 101.5 101C101.5 97.134 98.366 94 94.5 94H69.5C65.634 94 62.5 90.866 62.5 87C62.5 83.134 65.634 80 69.5 80H109.5C105.634 80 102.5 76.866 102.5 73C102.5 69.134 105.634 66 109.5 66H207.5ZM207.5 94C211.366 94 214.5 97.134 214.5 101C214.5 104.866 211.366 108 207.5 108C203.634 108 200.5 104.866 200.5 101C200.5 97.134 203.634 94 207.5 94Z"
          fill="#F5F7F7"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M100.442 133.192L101.932 133.141V154.14C101.932 154.615 102.319 155 102.796 155H154.636C155.113 155 155.5 154.615 155.5 154.14V82.155C155.5 80.4126 154.082 79 152.332 79H105.1C103.35 79 101.932 80.4126 101.932 82.155V97.1227L100.442 97.0711C100.267 97.065 100.092 97.062 99.916 97.062C90.8809 97.062 83.5 105.124 83.5 115.132C83.5 125.139 90.8809 133.202 99.916 133.202C100.092 133.202 100.267 133.199 100.442 133.192ZM100.607 126.29C100.378 126.309 100.147 126.318 99.916 126.318C94.4625 126.318 90.1432 121.263 90.1432 115.132C90.1432 109.001 94.4625 103.946 99.916 103.946C100.147 103.946 100.378 103.955 100.607 103.973L101.932 104.079V126.184L100.607 126.29Z"
          fill="white"
          stroke="#0DA3BA"
          strokeWidth="2"
          strokeLinejoin="round"
        />
        <path
          d="M147.5 85.1816V103.5M147.5 109.182V113.925V109.182Z"
          stroke="#CCEDED"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M67.6279 147H74.5M181.628 147H184.5M161.5 147H176.928M80.5 147H97.9541"
          stroke="#0DA3BA"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M128.5 35C125.833 39.2135 124.5 42.8802 124.5 46C124.5 51.5562 129.154 54.0444 129.154 60.063C129.154 63.1177 127.603 66.1507 124.5 69.1621"
          stroke="#CCEDED"
          strokeWidth="2.5"
          strokeLinecap="round"
        />
        <path
          d="M116.5 46C115.628 51.5127 119.5 52.5322 119.5 57.4746C119.5 59.9831 118.5 62.4915 116.5 65"
          stroke="#CCEDED"
          strokeWidth="2.5"
          strokeLinecap="round"
        />
        <path
          d="M135.295 43.4746C133.974 46.8938 134.717 48.9779 135.295 50.2046C136.533 52.8267 138.5 55.0901 138.5 57.7583C138.5 60.8573 137.432 63.8397 135.295 66.7056"
          stroke="#CCEDED"
          strokeWidth="2.5"
          strokeLinecap="round"
        />
        <path
          d="M105.5 84C105.5 83.4477 105.948 83 106.5 83L125.5 83V152H106.5C105.948 152 105.5 151.552 105.5 151V84Z"
          fill="#F5F7F7"
        />
      </svg>

      <NoDataTitle>
        No data available for the requested time period. Please select a
        different time period.
      </NoDataTitle>
    </NoDataWrapper>
  )
}

export default NoData
