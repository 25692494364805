import React from "react"
import {
  StyledTermOfUse,
  TermOfUseContent,
  TermOfUseDate,
  TermOfUseHeader,
  TermOfUseSectionTitle,
  TermOfUseText,
  TermOfUseTitle,
} from "./styled"
import { CTABox } from "features/onboarding/styled"
import Button from "components/Buttons/Button"
import { Link } from "react-router-dom"
import PATHS from "router/paths"
import { useSetPageTitle } from "libs/ga"

const TermOfUse = () => {
  useSetPageTitle("Terms of Use")
  return (
    <StyledTermOfUse>
      <TermOfUseHeader>
        <TermOfUseTitle>Terms of Use</TermOfUseTitle>
        <TermOfUseDate>Last updated on 1/12/2024</TermOfUseDate>
      </TermOfUseHeader>

      <TermOfUseContent>
        <TermOfUseText style={{ marginTop: 8 }}>
          If you agree to the following, please click the "I agree" button
          below:
        </TermOfUseText>

        <TermOfUseSectionTitle>Clause 1</TermOfUseSectionTitle>

        <TermOfUseText>
          I will not disclose to any person, or use for any purpose other than
          to use the Food Journal app (beta version) (the "App"), any
          information related to the App including but not limited to its
          existence and purpose, any aspect of the services provided through the
          App, and any feedback that I provide to ZOZO Apparel USA, Inc. or
          other ZOZO entities ("ZOZO"), whether by email, on the App, or by any
          other means ("Feedback").
        </TermOfUseText>

        <TermOfUseSectionTitle style={{ marginTop: 24 }}>
          Clause 2
        </TermOfUseSectionTitle>

        <TermOfUseText>
          I hereby acknowledge and agree that ZOZO is the sole and exclusive
          owner of all right, title, and interest in and to any and all ideas,
          know-how, concepts, techniques, and methods contained in the Feedback
          and any and all intellectual property rights therein, and that I am
          not entitled to any remuneration, compensation, or attribution with
          respect to the Feedback. To the extent that any such intellectual
          property right initially vests in me, I hereby irrevocably assign to
          ZOZO and its successors and assigns, for no additional consideration,
          such intellectual property right along with the right to sue and
          recover damages and/or other relief with respect to all past, present,
          and future infringement of such right, and I will take all actions
          necessary to effect such assignment.
        </TermOfUseText>
      </TermOfUseContent>

      <CTABox>
        <Link to={PATHS.auth.root}>
          <Button $type="primary" shape="rounded" style={{ width: "100%" }}>
            Continue
          </Button>
        </Link>
      </CTABox>
    </StyledTermOfUse>
  )
}

export default TermOfUse
