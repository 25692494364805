import { FlexBox } from "components/Common/FlexBox"
import styled from "styled-components"

const NoFoodText = styled.p`
  max-width: 316px;
  text-align: center;
`

const NoFoodFound = () => {
  return (
    <FlexBox
      $direction="column"
      $alignItems="center"
      $justify="center"
      $gap={24}
      style={{paddingTop: "50px"}}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="96"
        height="95"
        viewBox="0 0 96 95"
        fill="none"
      >
        <g clipPath="url(#clip0_6188_13428)">
          <path
            d="M29.2019 66.0648C26.6898 64.6239 24.9923 61.9168 24.9923 58.8184C24.9923 46.0294 35.3967 35.625 48.1857 35.625H57.7435L58.1936 28.2031H4.03711L7.92707 92.3849C8.01622 93.8541 9.23315 95 10.7052 95H27.7828C26.0317 92.6727 24.9923 89.7808 24.9923 86.6504C24.9923 83.0416 27.2943 79.9606 30.5065 78.7973C29.3679 77.0512 28.7032 74.9696 28.7032 72.7344C28.7032 70.4969 29.3693 68.4132 30.5102 66.6657C29.6027 66.4613 29.099 66.2605 29.2019 66.0648Z"
            fill="#00263A"
            fillOpacity="0.14"
          />
          <path
            d="M31.1123 22.6374C31.1145 22.6374 31.1167 22.6367 31.1189 22.6367H58.9473C60.4846 22.6367 61.7305 21.3908 61.7305 19.8535C61.7305 18.3162 60.4846 17.0703 58.9473 17.0703H36.2533L43.7556 5.56641H53.3809C54.9181 5.56641 56.1641 4.32049 56.1641 2.7832C56.1641 1.24592 54.9181 0 53.3809 0H42.248C41.3073 0 40.4303 0.474739 39.9171 1.26259L29.6077 17.0703H3.2832C1.74592 17.0703 0.5 18.3162 0.5 19.8535C0.5 21.3908 1.74592 22.6367 3.2832 22.6367H31.1022C31.1058 22.6367 31.1094 22.6374 31.1123 22.6374Z"
            fill="#00263A"
            fillOpacity="0.14"
          />
          <path
            d="M30.5586 58.8145C30.5586 60.3517 31.8045 61.5977 33.3418 61.5977H92.7168C94.2541 61.5977 95.5 60.3517 95.5 58.8145C95.5 49.095 87.5925 41.1875 77.873 41.1875H48.1855C38.4661 41.1875 30.5586 49.095 30.5586 58.8145Z"
            fill="#00263A"
            fillOpacity="0.14"
          />
          <path
            d="M39.8359 67.1719C36.7614 67.1719 34.2695 69.6637 34.2695 72.7383C34.2695 75.8129 36.7614 78.3047 39.8359 78.3047H86.2227C89.2972 78.3047 91.7891 75.8129 91.7891 72.7383C91.7891 69.6637 89.2972 67.1719 86.2227 67.1719H39.8359Z"
            fill="#00263A"
            fillOpacity="0.14"
          />
          <path
            d="M92.7168 83.8672H33.3418C31.8045 83.8672 30.5586 85.1131 30.5586 86.6504C30.5586 91.2543 34.3043 95 38.9082 95H87.1504C91.7543 95 95.5 91.2543 95.5 86.6504C95.5 85.1131 94.2541 83.8672 92.7168 83.8672Z"
            fill="#00263A"
            fillOpacity="0.14"
          />
        </g>
        <defs>
          <clipPath id="clip0_6188_13428">
            <rect
              width="95"
              height="95"
              fill="white"
              transform="translate(0.5)"
            />
          </clipPath>
        </defs>
      </svg>

      <NoFoodText>
        Sorry, we couldn't find any results for your search. Try using different
        keywords for a better match, or add your own custom meal entry.
      </NoFoodText>
    </FlexBox>
  )
}

export default NoFoodFound
