import {
  InfoItemLabel,
  InfoSection,
  StyledInfoItem,
  StyledPersonalInfo,
  TitleBox,
  InfoItemValue,
  MetricContainer,
} from "./styled"
import { Description, Title20 } from "components/Typo"
import Switch, { ISwitchOption } from "components/Switch"
import Button from "components/Buttons/Button"
import { Link } from "react-router-dom"
import PATHS from "router/paths"
import { CTABox } from "features/onboarding/styled"
import { IMetricType } from "features/onboarding/types"
import { useMetricType, useUser } from "features/onboarding/hooks"
import { FlexBox } from "components/Common/FlexBox"
import ChevronLeft from "components/Icons/ChevronLeft"
import { Fragment } from "react"
import { getDisplayHeight, getWeight } from "utils"
import { useSetPageTitle } from "libs/ga"

const metricOptions: [ISwitchOption, ISwitchOption] = [
  { label: "Imperial", value: "IMPERIAL" },
  {
    label: "Metric",
    value: "METRIC",
  },
]

const InfoItem = ({
  label,
  value,
  hasBorder = false,
}: {
  label: string
  value: string
  hasBorder?: boolean
}) => {
  return (
    <StyledInfoItem $hasBorder={hasBorder}>
      <InfoItemLabel>{label}</InfoItemLabel>
      <InfoItemValue>{value}</InfoItemValue>
    </StyledInfoItem>
  )
}

const BodyMetric = () => {
  useSetPageTitle("Body Metric - Profile")
  const { user } = useUser()
  const { onUpdateMetricType, metricType } = useMetricType()

  return (
    <Fragment>
      <FlexBox
        style={{ padding: "9px 6px", background: "rgb(245, 247, 247)" }}
        $justify="space-between"
        $alignItems="center"
        $gap={12}
      >
        <Link
          to={PATHS.app.profile}
          style={{
            textDecoration: "none",
            color: "currentcolor",
            paddingLeft: 8,
          }}
        >
          <FlexBox $gap={12} $alignItems="center">
            <ChevronLeft />
            <div style={{ fontSize: 16 }}>Profile</div>{" "}
          </FlexBox>
        </Link>
      </FlexBox>

      <StyledPersonalInfo style={{ height: "calc(100% - var(--footer-height) - var(--header-height))" }}>
        <TitleBox>
          <Title20>Body Metrics</Title20>
          <Description>Data fetched from your ZOZOFIT Profile</Description>
        </TitleBox>

        <div style={{ height: "var(--spacing_m)" }} />

        <MetricContainer>
          <Switch
            onChange={(value) => onUpdateMetricType(value as IMetricType)}
            options={metricOptions}
            value={metricType}
          />
        </MetricContainer>

        <div style={{ height: 24 }} />

        <InfoSection>
          <InfoItem label="Height" value={getDisplayHeight(metricType, user)} />
          <InfoItem label="Weight" value={getWeight(metricType, user)} />
        </InfoSection>
      </StyledPersonalInfo>
    </Fragment>
  )
}

export default BodyMetric
