import { Fragment } from "react"
import { StepContent, StepTitle } from "../styled"
import { FlexBox } from "components/Common/FlexBox"
import {
  DailyNutritionTitle,
  DailyText,
  NutritionBox,
  NutritionPercent,
  NutritionValue,
  PlanDescription,
  PlanDescriptionStrong,
  PlanInfoBox,
  PlanKg,
  StyledNutrition,
} from "./styled"
import ArrowLeft from "components/Icons/ArrowLeft"
import { useMetricType, useUser } from "features/onboarding/hooks"
import { IMetricType } from "features/onboarding/types"
import { getDisplayCalories, getWeight, truncateByDecimalPlace } from "utils"
import { gramToKg } from "utils"
import dayjs from "dayjs"
import { useAppSelector } from "app/hooks"
import { GOAL_WORDING, MAINTAIN_WEIGHT_TYPES, US_DATE_FORMAT_WITH_YEAR } from "config"

export interface INutritionProps {
  label: string
  value: string
  color: string
  percent: number
}

const Nutrition = (props: INutritionProps) => {
  const { label, value, color, percent } = props
  return (
    <StyledNutrition>
      <FlexBox $justify="space-between">
        <NutritionValue>{label}</NutritionValue>
        <NutritionValue>{value}</NutritionValue>
      </FlexBox>
      <NutritionPercent $color={color} $percent={percent} />
    </StyledNutrition>
  )
}

const getDisplayedTargetWeight = (metricType: IMetricType, weight: number) => {
  if (metricType === "IMPERIAL") {
    return weight
  }

  return gramToKg(weight)
}

const Plan = () => {
  const goal = useAppSelector((state) => state.onboarding.goal)
  const { user } = useUser()
  const { metricType } = useMetricType()

  const displayedStartWeight = getWeight(metricType, user)
  let displayedTargetWeight = getDisplayedTargetWeight(
    metricType,
    goal?.targetWeight ?? 0,
  )

  displayedTargetWeight = truncateByDecimalPlace(displayedTargetWeight, 2)

  const nutrition = [
    {
      label: "Calories",
      value: `${getDisplayCalories(goal?.caloriesPerDay)} cal/day`,
      color: "var(--color-primary)",
      percent: 100,
    },
    {
      label: "Protein",
      value: `${getDisplayCalories(goal?.proteinGram)} g`,
      color: "#F17EE6",
      percent: (goal?.protein ?? 0) * 100,
    },
    {
      label: "Fat",
      value: `${getDisplayCalories(goal?.fatGram)} g`,
      color: "#F39D39",
      percent: (goal?.fat ?? 0) * 100,
    },
    {
      label: "Carbs",
      value: `${getDisplayCalories(goal?.carbsGram)} g`,
      color: "#8653E0",
      percent: (goal?.carb ?? 0) * 100,
    },
  ]

  const weightUnit = metricType === "IMPERIAL" ? "lb" : "kg"

  return (
    <Fragment>
      <StepTitle>Your personalized health plan is ready!</StepTitle>

      <StepContent>
        {goal && MAINTAIN_WEIGHT_TYPES.includes(goal.typeName) ? (
          <PlanInfoBox
            style={{ paddingTop: 16, paddingBottom: 16 }}
            $gap={6}
            $direction="column"
          >
            <PlanDescription>Try maintain the current</PlanDescription>
            <FlexBox
              $justify="center"
              $alignItems="center"
              style={{ width: "100%" }}
            >
              <PlanKg>{displayedStartWeight}</PlanKg>
            </FlexBox>
            {goal.typeName === "NOT_SURE" && (
              <PlanDescription style={{ fontSize: 14 }}>
                Your personalized health plan to maintain your weight at the
                moment! You still can change your goal anytime you have in mind
              </PlanDescription>
            )}
          </PlanInfoBox>
        ) : (
          <Fragment>
            <PlanInfoBox $gap={12} $direction="column">
              <FlexBox
                $gap={12}
                $justify="center"
                $alignItems="center"
                style={{ width: "100%" }}
              >
                <PlanKg>{displayedStartWeight}</PlanKg>
                <ArrowLeft />
                <PlanKg>
                  {" "}
                  {displayedTargetWeight} {weightUnit}
                </PlanKg>
              </FlexBox>

              <PlanDescription>
                Follow our recommendations and you can reach your goal on{" "}
                <PlanDescriptionStrong>
                  {goal &&
                    dayjs(goal.reachedOn).format(US_DATE_FORMAT_WITH_YEAR)}
                </PlanDescriptionStrong>
              </PlanDescription>
            </PlanInfoBox>
          </Fragment>
        )}

        <PlanInfoBox $gap={10} $direction="column" style={{ marginTop: 16 }}>
          <DailyNutritionTitle>Daily nutrients</DailyNutritionTitle>
          <NutritionBox>
            {nutrition.map((item, index) => (
              <Nutrition key={index} {...item} />
            ))}
          </NutritionBox>

          <DailyText>{goal ? GOAL_WORDING[goal.typeName] : ""}</DailyText>
        </PlanInfoBox>
      </StepContent>
    </Fragment>
  )
}

export default Plan
