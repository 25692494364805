import { IFood } from "./foodTypes"
import { v4 as uuidv4 } from "uuid"

export const convertServerToClientFood = (
  food: IFood,
  genNewId?: boolean,
): IFood => {
  return {
    ...food,
    id: genNewId ? uuidv4() : food.id,
    originalId: food.id,
  }
}
