import React from "react"

const MealIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M3 15C2.45 15 1.97933 14.8043 1.588 14.413C1.19667 14.0217 1.00067 13.5507 1 13V4C1 3.45 1.196 2.97933 1.588 2.588C1.98 2.19667 2.45067 2.00067 3 2H21C21.55 2 22.021 2.196 22.413 2.588C22.805 2.98 23.0007 3.45067 23 4V13C23 13.55 22.8043 14.021 22.413 14.413C22.0217 14.805 21.5507 15.0007 21 15H3ZM20.3 17.6L3.825 18.45C3.60833 18.4667 3.425 18.4083 3.275 18.275C3.125 18.1417 3.04167 17.9667 3.025 17.75C3.00833 17.5333 3.07067 17.35 3.212 17.2C3.35333 17.05 3.53267 16.9667 3.75 16.95L20.225 16.1C20.4417 16.0833 20.625 16.1417 20.775 16.275C20.925 16.4083 21.0083 16.5833 21.025 16.8C21.0417 17.0167 20.9793 17.2 20.838 17.35C20.6967 17.5 20.5173 17.5833 20.3 17.6ZM20.25 20.975H3.75C3.53333 20.975 3.35433 20.904 3.213 20.762C3.07167 20.62 3.00067 20.4413 3 20.226C2.99933 20.0107 3.07033 19.8317 3.213 19.689C3.35567 19.5463 3.53467 19.4753 3.75 19.476H20.25C20.4667 19.476 20.646 19.547 20.788 19.689C20.93 19.831 21.0007 20.01 21 20.226C20.9993 20.442 20.9283 20.6213 20.787 20.764C20.6457 20.9067 20.4667 20.9763 20.25 20.975ZM10.25 11.501C11.4833 11.501 12.671 11.2843 13.813 10.851C14.955 10.4177 15.9507 9.751 16.8 8.851C16.8833 9.41767 17.1417 9.876 17.575 10.226C18.0083 10.576 18.5167 10.801 19.1 10.901C19.2833 10.9343 19.479 10.9387 19.687 10.914C19.895 10.8893 19.9993 10.7347 20 10.45V6.55C20 6.26667 19.896 6.11267 19.688 6.088C19.48 6.06333 19.284 6.06733 19.1 6.1C18.5167 6.21667 18.0083 6.45 17.575 6.8C17.1417 7.15 16.8833 7.61667 16.8 8.2C15.9167 7.33333 14.9127 6.66667 13.788 6.2C12.6633 5.73333 11.484 5.5 10.25 5.5C8.93333 5.5 7.75 5.696 6.7 6.088C5.65 6.48 4.68333 7.17567 3.8 8.175C3.78333 8.19167 3.74167 8.3 3.675 8.5C3.675 8.56667 3.71667 8.675 3.8 8.825C4.68333 9.825 5.65 10.521 6.7 10.913C7.75 11.305 8.93333 11.5007 10.25 11.5"
        fill="#044257"
      />
    </svg>
  )
}

export default MealIcon
