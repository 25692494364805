import { useAppSelector } from "app/hooks"
import { Fragment, useState } from "react"
import NotFound from "./NotFound"
import {
  AddMoreFoodsButton,
  FoodList,
  ScanMealResultsBody,
  ScanMealResultsWrapper,
} from "./styled"
import Header from "components/Header"
import MealTypePickerFormItem from "components/MealTypePicker"
import { useBackToHome, useSetMealTypeParams } from "features/meal/hooks/hooks"
import { IMealType } from "features/home/types"
import PlusIcon from "components/Icons/PlusIcon"
import FoodListItemSelectAble from "./FoodItemSelectable"
import { IAPIFood, IFood, IMyFood } from "features/food/foodTypes"
import APIFoodDetails from "components/APIFoodDetails"
import {
  IScannedFood,
  useScannedFoods,
} from "features/food/hooks/useScanedFoods"
import SearchFoods from "./SearchFoods"
import MyFoodDetails from "components/MyFoodDetails"
import { useLogScannedFoods } from "features/meal/hooks/scanMealHooks"
import CtaBox from "components/Common/CtaBox"
import Button from "components/Buttons/Button"
import { ILogMealPayload } from "features/meal/mealTypes"
import { useResetSearchFoodsState } from "features/meal/hooks/hooks"

enum ScanResultSection {
  Main = "Main",
  SearchFoods = "SearchFoods",
  FoodIngredientDetails = "FoodIngredientDetails",
}

const ScanningResult = () => {
  useResetSearchFoodsState()
  const step = useAppSelector((state) => state.meal.step)

  const [section, setSection] = useState<ScanResultSection>(
    ScanResultSection.Main,
  )

  const { mealType, setMealType } = useSetMealTypeParams()
  const { foods, onUpdateFood, toggleSelectFood, onAddFood } = useScannedFoods()
  const [viewDetailsFood, setViewDetailsFood] = useState<IFood | null>(null)
  const [isHideHeader, setIsHideHeader] = useState(false)

  const onViewFoodDetails = (food: IFood) => {
    setViewDetailsFood(food)
    setSection(ScanResultSection.FoodIngredientDetails)
  }

  const backToHome = useBackToHome()
  const { handleLogScannedFoods, logScannedFoodsLoading } =
    useLogScannedFoods(backToHome)

  const isNoFoodSelected = foods.every((food) => !food.isSelected)
  const onLogSelectedFoods = () => {
    const foodsPayload: ILogMealPayload[] = foods
      .filter((food) => food.isSelected)
      .map((food) => {
        const payload: ILogMealPayload = {
          type: mealType?.toUpperCase() as IMealType,
          name: food.name,
          unit: food.unit,
          portion: food.portion,
          calorie: food.calorie,
          protein: food.protein,
          carbohydrate: food.carbohydrate,
          fat: food.fat,
          ingredients: food.ingredients,
          units: food.units,
        }

        const foodId = food.originalId ?? food.id

        if (food.isExternal) {
          payload.imageUrl = food.imageUrl
          payload.externalFoodId = foodId
        } else {
          payload.imageId = food.imageId
          payload.foodId = foodId
        }

        return payload
      })

    handleLogScannedFoods(foodsPayload)
  }
  if (section === ScanResultSection.SearchFoods) {
    return (
      <ScanMealResultsWrapper>
        {!isHideHeader && (
          <Header
            onBack={() => {
              setSection(ScanResultSection.Main)
            }}
            title="Scan a meal"
          />
        )}

        <SearchFoods
          setIsHideHeader={setIsHideHeader}
          onAddFood={(food) => {
            onAddFood(food)
          }}
        />
      </ScanMealResultsWrapper>
    )
  }

  if (section === ScanResultSection.FoodIngredientDetails && viewDetailsFood) {
    if (viewDetailsFood.isExternal) {
      return (
        <APIFoodDetails
          editOnly
          onBack={() => setSection(ScanResultSection.Main)}
          food={viewDetailsFood as IAPIFood}
          mode="edit"
          onEdit={(food) => onUpdateFood(food as IScannedFood)}
        />
      )
    } else {
      return (
        <MyFoodDetails
          editOnly
          onBack={() => setSection(ScanResultSection.Main)}
          food={viewDetailsFood as IMyFood}
          mode="edit"
          onEdit={(food) => onUpdateFood(food)}
        />
      )
    }
  }

  return (
    <Fragment>
      {step === "not-found" ? (
        <NotFound />
      ) : (
        <ScanMealResultsWrapper>
          <Header
            onBack={() => {
              if (!logScannedFoodsLoading) {
                backToHome()
              }
            }}
            title="Scan a meal"
          />

          <ScanMealResultsBody>
            <MealTypePickerFormItem
              onChange={(value) => setMealType(value as IMealType)}
              value={mealType}
            />

            <div>Here is the list of foods we've detected from your photo.</div>
            <AddMoreFoodsButton
              disabled={logScannedFoodsLoading}
              onClick={() => {
                if (!logScannedFoodsLoading) {
                  setSection(ScanResultSection.SearchFoods)
                }
              }}
              fill="outline"
              style={{
                "--border-color": "#0da3ba",
              }}
            >
              <PlusIcon />
              <span>Add more foods</span>
            </AddMoreFoodsButton>

            <FoodList>
              {foods.map((food) => (
                <FoodListItemSelectAble
                  onViewDetails={onViewFoodDetails}
                  food={food}
                  key={food.id}
                  toggleSelectFood={toggleSelectFood}
                />
              ))}
            </FoodList>
          </ScanMealResultsBody>

          <CtaBox>
            <Button
              disabled={isNoFoodSelected}
              loading={logScannedFoodsLoading}
              onClick={onLogSelectedFoods}
              $type="primary"
            >
              Log this meal
            </Button>
          </CtaBox>
        </ScanMealResultsWrapper>
      )}
    </Fragment>
  )
}

export default ScanningResult
