import styled from "styled-components";

export const BackButton = styled.div<{ $disabled?: boolean }>`
  width: 24px;
  height: 24px;
  cursor: ${({ $disabled }) => ($disabled ? "not-allowed" : "pointer")};
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: ${({ $disabled }) => ($disabled ? 0.5 : 1)};
`
