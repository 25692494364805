import { SwipeAction } from "antd-mobile"
import styled from "styled-components"

export const StyledMealItem = styled.div`
  display: flex;
  padding-right: var(--spacing_l, 16px);
  align-items: flex-start;
  background: var(--Schemes-Surface-Container-Lowest, #fff);
  padding: 4px 8px;
`

export const MealImageBox = styled.div`
  display: flex;
  width: 38px;
  height: 38px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 6px;
  overflow: hidden;
`

export const ImagePlaceholderBox = styled.div`
  height: 38px;
  width: 38px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--Schemes-Primary-Container-Variant, #cceded);
  flex-shrink: 0;
  overflow: hidden;
`

export const MealImage = styled.img`
  border-radius: var(--spacing_xxs, 4px);
  object-fit: cover;
  width: 100%;
  height: 100%;
  overflow: hidden;
`

export const MealContent = styled.div`
  height: 100%;
  flex-grow: 1;
  padding-left: 8px;
  width: calc(100% - 53px - 16px - 40px);
  padding-right: 8px;
`
export const MealNameBox = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: var(--spacing_xs, 6px);
`

export const MealName = styled.div`
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 17px;
`

export const MealSlice = styled.div`
  font-size: 12px;
  font-weight: 600;
  color: var(--Schemes-On-Surface-Variant, #69757d);
`

export const MealCalorie = styled.div`
  padding: var(--spacing_xs, 6px) 0px;
  color: var(--Schemes-On-Surface-Variant, #69757d);
  font-weight: 500;
  flex-shrink: 0;
  font-size: 12px;
`

export const StyledSwipeAction = styled(SwipeAction)`
  --adm-color-danger: #ff4921;

  .adm-swipe-action-actions-right .adm-button {
    width: 93px;
  }

  .adm-swipe-action-content {
    border-top: 0.8px solid
      var(--Schemes-Outline-Variant, rgba(0, 38, 58, 0.14));
  }
`
