import { Fragment } from "react"

import { FoodListDivider } from "components/FoodListItem/styled"
import MealListItem from "components/MealListItem"
import { useLogMeal } from "features/meal/hooks/hooks"
import { useGoToMealDetails } from "features/meal/hooks/mealDetailsHooks"
import { useSuggestedMeals } from "../hooks/useSuggestedMeals"
import { useSetPageTitle } from "libs/ga"

const SuggestedMeals = () => {
  useSetPageTitle("Suggested Meals")
  const { suggestedMeals } = useSuggestedMeals()
  const { handleLogMealByMeal } = useLogMeal()
  const goToMealDetails = useGoToMealDetails()

  return (
    <Fragment>
      {suggestedMeals.map((meal, index) => (
        <div key={meal.id}>
          {" "}
          <MealListItem
            onSelect={() => goToMealDetails(meal)}
            meal={meal}
            onAdd={handleLogMealByMeal}
          />
          {index !== suggestedMeals.length - 1 && <FoodListDivider />}
        </div>
      ))}
    </Fragment>
  )
}

export default SuggestedMeals
