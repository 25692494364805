import { DotLoading } from "antd-mobile"
import React from "react"
import styled from "styled-components"

export const Mask = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(5, 5, 5, 0.48);
  position: absolute;
  top: 0;
  z-index: 100000;
`

export const MaskInner = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  bottom: 40px;
  z-index: 100000;
`

const LoadingContent = styled.div`
  color: #fff;
  font-size: 32px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
`

const AppLoading = ({
  content,
  style = {},
}: {
  content?: string
  style?: React.CSSProperties
}) => {
  return (
    <Mask>
      <MaskInner>
        {content && <LoadingContent>{content}</LoadingContent>}

        <DotLoading
          color="#fff"
          style={{
            height: 40,
            display: "flex",
            alignItems: "flex-end",
            ...style,
            fontSize: 20,
          }}
        />
      </MaskInner>
    </Mask>
  )
}

export default AppLoading
