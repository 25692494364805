import { useAppSelector } from "app/hooks"
import { useEffect, useState } from "react"
import WithMfa from "./WithMfa"
import SignInWithEmailForm from "./EmailForm"
import styled from "styled-components"

const Wrapper = styled.div`
  height: 100%;
  background: #ebedf0;
`

const LogInWithEmail = () => {
  const [isSignInWithMfa, setIsSignInWithMfa] = useState(false)

  const mfaMaskedPhoneNumber = useAppSelector(
    (state) => state.auth.mfaMaskedPhoneNumber,
  )
  const mfaSession = useAppSelector((state) => state.auth.mfaSession)

  useEffect(() => {
    if (mfaSession && mfaMaskedPhoneNumber) {
      setIsSignInWithMfa(true)
    } else {
      setIsSignInWithMfa(false)
    }
  }, [mfaSession, mfaMaskedPhoneNumber])

  return (
    <Wrapper>{isSignInWithMfa ? <WithMfa /> : <SignInWithEmailForm />}</Wrapper>
  )
}

export default LogInWithEmail
