import { Button } from "antd-mobile"
import styled from "styled-components"

export const ScanMealResultsWrapper = styled.div`
  --header-height: 44px;
  --footer-height: 80px;
  height: 100%;
`

export const ScanMealResultsBody = styled.div`
  height: calc(100% - var(--header-height) - var(--footer-height));
  display: flex;
  padding: 8px 16px 0px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
`

export const AddMoreFoodsButton = styled(Button)`
  width: 100%;
  display: flex;
  padding: 7px 24px;
  justify-content: space-between;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  overflow: hidden;

  span {
    height: 24px;
    display: flex;
    align-items: center;
    gap: 8px;

    ont-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 14px; /* 100% */
    letter-spacing: 0.14px;
    color: var(--Schemes-Primary, #0da3ba);
  }
`

export const FoodList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`
