import { useSetPageTitle } from "libs/ga"
import {
  FullLoading,
  OverviewButton,
  OverviewHeader,
  OverviewMain,
  OverViewTypeSettings,
  StyledDay,
  StyledOverview,
} from "./styled"
import { StyledPopup } from "components/StyledPopup"
import { FlexBox } from "components/Common/FlexBox"
import { BackButton } from "components/Common/BackButton"
import SmallChevronLeft from "components/Icons/SmallChevronLeft"
import SmallChevronRight from "components/Icons/SmallChevronRight"
import DropdownDownIcon from "components/Icons/DropdownDownIcon"
import {
  useDailyOverview,
  useDateSearchParam,
  useWeeklyOverview,
} from "../hooks"
import { useState } from "react"
import DatePicker from "components/DatePicker"
import DailyPieChart from "./DailyPieChart"
import MealTypePercent from "./MealTypePercent"
import CaloriesRemaining from "./CaloriesRemaining"
import MostMeals from "./MostMeals"
import NoData from "./NoData"
import { ICalendarPeriod } from "../types"
import WeeklyBarChart from "./WeeklyBarChart"
import dayjs, { Dayjs } from "dayjs"
import { SpinLoading } from "antd-mobile"
import { US_DATE_FORMAT_WITH_YEAR, US_DATE_FORMAT_WITHOUT_YEAR } from "config"

const getDateTitle = (date: Dayjs, period: ICalendarPeriod) => {
  if (period === "daily") {
    return date.isSame(dayjs(), "date")
      ? "Today"
      : date.format(US_DATE_FORMAT_WITH_YEAR)
  }

  const isThisWeek = date.isSame(dayjs(), "week")

  if (isThisWeek) {
    return "This week"
  }

  const startOfWeek = date.startOf("week")
  const endOfWeek = date.endOf("week")
  const isSameMonth = startOfWeek.isSame(endOfWeek, "month")

  if (!startOfWeek.isSame(dayjs(), "year")) {
    return `${startOfWeek.format(
      US_DATE_FORMAT_WITH_YEAR,
    )} - ${endOfWeek.format(US_DATE_FORMAT_WITH_YEAR)}`
  }

  // if (isSameMonth) {
  //   return `${startOfWeek.format(
  //     US_DATE_FORMAT_WITHOUT_YEAR,
  //   )} - ${endOfWeek.format(US_DATE_FORMAT_WITHOUT_YEAR)}`
  // }

  const isSameYear = startOfWeek.isSame(endOfWeek, "year")
  if (isSameYear) {
    return `${startOfWeek.format(
      US_DATE_FORMAT_WITHOUT_YEAR,
    )} - ${endOfWeek.format(US_DATE_FORMAT_WITH_YEAR)}`
  }

  return `${startOfWeek.format(US_DATE_FORMAT_WITH_YEAR)} - ${endOfWeek.format(
    US_DATE_FORMAT_WITH_YEAR,
  )}`
}

const Overview = () => {
  const [overviewPeriod, setOverviewPeriod] =
    useState<ICalendarPeriod>("weekly")

  useSetPageTitle("Overview")
  const [showCalendar, setShowCalendar] = useState(false)
  const {
    date,
    onBackToYesterday,
    onGoToTomorrow,
    onDateChange,
    dateString,
    onGoToNextWeek,
    onGoToPreviousWeek,
  } = useDateSearchParam()

  const { weeklyOverview, getWeeklyOverviewLoading } =
    useWeeklyOverview(dateString)

  const { dailyOverview, getDailyOverviewLoading } =
    useDailyOverview(dateString)

  const loading =
    overviewPeriod === "daily"
      ? getDailyOverviewLoading
      : getWeeklyOverviewLoading

  const data = overviewPeriod === "daily" ? dailyOverview : weeklyOverview

  const dateTitle = getDateTitle(date, overviewPeriod)

  const onPeriodChange = (period: ICalendarPeriod) => {
    if (overviewPeriod !== period) {
      setOverviewPeriod(period)

      if (period === "daily") {
        onDateChange(dayjs())
      } else {
        onDateChange(dayjs().startOf("week"))
      }
    }
  }

  const hasData = data && data.gainedCalories > 0

  return (
    <StyledOverview>
      <StyledPopup
        position="top"
        visible={showCalendar}
        onMaskClick={() => setShowCalendar(false)}
      >
        <DatePicker
          onChange={(date) => {
            onDateChange(date)
            setShowCalendar(false)
          }}
          value={date}
          period={overviewPeriod}
        />
      </StyledPopup>

      <div style={{ height: 4, background: "#fff", width: "100%" }}></div>

      <OverviewHeader>
        <FlexBox style={{ width: "100%" }} $justify="space-between">
          <BackButton
            onClick={() => {
              if (overviewPeriod === "daily") {
                onBackToYesterday()
              } else {
                onGoToPreviousWeek()
              }
            }}
          >
            <SmallChevronLeft />
          </BackButton>

          <FlexBox
            $alignItems="center"
            $gap={4}
            onClick={() => setShowCalendar(true)}
          >
            <StyledDay>{dateTitle}</StyledDay>
            <DropdownDownIcon />
          </FlexBox>

          <BackButton
            onClick={() => {
              if (overviewPeriod === "daily") {
                onGoToTomorrow()
              } else {
                onGoToNextWeek()
              }
            }}
          >
            <SmallChevronRight />
          </BackButton>
        </FlexBox>

        <FlexBox style={{ width: "100%" }} $justify="center">
          <OverViewTypeSettings>
            <OverviewButton
              $isActive={overviewPeriod === "weekly"}
              onClick={() => onPeriodChange("weekly")}
            >
              Week view
            </OverviewButton>
            <OverviewButton
              $isActive={overviewPeriod === "daily"}
              onClick={() => onPeriodChange("daily")}
            >
              Day view
            </OverviewButton>
          </OverViewTypeSettings>
        </FlexBox>
      </OverviewHeader>

      <OverviewMain>
        {loading ? (
          <FullLoading>
            <SpinLoading style={{ marginTop: "-300px" }} />
          </FullLoading>
        ) : hasData ? (
          <div style={{ background: "#fff", paddingTop: 8 }}>
            {overviewPeriod === "daily" && dailyOverview && (
              <FlexBox $justify="center">
                <DailyPieChart data={dailyOverview.mealTypePercent} />
              </FlexBox>
            )}

            {overviewPeriod === "weekly" && weeklyOverview && (
              <FlexBox style={{ width: "100%", height: 205 }}>
                <WeeklyBarChart
                  averageGainedCaloriesPerDay={
                    weeklyOverview.gainedCalories / 7
                  }
                  data={weeklyOverview.weeklyChartData}
                />
              </FlexBox>
            )}

            <MealTypePercent mealTypePercent={data.mealTypePercent} />

            {overviewPeriod === "daily" && dailyOverview && (
              <CaloriesRemaining
                caloriesPerDay={dailyOverview.caloriesPerDay}
                gainedCalories={dailyOverview.gainedCalories}
              />
            )}
          </div>
        ) : (
          <NoData />
        )}

        {data && !loading && <MostMeals mostMeals={data.mostMeals} />}
      </OverviewMain>
    </StyledOverview>
  )
}

export default Overview
