import { Fragment, useEffect, useState } from "react"
import {
  StyledInput,
  StyledLabel,
  StyledInputNumberFormItem,
  PickerPopupStyle,
  StyledPickerView,
  PickerText,
  PickerSelectedMask,
  PickerViewContainer,
  PickerHeader,
  PickerHeaderTop,
  PickerHeaderBottom,
  DoneButton,
  PickerTextFeet,
  PickerTextInches,
  StyledPickerViewInches,
} from "./styled"
import { Popup } from "antd-mobile"

const PopupIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="37"
      height="4"
      viewBox="0 0 37 4"
      fill="none"
    >
      <rect
        x="0.5"
        width="36"
        height="4"
        rx="2"
        fill="#00263A"
        fillOpacity="0.14"
      />
    </svg>
  )
}

interface IProps {
  feet: number
  inches: number
  isError?: boolean
  disabled?: boolean
  onChange: (feet: number, inches: number) => void
}

const feetColumns = Array.from(Array(9))
  .fill(0)
  .map((_, index) => {
    const value = index + 1
    return {
      label: value.toString(),
      value,
    }
  })

const inchesColumn = Array.from(Array(12))
  .fill(0)
  .map((_, index) => {
    return {
      label: index.toString(),
      value: index,
    }
  })

const HeightInput = (props: IProps) => {
  const { feet, inches, onChange } = props
  const [visible, setVisible] = useState(false)
  const [localFeet, setLocalFeet] = useState(feet)
  const [localInches, setLocalInches] = useState(inches)

  useEffect(() => {
    setLocalFeet(feet)
    setLocalInches(inches)
  }, [feet, inches])

  let displayValue = `${feet}'`
  if (inches > 0) {
    displayValue += `${inches}"`
  }

  return (
    <Fragment>
      <PickerPopupStyle />
      <Popup
        onClose={() => setVisible(false)}
        closeOnMaskClick={true}
        visible={visible}
        bodyStyle={{
          borderRadius: "12px 12px 0 0",
        }}
      >
        <PickerHeader>
          <PickerHeaderTop>
            <PopupIcon />
          </PickerHeaderTop>
          <PickerHeaderBottom>
            <DoneButton
              onClick={() => {
                onChange(localFeet, localInches)
                setVisible(false)
              }}
            >
              Done
            </DoneButton>
          </PickerHeaderBottom>
        </PickerHeader>

        <PickerViewContainer>
          <StyledPickerView
            columns={[feetColumns]}
            value={[localFeet]}
            onChange={(value) => {
              setLocalFeet(value[0] as number)
            }}
          />

          <PickerTextFeet>
            <span>ft</span>
          </PickerTextFeet>

          <StyledPickerViewInches
            columns={[inchesColumn]}
            value={[localInches]}
            onChange={(value) => {
              setLocalInches(value[0] as number)
            }}
          />

          <PickerTextInches>
            <span>in</span>
          </PickerTextInches>

          <PickerSelectedMask />
        </PickerViewContainer>
      </Popup>

      <StyledInputNumberFormItem>
        <StyledLabel>Height</StyledLabel>

        <StyledInput
          $isError={false}
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            setVisible(true)
          }}
          onFocus={(e) => {
            e.preventDefault()
            e.stopPropagation()
          }}
          value={displayValue}
          onChange={(e) => {}}
          style={{
            paddingRight: 16,
          }}
          readOnly={true}
        />
      </StyledInputNumberFormItem>
    </Fragment>
  )
}

export default HeightInput
