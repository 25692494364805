import styled from "styled-components"

export const StyledFoodListItem = styled.div`
  display: flex;
  align-items: center;
  padding: 4px 0;
  gap: var(--spacing_xl, 24px);
`

export const FoodLeft = styled.div`
  display: flex;
  gap: 8px;
  flex-grow: 1;
  max-width: calc(100% - 32px - 24px);
`

export const FoodImageBox = styled.div`
  height: 38px;
  width: 40px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--Schemes-Primary-Container-Variant, #cceded);
  flex-shrink: 0;
  overflow: hidden;
`

export const FoodInfoBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: calc(100% - 40px - 8px - 32px - 24px);
  flex-grow: 1;
  cursor: default;
`

export const FoodNameBox = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: var(--spacing_xs, 6px);
`

export const FoodName = styled.p`
  font-size: 14px;
  line-height: 160%;
  color: var(--Schemes-On-Surface, #1c1f21);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  .high-light-text {
    background-color: #fff;
    font-weight: 600;
  }
`

export const FoodNutrient = styled.div`
  color: var(--Schemes-On-Surface-Variant, #69757d);
  font-size: 12px;
  font-weight: 500;
  line-height: 12px; /* 100% */
  letter-spacing: 0.12px;
`

export const AddFoodButton = styled.div`
  display: flex;
  padding: var(--spacing_xxs, 4px);
  align-items: center;
  justify-content: center;
  gap: 10px;
  border-radius: 20px;
  width: 32px;
  height: 32px;
  background: var(--Schemes-Surface-Container-Low, #f5f7f7);
  flex-shrink: 0;
  background: rgb(245, 247, 247);
  cursor: pointer;
`

export const FoodListDivider = styled.div`
margin: 4px 0;
height: 0.5px;
background: var(--Schemes-Outline, rgba(0, 38, 58, 0.14));
width: 100%;

@supports (-webkit-touch-callout: none) {
  height: 0.8px;
`
