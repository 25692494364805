import React from "react"

const ScanMealIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="31"
      height="26"
      viewBox="0 0 31 26"
      fill="none"
    >
      <path
        d="M16.9958 9.63947C18.7316 9.42364 20.2343 10.4265 20.6819 12.1464L20.6811 12.1466C20.8384 12.7512 20.8395 13.361 20.7686 13.9769C20.6582 14.9377 20.3797 15.8503 19.9586 16.7208C19.5685 17.5273 19.0751 18.2623 18.3593 18.8331C17.9204 19.1831 17.4299 19.4283 16.864 19.4956C16.4261 19.5477 15.9957 19.4969 15.5858 19.3277L15.585 19.3274C15.5319 19.3057 15.4711 19.3057 15.4181 19.3274L15.4173 19.3277C15.0076 19.4969 14.5769 19.5477 14.139 19.4956C13.5731 19.4283 13.0827 19.1831 12.6437 18.8331C11.9282 18.2626 11.4349 17.5276 11.0444 16.7208C10.6233 15.8503 10.3449 14.9377 10.2345 13.9769C10.1638 13.361 10.1646 12.751 10.322 12.1464C10.7565 10.4769 12.185 9.48296 13.856 9.62364C13.7524 9.58459 13.6501 9.5399 13.5491 9.48955C13.3352 9.38348 13.1439 9.24653 12.9751 9.07869C12.6038 8.70945 12.3647 8.27016 12.258 7.76083C12.1509 7.25194 12.1255 6.73456 12.1817 6.20866C12.7107 6.15272 13.2313 6.17778 13.7435 6.28386C14.2554 6.39038 14.6969 6.62826 15.0683 6.9975C15.3272 7.25485 15.5155 7.54577 15.6335 7.87026C15.7519 8.19474 15.8279 8.53601 15.8617 8.89407C16.008 8.5472 16.1854 8.21981 16.3938 7.91188C16.6017 7.6044 16.8352 7.32199 17.094 7.06464C17.2178 6.94156 17.3753 6.88002 17.5666 6.88002C17.758 6.88002 17.9155 6.94156 18.0393 7.06464C18.1631 7.18772 18.225 7.34437 18.225 7.53458C18.225 7.7248 18.1631 7.88145 18.0393 8.00453C17.7917 8.25069 17.5723 8.52192 17.381 8.8182C17.214 9.07714 17.0856 9.35089 16.9958 9.63947Z"
        fill="#044257"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.5 1.54762C0.5 0.764273 1.10879 0 2.01442 0H8.3125C8.65768 0 8.9375 0.279822 8.9375 0.625V1.23214C8.9375 1.57732 8.65768 1.85714 8.3125 1.85714H3C2.65482 1.85714 2.375 2.13697 2.375 2.48214V6.49405C2.375 6.83923 2.09518 7.11905 1.75 7.11905H1.125C0.779822 7.11905 0.5 6.83923 0.5 6.49405V1.54762Z"
        fill="#044257"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.5 24.4524C0.5 25.2357 1.10879 26 2.01442 26H8.3125C8.65768 26 8.9375 25.7202 8.9375 25.375V24.7679C8.9375 24.4227 8.65768 24.1429 8.3125 24.1429H3C2.65482 24.1429 2.375 23.863 2.375 23.5179V19.5059C2.375 19.1608 2.09518 18.8809 1.75 18.8809H1.125C0.779822 18.8809 0.5 19.1608 0.5 19.5059V24.4524Z"
        fill="#044257"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.5 1.54762C30.5 0.764273 29.8912 0 28.9856 0H22.6875C22.3423 0 22.0625 0.279822 22.0625 0.625V1.23214C22.0625 1.57732 22.3423 1.85714 22.6875 1.85714H28C28.3452 1.85714 28.625 2.13697 28.625 2.48214V6.49405C28.625 6.83923 28.9048 7.11905 29.25 7.11905H29.875C30.2202 7.11905 30.5 6.83923 30.5 6.49405V1.54762Z"
        fill="#044257"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.5 24.4524C30.5 25.2357 29.8912 26 28.9856 26H22.6875C22.3423 26 22.0625 25.7202 22.0625 25.375V24.7679C22.0625 24.4227 22.3423 24.1429 22.6875 24.1429H28C28.3452 24.1429 28.625 23.863 28.625 23.5179V19.5059C28.625 19.1608 28.9048 18.8809 29.25 18.8809H29.875C30.2202 18.8809 30.5 19.1608 30.5 19.5059V24.4524Z"
        fill="#044257"
      />
    </svg>
  )
}

export default ScanMealIcon
