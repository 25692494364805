import { Link } from "react-router-dom"
import styled from "styled-components"

export const StyledAuthLayout = styled.div`
  --auth-header-height: 126px;
  --auth-header-padding-top: 36px;
  --overlay-overflow: 25px;

  // background-image: url("banner.jpeg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-position: top center;
  height: 100%;
  position: relative;
  overflow: hidden;
`

export const HeaderWrapper = styled.div`
  padding-top: calc(var(--auth-header-padding-top));
  position: relative;
  height: calc(
    var(--auth-header-height) + var(--auth-header-padding-top) +
      var(--overlay-overflow)
  );
`

export const Header = styled.div`
  padding: 24px 0;
  padding-top: 0;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-feature-settings: "liga" off, "clig" off;
  color: #fff;
`

export const TopOverlay = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.17) 27.8%,
    rgba(0, 0, 0, 0.36) 100%
  );
`

export const HeaderTitle = styled.div`
  font-weight: bold;
  font-size: 36px;
  line-height: 120%;
  letter-spacing: 1px;
  font-family: proxima-nova;
  text-align: center;
  font-weight: 800;
`

export const HeaderSubTitle = styled.div`
  font-size: 12px;
  font-weight: 700;
  line-height: 160%;
  letter-spacing: 0.48px;
  text-transform: uppercase;
  font-family: proxima-nova;
  margin-top: -10px;
`

export const AuthWrapper = styled.div`
  display: flex;
  height: calc(
    100% - 0px -
      calc(var(--auth-header-height) + var(--auth-header-padding-top))
  );
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  position: relative;

  padding: 16px;
  padding-bottom: 32px;
  margin-top: calc(0px - var(--overlay-overflow));
`

export const BottomOverlay = styled.div`
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: 690px;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.38) 18.07%,
    #000 100%
  );
`

export const Footer = styled.div`
  z-index: 1;
  margin-top: 36px;
`

export const FooterText = styled.p`
  color: rgba(255, 255, 255, 0.8);
  text-align: center;
  font-family: proxima-nova;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 22.4px */
  margin: 0;

  span {
    display: inline;
    font-weight: 400;
  }
`

export const FooterTextSpan = styled(Link)`
  color: var(--color-primary);
  font-size: 14px;
  text-decoration: none;
`

export const CTABox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
`

export const TitleBox = styled.div`
  margin-bottom: 32px;
  font-style: normal;
  color: #fff;
  text-align: center;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
`

export const Title = styled.div`
  font-family: proxima-nova;
  font-size: 28px;
  line-height: 120%;
  font-weight: 800;
  text-align: center;
`

export const SubTitle = styled(Title)`
  font-size: 20px;
  color: var(--Schemes-Primary-Container, #4bd9de);
`

export const Description = styled.div`
  font-family: proxima-nova;
  font-size: 16px;
  font-weight: 500;
  line-height: 160%;
`

export const SignUpText = styled.p`
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
  color: var(--White-White, #fff);
  text-align: center;
`

export const SignUpBtn = styled.a`
  display: flex;
  height: 48px;
  padding: var(--spacing_m, 12px) 10px;

  justify-content: center;
  align-items: center;
  gap: var(--spacing_s, 8px);

  align-self: stretch;

  border-radius: 100px;
  border: 1px solid var(--Schemes-Primary-Container, #4bd9de);

  font-size: 16px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0.16px;
  color: var(--Schemes-Primary-Container, #4bd9de);
  text-align: center;

  width: 100%;
  text-decoration: none;
`
