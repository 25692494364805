import { useAppDispatch, useAppSelector } from "app/hooks"
import { useFailed } from "features/notification/hooks"
import {
  getFoodDetails,
  setDetailsBackPath,
  setFoodDetails,
} from "../foodSlice"
import { useEffect } from "react"
import {
  generatePath,
  useLocation,
  useNavigate,
  useParams,
  useResolvedPath,
  useSearchParams,
} from "react-router-dom"
import { IFood } from "../foodTypes"
import PATHS from "router/paths"

export const useFoodDetails = () => {
  const params = useParams()
  const id = params.id
  const foodDetails = useAppSelector((state) => state.food.foodDetails)
  const getFoodDetailsLoading = useAppSelector(
    (state) => state.food.getFoodDetailsLoading,
  )
  const getFoodDetailsFailed = useAppSelector(
    (state) => state.food.getFoodDetailsFailed,
  )

  useFailed(getFoodDetailsFailed)

  const dispatch = useAppDispatch()

  useEffect(() => {
    if (id && foodDetails?.id !== id) {
      dispatch(getFoodDetails(id))
    }
  }, [dispatch])

  return {
    foodDetails,
    getFoodDetailsLoading,
  }
}

export const useGoToFoodDetails = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  const [searchParams] = useSearchParams()

  const onGoToFoodDetails = (food: IFood, path?: string) => {
    dispatch(setFoodDetails(food))

    const mealType = searchParams.get("mealType")
    navigate(
      generatePath(
        `${PATHS.app.food.details}${mealType ? `?mealType=${mealType}` : ""}`,
        { id: food.originalId ?? food.id },
      ),
    )
    dispatch(setDetailsBackPath(path ?? location.pathname))
  }

  return onGoToFoodDetails
}

export const useFoodDetailsOnBack = () => {
  const detailsBackPath = useAppSelector((state) => state.food.detailsBackPath)
  const navigate = useNavigate()

  return () => navigate(detailsBackPath ?? PATHS.app.root)
}
