import SearchFoodResults from "components/SearchFoodResults"
import SuggestedSearchTexts from "components/SuggestedSearchTexts"
import {
  IFood,
  ISearchFoodOverlayDisplaySection,
} from "features/food/foodTypes"
import {
  useSearchFoodOverlayDisplaySection,
  useSearchFoods,
  useSuggestedSearchTexts,
} from "features/food/hooks"
import { useEffect } from "react"
import { SearchFoodOverlayWrapper, SearchInputBox, StyledMask } from "./styled"
import SearchAutocomplete from "components/SearchAutocomplete"

const { AUTOCOMPLETE, SEARCH_RESULTS } = ISearchFoodOverlayDisplaySection

interface IProps {
  onAdd: (food: IFood) => void
  onSelect: (food: IFood) => void
  visible: boolean
  onClose: () => void
}

const SearchFoodOverlay = (props: IProps) => {
  const { onAdd, onSelect, visible, onClose } = props

  const {
    searchFoodOverlayDisplaySection: displaySection,
    setSearchFoodOverlayDisplaySection: setDisplaySection,
  } = useSearchFoodOverlayDisplaySection()

  useEffect(() => {
    if (visible) {
    }
  }, [visible])

  const {
    keyword,
    suggestedSearchTexts,
    handleKeywordChange,
    isNoSuggestedSearchTexts,
  } = useSuggestedSearchTexts()

  const {
    searchFoodsResults,
    handleSearchFoods,
    searchFoodsLoading,
    searchSession,
    noSearchedFoodFounded,
  } = useSearchFoods()

  return (
    <StyledMask visible={visible}>
      <SearchFoodOverlayWrapper>
        <SearchInputBox>
          <SearchAutocomplete
            visible={visible}
            onFocus={() => {}}
            placeholder="Search"
            value={keyword}
            onChange={(keyword) => {
              handleKeywordChange(keyword)
              if (displaySection !== AUTOCOMPLETE) {
                setDisplaySection(AUTOCOMPLETE)
              }
            }}
            onClose={() => {
              handleKeywordChange("")
              onClose()
            }}
            onBlur={() => {}}
            onSearch={() => {
              const forceReset = true
              handleSearchFoods({ nextKeyword: keyword, forceReset })
              setDisplaySection(SEARCH_RESULTS)
            }}
          />
        </SearchInputBox>

        {displaySection === AUTOCOMPLETE && (
          <div style={{ padding: "0 16px" }}>
            <SuggestedSearchTexts
              isNoSuggestedSearchTexts={isNoSuggestedSearchTexts}
              suggestedTexts={suggestedSearchTexts}
              keyword={keyword}
              onSelect={(keyword) => {
                const forceReset = true
                handleSearchFoods({ nextKeyword: keyword, forceReset })
                setDisplaySection(SEARCH_RESULTS)
              }}
            />
          </div>
        )}

        {displaySection === SEARCH_RESULTS && (
          <SearchFoodResults
            onAddFood={onAdd}
            onSelectFood={onSelect}
            noSearchedFoodFounded={noSearchedFoodFounded}
            foods={searchFoodsResults}
            onLoad={() => handleSearchFoods({ nextKeyword: keyword })}
            loading={searchFoodsLoading}
            hasMore={!!searchSession}
            keyword={keyword}
            hasCreateLink
          />
        )}
      </SearchFoodOverlayWrapper>
    </StyledMask>
  )
}

export default SearchFoodOverlay
