import { useAppDispatch, useAppSelector } from "app/hooks"
import { useFailed, useSuccess } from "features/notification/hooks"
import { useEffect } from "react"
import { getDailyScanProgress, logScannedFoods } from "../mealSlice"
import { ILogMealStep } from "../types"
import { ILogMealPayload } from "../mealTypes"

export const useDailyScanProgress = (step: ILogMealStep) => {
  const dailyScanProgress = useAppSelector(
    (state) => state.meal.dailyScanProgress,
  )
  const getDailyScanProgressLoading = useAppSelector(
    (state) => state.meal.getDailyScanProgressLoading,
  )
  const getDailyScanProgressFailed = useAppSelector(
    (state) => state.meal.getDailyScanProgressFailed,
  )

  const scanMealFailed = useAppSelector((state) => state.meal.scanMealFailed)

  useFailed(getDailyScanProgressFailed)

  const dispatch = useAppDispatch()

  useEffect(() => {
    if (step === "init" && !scanMealFailed) {
      dispatch(getDailyScanProgress())
    }
  }, [dispatch, step, scanMealFailed])

  const isScanDisabled =
    !dailyScanProgress ||
    dailyScanProgress.remainingScan === 0 ||
    getDailyScanProgressLoading

  return { dailyScanProgress, getDailyScanProgressLoading, isScanDisabled }
}

export const useLogScannedFoods = (callback?: () => void) => {
  const logScannedFoodsLoading = useAppSelector(
    (state) => state.meal.logScannedFoodsLoading,
  )
  const logScannedFoodsFailed = useAppSelector(
    (state) => state.meal.logScannedFoodsFailed,
  )
  const logScannedFoodsSuccess = useAppSelector(
    (state) => state.meal.logScannedFoodsSuccess,
  )

  const dispatch = useAppDispatch()

  const handleLogScannedFoods = (foods: ILogMealPayload[]) => {
    dispatch(logScannedFoods(foods))
  }

  useFailed(logScannedFoodsFailed)

  useSuccess(logScannedFoodsSuccess, { message: "" }, callback)

  return { logScannedFoodsLoading, handleLogScannedFoods }
}
