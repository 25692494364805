import { useAppDispatch, useAppSelector } from "app/hooks"
import {
  scanMeal,
  processImage,
  removeUploadedImage,
  resetMealState,
  setStep,
  uploadImage,
  logMeal,
} from "../mealSlice"
import { ILogMealStep, IMeal } from "../types"
import { useNavigate, useSearchParams } from "react-router-dom"
import { IMealType } from "features/home/types"
import { useEffect } from "react"
import PATHS from "router/paths"
import dayjs from "dayjs"
import { useFailed, useSuccess } from "features/notification/hooks"
import { DATE_PARAM_FORMAT } from "config"
import { ILogMealPayload } from "../mealTypes"
import { IFood } from "features/food/foodTypes"
import { resetSearchedFoodsState } from "features/food/foodSlice"
import { useSuggestedSearchTexts } from "features/food/hooks"

export const useScanMeal = () => {
  const scanMealLoading = useAppSelector((state) => state.meal.scanMealLoading)
  const scanMealFailed = useAppSelector((state) => state.meal.scanMealFailed)
  const file = useAppSelector((state) => state.meal.file)
  const imageId = useAppSelector((state) => state.meal.imageId)
  const displayImageType = useAppSelector(
    (state) => state.meal.displayImageType,
  )

  const [searchParams] = useSearchParams()

  const dispatch = useAppDispatch()
  const selectedDate = useAppSelector((state) => state.home.selectedDate)

  const handleScanMeal = () => {
    const type = searchParams.get("mealType")
    if (!type) {
      return
    }

    const isToDay = selectedDate && selectedDate.isSame(dayjs(), "day")
    const date = isToDay ? undefined : selectedDate?.format(DATE_PARAM_FORMAT)

    const mealType = type.toUpperCase() as IMealType
    if (displayImageType === "base64" && file) {
      dispatch(scanMeal({ image: file }))
    }

    if (displayImageType === "url" && imageId) {
      dispatch(scanMeal({ imageId }))
    }
  }

  useFailed(scanMealFailed)

  return {
    scanMealLoading,
    scanMealFailed,
    handleScanMeal,
  }
}

export const useUploadImage = () => {
  const imageBase64Encoded = useAppSelector(
    (state) => state.meal.imageBase64Encoded,
  )

  const dispatch = useAppDispatch()

  const onUploadImage = (base64: string, file: File) => {
    dispatch(uploadImage({ base64, file }))
  }

  const onRemoveUploadedImage = () => {
    dispatch(removeUploadedImage())
  }

  return {
    imageBase64Encoded,
    onUploadImage,
    onRemoveUploadedImage,
  }
}

export const useResetMealState = () => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    return () => {
      dispatch(resetMealState())
    }
  }, [dispatch])
}

export const useProcessImage = () => {
  const dispatch = useAppDispatch()

  const imageUrl = useAppSelector((state) => state.meal.imageUrl)
  const processImageLoading = useAppSelector(
    (state) => state.meal.processImageLoading,
  )

  const displayImageType = useAppSelector(
    (state) => state.meal.displayImageType,
  )

  const handleProcessImage = (file: File) => {
    dispatch(processImage(file))
  }

  return {
    imageUrl,
    processImageLoading,
    handleProcessImage,
    displayImageType,
  }
}

export const useLogMealStep = () => {
  const dispatch = useAppDispatch()

  const handleSetStep = (step: ILogMealStep) => {
    dispatch(setStep(step))
  }

  return handleSetStep
}

export const useHomePathWithSelectedDate = () => {
  const selectedDate = useAppSelector((state) => state.home.selectedDate)

  return (
    PATHS.app.root +
    "?" +
    new URLSearchParams({
      date: selectedDate?.format(DATE_PARAM_FORMAT) ?? "",
    }).toString()
  )
}

export const useBackToHome = () => {
  const navigate = useNavigate()
  const homePath = useHomePathWithSelectedDate()
  return () => {
    navigate(homePath)
  }
}

export const useSetMealTypeParams = (notRedirect?: boolean) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const mealType = searchParams.get("mealType") ?? ""

  const setMealType = (mealType: IMealType) => {
    setSearchParams({ mealType: mealType.toLowerCase() })
  }

  useEffect(() => {
    if (notRedirect) {
      return
    }

    const MEAL_TYPES = ["breakfast", "lunch", "dinner", "snack"]

    if (!MEAL_TYPES.includes(mealType as string)) {
      setSearchParams({ mealType: "breakfast" })
    }
  }, [])

  return { mealType, setMealType }
}

const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
const safariVersion = navigator.userAgent.match(/Version\/(\d+)/)
let isSafari17 = isSafari && safariVersion && parseInt(safariVersion[1]) >= 17

const getBase64 = (file: File | Blob, callback: (url: string) => void) => {
  const reader = new FileReader()
  reader.addEventListener("load", () => callback(reader.result as string))
  reader.readAsDataURL(file)
}

export const useImageUploader = () => {
  const { onUploadImage, imageBase64Encoded, onRemoveUploadedImage } =
    useUploadImage()
  const {
    processImageLoading,
    handleProcessImage,
    imageUrl,
    displayImageType,
  } = useProcessImage()

  const onImageChange = (file?: File) => {
    if (file) {
      const isHeicFile = file.type === "image/heic"
      const isHeifFile = file.type === "image/heif"
      const shouldConvert = isHeicFile || isHeifFile

      if (shouldConvert && !isSafari17) {
        handleProcessImage(file)
      } else {
        getBase64(file, (base64) => {
          onUploadImage(base64, file)
        })
      }
    }
  }

  const displayImageUrl =
    displayImageType === "base64" ? imageBase64Encoded : imageUrl

  return {
    onImageChange,
    onRemoveUploadedImage,
    processImageLoading,
    displayImageUrl,
  }
}

export const useLogMeal = (callback?: () => void) => {
  const logMealLoading = useAppSelector((state) => state.meal.logMealLoading)
  const logMealFailed = useAppSelector((state) => state.meal.logMealFailed)
  const logMealSuccess = useAppSelector((state) => state.meal.logMealSuccess)

  const [searchParams] = useSearchParams()

  const dispatch = useAppDispatch()
  const mealType = searchParams.get("mealType") as string

  const handleLogMealByFood = (food: IFood) => {
    const payload: ILogMealPayload = {
      type: mealType?.toUpperCase() as IMealType,
      name: food.name,
      unit: food.unit,
      portion: food.portion,
      calorie: food.calorie,
      protein: food.protein,
      carbohydrate: food.carbohydrate,
      fat: food.fat,
      ingredients: food.ingredients,
      units: food.units,
    }
    
    const foodId = food.originalId ?? food.id

    if (food.isExternal) {
      payload.imageUrl = food.imageUrl
      payload.externalFoodId = foodId
    } else {
      payload.imageId = food.imageId
      payload.foodId = foodId
    }

    dispatch(logMeal(payload))
  }

  const handleLogMealByMeal = (meal: IMeal) => {
    const payload: ILogMealPayload = {
      type: mealType?.toUpperCase() as IMealType,
      name: meal.name,
      unit: meal.unit,
      portion: meal.portion,
      calorie: meal.calorie,
      protein: meal.pfc.protein,
      carbohydrate: meal.pfc.carb,
      fat: meal.pfc.fat,
      ingredients: meal.ingredients,
      units: meal.units,
    }

    if (meal.externalFoodId) {
      payload.imageUrl = meal.imageUrl
      payload.externalFoodId = meal.externalFoodId
    } else {
      payload.imageId = meal.imageId
      payload.foodId = meal.foodId
    }

    dispatch(logMeal(payload))
  }

  useFailed(logMealFailed)
  useSuccess(logMealSuccess, { message: "" }, callback)

  return {
    logMealLoading,
    handleLogMealByFood,
    handleLogMealByMeal,
  }
}

export const useResetSearchFoodsState = () => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(resetSearchedFoodsState())
  }, [])
}

