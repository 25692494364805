import AppleSignin from "react-apple-signin-auth"
import PrimaryButton from "components/Buttons/PrimaryButton"
import { useLoginWithApple } from "../../hooks"
import { ISIWAResponse } from "../../types"
import Button from "components/Buttons/Button"
import AppleIcon from "components/Icons/AppleIcon"
import { useShowSnackbar } from "features/notification/hooks"

const redirectURI = `${import.meta.env.VITE_REDIRECT_URL}/callback/apple`
const clientId = import.meta.env.VITE_SIWA_CLIENT_ID

const SignInWithAppleButton = () => {
  const { loginWithAppleCallback, loginWithAppleLoading } = useLoginWithApple()

  const { onShowSnackbar } = useShowSnackbar()

  return (
    <AppleSignin
      /** Auth options passed to AppleID.auth.init() */
      authOptions={{
        /** Client ID - eg: 'com.example.com' */
        clientId,
        /** Requested scopes, seperated by spaces - eg: 'email name' */
        scope: "email name",
        /** Apple's redirectURI - must be one of the URIs you added to the serviceID - the undocumented trick in apple docs is that you should call auth from a page that is listed as a redirectURI, localhost fails */
        redirectURI,
        /** State string that is returned with the apple response */
        state: "state",
        /** Nonce */
        nonce: "nonce",
        /** Uses popup auth instead of redirection */
        usePopup: true,
      }} // REQUIRED
      /** General props */
      uiType="dark"
      /** className */
      className="apple-auth-btn"
      /** Removes default style tag */
      noDefaultStyle={false}
      /** Allows to change the button's children, eg: for changing the button text */
      buttonExtraChildren="Continue with Apple"
      /** Extra controlling props */
      /** Called upon signin success in case authOptions.usePopup = true -- which means auth is handled client side */
      onSuccess={(response: ISIWAResponse) => {
        console.log(response)
        loginWithAppleCallback(response)
      }} // default = undefined
      /** Called upon signin error */
      onError={(error: any) => {
        onShowSnackbar({
          type: "error",
          title: "Login with apple failed!",
        })
      }} // default = undefined
      /** Skips loading the apple script if true */
      skipScript={false} // default = undefined
      /** Apple image props */
      /** render function - called with all props - can be used to fully customize the UI by rendering your own component  */
      render={(props: any) => (
        <Button
          {...props}
          style={{ width: "100%", background: "#fff" }}
          loading={loginWithAppleLoading}
        >
          {" "}
          <AppleIcon /> Login with Apple
        </Button>
      )}
    />
  )
}

export default SignInWithAppleButton
