function snakeCaseToCamelCase(str: string) {
  return str.replace(/_([a-z])/g, (match, letter) => letter.toUpperCase())
}

export const transformObjectKeysToCamelCase = (obj: any): any => {
  if (Array.isArray(obj)) {
    return obj.map((item) => transformObjectKeysToCamelCase(item))
  }

  if (obj && typeof obj === "object") {
    return Object.keys(obj).reduce((acc, key) => {
      const camelKey = snakeCaseToCamelCase(key)
      acc[camelKey] = transformObjectKeysToCamelCase(obj[key])
      return acc
    }, {} as any)
  }

  return obj
}

function camelCaseToSnakeCase(str: string) {
  return str.replace(/[A-Z]/g, (match) => `_${match.toLowerCase()}`)
}

export const transformObjectKeysToSnakeCase = (obj: any): any => {
  if (Array.isArray(obj)) {
    return obj.map((item) => transformObjectKeysToSnakeCase(item))
  }

  if (obj && typeof obj === "object") {
    return Object.keys(obj).reduce((acc, key) => {
      const snakeKey = camelCaseToSnakeCase(key)
      acc[snakeKey] = transformObjectKeysToSnakeCase(obj[key])
      return acc
    }, {} as any)
  }

  return obj
}
