import styled from "styled-components"

export const StyledFoodDetails = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
`

export const FoodDetailsHeader = styled.div`
  position: absolute;
  background: var(--White_20, rgba(255, 255, 255, 0.2));
  height: 44px;
  width: 100%;
  display: flex;
  align-items: center;
  z-index: 1000;
`

export const FoodDetailsContent = styled.div`
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`

export const FoodDetailsInfo = styled.div`
  padding: 16px;
  padding-bottom: 0;
  gap: 8px;
  display: flex;
  flex-direction: column;
`

export const FoodName = styled.div`
  font-size: 20px;
  font-weight: 800;
  line-height: 120%;
`
