import React, { forwardRef, Fragment, useEffect, useRef, useState } from "react"
import {
  StyledPortionFormItem,
  PortionLabel,
  StyledPortionInput,
  PickerPopupStyle,
  PickerHeader,
  DisabledUnitText,
} from "./styled"
import { FlexBox } from "components/Common/FlexBox"
import { IFoodUnit } from "features/food/foodTypes"
import { Picker } from "antd-mobile"
import { validateMaximumToDecimalPlace } from "utils"

export interface IPortionInputProps {
  value: number
  onChange: (value: number) => void
  isError?: boolean
  disabled?: boolean
  setIsPortionValid?: (isValid: boolean) => void
}

type Ref = HTMLInputElement

const PortionInputItem = forwardRef<Ref, IPortionInputProps>((props, ref) => {
  const { value, disabled = false, onChange, setIsPortionValid } = props
  const [localValue, setLocalValue] = useState<string>(value.toString())

  useEffect(() => {
    if (value !== Number(localValue)) {
      setLocalValue(value.toString())
    }
  }, [value])

  const onUnitChange = (value: string) => {
    if (!validateMaximumToDecimalPlace(value) || value === "0.00") {
      return
    }

    if (value === "" || value === null) {
      setLocalValue("")
      setIsPortionValid?.(false)
      return
    }

    let nextValue = Number(value)

    if (nextValue === 0) {
      setLocalValue(value)
      setIsPortionValid?.(false)
      return
    }

    if (!isNaN(nextValue)) {
      setIsPortionValid?.(true)
      setLocalValue(value)
      onChange(nextValue)
    }
  }

  const onBlur = () => {
    const nextValue = value ?? 1
    if (localValue !== nextValue.toString()) {
      setLocalValue(nextValue.toString())
    }

    onChange(nextValue)
    setIsPortionValid?.(true)
  }

  return (
    <StyledPortionInput
      onBlur={onBlur}
      ref={ref}
      value={localValue}
      onChange={(e) => {
        if (!disabled) {
          onUnitChange(e.target.value)
        }
      }}
    />
  )
})

export interface IUnitPickerProps {
  value: string
  onChange: (unitName: string) => void
  options: IFoodUnit[]
}

const UnitInputItem = forwardRef<Ref, IUnitPickerProps>((props, ref) => {
  const { value, options, onChange } = props
  const [localValue, setLocalValue] = useState(value)
  const [pickerVisible, setPickerVisible] = useState(false)

  useEffect(() => {
    if (value !== localValue) {
      setLocalValue(value)
    }
  }, [value])

  const onUnitChange = (value: string) => {
    setLocalValue(value)
    onChange(value)
  }

  const columns = options.map((option) => {
    return {
      label: option.label,
      value: option.label,
    }
  })

  return (
    <Fragment>
      <PickerPopupStyle />
      <Picker
        cancelText=""
        confirmText="Done"
        popupClassName="picker-popup"
        title={<PickerHeader />}
        visible={pickerVisible}
        onClose={() => {
          setPickerVisible(false)
        }}
        columns={[columns]}
        onConfirm={(value) => {
          onUnitChange(value[0] as string)
        }}
        value={[value]}
        onSelect={(value) => {
          setLocalValue(value[0] as string)
        }}
      />
      <StyledPortionInput
        ref={ref}
        value={value}
        readOnly
        onClick={() => setPickerVisible(true)}
      />
    </Fragment>
  )
})

export interface IPortionUnitPickerProps {
  portionValue: number
  onPortionChange: (portion: number) => void

  unitValue: string
  onUnitChange: (unit: string) => void

  style?: React.CSSProperties
  disabled?: boolean
  unitOptions?: IFoodUnit[]

  isDisabledUnit?: boolean
  setIsPortionValid?: (isValid: boolean) => void
}

const PortionUnitPickerFormItem = (props: IPortionUnitPickerProps) => {
  const {
    onPortionChange,
    portionValue,
    unitValue,
    onUnitChange,
    style = {},
    disabled,
    unitOptions,
    isDisabledUnit,
    setIsPortionValid,
  } = props

  const firstInputRef = useRef<HTMLInputElement>(null)
  const secondInputRef = useRef<HTMLInputElement>(null)

  return (
    <StyledPortionFormItem style={style}>
      <FlexBox $direction="column" $gap={4}>
        <PortionLabel>Portion</PortionLabel>
      </FlexBox>

      <FlexBox $alignItems="center" $gap={12}>
        <PortionInputItem
          disabled={disabled}
          ref={firstInputRef}
          value={portionValue}
          onChange={onPortionChange}
          setIsPortionValid={setIsPortionValid}
        />

        {!isDisabledUnit && unitOptions ? (
          <UnitInputItem
            ref={secondInputRef}
            value={unitValue}
            onChange={onUnitChange}
            options={unitOptions}
          />
        ) : (
          <DisabledUnitText>{unitValue}</DisabledUnitText>
        )}
      </FlexBox>
    </StyledPortionFormItem>
  )
}

export default PortionUnitPickerFormItem
