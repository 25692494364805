import styled from "styled-components"

export const CreateFoodWrapper = styled.div<{ $background: string }>`
  --header-height: 44px;
  --cta-box-height: 72px;

  height: 100%;
  width: 100%;
  background: ${({ $background }) => $background};
`

export const CreateFoodCtaBox = styled.div`
  padding: 12px var(--spacing_l, 16px);
  background: var(--Schemes-Surface-Container, #ffffff);
`
