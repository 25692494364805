import { Popover } from "antd-mobile"
import styled from "styled-components"

export const StyledFoodDetails = styled.div`
  height: 100%;
  width: 100%;
  position: relative;

  .image-viewer-mask {
    background: #404040 !important;
  }
`

export const FoodDetailsHeader = styled.div`
  background: var(--White_20, rgba(255, 255, 255, 0.2));
  height: 44px;
  width: 100%;
  display: flex;
  align-items: center;
  z-index: 10;
  justify-content: space-between;
  padding: 0 6px;

  svg {
    cursor: pointer;
  }
`

export const FoodDetailsContent = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`

export const FoodDetailsBody = styled.div`
  overflow-y: auto;
  height: calc(100% - var(--cta-box-height, 80px));
`

export const FoodDetailsInfo = styled.div`
  padding: 16px;
  padding-bottom: 0;
  gap: 8px;
  display: flex;
  flex-direction: column;
`

export const FoodName = styled.div`
  font-size: 20px;
  font-weight: 800;
  line-height: 120%;
`

export const IngredientList = styled.div`
  padding: 6px 0px;
  border-radius: 8px;
  background: var(--Schemes-Surface-Container-Lowest, #fff);
  border: 1px solid var(--Schemes-Outline-Variant, rgba(0, 38, 58, 0.14));
`

export const FoodDetailsPopover = styled(Popover.Menu)`
  --arrow-size: 0px;

  .adm-popover-menu-list-inner {
    padding: 4px 0px;
  }

  .adm-popover-menu-item {
    svg {
      width: 20px;
      height: 20px;
    }

    padding-left: 0px;
    width: 173px;

    &:first-child {
      .adm-popover-menu-item-text {
        border-top: none;
      }
    }

    &:last-child {
      color: var(--Schemes-On-Error-Container, #ff4921);
    }

    .adm-popover-menu-item-text {
      padding: 8px 12px;
      line-height: 22px;
      font-weight: 600;
      font-size: 16px;
    }
  }
`
