import { useEffect, useState } from "react"
import { IFood } from "../foodTypes"
import { useAppSelector } from "app/hooks"
import { v4 as uuidv4 } from "uuid"

export interface IScannedFood extends IFood {
  isSelected?: boolean
}

export const useScannedFoods = () => {
  const scanMealResults = useAppSelector((state) => state.meal.scanMealResults)
  const [foods, setFoods] = useState<IScannedFood[]>([])

  useEffect(() => {
    setFoods(
      scanMealResults.map((food) => ({
        ...food,
        isSelected: true,
        id: uuidv4(),
      })),
    )
  }, [scanMealResults])

  const toggleSelectFood = (updatedFood: IScannedFood) => {
    const updatedFoods = foods.map((food) =>
      food.id === updatedFood.id
        ? { ...food, isSelected: !food.isSelected }
        : food,
    )
    setFoods(updatedFoods)
  }

  const onUpdateFood = (updatedFood: IScannedFood) => {
    const updatedFoods = foods.map((food) =>
      food.id === updatedFood.id ? updatedFood : food,
    )
    setFoods(updatedFoods)
  }

  const onAddFood = (food: IFood) => {
    setFoods([...foods, { ...food, isSelected: true, id: uuidv4() }])
  }

  return {
    foods,
    toggleSelectFood,
    onUpdateFood,
    onAddFood,
  }
}
