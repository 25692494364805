import { useAppDispatch, useAppSelector } from "app/hooks"
import { useEffect } from "react"
import {
  deleteMeal,
  getDailyProgress,
  getMeals,
  getMonthlyProgress,
  setSelectedDate,
} from "./homeSlice"
import { useNavigate, useSearchParams } from "react-router-dom"
import dayjs, { Dayjs } from "dayjs"
import utc from "dayjs/plugin/utc"
import timeZone from "dayjs/plugin/timezone"
import { useFailed, useSuccess } from "features/notification/hooks"
import { DATE_PARAM_FORMAT } from "config"

dayjs.extend(utc)
dayjs.extend(timeZone)

export const useMeals = () => {
  const dispatch = useAppDispatch()
  const [searchParams] = useSearchParams()
  const date = searchParams.get("date") ?? dayjs().format(DATE_PARAM_FORMAT)
  const from = dayjs(date).startOf("day").utc().toISOString()
  const to = dayjs(date).endOf("day").utc().toISOString()
  const navigate = useNavigate()

  const onDateChange = (date: Dayjs) => {
    navigate(`?date=${date.format(DATE_PARAM_FORMAT)}`)
  }

  useEffect(() => {
    dispatch(
      getMeals({
        from,
        to,
      }),
    )
  }, [date])

  const getMealsLoading = useAppSelector((state) => state.home.getMealsLoading)
  const getMealsFailed = useAppSelector((state) => state.home.getMealsFailed)
  const meals = useAppSelector((state) => state.home.meals)
  const isNoMeals = useAppSelector((state) => state.home.isNoMeals)

  useFailed(getMealsFailed)

  return {
    getMealsLoading,
    getMealsFailed,
    meals,
    date: dayjs(date).startOf("day"),
    onDateChange,
    isNoMeals,
  }
}

export const useUpdateSelectedDate = () => {
  const dispatch = useAppDispatch()
  const [searchParams] = useSearchParams()
  const searchParamDate = searchParams.get("date")
  const selectedDate = useAppSelector((state) => state.home.selectedDate)

  useEffect(() => {
    if (
      searchParamDate &&
      dayjs(searchParamDate).isValid() &&
      !dayjs(searchParamDate).isSame(selectedDate, "day")
    ) {
      dispatch(setSelectedDate(dayjs(searchParamDate)))
    }
  }, [searchParamDate])
}

export const useDailyProgress = () => {
  const dispatch = useAppDispatch()
  const date = useAppSelector((state) => state.home.selectedDate)
  const deleteMealSuccess = useAppSelector(
    (state) => state.home.deleteMealSuccess,
  )

  useEffect(() => {
    if (date) {
      dispatch(getDailyProgress(date.format(DATE_PARAM_FORMAT)))
    }
  }, [date, deleteMealSuccess])

  const getDailyProgressLoading = useAppSelector(
    (state) => state.home.getDailyProgressLoading,
  )
  const getDailyProgressFailed = useAppSelector(
    (state) => state.home.getDailyProgressFailed,
  )
  const dailyProgress = useAppSelector((state) => state.home.dailyProgress)

  useFailed(getDailyProgressFailed)

  return {
    getDailyProgressLoading,
    getDailyProgressFailed,
    dailyProgress,
  }
}

export const useMonthlyProgress = (startDate: Dayjs, endDate: Dayjs) => {
  const dispatch = useAppDispatch()

  const from = startDate.format(DATE_PARAM_FORMAT)
  const to = endDate.format(DATE_PARAM_FORMAT)

  useEffect(() => {
    if (from && to) {
      dispatch(getMonthlyProgress({ from, to }))
    }
  }, [from, to])

  const getMonthlyProgressLoading = useAppSelector(
    (state) => state.home.getMonthlyProgressLoading,
  )
  const getMonthlyProgressFailed = useAppSelector(
    (state) => state.home.getMonthlyProgressFailed,
  )
  const monthlyProgress = useAppSelector((state) => state.home.monthlyProgress)

  useFailed(getMonthlyProgressFailed)

  return {
    getMonthlyProgressLoading,
    getMonthlyProgressFailed,
    monthlyProgress,
  }
}

export const useDeleteMeal = (callback?: () => void) => {
  const dispatch = useAppDispatch()

  const handleDeleteMeal = (mealId: string) => {
    dispatch(deleteMeal(mealId))
  }

  const deleteMealLoading = useAppSelector(
    (state) => state.home.deleteMealLoading,
  )

  const deleteMealSuccess = useAppSelector(
    (state) => state.home.deleteMealSuccess,
  )

  useSuccess(deleteMealSuccess, { message: "" }, callback)

  return {
    handleDeleteMeal,
    deleteMealLoading,
  }
}

export const useNotifyDeleteMealFailed = () => {
  const deleteMealFailed = useAppSelector(
    (state) => state.home.deleteMealFailed,
  )
  useFailed(deleteMealFailed)
}
