import { Modal } from "antd-mobile"
import styled from "styled-components"

export const StyledModal = styled(Modal)`
  .adm-modal-body {
    padding-top: 16px;
  }

  .adm-modal-content {
    padding: 0 16px 16px;
  }

  .adm-modal-footer {
    display: none;
  }
`

export const IconWrapper = styled.div<{ $type: "info" | "danger" }>`
  display: flex;
  width: 34px;
  height: 34px;
  padding: 5px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background: ${({ $type }) =>
    $type === "info"
      ? "var(--Schemes-Primary-Container-Variant, #CCEDED)"
      : "var(--Error-variant, #fddcdc)"};
`

export const ConfirmTitle = styled.div`
  font-weight: 700;
  font-size: 14px;
  text-align: center;
`

export const ConfirmBody = styled.div`
  font-size: 14px;
  white-space: wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
`
export const ConfirmEntry = styled.span`
  font-size: 14px;
  font-weight: 600;
  display: inline;
  color: var(--color-dark);
`
