import React from "react"

const DropdownDownIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M8.76822 11.0781C8.36843 11.5579 7.63157 11.5579 7.23178 11.0781L3.68341 6.82009C3.41202 6.49443 3.6436 6 4.06752 6H11.9325C12.3564 6 12.588 6.49443 12.3166 6.82009L8.76822 11.0781Z"
        fill="#1C1F21"
      />
    </svg>
  )
}

export default DropdownDownIcon
