import { StyledPersonalInfo, TitleBox, MetricContainer } from "./styled"
import { Description, Title20 } from "components/Typo"
import Switch, { ISwitchOption } from "components/Switch"
import { useMetricType, useUpdateUser, useUser } from "../hooks"
import { CTABox } from "../styled"
import Button from "components/Buttons/Button"
import { useNavigate } from "react-router-dom"
import PATHS from "router/paths"
import { IMetricType } from "../types"
import {
  cmToFeetInches,
  cmToMm,
  feetInchesToCm,
  feetInchesToFeet,
  feetToFeetInches,
  kgToGram,
  kgToPound,
  poundToKg,
  truncateByDecimalPlace,
} from "utils"
import { useEffect, useState } from "react"
import { FlexBox } from "components/Common/FlexBox"
import InputNumberFormItem from "components/InputNumberFormItem"
import { useSetPageTitle } from "libs/ga"
import HeightInput from "components/HeightInput"

const metricOptions: [ISwitchOption, ISwitchOption] = [
  { label: "Imperial", value: "IMPERIAL" },
  {
    label: "Metric",
    value: "METRIC",
  },
]

const PersonalInfo = () => {
  useSetPageTitle("Body Metric - Onboarding")
  const { user, getUserFailed } = useUser()
  const { onUpdateMetricType, metricType } = useMetricType()
  const navigate = useNavigate()

  const [weight, setWeight] = useState(0)
  const [cmHeight, setCmHeight] = useState(0)

  const [feet, setFeet] = useState(0)
  const [inches, setInches] = useState(0)

  const onFeetInchesChange = (feet: number, inches: number) => {
    setFeet(feet)
    setInches(inches)
    setCmHeight(feetInchesToCm(feet, inches))
  }

  useEffect(() => {
    if (user) {
      setWeight(user.weight)

      if (user.metricType === "METRIC") {
        setCmHeight(user.height)

        const { feet, inches } = cmToFeetInches(
          Number(user.cmHeight.replace("cm", "")),
        )

        setFeet(feet)
        setInches(inches)
      } else {
        const { feet, inches } = feetToFeetInches(user.height)
        setCmHeight(feetInchesToCm(feet, inches))
        setFeet(feet)
        setInches(inches)
      }
    }
  }, [user])

  const onMetricTypeChange = (metricType: IMetricType) => {
    const nextWeight =
      metricType === "METRIC" ? poundToKg(weight) : kgToPound(weight)

    setWeight(truncateByDecimalPlace(nextWeight, 0))
    onUpdateMetricType(metricType)
  }

  const onWeightChange = (nextWeight: number) => {
    setWeight(nextWeight)
  }

  const onHeightChange = (nextHeight: number) => {
    setCmHeight(nextHeight)
    const { feet, inches } = cmToFeetInches(nextHeight)
    setFeet(feet)
    setInches(inches)
  }

  const onContinue = () => {
    navigate(PATHS.app.setGoal)
  }

  const { handleUpdateUser, updateUserLoading } = useUpdateUser(onContinue)

  const isWeightChanged = weight !== user?.weight
  const isHeightChanged =
    cmHeight !== user?.height && feetInchesToFeet(feet, inches) !== user?.height
  const isMetricTypeChanged = metricType !== user?.metricType

  const isUserChanged =
    isWeightChanged || isHeightChanged || isMetricTypeChanged

  const onSubmitUpdateUser = () => {
    const weightValue = metricType === "METRIC" ? kgToGram(weight) : weight
    const heightValue =
      metricType === "METRIC"
        ? cmToMm(cmHeight)
        : feetInchesToFeet(feet, inches)

    handleUpdateUser({
      weight: weightValue,
      height: heightValue,
      metricType,
    })
  }

  return (
    <StyledPersonalInfo>
      <TitleBox>
        <Title20>Body metric</Title20>
        <Description>Data fetched from your ZOZOFIT Profile</Description>
      </TitleBox>

      <MetricContainer>
        <Switch
          onChange={(value) => onMetricTypeChange(value as IMetricType)}
          options={metricOptions}
          value={metricType}
        />
      </MetricContainer>

      <FlexBox $direction="column" $gap={12}>
        {metricType === "METRIC" ? (
          <InputNumberFormItem
            min={1}
            label="Height"
            value={cmHeight}
            onChange={onHeightChange}
            detailTrailing="cm"
          />
        ) : (
          <HeightInput
            feet={feet}
            inches={inches}
            onChange={onFeetInchesChange}
          />
        )}
        <InputNumberFormItem
          min={1}
          label="Weight"
          value={weight}
          onChange={onWeightChange}
          detailTrailing={metricType === "IMPERIAL" ? "lb" : "kg"}
        />
      </FlexBox>

      <CTABox>
        <Button
          loading={updateUserLoading}
          onClick={() => {
            if (isUserChanged) {
              onSubmitUpdateUser()
            } else {
              onContinue()
            }
          }}
          $type="primary"
          style={{ width: "100%" }}
          disabled={getUserFailed}
        >
          {isUserChanged ? "Update" : "Continue"}
        </Button>
      </CTABox>
    </StyledPersonalInfo>
  )
}

export default PersonalInfo
