import {
  useDailyProgress,
  useMeals,
  useNotifyDeleteMealFailed,
  useUpdateSelectedDate,
} from "./hooks"
import dayjs from "dayjs"
import { HomeMain, NoMealsBox, StyledDay, StyledHome } from "./styled"
import { FlexBox } from "components/Common/FlexBox"
import SmallChevronLeft from "components/Icons/SmallChevronLeft"
import SmallChevronRight from "components/Icons/SmallChevronRight"
import DropdownDownIcon from "components/Icons/DropdownDownIcon"
import Progress from "./Progress"
import NutritionRemaining from "./NutritionRemaining"
import MealSection from "./MealSection"
import { useState } from "react"
import DatePicker from "components/DatePicker"
import { useSearchParams } from "react-router-dom"
import { useSetPageTitle } from "libs/ga"
import { useUser } from "features/onboarding/hooks"
import { StyledPopup } from "components/StyledPopup"
import { DATE_PARAM_FORMAT, US_DATE_FORMAT_WITH_YEAR } from "config"
import { BackButton } from "components/Common/BackButton"
import { useResetSearchFoodsState } from "features/meal/hooks/hooks"
import { useResetMealDetailsBackPath } from "features/meal/hooks/mealDetailsHooks"

const Home = () => {
  useUser()
  useSetPageTitle("Diary")
  useResetSearchFoodsState()

  const { meals, date, onDateChange, isNoMeals } = useMeals()
  const isToday = date.isSame(dayjs().startOf("day"))
  const mealTypes = ["BREAKFAST", "LUNCH", "DINNER", "SNACK"]
  const [showCalendar, setShowCalendar] = useState(false)
  const { dailyProgress } = useDailyProgress()

  const [swipedId, setSwipedId] = useState<string | null>(null)
  useNotifyDeleteMealFailed()
  useUpdateSelectedDate()

  const [_, setSearchParams] = useSearchParams()
  const onBackToYesterday = () => {
    setSearchParams({ date: date.subtract(1, "day").format(DATE_PARAM_FORMAT) })
  }

  const onGoToTomorrow = () => {
    setSearchParams({ date: date.add(1, "day").format(DATE_PARAM_FORMAT) })
  }
  useResetMealDetailsBackPath()

  return (
    <StyledHome>
      <StyledPopup
        position="top"
        visible={showCalendar}
        onMaskClick={() => setShowCalendar(false)}
      >
        <DatePicker
          onChange={(date) => {
            onDateChange(date)
            setShowCalendar(false)
          }}
          value={date}
        />
      </StyledPopup>
      <FlexBox
        style={{ height: 44, padding: "9px 12px" }}
        $justify="space-between"
        $alignItems="center"
      >
        <BackButton onClick={onBackToYesterday}>
          <SmallChevronLeft />
        </BackButton>

        <FlexBox
          $alignItems="center"
          $gap={4}
          onClick={() => setShowCalendar(true)}
        >
          <StyledDay>
            {isToday ? "Today" : date.format(US_DATE_FORMAT_WITH_YEAR)}
          </StyledDay>
          <DropdownDownIcon />
        </FlexBox>

        <BackButton onClick={onGoToTomorrow}>
          <SmallChevronRight />
        </BackButton>
      </FlexBox>

      <HomeMain>
        <FlexBox $alignItems="center" $justify="center">
          <Progress dailyProgress={dailyProgress} />
        </FlexBox>

        <NutritionRemaining dailyProgress={dailyProgress} />

        <FlexBox $direction="column" $gap={12} style={{ marginTop: 12 }}>
          {isNoMeals && (
            <NoMealsBox>Log your first meal of the day!</NoMealsBox>
          )}

          {mealTypes.map((type) => (
            <MealSection
              swipedId={swipedId}
              setSwipedId={setSwipedId}
              key={type}
              mealType={type}
              meals={meals.filter((meal) => meal.type === type)}
            />
          ))}
        </FlexBox>
      </HomeMain>
    </StyledHome>
  )
}

export default Home
