import styled from "styled-components"

export const StyledMealSection = styled.section`
  border: 0.8px solid var(--Schemes-Outline-Variant, rgba(0, 38, 58, 0.14));
  border-radius: var(--spacing_s, 8px);
  overflow: hidden;
`

export const MealSectionHeader = styled.div`
  padding: var(--spacing_s, 8px) var(--spacing_s, 8px) var(--spacing_s, 8px)
    var(--spacing_m, 12px);
  background: var(--Schemes-Surface-Container-Lowest, #fff);
  display: flex;
  justify-content: space-between;
  height: 50px;
  align-items: center;
  font-size: 14px;
`

export const MealSectionCalories = styled.div`
  font-size: 16px;
  font-weight: 700;
`

export const MealSectionTitle = styled(MealSectionCalories)`
  text-transform: capitalize;
`

export const MealSectionContent = styled.div`
  background: var(--Schemes-Surface-Container-Lowest, #fff);
`

export const MealSectionFooter = styled.div`
  border-top: 0.8px solid var(--Schemes-Outline-Variant, rgba(0, 38, 58, 0.14));
  padding: 10px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--Schemes-Surface-Container-Lowest, #fff);

  &:hover {
    background: var(--Schemes-Outline-Variant, rgba(0, 38, 58, 0.14));
  }
`

export const MealItemWrapper = styled.div`
  position: relative;
  transition: all 0.3s;
  border-top: 0.5px solid rgb(190, 198, 202);
  cursor: pointer;
`

export const MealItemRightAction = styled.div`
  position: absolute;
  left: 100%;
  top: 0;
  height: 100%;
  background: var(--Schemes-On-Error-Container, #ff4921);
  width: 93px;
  display: flex;
  align-items: center;
  justify-content: center;
`
