import * as braze from "@braze/web-sdk"
import { useAppSelector } from "app/hooks"
import { useEffect, useRef, useState } from "react"

const VITE_BRAZE_API_KEY = import.meta.env.VITE_BRAZE_API_KEY
const VITE_BRAZE_ENDPOINT = import.meta.env.VITE_BRAZE_ENDPOINT

export const useInitBraze = () => {
  const userId = useAppSelector((state) => state.auth.userId)
  const hasInit = useRef(false)
  const sessionStarted = useRef(false)

  useEffect(() => {
    if (!VITE_BRAZE_API_KEY || !VITE_BRAZE_ENDPOINT) {
      return
    }

    if (!hasInit.current) {
      hasInit.current = true

      braze.initialize(VITE_BRAZE_API_KEY, {
        baseUrl: VITE_BRAZE_ENDPOINT,
      })
    }

    if (userId) {
      sessionStarted.current = true
      braze.changeUser(userId)
      braze.openSession()
      braze
        .getUser()
        ?.setCustomUserAttribute(
          "food_last_app_open_date",
          new Date().toISOString(),
        )

      braze.logCustomEvent("food_session")
    } else if (sessionStarted.current) {
      sessionStarted.current = false
      braze.changeUser("")
    }
  }, [userId])
}

export const useLogBrazeEvent = () => {
  const userId = useAppSelector((state) => state.auth.userId)

  const logBrazeEvent = (eventName: string, payload: Record<string, any>) => {
    if (!userId) {
      return
    }

    braze.logCustomEvent(eventName, payload)
  }

  const logBrazeTryAI = () => {
    logBrazeEvent("food_ai_input_started", {})
  }

  const logBrazeManualInput = () => {
    logBrazeEvent("food_manual_input_started", {})
  }

  const logBrazeDailyTrendView = () => {
    logBrazeEvent("food_daily_trend_view", {})
  }

  return {
    logBrazeTryAI,
    logBrazeManualInput,
    logBrazeDailyTrendView,
  }
}
