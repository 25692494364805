import React from "react"

const MyFoodIcon = () => {
  return (
    <svg
      style={{ flexShrink: 0 }}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <rect width="16" height="16" rx="8" fill="#FFC800" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.6155 4.40953C10.4097 4.40953 10.2103 4.44347 10.0217 4.51135C9.5243 3.86653 8.79323 3.5 8.00214 3.5C7.20891 3.5 6.47785 3.86653 5.98261 4.51135C5.79181 4.44347 5.59242 4.40953 5.38661 4.40953C4.34683 4.40953 3.5 5.30323 3.5 6.40056C3.5 7.40512 4.21177 8.24678 5.14221 8.37348V10.5866H6.52832V9.79727C6.52832 9.66195 6.63802 9.55225 6.77333 9.55225C6.90865 9.55225 7.01835 9.66195 7.01835 9.79727V10.5866H7.75342V9.28012C7.75342 9.1448 7.86311 9.0351 7.99843 9.0351C8.13375 9.0351 8.24345 9.1448 8.24345 9.28012V10.5866H8.97852V9.79727C8.97852 9.66195 9.08821 9.55225 9.22353 9.55225C9.35885 9.55225 9.46854 9.66195 9.46854 9.79727V10.5866H10.757V8.38705C11.7282 8.31239 12.5 7.45037 12.5 6.40056C12.5 5.30323 11.6532 4.40953 10.6155 4.40953ZM6.77333 11.6208H5.14221V12.2127C5.14221 12.3714 5.26411 12.5 5.41448 12.5H10.4848C10.6351 12.5 10.757 12.3714 10.757 12.2127V11.6208H9.22353H6.77333Z"
        fill="white"
      />
    </svg>
  )
}

export default MyFoodIcon
