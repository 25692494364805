import React from "react"

const BigSearchIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.85593 3.61739C7.1902 2.72585 8.75888 2.25 10.3636 2.25H10.3636C12.5154 2.25014 14.5791 3.105 16.1006 4.62655C17.6222 6.14811 18.4771 8.21174 18.4772 10.3635V10.3636C18.4772 11.9683 18.0013 13.537 17.1098 14.8713C16.9533 15.1054 16.7853 15.3305 16.6065 15.5459L21.5303 20.4697C21.8232 20.7626 21.8232 21.2374 21.5303 21.5303C21.2374 21.8232 20.7626 21.8232 20.4697 21.5303L15.5459 16.6065C14.9235 17.1232 14.224 17.5467 13.4685 17.8596C11.986 18.4737 10.3546 18.6344 8.78071 18.3213C7.20683 18.0082 5.76113 17.2355 4.62642 16.1008C3.49171 14.9661 2.71897 13.5204 2.4059 11.9465C2.09284 10.3726 2.25352 8.74122 2.86761 7.25866C3.48171 5.77609 4.52165 4.50892 5.85593 3.61739ZM10.3636 3.75C9.05552 3.75001 7.77687 4.13789 6.68928 4.86459C5.60168 5.5913 4.754 6.6242 4.25343 7.83268C3.75287 9.04116 3.6219 10.3709 3.87708 11.6538C4.13227 12.9368 4.76215 14.1152 5.68708 15.0401C6.61201 15.965 7.79044 16.5949 9.07335 16.8501C10.3563 17.1053 11.686 16.9743 12.8945 16.4738C14.103 15.9732 15.1359 15.1255 15.8626 14.0379C16.5893 12.9503 16.9772 11.6717 16.9772 10.3636M10.3636 3.75C12.1176 3.75012 13.7997 4.44695 15.04 5.68721C16.2802 6.92748 16.9771 8.6096 16.9772 10.3636"
        fill="#00263A"
        fillOpacity="0.32"
      />
    </svg>
  )
}

export default BigSearchIcon
