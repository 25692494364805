import React, { useState } from "react"
import { FormItemLabel, StyledCreateFoodForm } from "./styled"
import TextFieldFormItem from "components/TextFieldFormItem"
import PortionFormItem from "components/PortionFormItem"
import NutrientInformation from "components/NutrientInformation"
import Ingredients from "./Ingredients"
import ImageUploader from "components/ImageUploader"
import { ICreateFoodPayload, IIngredientFood } from "features/food/foodTypes"

interface ICreateFoodFormProps {
  showSearchIngredients: () => void
  food: ICreateFoodPayload
  onNameChange: (name: string) => void
  onPortionChange: (portion: number) => void
  onUnitChange: (unit: string) => void
  onRemoveIngredient: (id: string) => void
  onViewIngredientFoodDetails: (food: IIngredientFood) => void
  setIsPortionValid?: (isValid: boolean) => void
}

const CreateFoodForm = (props: ICreateFoodFormProps) => {
  const {
    food,
    showSearchIngredients,
    onNameChange,
    onPortionChange,
    onUnitChange,
    onRemoveIngredient,
    onViewIngredientFoodDetails,
    setIsPortionValid
  } = props

  return (
    <StyledCreateFoodForm>
      <TextFieldFormItem
        label="Name"
        placeholder="Name your meal"
        value={food.name}
        onChange={onNameChange}
      />

      <PortionFormItem
        portionValue={food.portion}
        unitValue={food.unit}
        onUnitChange={onUnitChange}
        onPortionChange={onPortionChange}
        setIsPortionValid={setIsPortionValid}
      />

      <NutrientInformation
        calorie={food.calorie}
        carbohydrate={food.carbohydrate}
        fat={food.fat}
        protein={food.protein}
      />

      <Ingredients
        showSearchIngredients={showSearchIngredients}
        ingredients={food.ingredients ?? []}
        onRemoveIngredient={onRemoveIngredient}
        onViewIngredientDetails={onViewIngredientFoodDetails}
      />

      <div>
        <FormItemLabel>
          Image <span>{"(optional)"}</span>
        </FormItemLabel>
        <ImageUploader />
      </div>
    </StyledCreateFoodForm>
  )
}

export default CreateFoodForm
