import { useLoginWithMfa, useRemoveMfaSection } from "features/auth/hooks"
import { Fragment } from "react"
import { Header, Title } from "../EmailForm/styled"
import { Link } from "react-router-dom"
import ChevronLeft from "components/Icons/ChevronLeft"
import PATHS from "router/paths"
import { MfaBody, MfaDescription, MfaTitle, PassCode } from "./styled"
import CloseIcon from "components/Icons/CloseIcon"

const WithMfa = () => {
  const onRemoveMfaSection = useRemoveMfaSection()
  const { handleLoginWithMfa, mfaMaskedPhoneNumber } = useLoginWithMfa()
  const onMfaChange = (value: string) => {
    if (value.length === 6) {
      handleLoginWithMfa(value)
    }
  }

  return (
    <Fragment>
      <div
        style={{
          height: 6,
          background: "var(--Schemes-Surface-Container-Low, #F5F7F7)",
        }}
      />
      <Header
        $alignItems="center"
        $justify="space-between"
        style={{ background: "var(--Schemes-Surface-Container-Low, #F5F7F7)" }}
      >
        <div style={{ cursor: "pointer" }} onClick={() => onRemoveMfaSection()}>
          <CloseIcon />
        </div>

        <Title>Two-Factor Authentication</Title>
        <div />
      </Header>

      <MfaBody>
        <MfaTitle>Security code</MfaTitle>
        <MfaDescription style={{ display: "flex", gap: 4 }}>
          Please enter the security code we texted to{" "}
          <span>{mfaMaskedPhoneNumber}</span>
        </MfaDescription>

        <PassCode plain onChange={onMfaChange} />
      </MfaBody>
    </Fragment>
  )
}

export default WithMfa
