import { styled } from "styled-components"

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  background: var(--Schemes-Surface-Container-Low, #f5f7f7);
  position: relative;
`

export const ChatContentBox = styled.div`
  overflow-y: auto;
  padding: 16px;
  flex: 1 1 0;

  & > *:not(:first-child) {
    margin-top: 16px;
  }
`
