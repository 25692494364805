import axios from "axios"
import mem from "mem"
import { ToolkitStore } from "@reduxjs/toolkit/dist/configureStore"
import { onLogOut, updateToken } from "features/auth/authSlice"
import { resetStoreAction } from "config"
import { removeAxiosToken, setAxiosToken } from "./requester"
import dayjs from "dayjs"
import { isIOSWebView } from "utils"

export const notifyLogOutToIOSApp = () => {
  ;(window as any)?.webkit?.messageHandlers?.IOS_BRIDGE?.postMessage({
    message: "LOGOUT_FROM_FOOD_JOURNAL_WEB",
  })
}

export const refreshInstant = axios.create({
  baseURL: import.meta.env.VITE_API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
    "X-Timezone-Offset": dayjs().format("Z").split(":")[0],
  },
})

const refreshTokenFn = async (store: ToolkitStore) => {
  try {
    const { refreshToken } = store.getState().auth

    const response = await refreshInstant.put("/accounts/token", {
      refreshToken,
    })

    const accessToken = response.data.accessToken

    store.dispatch(updateToken(accessToken))
    setAxiosToken(accessToken)
    return { accessToken }
  } catch (error) {
    if (isIOSWebView()) {
      notifyLogOutToIOSApp()
    } else {
      store.dispatch(onLogOut())
      store.dispatch(resetStoreAction())
      removeAxiosToken()
    }
  }
}

const maxAge = 20000

const memoizedRefreshToken = mem(refreshTokenFn, {
  maxAge,
})

export default memoizedRefreshToken
