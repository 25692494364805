import { Fragment, useEffect, useMemo, useState } from "react"
import {
  CurrentMonth,
  DateButton,
  DateButtonText,
  HeaderWrapper,
  NavigateBox,
  NavigateButton,
  StyledDatePicker,
  StyledTd,
  StyledTh,
  StyledThR,
  StyledTr,
  TodayButton,
  TodayButtonDot,
} from "./styled"
import dayjs, { Dayjs } from "dayjs"
import utc from "dayjs/plugin/utc"
import timeZone from "dayjs/plugin/timezone"
import LeftIcon from "./Icons/LeftIcon"
import RightIcon from "./Icons/RightIcon"
import { useMonthlyProgress } from "features/home/hooks"
import { ICalendarPeriod } from "features/overview/types"

dayjs.extend(utc)
dayjs.extend(timeZone)

const DATE_FORMAT = "YYYY-MM-DD"

const Header = ({
  month,
  setMonth,
  backToToday,
  period,
}: {
  month: Dayjs
  setMonth: (month: Dayjs) => void
  backToToday: () => void
  period: ICalendarPeriod
}) => {
  const isDiffMonth = !dayjs().isSame(month, "month")
  return (
    <HeaderWrapper>
      <CurrentMonth>{month.format("MMMM YYYY")}</CurrentMonth>
      <NavigateBox>
        {isDiffMonth && (
          <TodayButton onClick={backToToday} style={{ marginRight: 16 }}>
            {period === "daily" ? "Today" : "This week"}
          </TodayButton>
        )}

        <NavigateButton onClick={() => setMonth(month.subtract(1, "month"))}>
          <LeftIcon />
        </NavigateButton>
        <NavigateButton onClick={() => setMonth(month.add(1, "month"))}>
          <RightIcon />
        </NavigateButton>
      </NavigateBox>
    </HeaderWrapper>
  )
}

const getArraySeven = () => Array(7).fill(0)

const TableHead = () => {
  return (
    <thead>
      <StyledThR>
        {getArraySeven().map((_, index) => (
          <StyledTh key={index}>{dayjs().day(index).format("ddd")}</StyledTh>
        ))}
      </StyledThR>
    </thead>
  )
}

const Date = ({
  day,
  month,
  value,
  onChange,
  journal,
  isActiveWeek,
}: {
  day: Dayjs
  month: Dayjs
  value?: Dayjs
  onChange?: (day: Dayjs) => void
  journal: any
  isActiveWeek: boolean
}) => {
  const isInDifferentMonth =
    day.isBefore(month.startOf("month")) || day.isAfter(month.endOf("month"))

  const isAvailable = !!journal[day.format(DATE_FORMAT)]
  const isToday = day.isSame(dayjs().startOf("day"))
  const isSelected = value && value.startOf("day").isSame(day)

  return (
    <StyledTd>
      <DateButton
        $available={!isInDifferentMonth}
        $isSelected={!!isSelected}
        $isToday={isToday}
        $isInActiveWeek={isActiveWeek}
        onClick={() => {
          onChange?.(day)
        }}
      >
        <DateButtonText
          className="date_btn__text"
          $available={!isInDifferentMonth}
          $isSelected={!!isSelected}
          $isToday={isToday}
          $isInActiveWeek={isActiveWeek}
        >
          {day.get("date")}
        </DateButtonText>
        {isAvailable && (
          <TodayButtonDot
            className="date_btn__dot"
            $isSelected={!!isSelected}
            $isInDifferentMonth={isInDifferentMonth}
          />
        )}
      </DateButton>
    </StyledTd>
  )
}

const WeekRow = ({
  sunday,
  value,
  onChange,
  month,
  journal,
  isActive,
}: {
  sunday: Dayjs
  value?: Dayjs
  month: Dayjs
  onChange?: (day: Dayjs) => void
  journal: any
  isActive: boolean
}) => {
  return (
    <StyledTr $isActive={isActive}>
      {getArraySeven().map((_, index) => (
        <Date
          isActiveWeek={isActive}
          month={month}
          key={index}
          day={sunday.add(index, "day")}
          value={value}
          onChange={onChange}
          journal={journal}
        />
      ))}
    </StyledTr>
  )
}

const DatePicker = ({
  value,
  onChange,
  period = "daily",
}: {
  value?: Dayjs
  onChange?: (day: Dayjs) => void
  period?: ICalendarPeriod
}) => {
  const [month, setMonth] = useState(dayjs().startOf("month"))
  const startDate = useMemo(() => month.startOf("week"), [month])
  const endDate = useMemo(() => month.endOf("month").endOf("week"), [month])

  useEffect(() => {
    if (value && value.month() !== month.month()) {
      setMonth(value.startOf("month"))
    }
  }, [value])

  const { monthlyProgress } = useMonthlyProgress(startDate, endDate)

  const journal = useMemo(() => {
    if (!monthlyProgress) return {}
    return monthlyProgress.reduce((acc: any, date: string) => {
      acc[date] = true
      return acc
    }, {})
  }, [monthlyProgress])

  const backToToday = () => {
    setMonth(dayjs().startOf("month"))
  }

  const weeks = useMemo(() => {
    const weeks = []
    let done = false
    let sunday = startDate
    let monthIndex = month.month()
    let count = 0

    while (!done) {
      weeks.push(
        <WeekRow
          key={sunday.toString()}
          sunday={sunday}
          value={value}
          month={month}
          onChange={onChange}
          journal={journal}
          isActive={period === "weekly" && sunday.isSame(value, "week")}
        />,
      )
      sunday = sunday.add(1, "week")
      done = count++ > 2 && monthIndex !== sunday.month()
      monthIndex = sunday.month()
    }

    return weeks
  }, [month, value, journal, timeZone])

  return (
    <StyledDatePicker>
      <Header
        month={month}
        setMonth={setMonth}
        backToToday={backToToday}
        period={period}
      />
      <table>
        <TableHead />
        <tbody>{weeks}</tbody>
      </table>
    </StyledDatePicker>
  )
}

export default DatePicker
