import { SpinLoading } from "antd-mobile"
import React from "react"
import styled from "styled-components"

const StyledFullPageLoading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`

const FullPageLoading = () => {
  return (
    <StyledFullPageLoading>
      <SpinLoading />
    </StyledFullPageLoading>
  )
}

export default FullPageLoading
