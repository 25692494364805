import { Fragment } from "react"

import FormItem from "components/FormItem"
import { StyledWeeklyOption } from "./styled"
import { FlexBox } from "components/Common/FlexBox"
import { Title24 } from "components/Typo"
import { TitleBox } from "features/onboarding/PersonalInfo/styled"
import { useMetricType } from "features/onboarding/hooks"
import InputNumberFormItem from "components/InputNumberFormItem"
import { IOnboardingTypeName, IWeeklyGoal } from "features/onboarding/types"

export interface IWeeklyOption {
  label: string
  value: IWeeklyGoal
}

const WeeklyGoal = ({
  value,
  onChange,
  targetWeight,
  onTargetWeightChange,
  isWeightError,
  typeName,
}: {
  value: IWeeklyGoal
  onChange: (value: IWeeklyGoal) => void
  targetWeight: number
  onTargetWeightChange: (value: number) => void
  isWeightError: boolean
  typeName: IOnboardingTypeName
}) => {
  const { metricType } = useMetricType()
  const labelPrefix = typeName === "BUILDING" ? "Gain" : "Lose"
  const weightUnit = metricType === "IMPERIAL" ? "lb" : "kg"

  const options: IWeeklyOption[] =
    metricType === "METRIC"
      ? [
          {
            label: " 0.25 kg per week",
            value: 0.25,
          },
          {
            label: " 0.5 kg per week (Recommended)",
            value: 0.5,
          },
          {
            label: " 0.75 kg per week",
            value: 0.75,
          },
          {
            label: " 1 kg per week",
            value: 1,
          },
        ]
      : [
          {
            label: " 0.5 lb per week",
            value: 0.5,
          },
          {
            label: " 1 lb per week (Recommended)",
            value: 1,
          },
          {
            label: " 1.5 lb per week",
            value: 1.5,
          },
          {
            label: " 2 lb per week",
            value: 2,
          },
        ]
  return (
    <Fragment>
      <TitleBox style={{ paddingLeft: 8 }}>
        <Title24>Weekly Goal </Title24>
      </TitleBox>

      <div style={{ marginTop: 12 }}>
        <FormItem
          labelStyle={{ paddingLeft: 8 }}
          label="What is your goal weight?"
        >
          <InputNumberFormItem
            label="Weight"
            placeholder="Target weight"
            value={targetWeight}
            onChange={(val) => onTargetWeightChange(val)}
            detailTrailing={weightUnit}
            isError={isWeightError}
          ></InputNumberFormItem>
        </FormItem>
      </div>

      <div style={{ marginTop: 14 }}>
        <FormItem
          labelStyle={{ paddingLeft: 8 }}
          label="What is your weekly goal?"
        >
          <FlexBox $gap={10} $direction="column">
            {options.map((option) => (
              <StyledWeeklyOption
                key={option.value}
                onClick={() => onChange(option.value)}
                $active={value === option.value}
              >
                {labelPrefix}
                {option.label}
              </StyledWeeklyOption>
            ))}
          </FlexBox>
        </FormItem>
      </div>
    </Fragment>
  )
}

export default WeeklyGoal
