import routes from "router"
import { useRoutes } from "react-router-dom"
import { useGA } from "libs/ga"
import { useEffect } from "react"
import { useAppSelector } from "app/hooks"
import { setAxiosToken } from "api/requester"
import SnackbarHub from "components/SnackbarHub"
import { useInitBraze } from "libs/braze"

function App() {
  const token = useAppSelector((state) => state.auth.token)

  if (token) {
    setAxiosToken(token)
  }

  const elements = useRoutes(routes)
  const { initGA } = useGA()

  useEffect(() => {
    initGA()
  }, [])

  useInitBraze()

  return (
    <div className="app">
      <SnackbarHub />
      {elements}
    </div>
  )
}

export default App
