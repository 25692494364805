import { useAppDispatch, useAppSelector } from "app/hooks"
import {
  ILoginWithEmailPayload,
  ILoginWithMfaPayload,
  ISIWAResponse,
} from "./types"
import {
  loginWithApple,
  loginWithEmail,
  loginWithMfa,
  logOut,
  removeMfaSection,
} from "./authSlice"
import { useFailed } from "features/notification/hooks"

export const useLoginWithEmail = () => {
  const loginWithEmailLoading = useAppSelector(
    (state) => state.auth.loginWithEmailLoading,
  )
  const loginWithEmailFailed = useAppSelector(
    (state) => state.auth.loginWithEmailFailed,
  )
  const loginWithEmailSuccess = useAppSelector(
    (state) => state.auth.loginWithEmailSuccess,
  )

  const dispatch = useAppDispatch()
  const handleLoginWithEmail = (payload: ILoginWithEmailPayload) => {
    dispatch(loginWithEmail(payload))
  }

  useFailed(loginWithEmailFailed)

  return {
    loginWithEmailLoading,
    loginWithEmailFailed,
    loginWithEmailSuccess,
    handleLoginWithEmail,
  }
}

export const useLoginWithApple = () => {
  const loginWithAppleLoading = useAppSelector(
    (state) => state.auth.loginWithAppleLoading,
  )
  const loginWithAppleFailed = useAppSelector(
    (state) => state.auth.loginWithAppleFailed,
  )
  const loginWithAppleSuccess = useAppSelector(
    (state) => state.auth.loginWithAppleSuccess,
  )

  const dispatch = useAppDispatch()

  const handleLoginWithApple = (response: ISIWAResponse) => {
    dispatch(
      loginWithApple({
        idToken: response.authorization.id_token,
        source: "SIWA",
      }),
    )
  }

  const loginWithAppleCallback = (response: ISIWAResponse) => {
    handleLoginWithApple(response)
  }

  useFailed(loginWithAppleFailed)

  return {
    loginWithAppleLoading,
    loginWithAppleFailed,
    loginWithAppleSuccess,
    loginWithAppleCallback,
  }
}

export const useLogOut = () => {
  const dispatch = useAppDispatch()
  const logOutLoading = useAppSelector((state) => state.auth.logOutLoading)
  const refreshToken = useAppSelector((state) => state.auth.refreshToken)
  const handleLogout = () => {
    dispatch(logOut(refreshToken))
  }

  return { handleLogout, logOutLoading }
}

export const useLoginWithMfa = () => {
  const loginWithMfaLoading = useAppSelector(
    (state) => state.auth.loginWithMfaLoading,
  )
  const loginWithMfaFailed = useAppSelector(
    (state) => state.auth.loginWithMfaFailed,
  )

  const mfaMaskedPhoneNumber = useAppSelector(
    (state) => state.auth.mfaMaskedPhoneNumber,
  )

  const session = useAppSelector((state) => state.auth.mfaSession)
  const email = useAppSelector((state) => state.auth.email)

  useFailed(loginWithMfaFailed)

  const dispatch = useAppDispatch()

  const handleLoginWithMfa = (confirmationCode: string) => {
    dispatch(
      loginWithMfa({
        confirmationCode,
        session,
        email,
      } as ILoginWithMfaPayload),
    )
  }

  return {
    loginWithMfaLoading,
    loginWithMfaFailed,
    handleLoginWithMfa,
    mfaMaskedPhoneNumber,
  }
}

export const useRemoveMfaSection = () => {
  const dispatch = useAppDispatch()

  const onRemoveMfaSection = () => {
    dispatch(removeMfaSection())
  }

  return onRemoveMfaSection
}
