import styled from "styled-components"

export const StyledNutritionRemaining = styled.div`
  display: flex;
  padding: var(--spacing_m, 12px) var(--spacing_l, 16px);
  flex-direction: column;
  gap: 10px;

  border-radius: var(--spacing_s, 8px);
  border: 0.5px solid var(--Schemes-Outline-Variant, rgba(0, 38, 58, 0.14));
  background: var(--Schemes-Surface-Container-Lowest, #fff);

  margin-top: var(--spacing_xl, 24px);
`

export const NutritionRemainingTitle = styled.div`
  font-size: 14px;
  font-weight: 700;
  line-height: 160%;
`
