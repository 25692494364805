import {
  ImagePlaceholderBox,
  MealCalorie,
  MealContent,
  MealImage,
  MealImageBox,
  MealName,
  MealNameBox,
  MealSlice,
  StyledMealItem,
  StyledSwipeAction,
} from "./styled"
import { IMeal } from "features/meal/types"
import { useEffect, useRef } from "react"
import { getDisplayCalories } from "utils"
import TrashIcon from "components/Icons/TrashIcon"
import { useDeleteMeal } from "../../hooks"
import { SwipeActionRef } from "antd-mobile"
import { generatePath, useNavigate } from "react-router-dom"
import { useSetMealDetails } from "features/meal/hooks/mealDetailsHooks"
import mealItemImagePlaceholder from "./MealItemImagePlaceholder.svg"
import PATHS from "router/paths"
import MyFoodIcon from "components/Icons/MyFood"
import SmallFoodImage from "components/SmallFoodImage"

const PreviewImagePlaceholder = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="18"
      viewBox="0 0 15 18"
      fill="rgba(13, 163, 186, 0.47)"
    >
      <path
        d="M9.0275 4.71846C11.3217 4.43046 13.3079 5.76873 13.8995 8.06382L13.8985 8.06417C14.1064 8.87094 14.1078 9.68471 14.0141 10.5065C13.8681 11.7887 13.5002 13.0065 12.9436 14.1681C12.4279 15.2444 11.7758 16.2252 10.8297 16.9869C10.2495 17.4539 9.60128 17.7811 8.8533 17.8709C8.27452 17.9405 7.7056 17.8727 7.16383 17.6469L7.16277 17.6465C7.09262 17.6175 7.01226 17.6175 6.94211 17.6465L6.94105 17.6469C6.39963 17.8727 5.83036 17.9405 5.25158 17.8709C4.5036 17.7811 3.85537 17.4539 3.27518 16.9869C2.32945 16.2256 1.67735 15.2447 1.16131 14.1681C0.604728 13.0065 0.236731 11.7887 0.0908009 10.5065C-0.00260846 9.68471 -0.00155101 8.87059 0.206417 8.06382C0.780736 5.83591 2.66884 4.50962 4.87746 4.69734C4.74057 4.64523 4.60535 4.58559 4.47179 4.51841C4.18917 4.37686 3.9363 4.1941 3.71319 3.97013C3.22233 3.47739 2.90639 2.89119 2.76538 2.21151C2.62378 1.53243 2.59016 0.842006 2.66454 0.140231C3.36364 0.0655746 4.05173 0.0990209 4.72882 0.24057C5.40531 0.382717 5.98898 0.700158 6.47984 1.19289C6.82195 1.53632 7.07095 1.92453 7.22684 2.35754C7.38332 2.79055 7.48387 3.24596 7.52849 3.72376C7.72186 3.26089 7.95628 2.824 8.23176 2.41309C8.50664 2.00277 8.81513 1.6259 9.15725 1.28248C9.32087 1.11824 9.52911 1.03611 9.78197 1.03611C10.0348 1.03611 10.2431 1.11824 10.4067 1.28248C10.5703 1.44673 10.6521 1.65577 10.6521 1.9096C10.6521 2.16343 10.5703 2.37247 10.4067 2.53672C10.0795 2.86521 9.78941 3.22714 9.53655 3.62253C9.3159 3.96806 9.14621 4.33337 9.0275 4.71846Z"
        fill="#0DA3BA"
        fillOpacity="0.47"
      />
    </svg>
  )
}
interface MealItemProps {
  meal: IMeal
  swipedId: string | null
  setSwipedId: (id: string | null) => void
}

const MealItem = (props: MealItemProps) => {
  const { meal, swipedId, setSwipedId } = props
  const { handleDeleteMeal } = useDeleteMeal()
  const setMealDetails = useSetMealDetails()

  const navigate = useNavigate()

  const goToUpdateMeal = () => {
    setMealDetails(meal)
    navigate(
      generatePath(PATHS.app.meal.edit, {
        id: meal.id,
      }),
    )
  }

  useEffect(() => {
    if (swipedId && swipedId !== meal.id) {
      ref.current?.close()
    }
  }, [swipedId])

  const ref = useRef<SwipeActionRef>(null)

  return (
    <StyledSwipeAction
      closeOnTouchOutside={false}
      ref={ref}
      onActionsReveal={() => {
        setSwipedId(meal.id)
      }}
      closeOnAction={false}
      rightActions={[
        {
          key: "delete",
          text: <TrashIcon />,
          color: "danger",
          onClick: () => handleDeleteMeal(meal.id),
        },
      ]}
    >
      <StyledMealItem onClick={goToUpdateMeal}>
        <MealImageBox>
          <SmallFoodImage src={meal.imageUrl} />
        </MealImageBox>
        <MealContent>
          <MealNameBox>
            <MealName>{meal.name}</MealName>
            {meal.foodId && <MyFoodIcon />}{" "}
          </MealNameBox>

          <MealSlice>
            {meal.portion} {meal.unit?.toLocaleLowerCase()}
          </MealSlice>
        </MealContent>

        <MealCalorie>{getDisplayCalories(meal.calorie)} cal</MealCalorie>
      </StyledMealItem>
    </StyledSwipeAction>
  )
}

export default MealItem
