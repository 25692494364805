const ProfileIcon = ({ isActive = false }: { isActive?: boolean }) => {
  const fill = isActive ? "#044257" : "#69757D"
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="17"
      viewBox="0 0 15 17"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.50015 1.85124C6.20493 1.85124 5.15495 2.90122 5.15495 4.19644C5.15495 5.49166 6.20493 6.54164 7.50015 6.54164C8.79537 6.54164 9.84535 5.49166 9.84535 4.19644C9.84535 2.90122 8.79537 1.85124 7.50015 1.85124ZM3.30371 4.19644C3.30371 1.87881 5.18252 0 7.50015 0C9.81778 0 11.6966 1.87881 11.6966 4.19644C11.6966 6.51407 9.81778 8.39288 7.50015 8.39288C5.18252 8.39288 3.30371 6.51407 3.30371 4.19644Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.5 10.8227C4.65642 10.8227 2.35124 13.1279 2.35124 15.9714C2.35124 16.4826 1.93683 16.8971 1.42562 16.8971C0.914414 16.8971 0.5 16.4826 0.5 15.9714C0.5 12.1054 3.63401 8.97144 7.5 8.97144C11.366 8.97144 14.5 12.1054 14.5 15.9714C14.5 16.4826 14.0856 16.8971 13.5744 16.8971C13.0632 16.8971 12.6488 16.4826 12.6488 15.9714C12.6488 13.1279 10.3436 10.8227 7.5 10.8227Z"
        fill={fill}
      />
    </svg>
  )
}

export default ProfileIcon
