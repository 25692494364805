"use client"

import { styled } from "styled-components"

export const SnackbarRoot = styled.div`
  transform: translateY(0px);
  transition-duration: var(--animation-duration-long);
  position: absolute;
  z-index: var(--z-index-toast);
  top: 0;
  display: flex;
  align-items: stretch;
  transition-property: opacity, transform;
  width: 100%;
`

export const SnackbarWrapper = styled.div`
  width: 100%;

  .adm-notice-bar-content {
    justify-content: flex-start;
  }
`
