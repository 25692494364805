import { ProgressCircle } from "antd-mobile"
import {
  StyledNutrientInformation,
  StyledLabel,
  StyledInfoBox,
  NutrientChartWrapper,
  NutrientChartCalorieBox,
  NutrientChartCalorie,
  NutrientChartCalorieLabel,
  NutrientDetailsBox,
  NutrientDetailsItemLabel,
  NutrientDetailsItemValue,
} from "./styled"
import { PieChart, Pie, Cell } from "recharts"
import { getDisplayCalories } from "utils"

const NutrientPieChart = ({
  nutrient,
  calorie,
}: {
  nutrient: INutrientDetailsItem[]
  calorie: number
}) => {
  const data =
    calorie === 0
      ? [{ name: "No Data", value: 100, color: "rgba(0, 38, 58, 0.14)" }]
      : nutrient
  return (
    <NutrientChartWrapper>
      {calorie === 0 ? (
        <ProgressCircle
          percent={0}
          style={{
            "--track-width": "4px",
            "--size": "60px",
            "--fill-color": "#fff",
            "--track-color": "#00263A24 ",
          }}
        ></ProgressCircle>
      ) : (
        <PieChart width={60} height={60}>
          {" "}
          <Pie
            startAngle={90}
            endAngle={-270}
            cx="50%"
            cy="50%"
            data={data}
            innerRadius={25}
            outerRadius={30}
            fill="#8884d8"
            paddingAngle={1}
            dataKey="value"
            cornerRadius={50}
          >
            {" "}
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={entry.color}
              
              />
            ))}
          </Pie>
        </PieChart>
      )}

      <NutrientChartCalorieBox>
        <NutrientChartCalorie>
          {getDisplayCalories(calorie)}
        </NutrientChartCalorie>
        <NutrientChartCalorieLabel>cal</NutrientChartCalorieLabel>
      </NutrientChartCalorieBox>
    </NutrientChartWrapper>
  )
}

interface INutrientDetailsItem {
  name: string
  color: string
  value: number
}

const NutrientDetailsItem = ({ name, color, value }: INutrientDetailsItem) => {
  return (
    <div>
      <NutrientDetailsItemLabel>{name}</NutrientDetailsItemLabel>
      <NutrientDetailsItemValue $color={color}>
        {getDisplayCalories(value)} g
      </NutrientDetailsItemValue>
    </div>
  )
}

interface INutrientInformationProps {
  calorie: number
  protein: number
  fat: number
  carbohydrate: number
}

const NutrientInformation = (props: INutrientInformationProps) => {
  const { protein, carbohydrate, fat, calorie } = props
  const nutrient: INutrientDetailsItem[] = [
    {
      name: "Protein",
      color: "#722ED1",
      value: protein,
    },

    {
      name: "Fat",
      color: "#F7BA1E",
      value: fat,
    },
    {
      name: "Carbs",
      color: "#F354E4",
      value: carbohydrate,
    },
  ]
  return (
    <StyledNutrientInformation>
      <StyledLabel>Nutrients Information</StyledLabel>
      <StyledInfoBox>
        <NutrientPieChart nutrient={nutrient} calorie={calorie} />

        <NutrientDetailsBox>
          {nutrient.map((data, index) => (
            <NutrientDetailsItem key={index} {...data} />
          ))}
        </NutrientDetailsBox>
      </StyledInfoBox>
    </StyledNutrientInformation>
  )
}

export default NutrientInformation
