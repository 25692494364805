import { Button, SwipeAction } from "antd-mobile"
import styled from "styled-components"

export const StyledIngredients = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const Label = styled.div`
  font-size: 14px;
  font-weight: 700;
  line-height: 160%;
`

export const Placeholder = styled.div`
  color: var(--Schemes-On-Surface-Variant, #69757d);
  font-size: 14px;
  line-height: 160%;
  margin-top: -8px;
`

export const AddIngredientButton = styled(Button)`
  display: flex;
  padding: 7px 24px;
  justify-content: space-between;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  overflow: hidden;

  span {
    height: 24px;
    display: flex;
    align-items: center;
    gap: 8px;

    ont-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 14px; /* 100% */
    letter-spacing: 0.14px;
    color: var(--Schemes-Primary, #0da3ba);
  }
`

export const IngredientList = styled.div`
  padding: 4px 0px;
  border-radius: 8px;
  background: var(--Schemes-Surface-Container-Lowest, #fff);
  border: 1px solid var(--Schemes-Outline-Variant, rgba(0, 38, 58, 0.14));
`

export const StyledSwipeAction = styled(SwipeAction)`
  --adm-color-danger: #ff4921;

  .adm-swipe-action-actions-right .adm-button {
    width: 64px;
  }
`
