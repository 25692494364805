import requester from "api/requester"
import {
  ICreateFoodPayload,
  IFood,
  IMyFood,
  ISearchFoodsPayload,
  IUpdateFoodPayload,
} from "./foodTypes"

interface IFoodAPI {
  getSuggestedSearchTexts: (
    keyword: string,
    signal: AbortSignal,
  ) => Promise<string[]>

  searchFoods: (
    payload: ISearchFoodsPayload,
  ) => Promise<{ foods: IFood[]; next: string }>

  getMyFoods: () => Promise<IMyFood[]>
  createFood: (food: ICreateFoodPayload) => Promise<IMyFood>
  updateFood: (food: IUpdateFoodPayload) => Promise<IMyFood>
  deleteFood: (id: string) => Promise<void>
  getFood: (id: string) => Promise<IFood>
}

const getSnakeCaseFood = (food: ICreateFoodPayload) => {
  if (food.image) {
    delete food.imageId
    delete food.imageUrl
    delete food.previewImageUrl
  }

  return {
    ...food,
    id: undefined,
    image_id: food.imageId,
    imageId: undefined,
    isExternal: undefined,
    is_external: false,
    ingredients: food.ingredients?.map((ingredient) => ({
      ...ingredient,
      id: undefined,
      external_id: ingredient.id,
      image_url: ingredient.imageUrl,
      imageUrl: undefined,
      is_external: ingredient.isExternal,
      isExternal: undefined,
      externalId: undefined,
    })),
  }
}

const foodAPI: IFoodAPI = {
  getSuggestedSearchTexts(keyword, signal) {
    return requester.get(
      `/foods/auto-complete`,
      {
        keyword,
      },
      { signal },
    )
  },

  searchFoods(payload) {
    if (!payload.session) {
      delete payload.session
    }
    return requester.get("/foods/search", payload)
  },

  getMyFoods() {
    return requester.get("/foods")
  },

  createFood(food) {
    const payload = getSnakeCaseFood(food)
    const formData = new FormData()
    Object.keys(payload).forEach((key) => {
      const data = (payload as any)[key]
      if (data === undefined || data === null || data === "") return

      if (key === "ingredients") {
        formData.append(
          key,
          new Blob([JSON.stringify(data)], {
            type: "application/json",
          }),
        )
      } else {
        formData.append(key, data)
      }
    })

    return requester.post("/foods", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
  },

  updateFood(food) {
    const payload = getSnakeCaseFood(food)
    const formData = new FormData()
    Object.keys(payload).forEach((key) => {
      const data = (payload as any)[key]
      if (data === undefined || data === null || data === "") return

      if (key === "ingredients") {
        formData.append(
          key,
          new Blob([JSON.stringify(data)], {
            type: "application/json",
          }),
        )
      } else {
        formData.append(key, data)
      }
    })

    return requester.put(`/foods/${food.id}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
  },

  getFood(id) {
    return requester.get(`/foods/${id}`)
  },

  deleteFood(id) {
    return requester.delete(`/foods/${id}`)
  },
}

export default foodAPI
