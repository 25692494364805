import styled from "styled-components"

export const FoodImageBox = styled.div`
  height: 190px;
  width: 100%;
  position: relative;
`
export const Image = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
`

export const FoodImagePlaceholder = styled.img`
  width: 100%;
  height: 190px;
  object-fit: cover;
`

export const PlaceholderOverLay = styled.div`
  position: absolute;
  width: 100%;
  height: 190px;
  background: linear-gradient(180deg, rgba(27, 20, 1, 0) 0%, #1b1401 100%);
  top: 0;
`
