import React from "react"
import {
  SuggestedSearchIconBox,
  SuggestedTextItem,
  SuggestedTextList,
  SuggestedTextsTitle,
  SuggestedTextsWrapper,
} from "./styled"
import SmallSearchIcon from "components/Icons/SmallSearchIcon"
import Highlighter from "react-highlight-words"

const SuggestedSearchTexts = ({
  suggestedTexts,
  keyword,
  onSelect,
  isNoSuggestedSearchTexts,
}: {
  suggestedTexts: string[]
  keyword: string
  onSelect: (suggestedText: string) => void
  isNoSuggestedSearchTexts: boolean
}) => {
  return (
    <SuggestedTextsWrapper>
      <SuggestedTextsTitle>Suggested searches</SuggestedTextsTitle>

      {isNoSuggestedSearchTexts ? (
        <span style={{ padding: "0 8px" }}> No suggested founded</span>
      ) : (
        <SuggestedTextList>
          {suggestedTexts.map((text) => (
            <SuggestedTextItem key={text} onClick={() => onSelect(text)}>
              <SuggestedSearchIconBox>
                <SmallSearchIcon />
              </SuggestedSearchIconBox>

              <Highlighter
                highlightClassName="high-light-text"
                searchWords={keyword.split(" ")}
                textToHighlight={text}
              ></Highlighter>
            </SuggestedTextItem>
          ))}
        </SuggestedTextList>
      )}
    </SuggestedTextsWrapper>
  )
}

export default SuggestedSearchTexts
