import styled from "styled-components"

export const StyledOnboardingOption = styled.div<{ $active: boolean }>`
  display: flex;
  padding: 20px 25px;
  align-items: center;
  gap: 14px;

  border-radius: 8px;
  background: ${({ $active }) =>
    $active
      ? "var(--Schemes-Primary-Container-Variant-2, #0FBDD4)"
      : "rgba(255, 255, 255, 0.6)"};
  box-shadow: 0px 1px 7px 0px rgba(0, 0, 0, 0.12);
  cursor: default;
  width: 100%;
`

export const OnboardingOptionTitle = styled.div`
  font-size: 18px;
  font-weight: 700;
  line-height: 140%;
`

export const OnboardingOptionDescription = styled.div`
  font-size: 14px;
`

export const ActiveList = styled.div`
  display: flex;
  padding-top: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing_m, 12px);
  align-self: stretch;
`
