import styled from "styled-components"

export const Title24 = styled.h1`
  font-size: 24px;
  font-weight: 700;
  line-height: 120%;
  margin: 0px;
  padding: 0px;
`

export const Title20 = styled.h2`
  font-size: 20px;
  font-weight: 800;
  line-height: 120%;
  margin: 0px;
  padding: 0px;
`

export const Description = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 160%;
  margin: 0px;
  padding: 0px;
`
