const ChevronLeft = ({ disabled = false }: { disabled?: boolean }) => {
  return (
    <svg
      style={{ cursor: disabled ? "not-allowed" : "pointer" }}
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="20"
      viewBox="0 0 12 20"
      fill="none"
    >
      <g id="Regular-S">
        <path
          id="Vector"
          d="M-0.000244141 10C-0.000244141 10.1765 0.0291675 10.3235 0.0879911 10.4412C0.146815 10.5882 0.23505 10.7059 0.352697 10.8235L9.23505 19.6471C9.47034 19.8824 9.73505 20 10.088 20C10.4115 20 10.7056 19.8824 10.9409 19.6765C11.1762 19.4412 11.2645 19.1765 11.2645 18.8235C11.2645 18.5 11.1468 18.2353 10.9115 17.9706L2.88211 10L10.9115 2.02941C11.1468 1.79412 11.2645 1.5 11.2645 1.17647C11.2645 0.852941 11.1468 0.558825 10.9409 0.323531C10.7056 0.0882371 10.4409 0 10.088 0C9.76446 0 9.47034 0.117648 9.23505 0.352943L0.352697 9.17647C0.117403 9.41177 -0.000244141 9.67647 -0.000244141 10Z"
          fill="#1C1F21"
        />
      </g>
    </svg>
  )
}

export default ChevronLeft
