import React from "react"

const SmallChevronLeft = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.7194 6.24006C15.0697 6.58299 15.0955 7.16679 14.7771 7.54401L11.0155 12L14.7771 16.456C15.0955 16.8332 15.0697 17.417 14.7194 17.7599C14.3692 18.1029 13.8271 18.0751 13.5086 17.6978L9.22291 12.6209C8.9257 12.2688 8.9257 11.7312 9.22291 11.3791L13.5086 6.30215C13.8271 5.92493 14.3692 5.89713 14.7194 6.24006Z"
        fill="#1C1F21"
      />
    </svg>
  )
}

export default SmallChevronLeft
