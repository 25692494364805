import { FlexBox } from "components/Common/FlexBox"
import {
  ConfirmBody,
  ConfirmEntry,
  ConfirmTitle,
  IconWrapper,
  StyledModal,
} from "./styled"
import TrashIcon from "components/Icons/TrashIcon"
import Button from "components/Buttons/Button"
import InfoIcon from "components/Icons/InfoIcon"
import React from "react"

interface IPopConfirmProps {
  isOpen: boolean
  title: string
  loading: boolean
  onCancel: () => void
  onConfirm: () => void
  type?: "danger" | "info"
  children: React.ReactNode
  cancelText?: string
  confirmText?: string
}

const PopConfirm = (props: IPopConfirmProps) => {
  const {
    isOpen,
    title,
    cancelText = "No, Keep it",
    confirmText = "Yes, Delete",
    loading,
    onCancel,
    onConfirm,
    type = "info",
    children,
  } = props
  return (
    <StyledModal
      style={
        {
          "--max-width": "90vw",
        } as React.CSSProperties
      }
      visible={isOpen}
      content={
        <FlexBox $direction="column" $gap={16} style={{ width: 276 }}>
          <FlexBox $direction="column" $gap={4}>
            <FlexBox $justify="center">
              {type === "info" ? (
                <IconWrapper $type="info">
                  <InfoIcon />
                </IconWrapper>
              ) : (
                <IconWrapper $type="danger">
                  <TrashIcon color="#FF4921" />
                </IconWrapper>
              )}
            </FlexBox>
            <ConfirmTitle>{title}</ConfirmTitle>

            <ConfirmBody>{children}</ConfirmBody>
          </FlexBox>

          <FlexBox $gap={16}>
            <Button
              onClick={onCancel}
              disabled={loading}
              style={{ width: "50%" }}
              $type="cancel"
              $size="small"
            >
              {cancelText}
            </Button>

            <Button
              onClick={onConfirm}
              loading={loading}
              style={{ width: "50%" }}
              $type={type}
              $size="small"
            >
              {confirmText}
            </Button>
          </FlexBox>
        </FlexBox>
      }
    ></StyledModal>
  )
}

export default PopConfirm
