import { useAppDispatch, useAppSelector } from "app/hooks"
import { useEffect } from "react"
import {
  generatePath,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom"
import {
  getMealDetails,
  setMealDetails,
  setMealDetailsBackPath,
} from "../mealSlice"
import { IMeal } from "../types"
import PATHS from "router/paths"
import { useSetMealTypeParams } from "./hooks"

export const useMealDetails = () => {
  const { id } = useParams()
  const mealDetails = useAppSelector((state) => state.meal.mealDetails)
  const getMealDetailsLoading = useAppSelector(
    (state) => state.meal.getMealDetailsLoading,
  )
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (id && id !== mealDetails?.id) {
      setMealDetails(undefined)
      dispatch(getMealDetails(id))
    }
  }, [id])

  return {
    getMealDetailsLoading,
    mealDetails,
  }
}

export const useSetMealDetails = () => {
  const dispatch = useAppDispatch()

  return (mealDetails: IMeal) => {
    dispatch(setMealDetails(mealDetails))
  }
}

export const useMealDetailsOnBack = () => {
  const mealDetailsBackPath = useAppSelector(
    (state) => state.meal.mealDetailsBackPath,
  )
  const navigate = useNavigate()

  return () => navigate(mealDetailsBackPath ?? PATHS.app.root)
}

export const useResetMealDetailsBackPath = () => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(setMealDetailsBackPath(undefined))
  }, [])
}

export const useGoToMealDetails = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  const { mealType } = useSetMealTypeParams(true)

  const onGoMealFoodDetails = (meal: IMeal) => {
    dispatch(setMealDetails(meal))

    navigate(
      generatePath(
        `${PATHS.app.meal.details}${mealType ? `?mealType=${mealType}` : ""}`,
        { id: meal.id },
      ),
    )
    dispatch(setMealDetailsBackPath(location.pathname))
  }

  return onGoMealFoodDetails
}
