import { PickerView } from "antd-mobile"
import styled, { createGlobalStyle } from "styled-components"

export const StyledInputNumberFormItem = styled.div`
  --label-width: 70px;

  display: flex;
  position: relative;
  width: 100%;
`
export const StyledLabel = styled.label`
  position: absolute;
  text-align: left;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);

  font-size: 16px;
  line-height: 160%;
`

export const StyledInput = styled.input<{ $isError: boolean }>`
  padding: 18.5px 16px;
  padding-left: var(--label-width);
  line-height: 30px;
  font-weight: 500;
  border-radius: 8px;
  outline: ${(props) =>
    props.$isError
      ? "1px solid var(--Schemes-Error, #B3261E) !important"
      : "0.5px solid var(--Schemes-Outline, rgba(0, 38, 58, 0.32))"};

  @supports (-webkit-touch-callout: none) {
    outline: ${(props) =>
      props.$isError
        ? "1px solid var(--Schemes-Error, #B3261E) !important"
        : "0.8px solid var(--Schemes-Outline, rgba(0, 38, 58, 0.32))"};
  }

  background: var(#fff);
  color: ${(props) =>
    props.$isError
      ? "var(--Schemes-Error, #B3261E)"
      : "var(--Schemes-Primary, #0DA3BA)"};

  text-align: right;
  width: 100%;
  height: 100%;
  font-size: 16px;

  &,
  &:focus,
  &:active,
  &:focus-visible {
    border: none;
  }

  &:focus,
  &:active,
  &:focus-visible {
    outline: 1px solid var(--Schemes-Primary, #0da3ba);
  }

  &::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: var(--color-navy);
    opacity: 0.36;
    font-family: proxima-nova;
  }
  &::-moz-placeholder {
    /* Firefox 19+ */
    color: var(--color-navy);
    opacity: 0.36;
    font-family: proxima-nova;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type="number"] {
    -moz-appearance: textfield;
  }

  cursor: pointer;
`

export const PickerPopupStyle = createGlobalStyle`

 @media screen and (min-width: 500px) {
 .adm-popup {
   .adm-mask,
   .adm-popup-body {
     max-width: 500px;
     left: 50%;
     transform: translateX(-50%) !important;
     opacity: !important;
   };
 }
 };
 
 .adm-popup {
   .adm-picker-header-button {
   font-size: 14px;
   font-style: normal;
   font-weight: 700;
   line-height: 14px; /* 100% */
   letter-spacing: 0.14px;
   color: var(--Schemes-Primary, #0DA3BA);
   padding-top:14px;
   }
 
   .adm-picker-view-column-item {
     font-size: 18px;
     font-style: normal;
     font-weight: 600;
     line-height: 160%;
     color: var(--Schemes-On-Primary-Container, #044257);
   }
 
   .adm-picker-view-column-item-active {
     font-size: 20px;
     font-style: normal;
     font-weight: 800;
     line-height: 120%;
     color: var(--Schemes-On-Surface, #1C1F21);
   }
 }
 `

export const StyledPickerView = styled(PickerView)`
  width: 86px;
  padding: 0;
  padding-right: 0;

  .adm-picker-view-mask-middle {
    height: 39px;
    border: none;
  }

  .adm-picker-view-column-item {
    padding: 0;
  }

  .adm-picker-view-column-item-active {
    font-size: 20px;
    font-style: normal;
    font-weight: 800;
    line-height: 120%;
  }
`

export const StyledPickerViewInches = styled(StyledPickerView)`
  .adm-picker-view-column-item {
    justify-content: flex-start;
    padding-left: 15px;
  }
`

export const PickerText = styled.div`
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 160%;
  width: 10px;
  position: absolute;
  cursor: pointer;
  pointer-events: none;
  color: var(--Schemes-On-Surface-Variant, #69757d);

  span {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
`

export const PickerTextFeet = styled(PickerText)`
  left: calc(50% - 29px);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  transform: translateX(-100%);
`

export const PickerTextInches = styled(PickerText)`
  left: calc(50% + 56px);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`

export const PickerSelectedMask = styled.div`
  --picker-border: 1px solid var(--Schemes-Outline, rgba(0, 38, 58, 0.32));

  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 39px;

  border-top: var(--picker-border);
  border-bottom: var(--picker-border);
  width: 100%;
  pointer-events: none;
`

export const PickerViewContainer = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  gap: 30px;
`

export const PickerHeader = styled.div`
  border-bottom: 1px solid var(--Schemes-Outline-Variant, rgba(0, 38, 58, 0.14));
`
export const PickerHeaderTop = styled.div`
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const PickerHeaderBottom = styled.div`
  display: flex;
  padding: 0px var(--spacing_m, 12px) var(--spacing_l, 16px)
    var(--spacing_m, 12px);
  justify-content: flex-end;
  align-items: center;
`

export const DoneButton = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px; /* 100% */
  letter-spacing: 0.14px;
  color: var(--Schemes-Primary, #0da3ba);
  text-align: center;
  cursor: pointer;
`
