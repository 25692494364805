import { Fragment, useState } from "react"
import { IActive, IOnboardingPayload, IOnboardingStep } from "../types"
import Active from "../Steps/Active"
import Direction from "../Steps/Goal"
import {
  CTABox,
  OnboardingContent,
  OnboardingWrapper,
  ToPersonalLink,
} from "../styled"
import { HeaderBackBox, HeaderBackButton, OnboardingHeader } from "./styled"
import Button from "components/Buttons/Button"
import { useOnboarding, useUser } from "../hooks"
import { Link, useMatch } from "react-router-dom"
import PATHS from "router/paths"
import Stepper from "../Stepper"
import WeeklyGoal from "../Steps/WeeklyGoal"
import Plan from "../Steps/Plan"
import ChevronLeft from "components/Icons/ChevronLeft"
import {
  WeightErrorBox,
  WeightErrorText,
  WeightErrorTitle,
} from "../Steps/styled"
import { useSetPageTitle } from "libs/ga"
import { MAINTAIN_WEIGHT_TYPES } from "config"

const { active, setTypeName, weeklyGoal, plan } = IOnboardingStep

const Onboarding = () => {
  const isUpdatingGoal = useMatch(PATHS.app.updateGoal)

  const title = isUpdatingGoal
    ? "Update Goal - Profile"
    : "Set Goal - Onboarding"
  useSetPageTitle(title)
  const { user } = useUser()

  const [step, setStep] = useState<IOnboardingStep>(active)

  const [values, setValues] = useState<IOnboardingPayload>({
    active: "ACTIVE",
    typeName: "WEIGHT_LOSS",
    weeklyGoal: 0.5,
    targetWeight: 0,
  })

  const { handleOnboarding, onboardingLoading } = useOnboarding(() => {
    setStep(plan)
  })

  const [weightError, setWeightError] = useState("")

  const validateWeight = (targetWeight: number) => {
    let error = ""

    if (!user) return error

    if (values.typeName === "BUILDING" && targetWeight <= user.weight) {
      error = "Your goal weight must be more than your current weight"
    } else if (
      values.typeName === "WEIGHT_LOSS" &&
      targetWeight >= user.weight
    ) {
      error = "Your goal weight must be less than your current weight"
    } else if (!targetWeight) {
      error = "Goal weight can not be empty"
    } else {
      error = ""
    }

    setWeightError(error)
    return error
  }

  const handleWeightChange = (targetWeight: number) => {
    const error = validateWeight(targetWeight)
    setWeightError(error)
    setValues((val) => ({ ...val, targetWeight }))
  }

  const goNext = () => {
    const nextStep = step + 1

    if (
      step === setTypeName &&
      MAINTAIN_WEIGHT_TYPES.includes(values.typeName)
    ) {
      handleOnboarding(values)
      return
    }

    if (nextStep === plan) {
      const error = validateWeight(values.targetWeight)

      if (error) {
        return
      }
      handleOnboarding(values)
    } else {
      setStep(nextStep)
    }
  }

  return (
    <OnboardingWrapper>
      <div style={{ height: 6 }}></div>

      <OnboardingHeader>
        {step === active ? (
          <HeaderBackBox>
            <ToPersonalLink
              to={isUpdatingGoal ? PATHS.app.profile : PATHS.app.personalInfo}
            >
              <HeaderBackButton>
                <ChevronLeft />{" "}
              </HeaderBackButton>
            </ToPersonalLink>
            <span>{isUpdatingGoal ? "Your goal" : "Info"}</span>
          </HeaderBackBox>
        ) : (
          <HeaderBackBox>
            <HeaderBackButton
              onClick={() =>
                setStep((step) => {
                  if (step === plan && MAINTAIN_WEIGHT_TYPES.includes(values.typeName)) {
                    return step - 2
                  }

                  return step - 1
                })
              }
            >
              <ChevronLeft />{" "}
            </HeaderBackButton>
          </HeaderBackBox>
        )}

        <Stepper currentStep={step} />
      </OnboardingHeader>

      <OnboardingContent>
        {step === active && (
          <Active
            value={values.active}
            onChange={(active: IActive) =>
              setValues((val) => ({ ...val, active }))
            }
          />
        )}

        {step === setTypeName && (
          <Direction
            value={values.typeName}
            onChange={(typeName) => {
              setValues((val) => ({ ...val, typeName }))
            }}
          />
        )}

        {step === weeklyGoal && (
          <Fragment>
            <WeeklyGoal
              isWeightError={!!weightError}
              targetWeight={values.targetWeight}
              value={values.weeklyGoal}
              onChange={(goal) => {
                setValues((val) => ({ ...val, weeklyGoal: goal }))
              }}
              onTargetWeightChange={handleWeightChange}
              typeName={values.typeName}
            />
            {!!weightError ? (
              <WeightErrorBox>
                <WeightErrorTitle>Invalid weight</WeightErrorTitle>
                <WeightErrorText>{weightError}</WeightErrorText>
              </WeightErrorBox>
            ) : null}
          </Fragment>
        )}

        {step === plan && <Plan />}
      </OnboardingContent>

      <CTABox>
        {step !== plan ? (
          <Button
            $type="primary"
            style={{ width: "100%" }}
            loading={onboardingLoading}
            onClick={goNext}
            disabled={!!weightError}
          >
            Continue
          </Button>
        ) : (
          <Link
            to={PATHS.app.root}
            style={{ textDecoration: "none", width: "100%" }}
          >
            <Button $type="primary" style={{ width: "100%" }}>
              Get Started
            </Button>
          </Link>
        )}
      </CTABox>
    </OnboardingWrapper>
  )
}

export default Onboarding
