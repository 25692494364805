import { ReactNode, forwardRef, useState } from "react"
import { styled } from "styled-components"

interface Props {
  children?: ReactNode
  onChange: (image?: File) => void
}
export type Ref = HTMLInputElement

const ImageUploaderWrapper = styled.div`
  input {
    display: none;
  }

  .image-uploader__loading-overlay {
    background: rgba(0, 0, 0, 0.55);
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

const HiddenImageInput = forwardRef<Ref, Props>(({ onChange }, ref) => {
  const [key, setKey] = useState(0)
  return (
    <ImageUploaderWrapper className="image-uploader">
      <input
        ref={ref}
        type="file"
        accept="image/*, .heic"
        onChange={(e) => {
          onChange(e.target.files?.[0])
          setKey((prev) => prev + 1)
        }}
        key={key}
      />
    </ImageUploaderWrapper>
  )
})

export default HiddenImageInput
