import React from "react"
import { getSvgFill } from "utils"

const VeryActive = ({ isSelected }: { isSelected: boolean }) => {
  const { backgroundFill, pathFill } = getSvgFill(isSelected)
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
    >
      <g clipPath="url(#clip0_370_1665)">
        <g filter="url(#filter0_b_370_1665)">
          <path
            d="M0 30C0 13.4315 13.4315 0 30 0C46.5685 0 60 13.4315 60 30C60 46.5685 46.5685 60 30 60C13.4315 60 0 46.5685 0 30Z"
            fill={backgroundFill}
          />
        </g>
        <g clipPath="url(#clip1_370_1665)">
          <path
            d="M32.6211 21.25C31.8649 22.5562 32.3186 24.2475 33.6249 25.0037C34.9311 25.76 36.6224 25.3062 37.3786 24C38.1349 22.6937 37.6811 21.0025 36.3749 20.2462C35.0686 19.49 33.3774 19.9437 32.6211 21.25Z"
            fill={pathFill}
          />
          <path
            d="M34.7798 27.3138C34.7798 27.3138 32.5386 26.0213 31.2048 25.2513C28.2761 23.5463 27.0661 19.985 28.2073 16.905C28.4411 16.2725 28.1936 15.5713 27.6161 15.2413C26.8873 14.8288 25.9386 15.145 25.6498 15.9288C24.3436 19.38 25.1686 23.285 27.6573 25.925L21.2636 36.9938C20.8786 37.6538 21.1123 38.4925 21.7723 38.8775C22.4323 39.2625 23.2711 39.0288 23.6561 38.3688L25.0311 35.99L27.4098 37.365L23.9723 43.3188C23.5873 43.9788 23.8211 44.8175 24.4811 45.2025C25.1411 45.5875 25.9798 45.3538 26.3648 44.6938L34.3261 30.9163C35.6736 32.7588 36.0586 35.2063 35.2336 37.4063C34.9998 38.025 35.2611 38.7263 35.8248 39.0563C36.5536 39.4825 37.5023 39.1663 37.8048 38.3688C39.1386 34.7663 38.3686 30.3388 34.7798 27.3138Z"
            fill={pathFill}
          />
          <path
            d="M30.5313 18.225C31.5213 18.7888 32.7725 18.4588 33.35 17.4688C33.9138 16.4788 33.5838 15.2275 32.5938 14.65C31.6038 14.0863 30.3525 14.4163 29.775 15.4063C29.2113 16.3963 29.5413 17.6613 30.5313 18.225Z"
            fill={pathFill}
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_b_370_1665"
          x="-120"
          y="-120"
          width="300"
          height="300"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="60" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_370_1665"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_370_1665"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_370_1665">
          <rect width="60" height="60" rx="30" fill="white" />
        </clipPath>
        <clipPath id="clip1_370_1665">
          <rect
            width="33"
            height="33"
            fill="white"
            transform="translate(13 13)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default VeryActive
