import { FlexBox } from "components/Common/FlexBox"
import { Link } from "react-router-dom"
import {
  useHomePathWithSelectedDate,
  useSetMealTypeParams,
} from "../../hooks/hooks"
import { BackButton } from "components/Common/BackButton"
import ChevronLeft from "components/Icons/ChevronLeft"
import { MealTypePopover, PageTitle } from "./styled"
import DropdownDownIcon from "components/Icons/DropdownDownIcon"
import { Action } from "antd-mobile/es/components/action-sheet"
import { IMealType } from "features/home/types"
import { Fragment } from "react"

const actions: Action[] = [
  { key: "breakfast", text: "Breakfast" },
  { key: "lunch", text: "Lunch" },
  { key: "dinner", text: "Dinner" },
  { key: "snack", text: "Snack" },
]

const Header = () => {
  const homePath = useHomePathWithSelectedDate()
  const { mealType, setMealType } = useSetMealTypeParams()

  return (
    <Fragment>
      <FlexBox
        style={{ height: 44, padding: "9px 12px" }}
        $justify="space-between"
        $alignItems="center"
      >
        <Link to={homePath}>
          <BackButton>
            <ChevronLeft />
          </BackButton>
        </Link>

        <MealTypePopover
          actions={actions}
          placement="bottom"
          onAction={({ key }) => {
            setMealType(key as IMealType)
          }}
          trigger="click"
        >
          <PageTitle>
            {mealType} <DropdownDownIcon />
          </PageTitle>
        </MealTypePopover>

        <BackButton></BackButton>
      </FlexBox>
    </Fragment>
  )
}

export default Header
