import { Fragment } from "react"

import OnboardingOptions, {
  IOnboardingOption,
} from "components/OnboardingOptions"
import LoseWeight from "./Icons/LoseWeight"
import MaintainWeight from "./Icons/MaintainWeight"
import GainWeight from "./Icons/GainWeight"
import { Title24 } from "components/Typo"
import { TitleBox } from "features/onboarding/PersonalInfo/styled"
import { IOnboardingTypeName } from "features/onboarding/types"
import NotSure from "./Icons/NotSure"

const options: IOnboardingOption[] = [
  {
    label: "Lose",
    value: "WEIGHT_LOSS",
    Icon: LoseWeight,
    description: "",
  },
  {
    label: "Maintain",
    value: "MAINTAIN",
    Icon: MaintainWeight,
    description: "",
  },
  {
    label: "Gain",
    value: "BUILDING",
    Icon: GainWeight,
    description: "",
  },
  {
    label: "I'm not sure yet",
    value: "NOT_SURE",
    Icon: NotSure,
    description: "",
  },
]

const Direction = ({
  value,
  onChange,
}: {
  value: IOnboardingTypeName
  onChange: (value: IOnboardingTypeName) => void
}) => {
  return (
    <Fragment>
      <TitleBox>
        <Title24>What goal do you have in mind? </Title24>
      </TitleBox>
      <OnboardingOptions
        options={options}
        onChange={(val) => onChange(val as IOnboardingTypeName)}
        value={value}
      />
    </Fragment>
  )
}

export default Direction
