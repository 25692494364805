import styled, { createGlobalStyle } from "styled-components"

export const StyledTextFieldFormItem = styled.div`
  --label-width: 70px;

  display: flex;
  position: relative;
  width: 100%;
`
export const StyledLabel = styled.label`
  position: absolute;
  text-align: left;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);

  font-size: 16px;
  line-height: 160%;
`

export const StyledInput = styled.input<{ $isError: boolean }>`
  cursor: pointer;
  text-transform: capitalize;
  padding: 18.5px 16px;
  padding-left: var(--label-width);
  line-height: 26px;
  font-weight: 500;
  border-radius: 8px;
  outline: ${(props) =>
    props.$isError
      ? "1px solid var(--Schemes-Error, #B3261E) !important"
      : "0.5px solid var(--Schemes-Outline, rgba(0, 38, 58, 0.32))"};

  @supports (-webkit-touch-callout: none) {
    outline: ${(props) =>
      props.$isError
        ? "1px solid var(--Schemes-Error, #B3261E) !important"
        : "0.8px solid var(--Schemes-Outline, rgba(0, 38, 58, 0.32))"};
  }

  background: var(#fff);
  color: ${(props) =>
    props.$isError
      ? "var(--Schemes-Error, #B3261E)"
      : "var(--Schemes-Primary, #0DA3BA)"};

  text-align: right;
  width: 100%;
  height: 100%;
  font-size: 16px;

  &,
  &:focus,
  &:active,
  &:focus-visible {
    border: none;
  }

  &:focus,
  &:active,
  &:focus-visible {
    outline: 1px solid var(--Schemes-Primary, #0da3ba);
  }

  &::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: var(--Schemes-Outline, rgba(0, 38, 58, 1));
    font-size: 16px;
    font-weight: 400;
  }
  &::-moz-placeholder {
    /* Firefox 19+ */
    color: var(--Schemes-Outline, rgba(0, 38, 58, 1));
    font-size: 16px;
    font-weight: 400;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type="number"] {
    -moz-appearance: textfield;
  }
`

export const PickerPopupStyle = createGlobalStyle`

 @media screen and (min-width: 500px) {
 .picker-popup {
   .adm-mask,
   .adm-popup-body {
     max-width: 500px;
     left: 50%;
     transform: translateX(-50%) !important;
     opacity: !important;
   };
 }
 };
 
 .picker-popup {
   .adm-picker-header-button {
   font-size: 14px;
   font-style: normal;
   font-weight: 700;
   line-height: 14px; /* 100% */
   letter-spacing: 0.14px;
   color: var(--Schemes-Primary, #0DA3BA);
   padding-top:14px;
   }
 
   .adm-picker-view-column-item {
     font-size: 16px;
     font-style: normal;
     font-weight: 600;
     line-height: 160%;
     color: var(--Schemes-On-Primary-Container, #044257);
   }
 
   .adm-picker-view-column-item-active {
     font-size: 20px;
     font-style: normal;
     font-weight: 800;
     line-height: 120%;
     color: var(--Schemes-On-Surface, #1C1F21);
   }
 }
 `
export const PickerHeader = styled.div`
  position: absolute;
  top: 6px;
  width: 36px;
  height: var(--spacing_xxs, 4px);
  border-radius: var(--spacing_l, 16px);
  background: var(--Schemes-Outline-Variant, rgba(0, 38, 58, 0.14));
  transform: translateX(-50%);
  left: 50%;
`
