import styled from "styled-components"

export const StyledNutrientInformation = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const StyledLabel = styled.div`
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0.14px;
`

export const StyledInfoBox = styled.div`
  border-radius: 8px;
  border: 1px solid var(--Schemes-Outline-Variant, rgba(0, 38, 58, 0.14));
  background: #fff;

  display: flex;
  padding: 9px 12px;
  gap: 42px;
`

export const NutrientChartWrapper = styled.div`
  width: 60px;
  height: 60px;
  position: relative;
  flex-shrink: 0;
`

export const NutrientChartCalorieBox = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const NutrientChartCalorie = styled.div`
  font-size: 14px;
  font-weight: 700;
  line-height: 160%;
`
export const NutrientChartCalorieLabel = styled.div`
  color: #8c8c8c;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px; /* 100% */
  letter-spacing: 0.109px;
`

export const NutrientDetailsBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
`

export const NutrientDetailsItemLabel = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
`

export const NutrientDetailsItemValue = styled.div<{ $color: string }>`
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 160%;
  color: ${({ $color }) => $color};
`
