import { useState } from "react"
import { IAPIFood, ICreateFoodPayload, IIngredientFood } from "../foodTypes"
import { getNutrientInformation } from "helper/nutrient"
import { v4 as uuidv4 } from "uuid"

export const useCreateLocalFood = (initFood: ICreateFoodPayload) => {
  const [food, setFood] = useState(initFood)

  const onNameChange = (name: string) => {
    setFood({ ...food, name })
  }

  const onPortionChange = (portion: number) => {
    setFood({ ...food, portion })
  }

  const onUnitChange = (unit: string) => {
    setFood({ ...food, unit })
  }

  const onAddIngredient = (ingredient: IAPIFood) => {
    if (!food.ingredients) return

    const newIngredient: IIngredientFood = {
      ...ingredient,
      externalId: ingredient.id,
      id: uuidv4(),
    }

    const ingredients = [...food.ingredients, newIngredient]
    const nutrient = getNutrientInformation(ingredients)

    setFood({
      ...food,
      ...nutrient,
      ingredients,
    })
  }

  const onRemoveIngredient = (id: string) => {
    if (!food.ingredients) return

    const ingredients = food.ingredients.filter(
      (ingredient) => ingredient.id !== id,
    )
    const nutrient = getNutrientInformation(ingredients)

    setFood({
      ...food,
      ...nutrient,
      ingredients,
    })
  }

  const onUpdateIngredient = (updatedIngredient: IIngredientFood) => {
    if (!food.ingredients) return
    const ingredients = food.ingredients.map((ingredient) =>
      updatedIngredient.id === ingredient.id ? updatedIngredient : ingredient,
    )
    const nutrient = getNutrientInformation(ingredients)

    setFood({
      ...food,
      ...nutrient,
      ingredients,
    })
  }

  return {
    food,
    onNameChange,
    onPortionChange,
    onUnitChange,
    onAddIngredient,
    onRemoveIngredient,
    onUpdateIngredient,
  }
}
