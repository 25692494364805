import styled from "styled-components"

export const StyledPersonalInfo = styled.div`
  height: 100%;
  padding: 24px 8px 0 8px;
  background: var(--Schemes-Surface-Container-Low, #f9fbfc);

  display: flex;
  flex-direction: column;
`

export const TitleBox = styled.div`
  display: flex;
  padding: var(--spacing_xs, 6px) 8px;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing_xxs, 4px);
`

export const InfoSection = styled.div<{ $bold?: boolean; $noBorder?: boolean }>`
  padding: var(--spacing_xs, 6px) 0px;
  border-radius: var(--spacing_s, 8px);

  border: ${({ $noBorder }) =>
    $noBorder
      ? "none"
      : "0.5px solid var(--Schemes-Outline, rgba(0, 38, 58, 0.14))"};
  background: ${({ $bold }) =>
    $bold
      ? "var(--Schemes-Surface-Container, #EBEDF0)"
      : "var(--Schemes-Surface-Container-Lowest, #fff)"};
`

export const MetricContainer = styled.div`
  padding: 12px 0;
  display: flex;
  justify-content: center;
`
