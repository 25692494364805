import { useAppSelector } from "app/hooks"
import { useEffect, useState } from "react"
import ReactGA from "react-ga4"

const gaTrackingId = import.meta.env.VITE_GOOGLE_ANALYTICS_ID
const isProduction = process.env.NODE_ENV === "production"

const hasGa = gaTrackingId && isProduction

const getTitle = (title?: string) => {
  return title ? `${title} | ZOZOFIT Food Journal` : "ZOZOFIT Food Journal"
}

export const useGA = () => {
  const userId = useAppSelector((state) => state.auth.userId)
  const [hasUserId, setHasUserId] = useState(false)

  const initGA = () => {
    if (!hasGa) {
      return
    }

    ReactGA.initialize(gaTrackingId, {
      gaOptions: { userId },
    })

    setHasUserId(!!userId)
  }

  const pageView = (title: string) => {
    const payload: any = { hitType: "pageview", page: location.pathname }
    payload.title = getTitle(title)

    ReactGA.send(payload)
  }

  useEffect(() => {
    if (!hasGa) {
      return
    }
    ReactGA.set({ userId })

    const shouldSetUserId = userId && !hasUserId
    if (shouldSetUserId) {
      setHasUserId(true)
      ReactGA.set({ userId })
    }

    const shouldRemoveUserId = !userId && hasUserId
    if (shouldRemoveUserId) {
      setHasUserId(false)
    }
  }, [userId, hasUserId])

  return {
    initGA,
    pageView,
  }
}

export const useSetPageTitle = (title: string) => {
  useEffect(() => {
    document.title = getTitle(title)
  }, [])
}
