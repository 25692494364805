import { useEffect, useState } from "react"
import {
  IAPIFood,
  ICreateFoodPayload,
  IIngredientFood,
  IMyFood,
} from "../foodTypes"
import { getNutrientInformation } from "helper/nutrient"
import { v4 as uuidv4 } from "uuid"

export const useUpdateLocalFood = (initFood?: ICreateFoodPayload) => {
  const [food, setFood] = useState(initFood)
  useEffect(() => {
    setFood(initFood)
  }, [initFood])

  const onNameChange = (name: string) => {
    if (!food) return
    setFood({ ...food, name })
  }

  const onPortionChange = (portion: number) => {
    if (!food) return
    setFood({ ...food, portion })
  }

  const onUnitChange = (unit: string) => {
    if (!food) return
    setFood({ ...food, unit })
  }

  const onAddIngredient = (ingredient: IAPIFood) => {
    if (!food) return
    if (!food.ingredients) return

    const newIngredient: IIngredientFood = {
      ...ingredient,
      externalId: ingredient.id,
      id: uuidv4(),
    }

    const ingredients = [...food.ingredients, newIngredient]
    const nutrient = getNutrientInformation(ingredients)

    setFood({
      ...food,
      ...nutrient,
      ingredients,
    })
  }

  const onRemoveIngredient = (id: string) => {
    if (!food?.ingredients) return

    const ingredients = food.ingredients.filter(
      (ingredient) => ingredient.id !== id,
    )
    const nutrient = getNutrientInformation(ingredients)

    setFood({
      ...food,
      ...nutrient,
      ingredients,
    })
  }

  const onUpdateIngredient = (updatedIngredient: IIngredientFood) => {
    if (!food?.ingredients) return
    const ingredients = food.ingredients.map((ingredient) =>
      updatedIngredient.id === ingredient.id ? updatedIngredient : ingredient,
    )
    const nutrient = getNutrientInformation(ingredients)

    setFood({
      ...food,
      ...nutrient,
      ingredients,
    })
  }

  const onRemoveImage = () => {
    if (!food) return
    setFood({
      ...food,
      imageUrl: undefined,
      imageId: undefined,
      previewImageUrl: undefined,
    })
  }

  return {
    food,
    onNameChange,
    onPortionChange,
    onUnitChange,
    onAddIngredient,
    onRemoveIngredient,
    onUpdateIngredient,
    onRemoveImage
  }
}
