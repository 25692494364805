import { FlexBox } from "components/Common/FlexBox"
import styled from "styled-components"
import { IMealTypePercent, IMealTypePercentItem } from "../types"
import { getDisplayCalories } from "utils"

const IconBox = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  height: 22px;
`

const LegendLabel = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 160%;
  margin-left: 6px;
  margin-right: 8px;
`
const LegendPercent = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 160%;
  top: 1px;
  position: relative;
`

const MealTypePercentItem = ({
  mealType,
}: {
  mealType: IMealTypePercentItem
}) => {
  return (
    <FlexBox $alignItems="center" style={{ width: "max(40% , 170px)" }}>
      <IconBox>
        {" "}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="8"
          height="8"
          viewBox="0 0 8 8"
          fill="none"
        >
          <circle cx="4" cy="4" r="4" fill={mealType.color} />
        </svg>
      </IconBox>

      <LegendLabel>{mealType.name}</LegendLabel>
      <LegendPercent>{`${mealType.percent}% (${getDisplayCalories(
        mealType.value,
      )} cal)`}</LegendPercent>
    </FlexBox>
  )
}

const MealTypePercent = ({
  mealTypePercent,
}: {
  mealTypePercent: IMealTypePercent
}) => {
  return (
    <FlexBox
      $direction="column"
      $justify="center"
      $gap={4}
      style={{ padding: "16px 0px 12px 0px" }}
    >
      <FlexBox $justify="center" $gap={20}>
        {[mealTypePercent[0], mealTypePercent[1]].map((mealType) => {
          return <MealTypePercentItem key={mealType.name} mealType={mealType} />
        })}
      </FlexBox>

      <FlexBox $justify="center" $gap={20}>
        {[mealTypePercent[2], mealTypePercent[3]].map((mealType) => {
          return <MealTypePercentItem key={mealType.name} mealType={mealType} />
        })}
      </FlexBox>
    </FlexBox>
  )
}

export default MealTypePercent
