import styled from "styled-components"

const CtaBox = styled.div`
  display: flex;
  padding: var(--spacing_l, 16px) var(--spacing_l, 16px) var(--spacing_l, 16px)
    var(--spacing_l, 16px);
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  margin-top: auto;
`

export default CtaBox
