import styled from "styled-components"

export const StyledOverview = styled.div`
  height: calc(100% - var(--footer-height));
  background: var(--Schemes-Surface-Container-Low, #f5f7f7);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`

export const StyledDay = styled.div`
  font-size: 16px;
  font-weight: 500;
  line-height: 160%;
  line-height: 24px;
  cursor: pointer;
`

export const OverviewMain = styled.div`
  overflow-y: auto;
  height: calc(100% - var(--header-height));
  width: 100%;

  svg,
  g,
  path {
    &:focus,
    &:active {
      outline: none;
    }
  }

  .recharts-cartesian-axis-line {
    stroke: rgba(0, 38, 58, 0.14);
  }

  .recharts-surface {
    overflow: visible;
  }

  .recharts-yAxis .recharts-label {
    transform: translateX(0px);
    font-size: 10px;
    font-weight: 600;
    fill: #69757d;
    color: #69757d;
  }

  .recharts-cartesian-grid-horizontal {
    line {
      &:first-child {
        display: none;
      }
    }
  }
`

export const OverviewHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px 12px;
  width: 100%;
  background: #fff;
  border-bottom: 1px solid var(--Schemes-Outline-Variant, rgba(0, 38, 58, 0.14));
`

export const OverViewTypeSettings = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  overflow: hidden;
`

export const OverviewButton = styled.div<{ $isActive: boolean }>`
  padding: var(--spacing_xxs, 4px) var(--spacing_m, 12px);
  background: ${(props) =>
    props.$isActive
      ? "var(--Schemes-Surface-Container, #EBEDF0)"
      : "var(--Schemes-Surface-Container-Low, #f5f7f7)"};

  color: ${(props) =>
    props.$isActive
      ? "var(--Schemes-Primary, #0DA3BA)"
      : "var(--Schemes-On-Surface-Variant, #69757D)"};

  font-size: 14px;
  font-weight: 600;
  line-height: 160%;
  cursor: pointer;
`

export const FullLoading = styled.div`
  height: 100%;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
`
