import { createSlice } from "@reduxjs/toolkit"
import { createAppAsyncThunk } from "app/hooks"
import {
  ICreateFoodPayload,
  IFoodState,
  ISearchFoodOverlayDisplaySection,
  IUpdateFoodPayload,
} from "./foodTypes"
import { convertServerToClientFood } from "./foodConverter"
import { ISearchFoodsPayload } from "./foodTypes"
import axios from "axios"
import foodAPI from "./foodAPI"

export const deleteFood = createAppAsyncThunk(
  "food/deleteFood",
  async (id: string, { rejectWithValue }) => {
    try {
      await foodAPI.deleteFood(id)
      return id
    } catch (err: any) {
      return rejectWithValue(err)
    }
  },
)

export const updateFood = createAppAsyncThunk(
  "food/updateFood",
  async (payload: IUpdateFoodPayload, { rejectWithValue }) => {
    try {
      const food = await foodAPI.updateFood(payload)
      return food
    } catch (err: any) {
      return rejectWithValue(err)
    }
  },
)

export const createFood = createAppAsyncThunk(
  "food/createFood",
  async (payload: ICreateFoodPayload, { rejectWithValue }) => {
    try {
      const food = await foodAPI.createFood(payload)
      return food
    } catch (err: any) {
      return rejectWithValue(err)
    }
  },
)

let gettingSuggestedTextController: any = null

export const getSuggestedSearchTexts = createAppAsyncThunk(
  "food/getSuggestedSearchTexts",
  async (keyword: string, { rejectWithValue }) => {
    try {
      if (gettingSuggestedTextController) {
        gettingSuggestedTextController.abort()
      }
      if (!keyword) {
        return []
      }

      gettingSuggestedTextController = new AbortController()

      const results = await foodAPI.getSuggestedSearchTexts(
        keyword,
        gettingSuggestedTextController.signal,
      )
      return results
    } catch (err: any) {
      if (!axios.isCancel(err)) {
        return rejectWithValue(err)
      }
    } finally {
      gettingSuggestedTextController = null
    }
  },
)

let gettingExternalSuggestedTextController: any = null

export const getExternalSuggestedSearchTexts = createAppAsyncThunk(
  "food/getExternalSuggestedSearchTexts",
  async (keyword: string, { rejectWithValue }) => {
    try {
      if (gettingExternalSuggestedTextController) {
        gettingExternalSuggestedTextController.abort()
      }
      if (!keyword) {
        return []
      }

      gettingExternalSuggestedTextController = new AbortController()

      const results = await foodAPI.getSuggestedSearchTexts(
        keyword,
        gettingExternalSuggestedTextController.signal,
      )
      return results
    } catch (err: any) {
      if (!axios.isCancel(err)) {
        return rejectWithValue(err)
      }
    } finally {
      gettingExternalSuggestedTextController = null
    }
  },
)

export const searchFoods = createAppAsyncThunk(
  "food/searchFoods",
  async (payload: ISearchFoodsPayload, { rejectWithValue }) => {
    try {
      const { foods, next } = await foodAPI.searchFoods(payload)
      const clientFoods = foods.map((food) =>
        convertServerToClientFood(food, true),
      )

      return {
        foods: clientFoods,
        session: next,
      }
    } catch (err: any) {
      return rejectWithValue(err)
    }
  },
)

export const searchExternalFoods = createAppAsyncThunk(
  "food/searchExternalFoods",
  async (payload: ISearchFoodsPayload, { rejectWithValue }) => {
    try {
      const { foods, next } = await foodAPI.searchFoods({
        ...payload,
        externalOnly: true,
      })
      const clientFoods = foods.map((food) =>
        convertServerToClientFood(food, true),
      )

      return {
        foods: clientFoods,
        session: next,
      }
    } catch (err: any) {
      return rejectWithValue(err)
    }
  },
)

export const getMyFoods = createAppAsyncThunk(
  "meal/getMyFoods",
  async (_, { rejectWithValue }) => {
    try {
      const foods = await foodAPI.getMyFoods()
      return foods
    } catch (err: any) {
      console.error(err)
      return rejectWithValue(err)
    }
  },
)

export const getFoodDetails = createAppAsyncThunk(
  "food/getFoodDetails",
  async (foodId: string, { rejectWithValue }) => {
    try {
      const food = await foodAPI.getFood(foodId)
      return convertServerToClientFood(food)
    } catch (err: any) {
      return rejectWithValue(err)
    }
  },
)

const initialState: IFoodState = {
  isSearchInAddMealPageVisible: false,
  searchFoodOverlayDisplaySection:
    ISearchFoodOverlayDisplaySection.AUTOCOMPLETE,

  deleteFoodLoading: false,
  deleteFoodFailed: undefined,
  deleteFoodSuccess: undefined,

  updateFoodLoading: false,
  updateFoodSuccess: undefined,
  updateFoodFailed: undefined,

  foodDetails: undefined,
  getFoodDetailsLoading: false,
  getFoodDetailsFailed: undefined,
  detailsBackPath: undefined,

  createFoodLoading: false,
  createFoodFailed: undefined,
  createFoodSuccess: undefined,

  myFoods: [],
  getMyFoodsLoading: false,
  getMyFoodsFailed: undefined,

  keyword: "",
  getSuggestedSearchTextsLoading: false,
  getSuggestedSearchTextsFailed: undefined,
  suggestedSearchTexts: [],
  isNoSuggestedSearchTexts: false,

  searchSession: "",
  searchFoodsResults: [],
  searchFoodsLoading: false,
  searchFoodsFailed: undefined,
  noSearchedFoodFounded: false,

  externalKeyword: "",
  getExternalSuggestedSearchTextsLoading: false,
  getExternalSuggestedSearchTextsFailed: undefined,
  externalSuggestedSearchTexts: [],
  isNoExternalSuggestedSearchTexts: false,

  externalSearchSession: "",
  searchExternalFoodsResults: [],
  searchExternalFoodsLoading: false,
  searchExternalFoodsFailed: undefined,
  noSearchedExternalFoodFounded: false,
}

export const foodSlice = createSlice({
  name: "foodSlice",
  initialState,
  reducers: {
    setSearchFoodOverlayDisplaySection(state, { payload }) {
      state.searchFoodOverlayDisplaySection = payload
    },

    setSearchInAddMealPageVisible(state, { payload }) {
      state.isSearchInAddMealPageVisible = payload
    },

    resetExternalFoodsState(state) {
      state.searchExternalFoodsResults = []
      state.externalKeyword = ""
      state.externalSuggestedSearchTexts = []
    },

    resetSearchedFoodsState(state) {
      state.isSearchInAddMealPageVisible = false
      state.searchFoodsResults = []
      state.keyword = ""
      state.suggestedSearchTexts = []
    },

    setFoodDetails(state, { payload }) {
      state.foodDetails = payload
    },

    setDetailsBackPath(state, { payload }) {
      state.detailsBackPath = payload
    },

    resetSearchedFoods(state) {
      state.searchFoodsResults = []
    },

    resetSearchedExternalFoods(state) {
      state.searchExternalFoodsResults = []
    },

    onExternalKeywordChange(state, { payload }) {
      state.externalKeyword = payload
      state.searchExternalFoodsResults = []

      if (payload === "") {
        state.isNoExternalSuggestedSearchTexts = false
      }
    },

    onKeywordChange(state, { payload }) {
      state.keyword = payload
      state.searchFoodsResults = []

      if (payload === "") {
        state.isNoSuggestedSearchTexts = false
      }
    },

    resetFoodState() {
      return initialState
    },
  },
  extraReducers(builder) {
    builder
      .addCase(deleteFood.pending, (state) => {
        state.deleteFoodLoading = true
      })
      .addCase(deleteFood.fulfilled, (state, { payload }) => {
        state.deleteFoodLoading = false
        state.deleteFoodSuccess = payload
      })
      .addCase(deleteFood.rejected, (state, { payload }) => {
        state.deleteFoodLoading = false
        state.deleteFoodFailed = payload
      })

      .addCase(getFoodDetails.pending, (state) => {
        state.getFoodDetailsLoading = true
      })
      .addCase(getFoodDetails.fulfilled, (state, { payload }) => {
        state.getFoodDetailsLoading = false
        state.foodDetails = payload
      })
      .addCase(getFoodDetails.rejected, (state, { payload }) => {
        state.getFoodDetailsLoading = false
        state.getFoodDetailsFailed = payload
      })

      .addCase(createFood.pending, (state) => {
        state.createFoodLoading = true
      })
      .addCase(createFood.fulfilled, (state, { payload }) => {
        state.createFoodLoading = false
        state.createFoodSuccess = payload
      })
      .addCase(createFood.rejected, (state, { payload }) => {
        state.createFoodLoading = false
        state.createFoodFailed = payload
      })

      .addCase(updateFood.pending, (state) => {
        state.updateFoodLoading = true
      })
      .addCase(updateFood.fulfilled, (state, { payload }) => {
        state.updateFoodLoading = false
        state.updateFoodSuccess = payload
      })
      .addCase(updateFood.rejected, (state, { payload }) => {
        state.updateFoodLoading = false
        state.updateFoodFailed = payload
      })

      .addCase(getMyFoods.pending, (state) => {
        state.getMyFoodsLoading = true
      })

      .addCase(getMyFoods.fulfilled, (state, { payload }) => {
        state.getMyFoodsLoading = false
        state.myFoods = payload
      })

      .addCase(getMyFoods.rejected, (state, { payload }) => {
        state.getMyFoodsLoading = false
        state.getMyFoodsFailed = payload
        state.myFoods = []
      })

      .addCase(getSuggestedSearchTexts.pending, (state) => {
        state.getSuggestedSearchTextsLoading = !!state.keyword
      })
      .addCase(getSuggestedSearchTexts.fulfilled, (state, { payload }) => {
        state.getSuggestedSearchTextsLoading = false

        if (payload) {
          state.suggestedSearchTexts = payload
          state.isNoSuggestedSearchTexts =
            payload.length === 0 && !!state.keyword
        }
      })

      .addCase(getSuggestedSearchTexts.rejected, (state, { payload }) => {
        state.getSuggestedSearchTextsLoading = false
        state.getSuggestedSearchTextsFailed = payload
      })

      .addCase(getExternalSuggestedSearchTexts.pending, (state) => {
        state.getExternalSuggestedSearchTextsLoading = !!state.keyword
      })
      .addCase(
        getExternalSuggestedSearchTexts.fulfilled,
        (state, { payload }) => {
          state.getExternalSuggestedSearchTextsLoading = false

          if (payload) {
            state.externalSuggestedSearchTexts = payload
            state.isNoExternalSuggestedSearchTexts = payload.length === 0
          }
        },
      )

      .addCase(
        getExternalSuggestedSearchTexts.rejected,
        (state, { payload }) => {
          state.getExternalSuggestedSearchTextsLoading = false
          state.getExternalSuggestedSearchTextsFailed = payload
        },
      )

      .addCase(searchFoods.pending, (state, { meta }) => {
        state.searchFoodsLoading = true
        state.keyword = meta.arg.keyword
        state.searchSession = meta.arg.session ?? ""
      })
      .addCase(searchFoods.fulfilled, (state, { payload }) => {
        state.searchFoodsLoading = false
        state.searchFoodsResults = [
          ...state.searchFoodsResults,
          ...payload.foods,
        ]
        state.searchSession = payload.session
        state.noSearchedFoodFounded = state.searchFoodsResults.length === 0
      })

      .addCase(searchFoods.rejected, (state, { payload }) => {
        state.searchFoodsLoading = false
        state.searchFoodsFailed = payload
        state.searchFoodsResults = []
      })

      .addCase(searchExternalFoods.pending, (state, { meta }) => {
        state.searchExternalFoodsLoading = true
        state.externalKeyword = meta.arg.keyword
        state.externalSearchSession = meta.arg.session ?? ""
      })
      .addCase(searchExternalFoods.fulfilled, (state, { payload }) => {
        state.searchExternalFoodsLoading = false
        state.searchExternalFoodsResults = [
          ...state.searchExternalFoodsResults,
          ...payload.foods,
        ]

        state.externalSearchSession = payload.session
        state.noSearchedExternalFoodFounded =
          state.searchExternalFoodsResults.length === 0
      })

      .addCase(searchExternalFoods.rejected, (state, { payload }) => {
        state.searchExternalFoodsLoading = false
        state.searchExternalFoodsFailed = payload
        state.searchExternalFoodsResults = []
      })
  },
})

export const {
  onKeywordChange,
  resetSearchedFoods,
  setFoodDetails,
  setDetailsBackPath,
  resetSearchedFoodsState,
  onExternalKeywordChange,
  resetSearchedExternalFoods,
  setSearchInAddMealPageVisible,
  setSearchFoodOverlayDisplaySection,
} = foodSlice.actions

export default foodSlice.reducer
