import React from "react"

const DairyIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="29"
      height="28"
      viewBox="0 0 29 28"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.5 6C22.5 5.45 22.3042 4.97917 21.9125 4.5875C21.5208 4.19583 21.05 4 20.5 4H10C9.03333 4 8.20833 4.34167 7.525 5.025C6.84167 5.70833 6.5 6.53333 6.5 7.5V20.5C6.5 20.8081 6.53471 21.1018 6.60413 21.3812C6.75251 21.9782 7.05947 22.5095 7.525 22.975C7.99056 23.4406 8.52187 23.7475 9.11895 23.8959C9.39824 23.9653 9.69193 24 10 24H21.5C21.7833 24 22.0208 23.9 22.2125 23.7C22.2164 23.6959 22.2203 23.6918 22.2242 23.6876C22.4081 23.49 22.5 23.2525 22.5 22.975V22.725C22.5 22.525 22.4333 22.3375 22.3 22.1625C22.1667 21.9875 21.9833 21.85 21.75 21.75C21.5167 21.65 21.3333 21.4875 21.2 21.2625C21.1715 21.2144 21.1461 21.165 21.1237 21.1143C21.0822 21.0203 21.0511 20.9217 21.0305 20.8185C21.0277 20.8047 21.0252 20.7909 21.0228 20.777C21.0076 20.688 21 20.5957 21 20.5C21 20.2167 21.0667 19.9667 21.2 19.75C21.3333 19.5333 21.5167 19.3667 21.75 19.25C22.0333 19.1167 22.2292 18.9958 22.3375 18.8875C22.4458 18.7792 22.5 18.6583 22.5 18.525V6ZM8.5 17.3233V7.5C8.5 7.06359 8.63108 6.74735 8.93921 6.43921C9.24735 6.13108 9.56359 6 10 6H20.4941C20.4957 6 20.4972 6.00062 20.4983 6.00171C20.4994 6.00282 20.5 6.00431 20.5 6.00587V17H10C9.4765 17 8.96733 17.1058 8.5 17.3233ZM19.325 19C19.2083 19.2333 19.125 19.475 19.075 19.725C19.0569 19.8156 19.0421 19.9072 19.0305 20C19.0102 20.1633 19 20.3299 19 20.5C19 20.6746 19.0111 20.8412 19.0332 21C19.0397 21.0463 19.0471 21.0919 19.0554 21.1369C19.0649 21.188 19.0756 21.2382 19.0875 21.2875C19.1353 21.4853 19.197 21.6803 19.2726 21.8726C19.2894 21.9152 19.3069 21.9577 19.325 22H10C9.86159 22 9.73084 21.9855 9.60773 21.9566C9.34542 21.8951 9.11784 21.7678 8.925 21.575C8.73198 21.382 8.6047 21.1541 8.54317 20.8915C8.51439 20.7686 8.5 20.6381 8.5 20.5C8.5 20.0833 8.64167 19.7292 8.925 19.4375C9.20833 19.1458 9.56667 19 10 19H19.325Z"
        fill="#69757D"
      />
    </svg>
  )
}

export default DairyIcon
