import styled from "styled-components"

export const Wrapper = styled.div<{ $disabled?: boolean }>`
  width: 100%;
  padding: 12px 0px;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 4px;

  border-radius: var(--spacing_s, 8px);
  border: 1.313px solid var(--Schemes-Outline-Variant, rgba(0, 38, 58, 0.14));
  background: url(<path-to-image>) lightgray 50% / cover no-repeat,
    var(--White, #fff);

  cursor: ${({ $disabled }) => ($disabled ? "not-allowed" : "pointer")};
  height: 106px;
  position: relative;
  overflow: hidden;
`

export const UploadTitle = styled.div`
  color: var(--Schemes-Primary, #0da3ba);
  text-align: center;
  font-size: 14px;
  font-weight: 600;
`

export const PreviewImage = styled.img`
  object-fit: cover;
  position: absolute;
  width: 100%;
  height: 100%;
`

export const RemoveImageWrapper = styled.div`
  position: absolute;
  right: -1px;
  top: -1px;
  cursor: pointer;
`

export const LoadingContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;

  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.55);
`
