import styled from "styled-components"

export const StyledSwitch = styled.div`
  display: flex;
`

export const FlexInner = styled.div`
  display: flex;
  flex-grow: 0;
  background: #ebedf0;
  position: relative;
  border-radius: 100px;
`

export const SwitchOption = styled.div<{
  $isActive: boolean
}>`
  display: flex;
  align-items: center;
  height: 40px;
  font-size: 12px;
  font-weight: 700;
  line-height: 12px; /* 100% */
  letter-spacing: 0.12px;
  z-index: 1;
  min-width: 77px;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  cursor: pointer;
  color: ${({ $isActive }) =>
    $isActive
      ? " var(--Schemes-Primary, #0DA3BA)"
      : "var(--Schemes-On-Surface, #1C1F21)"};
`

export const ActiveMask = styled.div<{
  $activeIndex: number
  $width: number
}>`
  background: var(--Schemes-Surface-Container-Lowest, #fff);
  border-radius: 100px;
  height: calc(100% - 8px);
  top: 4px;
  position: absolute;
  transition: all 0.2s;
  width: ${({ $width }) => 68}px;
  left: ${({ $activeIndex }) => ($activeIndex === 0 ? "4px" : "80px")};
  z-index: 0;
  border: 0.75px solid var(--Schemes-Outline, rgba(0, 38, 58, 0.14));
`
