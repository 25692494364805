import { Fragment, useEffect, useState } from "react"
import { CreateFoodCtaBox, CreateFoodWrapper } from "./styled"
import Header from "components/Header"
import CreateFoodForm from "./CreateFoodForm"
import SearchIngredients from "./SearchIngredients"
import Button from "components/Buttons/Button"
import { ICreateFoodPayload, IIngredientFood } from "../foodTypes"
import { useCreateLocalFood } from "../hooks/useCreateLocalFood"
import IngredientFoodDetails from "components/APIFoodDetails"
import { useCreateFood } from "../hooks"
import {
  useResetFoodImage,
  useUploadedImagePayload,
} from "features/image/hooks"
import { useNavigate, useSearchParams } from "react-router-dom"
import PATHS from "router/paths"
import { useSetMealTypeParams } from "features/meal/hooks/hooks"
import PopConfirm from "components/PopConfirm"
import { useAppSelector } from "app/hooks"

enum CreateFoodSection {
  CreateFoodForm = "CreateFoodForm",
  SearchIngredients = "SearchIngredients",
  FoodIngredientDetails = "FoodIngredientDetails",
}

const initFood: ICreateFoodPayload = {
  name: "",
  portion: 1,
  ingredients: [],
  unit: "unit(s)",
  calorie: 0,
  fat: 0,
  protein: 0,
  carbohydrate: 0,
}

const CreateFood = () => {
  const [isPopConfirmDiscardOpen, setIsPopConfirmDiscardOpen] = useState(false)
  const navigate = useNavigate()
  const { mealType } = useSetMealTypeParams()
  const createLocalFoodProps = useCreateLocalFood(initFood)
  const { onAddIngredient, food, onRemoveIngredient, onUpdateIngredient,onPortionChange } =
    createLocalFoodProps

  const { handleCreateFood, createFoodLoading } = useCreateFood()

  const imagePayload = useUploadedImagePayload()
  useResetFoodImage()

  const processImageLoading = useAppSelector(
    (state) => state.image.processImageLoading,
  )

  const [searchParams] = useSearchParams()

  useEffect(() => {
    const initMealName = searchParams.get("name")
    if (initMealName) {
      createLocalFoodProps.onNameChange(initMealName as string)
    }
  }, [])

  const onSubmitCreateFood = () => {
    let payload: ICreateFoodPayload = food

    if (imagePayload) {
      payload = { ...payload, ...imagePayload }
    }

    handleCreateFood(payload)
  }

  const [isHideHeader, setIsHideHeader] = useState(false)
  const [section, setSection] = useState<CreateFoodSection>(
    CreateFoodSection.CreateFoodForm,
  )

  const [ingredientFoodDetails, setIngredientFoodDetails] =
    useState<null | IIngredientFood>(null)

  const showSearchIngredients = () => {
    setSection(CreateFoodSection.SearchIngredients)
  }

  const isFormEmpty = food.ingredients?.length === 0 && !food.name
  const backToRecent = () => {
    navigate(`${PATHS.app.meal.add.root}?mealType=${mealType}`)
  }

  const onBack = () => {
    if (section !== CreateFoodSection.CreateFoodForm) {
      setSection(CreateFoodSection.CreateFoodForm)
    } else {
      if (isFormEmpty) {
        backToRecent()
      } else {
        setIsPopConfirmDiscardOpen(true)
      }
    }
  }

  const onViewIngredientFoodDetails = (food: IIngredientFood) => {
    setSection(CreateFoodSection.FoodIngredientDetails)
    setIngredientFoodDetails(food)
  }


  const [isPortionValid, setIsPortionValid] = useState(true)

  const handlePortionChange = (value: number) => {
    onPortionChange(value)
    setIsPortionValid(true)
  }


  const shouldShowHeader =
    !isHideHeader && section !== CreateFoodSection.FoodIngredientDetails

  return (
    <CreateFoodWrapper
      $background={
        section === CreateFoodSection.CreateFoodForm ? "#f5f7f7" : "#fff"
      }
    >
      {shouldShowHeader && (
        <Header
          title="Create New Meal"
          onBack={onBack}
          disabled={createFoodLoading}
        />
      )}

      {section === CreateFoodSection.CreateFoodForm && (
        <Fragment>
          <CreateFoodForm
            showSearchIngredients={showSearchIngredients}
            onViewIngredientFoodDetails={onViewIngredientFoodDetails}
            {...createLocalFoodProps}
            onPortionChange={handlePortionChange}
            setIsPortionValid={setIsPortionValid}
          />
          <CreateFoodCtaBox>
            <Button
              disabled={
                createFoodLoading ||
                food.ingredients?.length === 0 ||
                !food.name ||
                processImageLoading || !isPortionValid
              }
              loading={createFoodLoading}
              onClick={onSubmitCreateFood}
              style={{ width: "100%" }}
              $type="primary"
            >
              {" "}
              Save 
            </Button>
          </CreateFoodCtaBox>
        </Fragment>
      )}

      {section === CreateFoodSection.FoodIngredientDetails &&
        ingredientFoodDetails && (
          <IngredientFoodDetails
            food={ingredientFoodDetails}
            onBack={() => {
              setSection(CreateFoodSection.CreateFoodForm)
            }}
            onEdit={onUpdateIngredient}
            onDelete={() => {
              onRemoveIngredient(ingredientFoodDetails.id as string)
              setSection(CreateFoodSection.CreateFoodForm)
            }}
            mode="edit"
          />
        )}

      {section === CreateFoodSection.SearchIngredients && (
        <SearchIngredients
          setIsHideHeader={setIsHideHeader}
          onAddIngredient={onAddIngredient}
        />
      )}

      <PopConfirm
        isOpen={isPopConfirmDiscardOpen}
        title="Unsaved changes?"
        cancelText="Discard"
        confirmText="Stay"
        onCancel={backToRecent}
        onConfirm={() => {
          setIsPopConfirmDiscardOpen(false)
        }}
        loading={false}
      >
        <div style={{ textAlign: "center" }}>
          You haven't saved your meal yet. Are you sure you want to leave?
        </div>
      </PopConfirm>
    </CreateFoodWrapper>
  )
}

export default CreateFood
