import { Popup } from "antd-mobile"
import styled from "styled-components"

export const StyledHome = styled.div`
  height: calc(100% - var(--footer-height));
  background: var(--Schemes-Surface-Container-Low, #f5f7f7);
`

export const StyledDay = styled.div`
  font-size: 16px;
  font-weight: 500;
  line-height: 160%;
  line-height: 24px;
  cursor: pointer;
`

export const HomeMain = styled.div`
  padding: 16px 8px;
  overflow-y: auto;
  height: calc(100% - var(--header-height));
`

export const NoMealsBox = styled.div`
  border-radius: var(--spacing_s, 8px);
  background: var(--Schemes-Secondary-Container, #fce89d);
  padding: var(--spacing_m, 12px) var(--spacing_l, 16px);

  font-size: 14px;
  color: var(--Schemes-On-Secondary-Container, #473329);
`
