import { useAppDispatch, useAppSelector } from "app/hooks"
import {
  initFoodImage,
  processImage,
  removeUploadedImage,
  resetImageState,
  uploadImage,
} from "./imageSlice"
import { useEffect } from "react"
import { IMyFood } from "features/food/foodTypes"

export const useProcessImage = () => {
  const dispatch = useAppDispatch()

  const imageUrl = useAppSelector((state) => state.image.imageUrl)
  const processImageLoading = useAppSelector(
    (state) => state.image.processImageLoading,
  )

  const displayImageType = useAppSelector(
    (state) => state.image.displayImageType,
  )

  const handleProcessImage = (file: File) => {
    dispatch(processImage(file))
  }

  return {
    imageUrl,
    processImageLoading,
    handleProcessImage,
    displayImageType,
  }
}

export const useUploadImage = () => {
  const imageBase64Encoded = useAppSelector(
    (state) => state.image.imageBase64Encoded,
  )

  const dispatch = useAppDispatch()

  const onUploadImage = (base64: string, file: File) => {
    dispatch(uploadImage({ base64, file }))
  }

  const onRemoveUploadedImage = () => {
    dispatch(removeUploadedImage())
  }

  return {
    imageBase64Encoded,
    onUploadImage,
    onRemoveUploadedImage,
  }
}

const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
const safariVersion = navigator.userAgent.match(/Version\/(\d+)/)
let isSafari17 = isSafari && safariVersion && parseInt(safariVersion[1]) >= 17

const getBase64 = (file: File | Blob, callback: (url: string) => void) => {
  const reader = new FileReader()
  reader.addEventListener("load", () => callback(reader.result as string))
  reader.readAsDataURL(file)
}

export const useImageUploader = () => {
  const { onUploadImage, imageBase64Encoded, onRemoveUploadedImage } =
    useUploadImage()
  const {
    processImageLoading,
    handleProcessImage,
    imageUrl,
    displayImageType,
  } = useProcessImage()

  const onImageChange = (file?: File) => {
    if (file) {
      const isHeicFile = file.type === "image/heic"
      const isHeifFile = file.type === "image/heif"
      const shouldConvert = isHeicFile || isHeifFile

      if (shouldConvert && !isSafari17) {
        handleProcessImage(file)
      } else {
        getBase64(file, (base64) => {
          onUploadImage(base64, file)
        })
      }
    }
  }

  const displayImageUrl =
    displayImageType === "base64" ? imageBase64Encoded : imageUrl

  return {
    onImageChange,
    onRemoveUploadedImage,
    processImageLoading,
    displayImageUrl,
  }
}

export const useUploadedImagePayload = () => {
  const file = useAppSelector((state) => state.image.file)
  const displayImageType = useAppSelector(
    (state) => state.image.displayImageType,
  )
  const imageId = useAppSelector((state) => state.image.imageId)
  const imageUrl = useAppSelector((state) => state.image.imageUrl)

  if (!file && !imageUrl) {
    return false
  }

  if (displayImageType === "base64") {
    return {
      image: file,
    }
  }

  return {
    imageId,
    imageUrl,
  }
}

export const useInitFoodImage = (food: IMyFood) => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (food) {
      dispatch(
        initFoodImage({
          url: food.imageUrl,
          id: food.imageId,
        }),
      )
    }
  }, [food])
}

export const useResetFoodImage = () => {
  const dispatch = useAppDispatch()
  useEffect(() => {
    return () => {
      dispatch(resetImageState())
    }
  }, [])
}
