import styled from "styled-components"

export const StyledFormItem = styled.div`
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  gap: 12px;
`

export const FormItemLabel = styled.label`
  font-size: 16px;
  font-weight: 800;
  line-height: 140%;
`
