import { ReactNode } from "react"
import { FormItemLabel, StyledFormItem } from "./styled"

const FormItem = ({
  label,
  children,
  labelStyle = {},
}: {
  label: string
  children: ReactNode
  labelStyle?: React.CSSProperties
}) => {
  return (
    <StyledFormItem>
      <FormItemLabel style={labelStyle}>{label}</FormItemLabel>
      <div>{children}</div>
    </StyledFormItem>
  )
}

export default FormItem
