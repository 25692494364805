import { Navigate } from "react-router-dom"
import PATHS from "./paths"
import type { RouteObject } from "react-router-dom"

import AuthLayout from "components/Layouts/AuthLayout"
import AppLayout from "components/Layouts/AppLayout"

import SetGoal from "features/onboarding/SetGoal"
import Home from "features/home"
import Auth from "features/auth/Components/Auth"
import LogInWithEmail from "features/auth/Components/LogInWithEmail"
import PersonalInfo from "features/onboarding/PersonalInfo"
import ScanMeal from "features/meal/ScanMeal/index"
import Profile from "features/profile/Profile"
import BodyMetric from "features/profile/BodyMetric"
import Overview from "features/overview/Overview"
import TermOfUse from "features/auth/Components/TermOfUse"
import MealDetails from "features/meal/MealDetails"
import AddMeal from "features/meal/Add"
import Tutorial from "features/meal/Tutorial"
import YourGoal from "features/profile/YourGoal"
import CreateFood from "features/food/CreateFood"
import FoodDetails from "features/food/FoodDetails"
import UpdateFood from "features/food/UpdateFood"
import UpdateMeals from "features/meal/UpdateMeal"
import LoginInIOSApp from "features/auth/Components/LoginInNativeApp"
import LoginInNativeApp from "features/auth/Components/LoginInNativeApp"
import SuggestedMeals from "features/meal/Add/SuggestedMeals"
import MyFoods from "features/meal/Add/MyFoods"

export const routes: RouteObject[] = [
  {
    path: PATHS.app.root,
    element: <AppLayout />,
    children: [
      {
        index: true,
        element: <Home />,
      },

      {
        path: PATHS.app.meal.add.root,
        element: <AddMeal />,
        children: [
          {
            index: true,
            element: <SuggestedMeals />,
          },
          {
            path: PATHS.app.meal.add.myFoods,
            element: <MyFoods  />,
          },
        ],
      },

      {
        path: PATHS.app.meal.tutorial,
        element: <Tutorial />,
      },

      {
        path: PATHS.app.meal.scan,
        element: <ScanMeal />,
      },
      {
        path: PATHS.app.meal.details,
        element: <MealDetails />,
      },
      {
        path: PATHS.app.meal.edit,
        element: <UpdateMeals />,
      },
      {
        path: PATHS.app.food.create,
        element: <CreateFood />,
      },

      {
        path: PATHS.app.food.edit,
        element: <UpdateFood />,
      },
      {
        path: PATHS.app.food.details,
        element: <FoodDetails />,
      },

      { path: PATHS.app.yourGoal, element: <YourGoal /> },
      { path: PATHS.app.metric, element: <BodyMetric /> },
      { path: PATHS.app.profile, element: <Profile /> },
      {
        path: PATHS.app.personalInfo,
        element: <PersonalInfo />,
      },
      {
        path: PATHS.app.setGoal,
        element: <SetGoal />,
      },
      {
        path: PATHS.app.updateGoal,
        element: <SetGoal />,
      },

      { path: PATHS.app.overview, element: <Overview /> },
    ],
  },

  {
    path: PATHS.auth.root,
    element: <AuthLayout />,
    children: [
      {
        index: true,
        element: <Auth />,
      },
      { path: PATHS.auth.loginWithEmail, element: <LogInWithEmail /> },
    ],
  },

  {
    path: PATHS.auth.loginInNativeApp,
    element: <LoginInNativeApp />,
  },

  { path: PATHS.auth.termOfUse, element: <TermOfUse /> },

  {
    path: "*",
    element: <Navigate to={PATHS.app.root} />,
  },
]

export default routes
