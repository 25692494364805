import React from "react"

const SmallSearchIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.61179 3.01577C5.56485 2.37896 6.68533 2.03906 7.83156 2.03906H7.83159C9.3686 2.03916 10.8426 2.64978 11.9294 3.7366C13.0163 4.82343 13.6269 6.29745 13.627 7.83445V7.83449C13.627 8.98071 13.2871 10.1012 12.6503 11.0543C12.5385 11.2215 12.4185 11.3823 12.2908 11.5361L15.8078 15.0531C16.017 15.2623 16.017 15.6015 15.8078 15.8107C15.5986 16.0199 15.2594 16.0199 15.0502 15.8107L11.5332 12.2937C11.0886 12.6628 10.589 12.9653 10.0494 13.1888C8.9904 13.6274 7.82513 13.7422 6.70093 13.5186C5.57673 13.2949 4.54408 12.743 3.73358 11.9325C2.92307 11.122 2.37111 10.0893 2.14749 8.96512C1.92388 7.84092 2.03864 6.67565 2.47729 5.61667C2.91593 4.5577 3.65874 3.65258 4.61179 3.01577ZM7.83153 3.11049C6.89722 3.1105 5.9839 3.38755 5.20705 3.90663C4.43019 4.42571 3.8247 5.16349 3.46716 6.02669C3.10961 6.88989 3.01606 7.83973 3.19833 8.75609C3.38061 9.67246 3.83053 10.5142 4.49119 11.1749C5.15185 11.8355 5.99359 12.2854 6.90995 12.4677C7.82632 12.65 8.77616 12.5564 9.63936 12.1989C10.5026 11.8413 11.2403 11.2359 11.7594 10.459C12.2785 9.68215 12.5555 8.76883 12.5556 7.83452M7.83156 3.11049C9.08441 3.11058 10.2859 3.60831 11.1718 4.49422C12.0577 5.38012 12.5555 6.58163 12.5556 7.83449"
        fill="#00263A"
        fillOpacity="0.32"
      />
    </svg>
  )
}

export default SmallSearchIcon
