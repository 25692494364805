import { createSlice } from "@reduxjs/toolkit"
import overviewAPI from "./overviewAPI"
import { createAppAsyncThunk } from "app/hooks"
import { IOverviewState, IWeeklyOverviewPayload } from "./types"
import { resetStoreAction } from "config"
import { prepareDailyOverviewData, prepareWeeklyOverviewData } from "./helper"

let getDailyOverviewAbortController: any = undefined

export const getDailyOverview = createAppAsyncThunk(
  "overview/getDailyOverview",
  async (date: string, { rejectWithValue }) => {
    try {
      if (getDailyOverviewAbortController) {
        getDailyOverviewAbortController.abort()
      }

      getDailyOverviewAbortController = new AbortController()

      const response = await overviewAPI.getDailyOverview(
        date,
        getDailyOverviewAbortController.signal,
      )

      return response
    } catch (err: any) {
      return rejectWithValue(err)
    } finally {
      getDailyOverviewAbortController = undefined
    }
  },
)

let getWeeklyOverviewAbortController: any = undefined

export const getWeeklyOverview = createAppAsyncThunk(
  "overview/getWeeklyOverview",
  async (payload: IWeeklyOverviewPayload, { rejectWithValue }) => {
    try {
      if (getWeeklyOverviewAbortController) {
        getWeeklyOverviewAbortController.abort()
      }

      getWeeklyOverviewAbortController = new AbortController()

      const response = await overviewAPI.getWeeklyOverview(
        payload,
        getWeeklyOverviewAbortController.signal,
      )

      return response
    } catch (err: any) {
      return rejectWithValue(err)
    } finally {
      getWeeklyOverviewAbortController = undefined
    }
  },
)

const initialState: IOverviewState = {
  getDailyOverviewLoading: false,
  dailyOverview: undefined,
  getDailyOverviewFailed: null,

  getWeeklyOverviewLoading: false,
  weeklyOverview: undefined,
  getWeeklyOverviewFailed: null,
}

export const overviewSlice = createSlice({
  name: "overview",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getDailyOverview.pending, (state) => {
      state.getDailyOverviewLoading = true
      state.getDailyOverviewFailed = null
    })

    builder.addCase(getDailyOverview.fulfilled, (state, { payload }) => {
      state.getDailyOverviewLoading = false
      state.dailyOverview = prepareDailyOverviewData(payload)
    })

    builder.addCase(getDailyOverview.rejected, (state, action) => {
      state.getDailyOverviewLoading = false
      state.getDailyOverviewFailed = action.payload
    })

    builder.addCase(getWeeklyOverview.pending, (state) => {
      state.getWeeklyOverviewLoading = true
      state.getWeeklyOverviewFailed = null
    }),
      builder.addCase(getWeeklyOverview.fulfilled, (state, { payload }) => {
        state.getWeeklyOverviewLoading = false
        state.weeklyOverview = prepareWeeklyOverviewData(payload)
      }),
      builder.addCase(getWeeklyOverview.rejected, (state, action) => {
        state.getWeeklyOverviewLoading = false
        state.getDailyOverviewFailed = action.payload
      })

    builder.addCase(resetStoreAction, () => {
      return initialState
    })
  },
})

export default overviewSlice.reducer
