import { truncateByDecimalPlace } from "utils"
import {
  IDailyOverview,
  IDailyOverviewResponse,
  IMealTypePercent,
  IWeeklyOverview,
  IWeeklyOverviewResponse,
} from "./types"

const getPercent = (
  mealTypeBalance: number,
  gainedCalories: number,
  decimalPlaces?: number,
) => {
  return truncateByDecimalPlace(
    (mealTypeBalance / gainedCalories) * 100,
    decimalPlaces ?? 0,
  )
}

export const prepareDailyOverviewData = (
  overview: IDailyOverviewResponse,
): IDailyOverview => {
  console.log(overview)
  const {
    mealTypeBalance: { breakfast, lunch, dinner, snack },
    caloriesPerDay,
    mostCalories,
    gainedCalories,
    mostProtein,
    mostCarbs,
    mostFat,
  } = overview

  let breakfastPercent = getPercent(breakfast, gainedCalories)
  let lunchPercent = getPercent(lunch, gainedCalories)
  let dinnerPercent = getPercent(dinner, gainedCalories)
  let snackPercent = getPercent(snack, gainedCalories)

  const mealTypePercent: IMealTypePercent = [
    {
      name: "Breakfast",
      value: breakfast,
      percent: breakfastPercent,
      color: "#722ED1",
    },
    { name: "Lunch", value: lunch, percent: lunchPercent, color: "#165DFF" },
    { name: "Dinner", value: dinner, percent: dinnerPercent, color: "#14C9C9" },
    { name: "Snacks", value: snack, percent: snackPercent, color: "#F7BA1E" },
  ]

  return {
    mealTypePercent,
    mostMeals: [
      {
        title: "Most Calories",
        meals: mostCalories,
        nutritionType: "calories",
      },
      {
        title: "Most Protein",
        meals: mostProtein,
        nutritionType: "protein",
      },
      {
        title: "Most Carbs",
        meals: mostCarbs,
        nutritionType: "carbs",
      },
      {
        title: "Most Fat",
        meals: mostFat,
        nutritionType: "fat",
      },
    ],
    caloriesPerDay,
    gainedCalories,
    breakfast: {
      percent: breakfastPercent,
      calories: breakfast,
    },
    lunch: {
      percent: lunchPercent,
      calories: lunch,
    },
    dinner: {
      percent: dinnerPercent,
      calories: dinner,
    },
    snack: {
      percent: snackPercent,
      calories: snack,
    },
  }
}

export const prepareWeeklyOverviewData = (
  weeklyOverview: IWeeklyOverviewResponse,
): IWeeklyOverview => {
  const { weeklyChartData, mostCalories, mostCarbs, mostProtein, mostFat } =
    weeklyOverview

  const breakfast = weeklyChartData.reduce((acc, x) => acc + x.breakfast, 0)
  const lunch = weeklyChartData.reduce((acc, x) => acc + x.lunch, 0)
  const dinner = weeklyChartData.reduce((acc, x) => acc + x.dinner, 0)
  const snack = weeklyChartData.reduce((acc, x) => acc + x.snack, 0)

  const gainedCalories = breakfast + lunch + dinner + snack

  const breakfastPercent = getPercent(breakfast, gainedCalories)
  const lunchPercent = getPercent(lunch, gainedCalories)
  const dinnerPercent = getPercent(dinner, gainedCalories)
  const snackPercent = getPercent(snack, gainedCalories)

  const mealTypePercent: IMealTypePercent = [
    {
      name: "Breakfast",
      value: breakfast,
      percent: breakfastPercent,
      color: "#722ED1",
    },
    { name: "Lunch", value: lunch, percent: lunchPercent, color: "#165DFF" },
    { name: "Dinner", value: dinner, percent: dinnerPercent, color: "#14C9C9" },
    { name: "Snacks", value: snack, percent: snackPercent, color: "#F7BA1E" },
  ]

  return {
    mealTypePercent,
    gainedCalories,
    mostMeals: [
      {
        title: "Most Calories",
        meals: mostCalories,
        nutritionType: "calories",
      },
      {
        title: "Most Protein",
        meals: mostProtein,
        nutritionType: "protein",
      },
      {
        title: "Most Carbs",
        meals: mostCarbs,
        nutritionType: "carbs",
      },
      {
        title: "Most Fat",
        meals: mostFat,
        nutritionType: "fat",
      },
    ],
    weeklyChartData,
  }
}
