import { useAppDispatch } from "app/hooks"
import { hideSnackbar, showSnackbar, removeSnackbar } from "./notificationSlice"
import { v4 as uuidv4 } from "uuid"
import { useEffect, useRef } from "react"
import { getErrorMessage } from "utils"

export type ISnackbarType = "success" | "error" | "info"

export interface ISnackbarPayload {
  type: ISnackbarType
  title: string
  description?: string
}

export interface ISnackbarInfo extends ISnackbarPayload {
  id: string
  status: "active" | "inactive"
}

export interface INotificationState {
  snackbarInfos: ISnackbarInfo[]
}

const SNACKBAR_DURATION = 5000
const UN_ACTIVE_DURATION = 2000

export const useShowSnackbar = () => {
  const dispatch = useAppDispatch()

  const onHideSnackbar = (id: string) => {
    dispatch(hideSnackbar(id))
  }

  const onRemoveSnackbar = (id: string) => {
    dispatch(removeSnackbar(id))
  }

  const onShowSnackbar = (payload: ISnackbarPayload) => {
    const info: ISnackbarInfo = {
      ...payload,
      id: uuidv4(),
      status: "active",
    }

    dispatch(showSnackbar(info))

    setTimeout(() => {
      onHideSnackbar(info.id)

      setTimeout(() => {
        onRemoveSnackbar(info.id)
      }, UN_ACTIVE_DURATION)
    }, SNACKBAR_DURATION)
  }

  return { onShowSnackbar, onRemoveSnackbar }
}

type ReactToResult = (
  result: any,
  customMess?: { message: string; description?: string },
  callback?: (success?: any) => any,
) => any

let hideSnackbarTimeout: any = undefined

export const useFailed: ReactToResult = (error, customMess, callback) => {
  const isMounted = useRef<any>(null)
  const { onShowSnackbar } = useShowSnackbar()

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true
    } else {
      if (error) {
        if (hideSnackbarTimeout) {
          clearTimeout(hideSnackbarTimeout)
        }

        onShowSnackbar({
          title: customMess?.message ?? getErrorMessage(error),
          description: customMess?.description ?? "",
          type: "error",
        })

        callback?.(error)
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])
}

export const useSuccess: ReactToResult = (success, customMess, callback) => {
  const { onShowSnackbar } = useShowSnackbar()
  const isMounted = useRef<any>(null)

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true
    } else {
      if (success) {
        if (hideSnackbarTimeout) {
          clearTimeout(hideSnackbarTimeout)
        }

        if (customMess?.message) {
          onShowSnackbar({
            title: customMess?.message,
            description: customMess?.description ?? "",
            type: "success",
          })
        }

        callback?.(success)
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success])
}
