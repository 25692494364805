import React from "react"

const LeftIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.2896 4.66004C10.5523 4.88866 10.5716 5.27786 10.3328 5.52934L7.51165 8.5L10.3328 11.4707C10.5716 11.7221 10.5523 12.1113 10.2896 12.34C10.0269 12.5686 9.62029 12.55 9.38146 12.2986L6.16718 8.91395C5.94427 8.67923 5.94427 8.32077 6.16718 8.08605L9.38146 4.70144C9.62029 4.44995 10.0269 4.43142 10.2896 4.66004Z"
        fill="#69757D"
      />
    </svg>
  )
}

export default LeftIcon
