import styled from "styled-components"

export const OnboardingHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: var(--header-height);
  padding: 0px 8px;
`

export const HeaderBackBox = styled.div`
  display: flex;
  position: absolute;
  left: 0;
`

export const HeaderBackButton = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 32px;
  height: 24px;
  flex-shrink: 0;
  justify-content: center;
`
