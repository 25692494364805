import { Button as AntButton } from "antd-mobile"
import styled from "styled-components"

export type IButtonType =
  | "primary"
  | "secondary"
  | "default"
  | "danger"
  | "cancel"
  | "info"

export type IButtonSize = "small" | "large" | "middle"

const getButtonBg = (type: IButtonType) => {
  if (type === "primary") return "var(--color-primary)"

  if (type === "secondary") return "var(--color-secondary)"

  if (type === "danger") return "var(--Schemes-On-Error-Container, #FF4921)"

  if (type === "cancel") return "var(--Schemes-Surface-Container, #EBEDF0)"

  if (type === "info") return "var(--Schemes-Primary, #0DA3BA)"

  return "var(--color-white)"
}

const getButtonColor = (type: IButtonType) => {
  if (type === "danger") return "#fff"

  if (type === "info") return "#fff"

  return "var(--color-navy)"
}

const Button = styled(AntButton)<{
  $type: IButtonType
  $isOption?: boolean
  $size?: IButtonSize
  $isDeleteBtn?: boolean
}>`
  height: ${({ $isOption, $size }) =>
    $isOption ? "56px" : $size === "small" ? "36px" : "48px"};
  padding: ${({ $isOption, $size }) =>
    $isOption ? "15px 24px" : $size === "small" ? "6px 10px" : "12px 10px"};
  align-self: stretch;
  border-radius: ${({ $isOption, $isDeleteBtn }) =>
    $isOption || $isDeleteBtn ? "8px" : "100px"};
  background: ${({ $type }) => getButtonBg($type)};

  &::before {
    border-radius: ${({ $isOption, $isDeleteBtn }) =>
      $isOption || $isDeleteBtn ? "8px" : "100px"};
  }

  span {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;

    color: ${({ $type }) => getButtonColor($type)};
    text-align: center;
    font-family: proxima-nova;
    font-size: ${({ $size }) => ($size === "small" ? "14px" : "16px")};
    font-weight: ${({ $size }) => ($size === "small" ? "600" : "700")};
    line-height: ${({ $isOption }) => ($isOption ? "160%" : "16px")};
    letter-spacing: 0.16px;
  }

  &.adm-button-disabled {
    background: var(--Schemes-Outline-Variant, rgba(0, 38, 58, 0.14));
    opacity: 1;

    span {
      color: var(--Schemes-On-Surface-Variant, #69757d);

      svg path {
        fill: #8E8E93;
      }
    }
  }
`

export default Button
