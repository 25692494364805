import requester from "api/requester"

const imageAPI = {
  processImage(file: File): Promise<any> {
    return requester.post(
      "/images/process",
      { image: file },
      { headers: { "Content-Type": "multipart/form-data" } },
    )
  },
}

export default imageAPI
