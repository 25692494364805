import { useEffect, useRef, useState } from "react"
import {
  AddIngredientButton,
  IngredientList,
  Label,
  Placeholder,
  StyledIngredients,
  StyledSwipeAction,
} from "./styled"
import PlusIcon from "components/Icons/PlusIcon"
import { IFood, IIngredientFood } from "features/food/foodTypes"
import FoodListItem from "components/FoodListItem"
import { FoodListDivider } from "components/FoodListItem/styled"
import { SwipeActionRef } from "antd-mobile"
import TrashIcon from "components/Icons/TrashIcon"

interface FoodItemProps {
  food: IFood
  swipedId: any
  setSwipedId: (id: any) => void
  onRemove: () => void
  onViewDetails: (food: IFood) => void
}

const SwipeFoodItem = (props: FoodItemProps) => {
  const { food, swipedId, setSwipedId, onRemove, onViewDetails } = props

  useEffect(() => {
    if (swipedId && swipedId !== food.id) {
      ref.current?.close()
    }
  }, [swipedId])

  const ref = useRef<SwipeActionRef>(null)

  return (
    <StyledSwipeAction
      closeOnTouchOutside={false}
      ref={ref}
      onActionsReveal={(value) => {
        setSwipedId(food.id)
      }}
      closeOnAction={false}
      rightActions={[
        {
          key: "delete",
          text: <TrashIcon />,
          color: "danger",
          onClick: onRemove,
        },
      ]}
    >
      {" "}
      <FoodListItem
        food={food}
        hideAddBtn={true}
        style={{ paddingLeft: 8, paddingRight: 8 }}
        onSelect={onViewDetails}
      />
    </StyledSwipeAction>
  )
}

interface IIngredientsProps {
  ingredients: IIngredientFood[]
  showSearchIngredients: () => void
  onRemoveIngredient: (id: string) => void
  onViewIngredientDetails: (ingredient: IIngredientFood) => void
}

const Ingredients = (props: IIngredientsProps) => {
  const {
    ingredients,
    showSearchIngredients,
    onRemoveIngredient,
    onViewIngredientDetails,
  } = props
  const [swipedId, setSwipedId] = useState<any>(null)
  return (
    <StyledIngredients>
      <Label>Ingredients</Label>
      {!ingredients.length && (
        <Placeholder>Add ingredients to calculate your nutrition</Placeholder>
      )}

      <AddIngredientButton
        onClick={showSearchIngredients}
        fill="outline"
        style={{
          "--border-color": "#0da3ba",
        }}
      >
        <PlusIcon />
        Add Ingredient
      </AddIngredientButton>

      {ingredients.length ? (
        <IngredientList>
          {ingredients.map((food, index: number) => (
            <div key={food.id}>
              <SwipeFoodItem
                onViewDetails={() => onViewIngredientDetails(food)}
                food={food}
                swipedId={swipedId}
                setSwipedId={setSwipedId}
                onRemove={() => onRemoveIngredient(food.id as string)}
              />
              {index !== ingredients.length - 1 && <FoodListDivider />}
            </div>
          ))}
        </IngredientList>
      ) : null}
    </StyledIngredients>
  )
}

export default Ingredients
