const EmailIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <g id="icon/24">
        <path
          id="Vector"
          d="M19.1998 5C19.6539 4.99986 20.0913 5.16661 20.4242 5.46681C20.7572 5.76701 20.9612 6.17849 20.9953 6.61875L20.9998 6.75V17.25C20.9999 17.6915 20.8284 18.1167 20.5196 18.4405C20.2108 18.7642 19.7876 18.9625 19.3348 18.9956L19.1998 19H4.79976C4.34564 19.0001 3.90825 18.8334 3.57526 18.5332C3.24228 18.233 3.03831 17.8215 3.00426 17.3812L2.99976 17.25V6.75C2.99961 6.3085 3.17112 5.88325 3.4799 5.55952C3.78868 5.23579 4.21192 5.03749 4.66476 5.00438L4.79976 5H19.1998ZM19.1998 7.98725L12.9538 14.0597C12.7006 14.3055 12.3575 14.4436 11.9998 14.4436C11.642 14.4436 11.2989 14.3055 11.0458 14.0597L4.79976 7.98725V17.25H19.1998V7.98725ZM17.9272 6.75H6.07236L11.9998 12.5128L17.9272 6.75Z"
          fill="var(--color-navy)"
        />
      </g>
    </svg>
  )
}

export default EmailIcon
