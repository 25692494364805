import React from "react"
import { getSvgFill } from "utils"

const LightlyActive = ({ isSelected = false }: { isSelected?: boolean }) => {
  const { backgroundFill, pathFill } = getSvgFill(isSelected)
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
    >
      <g clipPath="url(#clip0_583_510)">
        <path
          d="M0 30C0 13.4315 13.4315 0 30 0C46.5685 0 60 13.4315 60 30C60 46.5685 46.5685 60 30 60C13.4315 60 0 46.5685 0 30Z"
          fill={backgroundFill}
        />
        <path
          d="M29.5 21.5938C31.0188 21.5938 32.25 20.3625 32.25 18.8438C32.25 17.325 31.0188 16.0938 29.5 16.0938C27.9812 16.0938 26.75 17.325 26.75 18.8438C26.75 20.3625 27.9812 21.5938 29.5 21.5938Z"
          fill={pathFill}
        />
        <path
          d="M39.125 29.3625C39.125 28.6887 38.63 28.1387 37.97 28.0287C36.2512 27.74 34.6288 26.8187 33.5288 25.6087L31.755 23.6425C31.5213 23.3812 31.2325 23.175 30.9162 23.0237C30.9025 23.0237 30.9025 23.01 30.8888 23.01H30.875C30.3663 22.7212 29.8025 22.5837 29.1562 22.6662C27.7538 22.8312 26.75 24.0962 26.75 25.5125V33.625C26.75 35.1375 27.9875 36.375 29.5 36.375H36.375V41.875C36.375 42.6312 36.9937 43.25 37.75 43.25C38.5063 43.25 39.125 42.6312 39.125 41.875V35.6875C39.125 34.175 37.8875 32.9375 36.375 32.9375H32.25V28.1937C33.625 29.335 35.55 30.3112 37.475 30.6962C38.3275 30.875 39.125 30.2287 39.125 29.3625Z"
          fill={pathFill}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.6562 22.625C24.4156 22.625 25.0312 23.2406 25.0312 24V34.3125C25.0312 36.4008 26.7242 38.0938 28.8125 38.0938H33.625C34.3844 38.0938 35 38.7094 35 39.4688C35 40.2281 34.3844 40.8438 33.625 40.8438H28.8125C25.2054 40.8438 22.2812 37.9196 22.2812 34.3125V24C22.2812 23.2406 22.8969 22.625 23.6562 22.625Z"
          fill={pathFill}
        />
      </g>
      <defs>
        <clipPath id="clip0_583_510">
          <rect width="60" height="60" rx="30" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default LightlyActive
