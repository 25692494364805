import { Popover } from "antd-mobile"
import styled from "styled-components"

export const MealTypePopover = styled(Popover.Menu)`
  --arrow-size: 0px;

  .adm-popover-menu-list-inner {
    padding: 4px 0px;
  }

  .adm-popover-menu-item {
    padding-left: 0px;
    width: 220px;

    &:first-child {
      .adm-popover-menu-item-text {
        border-top: none;
      }
    }

    .adm-popover-menu-item-text {
      padding: 8px 12px;
      line-height: 22px;
      font-weight: 600;
      font-size: 16px;
    }
  }
`

export const PageTitle = styled.div`
  font-family: sf-pro;
  font-size: 17px;
  font-style: normal;
  font-weight: 590;
  line-height: 22px; /* 129.412% */
  letter-spacing: -0.43px;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  gap: var(--spacing_xxs, 4px);
  cursor: pointer;
`
