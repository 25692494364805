import PlusIcon from "components/Icons/PlusIcon"
import {
  AddFoodButton,
  FoodImageBox,
  FoodInfoBox,
  FoodLeft,
  FoodName,
  FoodNameBox,
  FoodNutrient,
  StyledFoodListItem,
} from "./styled"

import Highlighter from "react-highlight-words"
import { getDisplayCalories, truncateByDecimalPlace } from "utils"
import {
  IAPIFood,
  IFood,
  IIngredientFood,
  IMealIngredient,
} from "features/food/foodTypes"
import { useState } from "react"
import { useAddedFoodAnimation } from "features/food/hooks/animation"
import SmallFoodImage from "components/SmallFoodImage"
import MyFoodIcon from "components/Icons/MyFood"

const AddedButton = () => {
  const style = useAddedFoodAnimation()

  return (
    <AddFoodButton style={{ cursor: "default" }}>
      <svg
        style={{ transform: `scale(${style.scale})`, opacity: style.opacity }}
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
      >
        <rect width="32" height="32" rx="16" fill="#0FBDD4" />
        <path
          d="M24 10L13 21L8 16"
          stroke="white"
          strokeWidth="3.42857"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </AddFoodButton>
  )
}

interface IFoodListItemProps {
  food: IFood | IIngredientFood | IAPIFood | IMealIngredient
  keyword?: string
  onSelect?: (food: IFood) => void
  onAdd?: (food: IFood) => void
  hideAddBtn?: boolean
  style?: React.CSSProperties
}

const FoodListItem = (props: IFoodListItemProps) => {
  const { food, keyword, onSelect, onAdd, hideAddBtn, style = {} } = props
  const { name, imageUrl, calorie, carbohydrate, protein, fat, unit, portion } =
    food
  const [showAddedIcon, setShowAddedIcon] = useState(false)

  const handleAddFood = () => {
    setShowAddedIcon(true)

    onAdd?.(food)

    setTimeout(() => {
      setShowAddedIcon(false)
    }, 1000)
  }

  return (
    <StyledFoodListItem style={style}>
      <FoodLeft onClick={() => onSelect?.(food)}>
        <FoodImageBox>
          <SmallFoodImage src={imageUrl} />
        </FoodImageBox>
        <FoodInfoBox>
          <FoodNameBox>
            <FoodName>
              {" "}
              {keyword ? (
                <Highlighter
                  highlightClassName="high-light-text"
                  searchWords={keyword.split(" ")}
                  textToHighlight={name}
                />
              ) : (
                name
              )}
            </FoodName>
            {!(food as IMealIngredient).externalFoodId &&
              !(food as IAPIFood).isExternal &&
              !(food as IIngredientFood).externalId && <MyFoodIcon />}
          </FoodNameBox>

          <FoodNutrient>
            {getDisplayCalories(calorie)} cal,{" "}
            {truncateByDecimalPlace(portion, 2)} {unit.toLocaleLowerCase()}
          </FoodNutrient>
        </FoodInfoBox>
      </FoodLeft>

      {!hideAddBtn &&
        (showAddedIcon ? (
          <AddedButton />
        ) : (
          <AddFoodButton onClick={() => handleAddFood()}>
            <PlusIcon />
          </AddFoodButton>
        ))}
    </StyledFoodListItem>
  )
}

export default FoodListItem
