import React from "react"

const RightIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.21043 12.34C5.94772 12.1113 5.92836 11.7221 6.16719 11.4707L8.98835 8.5L6.16719 5.52934C5.92836 5.27786 5.94772 4.88866 6.21043 4.66004C6.47314 4.43142 6.87971 4.44995 7.11854 4.70144L10.3328 8.08605C10.5557 8.32077 10.5557 8.67923 10.3328 8.91395L7.11854 12.2986C6.87971 12.55 6.47314 12.5686 6.21043 12.34Z"
        fill="#69757D"
      />
    </svg>
  )
}

export default RightIcon
