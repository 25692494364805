import { FlexBox } from "components/Common/FlexBox"
import { StyledSearchIngredients } from "./styled"
import SearchAutocomplete from "components/SearchAutocomplete"
import {
  useExternalSuggestedSearchTexts,
  useSearchExternalFoods,
  useSearchFoods,
  useSuggestedSearchTexts,
} from "features/food/hooks/index"
import { useState } from "react"
import SuggestedSearchTexts from "components/SuggestedSearchTexts"
import SearchFoodResults from "components/SearchFoodResults"
import { IAPIFood, IFood, IIngredientFood, IMyFood } from "features/food/foodTypes"
import IngredientFoodDetails from "components/APIFoodDetails"
import MyFoodDetails from "components/MyFoodDetails"

enum IDisplayContent {
  SuggestedSearchText = "Suggested-search-text",
  SearchResults = "Search-results",
  FoodDetails = "Food-details",
}

const SearchFoods = ({
  onAddFood,
  setIsHideHeader,
}: {
  onAddFood: (food: IFood) => void
  setIsHideHeader: (isHide: boolean) => void
}) => {
  const {
    keyword,
    suggestedSearchTexts,
    handleKeywordChange,
    isNoSuggestedSearchTexts,
  } = useSuggestedSearchTexts()

  const {
    searchFoodsResults,
    handleSearchFoods,
    searchFoodsLoading,
    searchSession,
    noSearchedFoodFounded,
  } = useSearchFoods()

  const [displayContent, setDisplayContent] = useState<IDisplayContent>(
    searchFoodsResults.length !== 0
      ? IDisplayContent.SearchResults
      : IDisplayContent.SuggestedSearchText,
  )

  const [foodDetails, setFoodDetails] = useState<IFood | null>(null)

  const onSelectFood = (food: IIngredientFood) => {
    setFoodDetails(food)
    setIsHideHeader(true)
    setDisplayContent(IDisplayContent.FoodDetails)
  }

  const onDeselectFood = () => {
    setFoodDetails(null)
    setIsHideHeader(false)
    setDisplayContent(IDisplayContent.SearchResults)
  }

  return (
    <StyledSearchIngredients>
      {displayContent !== IDisplayContent.FoodDetails && (
        <FlexBox style={{ padding: "0 16px" }} $direction="column" $gap={16}>
          <SearchAutocomplete
            onFocus={() => {
              if (!keyword) {
                setDisplayContent(IDisplayContent.SuggestedSearchText)
              }
            }}
            placeholder="Search"
            value={keyword}
            onChange={(keyword) => {
              handleKeywordChange(keyword)
              if (displayContent !== IDisplayContent.SuggestedSearchText) {
                setDisplayContent(IDisplayContent.SuggestedSearchText)
              }
            }}
            onClose={() => {
              handleKeywordChange("")
            }}
            onBlur={() => {}}
            onSearch={() => {
              const forceReset = true
              handleSearchFoods({
                nextKeyword: keyword,
                forceReset,
              })
              setDisplayContent(IDisplayContent.SearchResults)
            }}
            visible={!!keyword}
          />
        </FlexBox>
      )}

      {displayContent === IDisplayContent.SuggestedSearchText && (
        <div style={{ padding: "0 16px", marginTop: 20 }}>
          <SuggestedSearchTexts
            isNoSuggestedSearchTexts={isNoSuggestedSearchTexts}
            suggestedTexts={suggestedSearchTexts}
            keyword={keyword}
            onSelect={(keyword) => {
              const forceReset = true
              handleSearchFoods({
                nextKeyword: keyword,
                forceReset,
              })
              setDisplayContent(IDisplayContent.SearchResults)
            }}
          />
        </div>
      )}
      {displayContent === IDisplayContent.SearchResults && (
        <div
          style={{
            height: "calc(100% - var(--header-height) - var(--input-height))",
          }}
        >
          <SearchFoodResults
            noSearchedFoodFounded={noSearchedFoodFounded}
            foods={searchFoodsResults}
            onLoad={() => handleSearchFoods({ nextKeyword: keyword })}
            loading={searchFoodsLoading}
            hasMore={!!searchSession}
            keyword={keyword}
            onSelectFood={(food) => onSelectFood(food as IIngredientFood)}
            onAddFood={(food) => onAddFood(food as IAPIFood)}
            noCreateLinkIfNoFoodFounded={true}
          />
        </div>
      )}

      {displayContent === IDisplayContent.FoodDetails &&
        foodDetails &&
        (foodDetails.isExternal ? (
          <IngredientFoodDetails
            onBack={onDeselectFood}
            food={foodDetails as IAPIFood}
            onAdd={onAddFood}
            mode="add"
          />
        ) : (
          <MyFoodDetails
            onBack={onDeselectFood}
            food={foodDetails as IMyFood}
            onAdd={onAddFood}
            mode="add"
          />
        ))}
    </StyledSearchIngredients>
  )
}

export default SearchFoods
