import { ImageViewer } from "antd-mobile"
import { Fragment } from "react"
import { CloseReviewBtn, FoodImageOverlay } from "./styled"
import ClosePreview from "components/Icons/ClosePreview"

const ImagePreviewer = ({
  isOpen,
  onClose,
  imageUrl,
}: {
  isOpen: boolean
  onClose: () => void
  imageUrl?: string
}) => {
  return (
    <Fragment>
      <ImageViewer />
      <ImageViewer
        classNames={{
          mask: "image-viewer-mask",
        }}
        image={imageUrl}
        visible={isOpen}
        onClose={() => {}}
      />
      {isOpen && (
        <Fragment>
          <FoodImageOverlay />
          <CloseReviewBtn>
            <ClosePreview onClick={onClose} />
          </CloseReviewBtn>
        </Fragment>
      )}
    </Fragment>
  )
}

export default ImagePreviewer
