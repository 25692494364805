import React from "react"

const PlusIcon = ({ fill = "#0DA3BA" }: { fill?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
    >
      <path
        d="M0.5 8C0.5 8.275 0.600156 8.5 0.800469 8.7C1.00078 8.9 1.22613 9 1.50156 9H7.51095V15C7.51095 15.25 7.61111 15.5 7.81142 15.7C8.01174 15.9 8.23709 16 8.51252 16C8.78795 16 9.0133 15.9 9.21362 15.7C9.41393 15.5 9.48905 15.275 9.48905 15V9H15.5235C15.7739 9 16.0243 8.9 16.1995 8.7C16.3998 8.5 16.5 8.275 16.5 8C16.5 7.725 16.3998 7.5 16.1995 7.3C15.9992 7.1 15.7739 7 15.5235 7H9.46401V1C9.46401 0.750001 9.36385 0.500001 9.18858 0.300001C8.98826 0.100001 8.76291 0 8.48748 0C8.21205 0 7.9867 0.100001 7.78639 0.300001C7.58607 0.500001 7.48592 0.725001 7.48592 1V7H1.47653C1.2011 7 0.975743 7.1 0.77543 7.3C0.600156 7.5 0.5 7.75 0.5 8Z"
        fill={fill}
      />
    </svg>
  )
}

export default PlusIcon
