import { useAppDispatch, useAppSelector } from "app/hooks"
import { useEffect } from "react"
import { getSuggestedMeals } from "../mealSlice"
import { useFailed } from "features/notification/hooks"

export const useSuggestedMeals = () => {
  const suggestedMeals = useAppSelector((state) => state.meal.suggestedMeals)
  const getSuggestedMealsLoading = useAppSelector(
    (state) => state.meal.getSuggestedMealsLoading,
  )
  const getSuggestedMealsFailed = useAppSelector(
    (state) => state.meal.getSuggestedMealsFailed,
  )

  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(getSuggestedMeals())
  }, [dispatch])

  useFailed(getSuggestedMealsFailed)

  return {
    suggestedMeals,
    getSuggestedMealsLoading,
  }
}
