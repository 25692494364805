import { Fragment, useRef } from "react"
import {
  LoadingContainer,
  PreviewImage,
  RemoveImageWrapper,
  UploadTitle,
  Wrapper,
} from "./styled"
import UploadIcon from "./UploadIcon"
import { SpinLoading } from "antd-mobile"
import HiddenImageInput from "./HiddenImageInput"
import { useImageUploader } from "features/image/hooks"

const RemoveImageButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <RemoveImageWrapper>
      <svg
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
          onClick()
        }}
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <g opacity="0.85">
          <g clipPath="url(#clip0_3389_17635)">
            <path
              d="M0 0H17C20.866 0 24 3.13401 24 7V24H8C3.58172 24 0 20.4183 0 16V0Z"
              fill="#1C1F21"
            />
            <path
              d="M18.6894 5.3212C18.2753 4.90709 17.6064 4.90709 17.1923 5.3212L12 10.5028L6.80774 5.31058C6.39363 4.89647 5.72469 4.89647 5.31058 5.31058C4.89647 5.72469 4.89647 6.39363 5.31058 6.80774L10.5028 12L5.31058 17.1923C4.89647 17.6064 4.89647 18.2753 5.31058 18.6894C5.72469 19.1035 6.39363 19.1035 6.80774 18.6894L12 13.4972L17.1923 18.6894C17.6064 19.1035 18.2753 19.1035 18.6894 18.6894C19.1035 18.2753 19.1035 17.6064 18.6894 17.1923L13.4972 12L18.6894 6.80774C19.0929 6.40425 19.0929 5.72469 18.6894 5.3212Z"
              fill="#EBEDF0"
            />
          </g>
        </g>
        <defs>
          <clipPath id="clip0_3389_17635">
            <path
              d="M0 0H17C20.866 0 24 3.13401 24 7V24H8C3.58172 24 0 20.4183 0 16V0Z"
              fill="white"
            />
          </clipPath>
        </defs>
      </svg>
    </RemoveImageWrapper>
  )
}

const ImageUploader = ({ onRemoveImage }: { onRemoveImage?: () => void }) => {
  const uploadRef = useRef<HTMLInputElement>(null)
  const {
    onImageChange,
    displayImageUrl,
    onRemoveUploadedImage,
    processImageLoading,
  } = useImageUploader()

  return (
    <Wrapper
      $disabled={processImageLoading}
      onClick={() => {
        if (!processImageLoading) {
          uploadRef.current?.click()
        }
      }}
    >
      <UploadIcon />
      <UploadTitle>Upload Image</UploadTitle>

      {processImageLoading && (
        <LoadingContainer>
          <SpinLoading color="currentColor" />
        </LoadingContainer>
      )}

      {displayImageUrl && (
        <Fragment>
          <PreviewImage src={displayImageUrl} />
          <RemoveImageButton
            onClick={() => {
              onRemoveUploadedImage()
              onRemoveImage?.()
            }}
          />
        </Fragment>
      )}

      <HiddenImageInput
        ref={uploadRef}
        onChange={(image?: File) => {
          onImageChange(image)
        }}
      />
    </Wrapper>
  )
}

export default ImageUploader
