import React from "react"
import styled from "styled-components"
import { FlexBox } from "./FlexBox"
import { BackButton } from "./BackButton"
import ChevronLeft from "components/Icons/ChevronLeft"

const StyledTransparentHeader = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  background: var(--White_20, rgba(255, 255, 255, 0.2));
  z-index: 100;
`

const TransparentHeader = ({ onBack }: { onBack: () => void }) => {
  return (
    <StyledTransparentHeader>
      <FlexBox
        style={{ height: 44, padding: "9px 10px" }}
        $alignItems="center"
        $justify="space-between"
      >
        <BackButton onClick={onBack}>
          <ChevronLeft />
        </BackButton>

        <div />
      </FlexBox>{" "}
    </StyledTransparentHeader>
  )
}

export default TransparentHeader
