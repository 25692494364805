import React from "react"

const NotFoundIcon = () => {
  return (
    <svg
      style={{ width: '135.2px', height: '84.4px' }}
      xmlns="http://www.w3.org/2000/svg"
      width="139"
      height="88"
      viewBox="0 0 139 88"
      fill="none"
    >
      <path
        d="M70.1094 69C88.6109 69 103.609 54.0015 103.609 35.5C103.609 16.9985 88.6109 2 70.1094 2C51.6078 2 36.6094 16.9985 36.6094 35.5C36.6094 54.0015 51.6078 69 70.1094 69Z"
        fill="#F5F7F7"
        stroke="#00263A"
        strokeOpacity="0.14"
        strokeWidth="2.5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M64.7415 61.4939C66.5002 61.8185 68.2895 61.9872 70.1094 62C84.7449 62 96.6094 50.1355 96.6094 35.5C96.6094 20.8645 84.7449 9 70.1094 9C66.3497 9 62.773 9.78293 59.5332 11.1946C53.9032 13.6479 49.291 17.9999 46.5054 23.4419C44.6539 27.0589 43.6094 31.1575 43.6094 35.5C43.6094 39.44 44.4692 43.1792 46.0115 46.5401C47.1126 48.9396 48.5615 51.1463 50.2937 53.0956"
        fill="white"
      />
      <path
        d="M64.7415 61.4939C66.5002 61.8185 68.2895 61.9872 70.1094 62C84.7449 62 96.6094 50.1355 96.6094 35.5C96.6094 20.8645 84.7449 9 70.1094 9C66.3497 9 62.773 9.78293 59.5332 11.1946C53.9032 13.6479 49.291 17.9999 46.5054 23.4419C44.6539 27.0589 43.6094 31.1575 43.6094 35.5C43.6094 39.44 44.4692 43.1792 46.0115 46.5401C47.1126 48.9396 48.5615 51.1463 50.2937 53.0956"
        stroke="#00263A"
        strokeOpacity="0.14"
        strokeWidth="2.5"
        strokeLinecap="round"
      />
      <path
        d="M53.4062 56.0747C55.5544 57.8207 57.9812 59.237 60.6097 60.2466"
        stroke="#00263A"
        strokeOpacity="0.14"
        strokeWidth="2.5"
        strokeLinecap="round"
      />
      <path
        d="M97.6094 62L103.609 68"
        stroke="#00263A"
        strokeOpacity="0.14"
        strokeWidth="2.5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M102.64 67.0303C100.748 68.923 100.748 71.9917 102.64 73.8844L113.726 84.9697C115.618 86.8624 118.687 86.8624 120.58 84.9697C122.472 83.077 122.472 80.0083 120.58 78.1156L109.494 67.0303C107.602 65.1376 104.533 65.1376 102.64 67.0303Z"
        fill="#F5F7F7"
        stroke="#00263A"
        strokeOpacity="0.14"
        strokeWidth="2.5"
      />
      <path
        d="M107.609 69L118.609 80"
        stroke="white"
        strokeWidth="2.5"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M63.6099 24C63.6099 35.598 73.0119 45 84.6099 45C86.8882 45 89.0819 44.6372 91.1361 43.9662C87.7831 52.2871 79.6324 58.1607 70.1099 58.1607C57.5947 58.1607 47.4492 48.0152 47.4492 35.5C47.4492 24.1596 55.7796 14.7648 66.6549 13.1011C64.7228 16.2793 63.6099 20.0097 63.6099 24Z"
        fill="#F5F7F7"
      />
      <path
        d="M70.6094 17C69.336 17 68.0919 17.1253 66.8886 17.3642M63.2541 18.4761C56.4132 21.3508 51.6094 28.1144 51.6094 36"
        stroke="#00263A"
        strokeOpacity="0.14"
        strokeWidth="2.5"
        strokeLinecap="round"
      />
      <path
        d="M123.785 35.7773H115.609M130.109 28H112.934H130.109ZM137.109 28H134.888H137.109Z"
        stroke="#00263A"
        strokeOpacity="0.14"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33.7856 57.7773H25.6099M29.1099 49H11.9341H29.1099ZM6.10986 49H1.88867H6.10986Z"
        stroke="#00263A"
        strokeOpacity="0.14"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default NotFoundIcon
