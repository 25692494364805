import React from "react"
import {
  DetailsTrailing,
  StyledInput,
  StyledLabel,
  StyledTextFieldFormItem,
} from "./styled"

interface IProps {
  label: string
  value?: string
  onChange?: (value: string) => void
  placeholder?: string
  type?: string
  detailTrailing?: string
  isError?: boolean
  disabled?: boolean
  unit?: string
  setIsTouched?: () => void
}

const TextFieldFormItem = (props: IProps) => {
  const {
    detailTrailing,
    isError = false,
    disabled = false,
    unit,
    setIsTouched,
  } = props
  return (
    <StyledTextFieldFormItem>
      {props.label && <StyledLabel>{props.label}</StyledLabel>}

      <StyledInput
        onBlur={() => {
          setIsTouched?.()
        }}
        min={0}
        $isError={isError}
        type={props.type ?? "text"}
        placeholder={props.placeholder}
        value={props.value ?? ""}
        onChange={(e) => {
          if (disabled) {
            return
          }
          props.onChange?.(e.target.value)
        }}
        autoComplete="new-password"
        style={{ paddingRight: detailTrailing ? "28px" : "16px" }}
      />
      {detailTrailing && <DetailsTrailing>{detailTrailing}</DetailsTrailing>}
      {unit && <DetailsTrailing $isUnit>{unit}</DetailsTrailing>}
    </StyledTextFieldFormItem>
  )
}

export default TextFieldFormItem
