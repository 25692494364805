import React, { forwardRef, useEffect, useRef, useState } from "react"
import {
  StyledPortionFormItem,
  PortionLabel,
  StyledPortionInput,
} from "./styled"
import { FlexBox } from "components/Common/FlexBox"
import { validateMaximumToDecimalPlace } from "utils"

export interface IPortionInputProps {
  value: number
  onChange: (value: number) => void
  isError?: boolean
  disabled?: boolean
  setIsPortionValid?: (isValid: boolean) => void
}

type Ref = HTMLInputElement

const PortionInputItem = forwardRef<Ref, IPortionInputProps>((props, ref) => {
  const { value, disabled = false, onChange, setIsPortionValid } = props
  const [localValue, setLocalValue] = useState<string>(value.toString())

  useEffect(() => {
    if (value !== Number(localValue)) {
      setLocalValue(value.toString())
    }
  }, [value])

  const onPortionChange = (value: string) => {
    if (!validateMaximumToDecimalPlace(value) || value === "0.00") {
      return
    }

    if (value === "" || value === null) {
      setLocalValue("")
      setIsPortionValid?.(false)
      return
    }

    let nextValue = Number(value)

    if (nextValue === 0) {
      setLocalValue(value)
      setIsPortionValid?.(false)
      return
    }

    if (!isNaN(nextValue)) {
      setLocalValue(value)
      onChange(nextValue)
      setIsPortionValid?.(true)
    }
  }

  const onBlur = () => {
    const nextValue = value ?? 1
    if (localValue !== nextValue.toString()) {
      setLocalValue(nextValue.toString())
    }

    setIsPortionValid?.(true)

    onChange(nextValue)
  }

  return (
    <StyledPortionInput
      onBlur={onBlur}
      ref={ref}
      value={localValue}
      onChange={(e) => {
        if (!disabled) {
          onPortionChange(e.target.value)
        }
      }}
    />
  )
})

export interface IUnitInputProps {
  value: string
  onChange: (value: string) => void
  isError?: boolean
  disabled?: boolean
}

const UnitInputItem = forwardRef<Ref, IUnitInputProps>((props, ref) => {
  const { value, disabled = false, onChange } = props

  const [localValue, setLocalValue] = useState(value)

  useEffect(() => {
    if (value !== localValue) {
      setLocalValue(value)
    }
  }, [value])

  const onUnitChange = (value: string) => {
    setLocalValue(value)
    onChange(value)
  }

  const onBlur = () => {
    if (localValue === "" || localValue === null) {
      setLocalValue("unit")
      onChange("unit")
    }
  }

  return (
    <StyledPortionInput
      onBlur={onBlur}
      ref={ref}
      value={localValue}
      onChange={(e) => {
        if (!disabled) {
          onUnitChange(e.target.value)
        }
      }}
    />
  )
})

export interface IPortionFormItemProps {
  portionValue: number
  onPortionChange: (portion: number) => void

  unitValue: string
  onUnitChange: (unit: string) => void

  style?: React.CSSProperties
  disabled?: boolean
  setIsPortionValid?: (isValid: boolean) => void
}

const PortionFormItem = (props: IPortionFormItemProps) => {
  const {
    onPortionChange,
    portionValue,
    unitValue,
    onUnitChange,
    style = {},
    disabled,
    setIsPortionValid,
  } = props

  const firstInputRef = useRef<HTMLInputElement>(null)
  const secondInputRef = useRef<HTMLInputElement>(null)

  return (
    <StyledPortionFormItem style={style}>
      <FlexBox $direction="column" $gap={4}>
        <PortionLabel>Portion</PortionLabel>
      </FlexBox>

      <FlexBox $alignItems="center" $gap={12}>
        <PortionInputItem
          disabled={disabled}
          ref={firstInputRef}
          value={portionValue}
          onChange={onPortionChange}
          setIsPortionValid={setIsPortionValid}
        />

        <UnitInputItem
          disabled={disabled}
          ref={secondInputRef}
          value={unitValue}
          onChange={onUnitChange}
        />
      </FlexBox>
    </StyledPortionFormItem>
  )
}

export default PortionFormItem
