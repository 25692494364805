import { useAppDispatch, useAppSelector } from "app/hooks"
import { getDailyOverview, getWeeklyOverview } from "./overviewSlice"
import { useEffect } from "react"
import { useSearchParams } from "react-router-dom"
import dayjs from "dayjs"
import { DATE_PARAM_FORMAT } from "config"

export const useDailyOverview = (date: string) => {
  const dispatch = useAppDispatch()
  const dailyOverview = useAppSelector((state) => state.overview.dailyOverview)
  const getDailyOverviewLoading = useAppSelector(
    (state) => state.overview.getDailyOverviewLoading,
  )
  const getDailyOverviewFailed = useAppSelector(
    (state) => state.overview.getDailyOverviewFailed,
  )

  useEffect(() => {
    dispatch(getDailyOverview(date))
  }, [date])

  return {
    dailyOverview,
    getDailyOverviewLoading,
    getDailyOverviewFailed,
  }
}

export const useDateSearchParam = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const dateString = searchParams.get("date") ?? dayjs().format(DATE_PARAM_FORMAT)
  const date = dayjs(dateString).startOf("day")

  const isToday = date.isSame(dayjs().startOf("day"))

  const onBackToYesterday = () => {
    setSearchParams({ date: date.subtract(1, "day").format(DATE_PARAM_FORMAT) })
  }

  const onGoToTomorrow = () => {
    setSearchParams({ date: date.add(1, "day").format(DATE_PARAM_FORMAT) })
  }

  const onDateChange = (date: dayjs.Dayjs) => {
    setSearchParams({ date: date.format(DATE_PARAM_FORMAT) })
  }

  const onGoToNextWeek = () => {
    setSearchParams({ date: date.add(1, "week").format(DATE_PARAM_FORMAT) })
  }

  const onGoToPreviousWeek = () => {
    setSearchParams({ date: date.subtract(1, "week").format(DATE_PARAM_FORMAT) })
  }

  return {
    isToday,
    date,
    dateString,
    onBackToYesterday,
    onGoToTomorrow,
    onDateChange,
    onGoToNextWeek,
    onGoToPreviousWeek,
  }
}

export const useWeeklyOverview = (dateString: string) => {
  const dispatch = useAppDispatch()
  const weeklyOverview = useAppSelector(
    (state) => state.overview.weeklyOverview,
  )
  const getWeeklyOverviewLoading = useAppSelector(
    (state) => state.overview.getWeeklyOverviewLoading,
  )
  const getWeeklyOverviewFailed = useAppSelector(
    (state) => state.overview.getWeeklyOverviewFailed,
  )

  const start = dayjs(dateString).startOf("week")
  const end = start.endOf("week")

  useEffect(() => {
    dispatch(
      getWeeklyOverview({
        start: start.format(DATE_PARAM_FORMAT),
        end: end.format(DATE_PARAM_FORMAT),
      }),
    )
  }, [dateString])

  return {
    weeklyOverview,
    getWeeklyOverviewLoading,
    getWeeklyOverviewFailed,
  }
}
