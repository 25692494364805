import { useAppDispatch, useAppSelector } from "app/hooks"
import { loginFromApp } from "features/auth/authSlice"
import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import PATHS from "router/paths"

export function isInWebView() {
  const userAgent = navigator.userAgent.toLowerCase()

  // Android WebView detection
  const isAndroidWebView = /wv/.test(userAgent)

  // iOS WebView detection
  const isIOSWebView =
    /iphone|ipod|ipad/.test(navigator.platform) && /AppleWebKit/.test(userAgent)

  return isAndroidWebView || isIOSWebView
}

export const useLoginNativeApp = () => {
  const navigate = useNavigate()
  const loginFromAppLoading = useAppSelector(
    (state) => state.auth.loginFromAppLoading,
  )

  const loginFromAppFailed = useAppSelector(
    (state) => state.auth.loginFromAppFailed,
  )

  const token = useAppSelector((state) => state.auth.token)

  useEffect(() => {
    if (token) {
      navigate(PATHS.app.root)
    }
  }, [token])

  const dispatch = useAppDispatch()

  const handleLoginInNativeApp = (refreshToken: string) => {
    dispatch(loginFromApp(refreshToken))
  }

  return {
    loginFromAppLoading,
    loginFromAppFailed,
    handleLoginInNativeApp,
  }
}
