import styled from "styled-components"

export const ImagePlaceholderBox = styled.div`
  height: 38px;
  width: 40px;
  border-radius: 3.304px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--Schemes-Primary-Container-Variant, #cceded);
  flex-shrink: 0;
  overflow: hidden;
`

export const MealImage = styled.img`
  border-radius: var(--spacing_xxs, 4px);
  object-fit: cover;
  width: 100%;
  height: 100%;
  overflow: hidden;
`
