import { Fragment, useState } from "react"
import { CreateFoodCtaBox, CreateFoodWrapper } from "./styled"
import Header from "components/Header"
import CreateFoodForm from "./CreateFoodForm"
import SearchIngredients from "./SearchIngredients"
import Button from "components/Buttons/Button"
import {
  IIngredientFood,
  IUpdateFoodPayload,
  ICreateFoodPayload,
  IMyFood,
} from "../foodTypes"
import IngredientFoodDetails from "components/APIFoodDetails"
import { useCreateFood, useUpdateFood } from "../hooks"
import {
  useInitFoodImage,
  useResetFoodImage,
  useUploadedImagePayload,
} from "features/image/hooks"
import { generatePath, useNavigate, useSearchParams } from "react-router-dom"
import PATHS from "router/paths"
import { useSetMealTypeParams } from "features/meal/hooks/hooks"
import PopConfirm from "components/PopConfirm"
import { useAppSelector } from "app/hooks"
import { useFoodDetails } from "../hooks/useFoodDetails"
import { useUpdateLocalFood } from "../hooks/useUpdateLocalFood"
import FullPageLoading from "components/Layouts/FullPageLoading"

enum UpdateFoodSection {
  UpdateFoodForm = "UpdateFoodForm",
  SearchIngredients = "SearchIngredients",
  FoodIngredientDetails = "FoodIngredientDetails",
}

const UpdateFood = () => {
  const { foodDetails, getFoodDetailsLoading } = useFoodDetails()
  useInitFoodImage(foodDetails as IMyFood)
  useResetFoodImage()
  const [isPopConfirmDiscardOpen, setIsPopConfirmDiscardOpen] = useState(false)
  const navigate = useNavigate()
  const { mealType } = useSetMealTypeParams()
  const updateLocalFoodProps = useUpdateLocalFood(
    foodDetails as ICreateFoodPayload,
  )
  const { onAddIngredient, food, onRemoveIngredient, onUpdateIngredient, onPortionChange } =
    updateLocalFoodProps

  const { handleUpdateFood, updateFoodLoading } = useUpdateFood()
  const imagePayload = useUploadedImagePayload()
  const processImageLoading = useAppSelector(
    (state) => state.image.processImageLoading,
  )

  const onSubmitUpdateFood = () => {
    if (!food) return

    let payload: IUpdateFoodPayload = { ...food, id: foodDetails?.id as string }

    if (imagePayload) {
      payload = { ...payload, ...imagePayload }
    }

    handleUpdateFood(payload)
  }

  const [isHideHeader, setIsHideHeader] = useState(false)
  const [section, setSection] = useState<UpdateFoodSection>(
    UpdateFoodSection.UpdateFoodForm,
  )

  const [ingredientFoodDetails, setIngredientFoodDetails] =
    useState<null | IIngredientFood>(null)

  const showSearchIngredients = () => {
    setSection(UpdateFoodSection.SearchIngredients)
  }

  const isFormEmpty = food?.ingredients?.length === 0 || !food?.name

  const backToDetails = () => {
    navigate(generatePath(PATHS.app.food.details, { id: foodDetails?.id }))
  }

  const onBack = () => {
    if (section !== UpdateFoodSection.UpdateFoodForm) {
      setSection(UpdateFoodSection.UpdateFoodForm)
    } else {
      setIsPopConfirmDiscardOpen(true)
    }
  }

  const onViewIngredientFoodDetails = (food: IIngredientFood) => {
    setSection(UpdateFoodSection.FoodIngredientDetails)
    setIngredientFoodDetails(food)
  }

  const [isPortionValid, setIsPortionValid] = useState(true)

  const handlePortionChange = (value: number) => {
    onPortionChange(value)
    setIsPortionValid(true)
  }



  const shouldShowHeader =
    !isHideHeader && section !== UpdateFoodSection.FoodIngredientDetails

  

  return (
    <Fragment>
      {getFoodDetailsLoading || !food ? (
        <FullPageLoading />
      ) : (
        <CreateFoodWrapper
          $background={
            section === UpdateFoodSection.UpdateFoodForm ? "#f5f7f7" : "#fff"
          }
        >
          {shouldShowHeader && (
            <Header
              title="Edit Meal"
              onBack={onBack}
              disabled={updateFoodLoading}
            />
          )}

          {section === UpdateFoodSection.UpdateFoodForm && food && (
            <Fragment>
              <CreateFoodForm
                showSearchIngredients={showSearchIngredients}
                onViewIngredientFoodDetails={onViewIngredientFoodDetails}
                {...updateLocalFoodProps}
                onPortionChange={handlePortionChange}
                food={food}
                setIsPortionValid={setIsPortionValid}
              />
              <CreateFoodCtaBox>
                <Button
                  disabled={
                    food?.ingredients?.length === 0 ||
                    !food?.name ||
                    processImageLoading || !isPortionValid
                  }
                  loading={updateFoodLoading}
                  onClick={onSubmitUpdateFood}
                  style={{ width: "100%" }}
                  $type="primary"
                >
                  {" "}
                  Save 
                </Button>
              </CreateFoodCtaBox>
            </Fragment>
          )}

          {section === UpdateFoodSection.FoodIngredientDetails &&
            ingredientFoodDetails && (
              <IngredientFoodDetails
                food={ingredientFoodDetails}
                onBack={() => {
                  setSection(UpdateFoodSection.UpdateFoodForm)
                }}
                onEdit={onUpdateIngredient}
                onDelete={() => {
                  onRemoveIngredient(ingredientFoodDetails.id as string)
                  setSection(UpdateFoodSection.UpdateFoodForm)
                }}
                mode="edit"
              />
            )}

          {section === UpdateFoodSection.SearchIngredients && (
            <SearchIngredients
              setIsHideHeader={setIsHideHeader}
              onAddIngredient={onAddIngredient}
            />
          )}

          <PopConfirm
            isOpen={isPopConfirmDiscardOpen}
            title="Unsaved changes?"
            cancelText="Discard"
            confirmText="Stay"
            onCancel={backToDetails}
            onConfirm={() => {
              setIsPopConfirmDiscardOpen(false)
            }}
            loading={false}
          >
            <div style={{ textAlign: "center" }}>
              You haven't saved your meal yet. Are you sure you want to leave?
            </div>
          </PopConfirm>
        </CreateFoodWrapper>
      )}
    </Fragment>
  )
}

export default UpdateFood
