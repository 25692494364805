import { createSlice } from "@reduxjs/toolkit"
import { createAppAsyncThunk } from "app/hooks"
import imageAPI from "./imageAPI"
import { resetStoreAction } from "config"

const onImageLoaded = (src: string) =>
  new Promise((resolve) => {
    if (!src) resolve(true)
    const img = new Image()
    img.src = src

    img.onload = function () {
      resolve(true)
    }
  })

export const processImage = createAppAsyncThunk(
  "image/processImage",
  async (file: File, { rejectWithValue }) => {
    try {
      const response = await imageAPI.processImage(file)
      await onImageLoaded(response.url)

      return response
    } catch (err: any) {
      return rejectWithValue(err)
    }
  },
)

interface IImageState {
  imageUrl: string
  imageId: string

  processImageLoading: boolean
  processImageFailed: any
  displayImageType: "base64" | "url"

  imageBase64Encoded: string
  convertImageLoading: boolean

  file?: File
}

const initialState: IImageState = {
  imageUrl: "",
  imageId: "",

  processImageLoading: false,
  processImageFailed: undefined,
  displayImageType: "base64",

  imageBase64Encoded: "",
  convertImageLoading: false,

  file: undefined,
}

export const searchSlice = createSlice({
  name: "image",
  initialState,
  reducers: {
    initFoodImage(state, { payload }) {
      state.displayImageType = "url"
      state.imageUrl = payload.url
      state.imageId = payload.id
    },

    uploadImage(state, { payload }) {
      state.imageBase64Encoded = payload.base64
      state.file = payload.file
      state.displayImageType = "base64"
      state.imageUrl = ""
      state.imageId = ""
    },

    removeUploadedImage(state) {
      state.file = undefined
      state.imageBase64Encoded = ""
      state.imageId = ""
      state.imageUrl = ""
    },

    resetImageState() {
      return initialState
    },
  },
  extraReducers(builder) {
    builder

      .addCase(processImage.pending, (state) => {
        state.processImageLoading = true
        state.imageUrl = ""
        state.imageId = ""
        state.imageBase64Encoded = ""
        state.displayImageType = "url"
      })

      .addCase(processImage.fulfilled, (state, { payload }) => {
        state.imageUrl = payload.url
        state.imageId = payload.id
        state.processImageLoading = false
      })
      .addCase(processImage.rejected, (state, { payload }) => {
        state.processImageLoading = false
        state.imageUrl = ""
        state.imageId = ""
        state.processImageFailed = payload
      })

      .addCase(resetStoreAction, () => {
        return initialState
      })
  },
})

export const {
  uploadImage,
  removeUploadedImage,
  initFoodImage,
  resetImageState,
} = searchSlice.actions

export default searchSlice.reducer
