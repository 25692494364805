import { useAppDispatch, useAppSelector } from "app/hooks"
import { IMetricType, IOnboardingPayload, IUpdateUserPayload } from "./types"
import {
  onboarding,
  resetOnboardingState,
  getGoal,
  getUser,
  updateMetricType,
  updateUser,
  setIsTutorialShowed,
} from "./onboardingSlice"

import { useEffect, useState } from "react"
import { useFailed, useSuccess } from "features/notification/hooks"

export const useOnboarding = (callback: () => void) => {
  const onboardingLoading = useAppSelector(
    (state) => state.onboarding.onboardingLoading,
  )
  const onboardingSuccess = useAppSelector(
    (state) => state.onboarding.onboardingSuccess,
  )
  const onboardingFailed = useAppSelector(
    (state) => state.onboarding.onboardingFailed,
  )

  const dispatch = useAppDispatch()
  const handleOnboarding = (payload: IOnboardingPayload) => {
    dispatch(onboarding(payload))
  }

  useEffect(() => {
    if (onboardingSuccess) {
      callback()
    }
  }, [onboardingSuccess])

  useEffect(() => {
    return () => {
      dispatch(resetOnboardingState())
    }
  }, [])

  useFailed(onboardingFailed)

  return {
    onboardingLoading,
    onboardingFailed,
    onboardingSuccess,
    handleOnboarding,
  }
}

export const useGoal = () => {
  const getGoalLoading = useAppSelector(
    (state) => state.onboarding.getGoalLoading,
  )
  const getGoalFailed = useAppSelector(
    (state) => state.onboarding.getGoalFailed,
  )
  const goal = useAppSelector((state) => state.onboarding.goal)

  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(getGoal(false))
  }, [])

  return {
    getGoalLoading,
    getGoalFailed,
    goal,
  }
}

export const useUser = () => {
  const getUserLoading = useAppSelector(
    (state) => state.onboarding.getUserLoading,
  )
  const getUserFailed = useAppSelector(
    (state) => state.onboarding.getUserFailed,
  )
  const user = useAppSelector((state) => state.onboarding.user)

  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(getUser(false))
  }, [])

  useFailed(getUserFailed)

  return {
    getUserLoading,
    getUserFailed,
    user,
  }
}

export const useCheckShouldShowTutorial = () => {
  const user = useAppSelector((state) => state.onboarding.user)
  const isRevalidatedShouldShowTutorial = useAppSelector(
    (state) => state.onboarding.isRevalidatedShouldShowTutorial,
  )
  const loading = useAppSelector((state) => state.onboarding.getUserLoading)

  const dispatch = useAppDispatch()

  useEffect(() => {
    if (user?.isShowTutorial && !isRevalidatedShouldShowTutorial) {
      dispatch(getUser(true))
    }
  }, [user])
  return {
    isShowTutorial: user?.isShowTutorial ?? true,
    checkShowTutorialLoading: loading,
    isRevalidatedShouldShowTutorial,
  }
}

export const useIsTutorialShowed = () => {
  const isTutorialShowed = useAppSelector(
    (state) => state.onboarding.isTutorialShowed,
  )

  const dispatch = useAppDispatch()
  const handleTutorialShowedChange = (isShowed: boolean) => {
    dispatch(setIsTutorialShowed(isShowed))
  }

  return {
    isTutorialShowed,
    handleTutorialShowedChange,
  }
}

export const useMetricType = () => {
  const metricType = useAppSelector((state) => state.onboarding.metricType)

  const dispatch = useAppDispatch()

  const onUpdateMetricType = (metricType: IMetricType) => {
    dispatch(updateMetricType(metricType))
  }

  return {
    onUpdateMetricType,
    metricType,
  }
}

export const useUpdateUser = (callback?: () => void) => {
  const updateUserLoading = useAppSelector(
    (state) => state.onboarding.updateUserLoading,
  )
  const updateUserFailed = useAppSelector(
    (state) => state.onboarding.updateUserFailed,
  )
  const updateUserSuccess = useAppSelector(
    (state) => state.onboarding.updateUserSuccess,
  )

  const dispatch = useAppDispatch()

  const handleUpdateUser = (payload: IUpdateUserPayload) => {
    dispatch(updateUser(payload))
  }

  useFailed(updateUserFailed)

  useSuccess(updateUserSuccess, { message: "" }, () => {
    callback?.()
  })

  return {
    updateUserLoading,
    updateUserFailed,
    updateUserSuccess,
    handleUpdateUser,
  }
}
