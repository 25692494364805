import { Fragment, ReactNode, useState } from "react"
import {
  FoodImageBox,
  Image,
  FoodImagePlaceholder,
  PlaceholderOverLay,
} from "./styled"
import largeMealPlaceholder from "assets/images/large-meal-placeholder.png"
import ImagePreviewer from "components/FoodImage/ImageViewer"

const FoodImage = ({
  previewImageUrl,
  children,
}: {
  previewImageUrl?: string
  children?: ReactNode
}) => {
  const [previewerVisible, setPreviewerVisible] = useState(false)
  const [hasError, setHasError] = useState(false)

  const hasImage = previewImageUrl && !hasError

  return (
    <FoodImageBox>
      {children}
      {hasImage ? (
        <Image
          onClick={() => {
            setPreviewerVisible(true)
          }}
          src={previewImageUrl ?? ""}
          alt="food-image"
          onError={() => setHasError(true)}
        />
      ) : (
        <Fragment>
          <FoodImagePlaceholder src={largeMealPlaceholder} />
          <PlaceholderOverLay />
        </Fragment>
      )}
      {hasImage && (
        <ImagePreviewer
          isOpen={previewerVisible}
          onClose={() => {
            setPreviewerVisible(false)
          }}
          imageUrl={previewImageUrl}
        />
      )}
    </FoodImageBox>
  )
}

export default FoodImage
