import { useEffect, useState } from "react"
import {
  updateMealNutrientWhenPortionChange,
  updateMealNutrientWhenUnitChange,
} from "helper/nutrient"
import { IMeal } from "../types"
import { IMealType } from "features/home/types"
import { updateIngredientsWhenPortionChange } from "helper/ingredient"
import { IMealIngredient } from "features/food/foodTypes"

export const useUpdateLocalMeal = ({
  initMeal,
  mealType,
}: {
  initMeal?: IMeal
  mealType?: string
}) => {
  const [meal, setMeal] = useState<IMeal | undefined>()

  useEffect(() => {
    if (initMeal && mealType)
      setMeal({
        ...initMeal,
        type: mealType.toUpperCase() as IMealType,
      })
  }, [initMeal, mealType])

  const onMealTypeChange = (mealType: IMealType) => {
    if (meal) {
      setMeal({
        ...meal,
        type: mealType,
      })
    }
  }

  const onPortionChange = (portion: number) => {
    if (!meal) return
    const nutrient = updateMealNutrientWhenPortionChange(meal, portion)
    const ingredients =
      (updateIngredientsWhenPortionChange(
        meal,
        portion,
      ) as IMealIngredient[]) ?? []
    setMeal({
      ...meal,
      ...nutrient,
      ingredients,
    })
  }

  const onUnitChange = (unitLabel: string) => {
    if (!meal) return
    const nextMeal = updateMealNutrientWhenUnitChange(meal, unitLabel)

    setMeal(nextMeal)
  }

  return {
    meal,
    onPortionChange,
    onUnitChange,
    onMealTypeChange,
  }
}
