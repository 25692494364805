import { createSlice } from "@reduxjs/toolkit"
import authAPI from "./authAPI"
import { createAppAsyncThunk } from "app/hooks"
import {
  IAuthState,
  ILoginWithApplePayload,
  ILoginWithEmailPayload,
  ILoginWithMfaPayload,
} from "./types"
import { resetStoreAction } from "config"
import { jwtDecode } from "jwt-decode"
import { refreshInstant } from "api/refreshToken"

const getUserIdFromIdToken = (idToken: string) => {
  const decoded: any = jwtDecode(idToken)

  return decoded["custom:user_id"]
}

export const loginFromApp = createAppAsyncThunk(
  "auth/loginFromApp",
  async (refreshToken: string, { rejectWithValue }) => {
    try {
      const response = await refreshInstant.put("/accounts/token", {
        refreshToken,
      })

      return {
        accessToken: response.data.accessToken,
        refreshToken,
        idToken: response.data.idToken,
      }
    } catch (err: any) {
      return rejectWithValue(err)
    }
  },
)

export const loginWithEmail = createAppAsyncThunk(
  "auth/loginWithEmail",
  async (payload: ILoginWithEmailPayload, { rejectWithValue }) => {
    try {
      const response = await authAPI.loginWithEmail(payload)
      return { ...response, email: payload.email }
    } catch (err: any) {
      return rejectWithValue(err)
    }
  },
)

export const loginWithApple = createAppAsyncThunk(
  "auth/loginWithApple",
  async (payload: ILoginWithApplePayload, { rejectWithValue }) => {
    try {
      const response = await authAPI.loginWithApple(payload)
      return response
    } catch (err: any) {
      return rejectWithValue(err)
    }
  },
)

export const loginWithMfa = createAppAsyncThunk(
  "auth/loginWithMfa",
  async (payload: ILoginWithMfaPayload, { rejectWithValue }) => {
    try {
      const response = await authAPI.loginWithMfa(payload)
      return response
    } catch (err: any) {
      return rejectWithValue(err)
    }
  },
)

export const logOut = createAppAsyncThunk(
  "auth/logOut",
  async (refreshToken: string, { rejectWithValue }) => {
    try {
      const response = await authAPI.logOut(refreshToken)
      return response
    } catch (err: any) {
      return rejectWithValue(err)
    }
  },
)

const initialState: IAuthState = {
  loginFromAppLoading: true,
  loginFromAppFailed: null,
  token: "",
  refreshToken: "",
  mfaSession: "",
  mfaMaskedPhoneNumber: "",
  email: "",
  userId: "",

  loginWithEmailLoading: false,
  loginWithEmailFailed: null,
  loginWithEmailSuccess: null,

  loginWithAppleLoading: false,
  loginWithAppleFailed: null,
  loginWithAppleSuccess: null,

  loginWithMfaLoading: false,
  loginWithMfaFailed: null,

  logOutLoading: false,
  logOutFailed: null,

  prevEmailPayload: {
    email: "",
    password: "",
  },
}

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    
    onLogOut() {
      return initialState
    },

    updateToken(state, { payload: token }) {
      state.token = token
    },

    removeMfaSection(state) {
      state.mfaSession = ""
      state.mfaMaskedPhoneNumber = ""
    },
  },
  extraReducers(builder) {
    builder
      .addCase(loginFromApp.fulfilled, (state, { payload }) => {
        state.loginFromAppLoading = false
        state.token = payload.accessToken
        state.refreshToken = payload.refreshToken
        state.userId = getUserIdFromIdToken(payload.idToken)
      })

      .addCase(loginFromApp.rejected, (state) => {
        state.loginFromAppFailed = {}
        state.loginFromAppLoading = false
      })

      .addCase(loginWithEmail.pending, (state, { meta }) => {
        state.loginWithEmailLoading = true
        state.prevEmailPayload = meta.arg
      })
      .addCase(loginWithEmail.fulfilled, (state, { payload }) => {
        state.loginWithEmailLoading = false
        state.email = payload.email

        if (payload.token) {
          state.token = payload.token.accessToken
          state.refreshToken = payload.token.refreshToken
          state.userId = getUserIdFromIdToken(payload.token.idToken)
        }

        if (payload.mfa) {
          state.mfaSession = payload.mfa.session
          state.mfaMaskedPhoneNumber = payload.mfa.maskedPhoneNumber
        }
      })
      .addCase(loginWithEmail.rejected, (state, { payload }) => {
        state.loginWithEmailLoading = false
        state.loginWithEmailFailed = payload
      })

      .addCase(loginWithApple.pending, (state) => {
        state.loginWithAppleLoading = true
      })
      .addCase(loginWithApple.fulfilled, (state, { payload }) => {
        state.loginWithAppleLoading = false
        if (payload.token) {
          state.token = payload.token.accessToken
          state.refreshToken = payload.token.refreshToken
          state.userId = getUserIdFromIdToken(payload.token.idToken)
        }
      })
      .addCase(loginWithApple.rejected, (state, { payload }) => {
        state.loginWithAppleLoading = false
        state.loginWithAppleFailed = payload
      })

      .addCase(loginWithMfa.pending, (state) => {
        state.loginWithMfaLoading = true
      })
      .addCase(loginWithMfa.fulfilled, (state, { payload }) => {
        state.loginWithMfaLoading = false
        state.token = payload.accessToken
        state.refreshToken = payload.refreshToken
        state.userId = getUserIdFromIdToken(payload.idToken)
      })
      .addCase(loginWithMfa.rejected, (state, { payload }) => {
        state.loginWithMfaLoading = false
        state.loginWithMfaFailed = payload
      })
      .addCase(logOut.pending, (state) => {
        state.logOutLoading = true
      })
      .addCase(logOut.fulfilled, () => {
        return initialState
      })
      .addCase(logOut.rejected, (state, { payload }) => {
        state.logOutLoading = false
        state.logOutFailed = payload
      })
      .addCase(resetStoreAction, () => {
        return initialState
      })
  },
})

export const { updateToken, onLogOut, removeMfaSection } = authSlice.actions

export default authSlice.reducer
