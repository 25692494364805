import { ActiveMask, FlexInner, StyledSwitch, SwitchOption } from "./styled"

export interface ISwitchOption {
  label: string
  value: string
}

const Switch = ({
  options,
  value,
  onChange,
}: {
  options: [ISwitchOption, ISwitchOption]
  value: string
  onChange: (value: string) => void
}) => {
  const activeIndex = options.findIndex((option) => option.value === value)
  const nextValue = activeIndex === 0 ? options[1].value : options[0].value
  return (
    <StyledSwitch>
      <FlexInner>
        <ActiveMask $activeIndex={activeIndex} $width={77} />
        {options.map((option, optionIndex) => (
          <SwitchOption
            $isActive={activeIndex === optionIndex}
            key={option.value}
            onClick={() => onChange(nextValue)}
          >
            {option.label}
          </SwitchOption>
        ))}
      </FlexInner>
    </StyledSwitch>
  )
}

export default Switch
