import styled from "styled-components"

export const MessageItemWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 4px;

  .product-mess-content {
    &--kagami {
      margin-left: 8px;
    }

    &--user {
      margin-right: 8px;
    }
  }

  &.message-item {
    &--kagami {
      .text-content {
        color: var(--dark-dark, #05050c);
        border-radius: 25px 25px 25px 0px;
        background: #fff;
        box-shadow: 0px 1px 7px 0px rgba(0, 0, 0, 0.12);
      }
    }

    &--user {
      flex-direction: row-reverse;

      .text-content {
        border-radius: 25px 25px 0px 25px;
        background: var(--Schemes-Primary-Container, #4bd9de);
        box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.25);
        margin-right: 8px;
        width: auto;
        max-width: 232px;

        p {
          color: var(--Schemes-On-Primary-Container, #044257);
          font-size: 16px;
          line-height: 160%;
        }
      }
    }
  }

  .text-content {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    width: 297px;
    padding: 12px 16px;

    p {
      margin: 0;
      padding: 0;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px; /* 129.412% */
      letter-spacing: -0.43px;
      color: var(--Schemes-On-Surface, #1c1f21);
    }
  }

  .product-link {
    color: #05050c;
    text-decoration: none;
  }
`

export const ReviewPhoto = styled.img`
  width: 100%;
  height: 350px;
  object-fit: cover;
`

export const WelcomeImageBox = styled.div`
  width: 81.667px;
  height: 77px;
  border-radius: var(--spacing_xs, 6px);
  overflow: hidden;
`

export const WelcomeImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`
