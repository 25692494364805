import React from "react"

const EditIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M17.3974 10.1909L13.811 6.60447M3.5 20.5019L6.53444 20.1647C6.90518 20.1235 7.09055 20.1029 7.26381 20.0468C7.41753 19.9971 7.56382 19.9267 7.6987 19.8378C7.85074 19.7375 7.98262 19.6057 8.24639 19.3419L20.0872 7.50107C21.0776 6.51071 21.0776 4.90501 20.0872 3.91465C19.0969 2.92428 17.4912 2.92428 16.5008 3.91465L4.65996 15.7555C4.3962 16.0192 4.26432 16.1511 4.16406 16.3032C4.07511 16.438 4.0048 16.5843 3.95504 16.738C3.89895 16.9113 3.87835 17.0967 3.83716 17.4674L3.5 20.5019Z"
        stroke="#1C1F21"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default EditIcon
