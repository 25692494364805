import React from "react"

const AppleIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <g id="icon/24">
        <path
          id="Vector"
          d="M17.1984 10.6339C17.1764 8.10867 19.3591 6.87778 19.4607 6.82278C18.2235 5.09698 16.3059 4.86011 15.6299 4.84319C14.0172 4.68248 12.4574 5.76533 11.6356 5.76533C10.8005 5.76533 9.53681 4.86011 8.17153 4.88551C6.41739 4.91087 4.77815 5.88377 3.88121 7.38961C2.02545 10.4647 3.40844 14.9822 5.18908 17.4694C6.07715 18.6876 7.11553 20.0454 8.47642 19.9988C9.80635 19.9481 10.3056 19.1867 11.9095 19.1867C13.5002 19.1867 13.9685 19.9988 15.356 19.9692C16.7831 19.9481 17.6845 18.7468 18.5416 17.5201C19.5712 16.1243 19.9821 14.7496 19.9998 14.6819C19.9644 14.6607 17.225 13.6583 17.1984 10.6339ZM14.5783 3.20623C15.2941 2.34758 15.7845 1.18437 15.6475 0C14.6136 0.0422761 13.3146 0.685219 12.5723 1.52276C11.914 2.26298 11.3219 3.47271 11.4766 4.61056C12.6342 4.69091 13.8316 4.04797 14.5783 3.20623Z"
          fill="var(--color-navy)"
        />
      </g>
    </svg>
  )
}

export default AppleIcon
