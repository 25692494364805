import styled from "styled-components"

export const StyledInputNumberFormItem = styled.div`
  --label-width: 70px;

  display: flex;
  position: relative;
  width: 100%;
`
export const StyledLabel = styled.label`
  position: absolute;
  text-align: left;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);

  font-size: 16px;
  line-height: 160%;
`

export const StyledInput = styled.input<{ $isError: boolean }>`
  padding: 18.5px 16px;
  padding-left: var(--label-width);
  line-height: 26px;
  font-weight: 500;
  border-radius: 8px;
  outline: ${(props) =>
    props.$isError
      ? "1px solid var(--Schemes-Error, #B3261E) !important"
      : "0.5px solid var(--Schemes-Outline, rgba(0, 38, 58, 0.32))"};

  @supports (-webkit-touch-callout: none) {
    outline: ${(props) =>
      props.$isError
        ? "1px solid var(--Schemes-Error, #B3261E) !important"
        : "0.8px solid var(--Schemes-Outline, rgba(0, 38, 58, 0.32))"};
  }

  background: var(#fff);
  color: ${(props) =>
    props.$isError
      ? "var(--Schemes-Error, #B3261E)"
      : "var(--Schemes-Primary, #0DA3BA)"};

  text-align: right;
  width: 100%;
  height: 100%;
  font-size: 16px;


  &,
  &:focus,
  &:active,
  &:focus-visible {
    border: none;
  }

  &:focus,
  &:active,
  &:focus-visible {
    outline: 1px solid var(--Schemes-Primary, #0da3ba);
  }


  &::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: var(--color-navy);
    opacity: 0.36;
    font-family: proxima-nova;
  }
  &::-moz-placeholder {
    /* Firefox 19+ */
    color: var(--color-navy);
    opacity: 0.36;
    font-family: proxima-nova;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type="number"] {
    -moz-appearance: textfield;
  }
`

export const DetailsTrailing = styled.div<{ $isUnit?: boolean }>`
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 16px;
  color: var(--color-navy);

  ${(props) =>
    props.$isUnit &&
    `
    color: var(--Schemes-Primary, #0DA3BA);
    font-weight: 500;
    `}
`
