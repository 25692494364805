import { Link } from "react-router-dom"
import styled from "styled-components"

export const StyledNotFound = styled.div`
  height: 100%;
`

export const NotFoundContent = styled.div`
  display: flex;
  width: 100%;
  padding: 0px 20px;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing_s, 8px);
  margin-top: 58px;
`

export const NotFoundTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
  text-align: center;
`

export const NotFoundDescription = styled.div`
  font-size: 16px;
  font-weight: 400;
  text-align: center;
`

export const ReTry = styled.div`
  font-size: 14px;
  font-weight: 700;
  color: var(--Schemes-Primary, #0da3ba);
  text-align: center;
  display: flex;
  width: 121px;
  padding: var(--spacing_s, 8px) var(--spacing_l, 16px);
  justify-content: center;
  align-items: center;
  gap: var(--spacing_s, 8px);
  cursor: pointer;
`

export const ManualButton = styled(Link)`
  background: var(--White, #fff);
  display: flex;
  padding: var(--spacing_xs, 6px) var(--spacing_m, 12px);
  align-items: center;
  gap: var(--spacing_xxs, 4px);
  border-radius: var(--spacing_xxs, 4px);
  border: 1px solid var(--Schemes-Outline-Variant, rgba(0, 38, 58, 0.14));
  text-decoration: none;
  
  span {
    color: var(--Schemes-On-Primary-Container, #044257);
  }
`
