import {
  FoodDetailsContent,
  FoodDetailsHeader,
  FoodDetailsInfo,
  FoodName,
  StyledFoodDetails,
  IngredientList,
  FoodDetailsPopover,
  FoodDetailsBody,
} from "./styled"
import { BackButton } from "components/Common/BackButton"
import ChevronLeft from "components/Icons/ChevronLeft"
import NutrientInformation from "components/NutrientInformation"
import Button from "components/Buttons/Button"
import CtaBox from "components/Common/CtaBox"
import PortionUnitPickerFormItem from "components/PortionFormItemUnitPicker"
import { useUpdateFood } from "features/food/hooks/useUpdateApiFood"
import { useFoodDetails, useFoodDetailsOnBack } from "../hooks/useFoodDetails"
import { Fragment, useState } from "react"
import FullPageLoading from "components/Layouts/FullPageLoading"
import MealTypePickerFormItem from "components/MealTypePicker"
import {
  useBackToHome,
  useLogMeal,
  useSetMealTypeParams,
} from "features/meal/hooks/hooks"
import { IMealType } from "features/home/types"
import FoodListItem from "components/FoodListItem"
import { FoodListDivider, FoodNameBox } from "components/FoodListItem/styled"
import VerticalSettings from "components/Icons/VerticalSettings"
import { Action } from "antd-mobile/es/components/action-sheet"
import { generatePath, useNavigate } from "react-router-dom"
import PATHS from "router/paths"
import PopConfirm from "components/PopConfirm"
import { ConfirmEntry } from "components/PopConfirm/styled"
import { useDeleteFood } from "../hooks"
import FoodImage from "components/FoodImage"
import { FlexBox } from "components/Common/FlexBox"
import { EditSOutline } from "antd-mobile-icons"
import TrashIcon from "components/Icons/TrashIcon"
import EditIcon from "components/Icons/EditIcon"
import { useFoodTopNavPosition } from "../hooks/style"
import { TopNavWrapper } from "components/TopNavWrapper"
import { FOOD_DETAILS_BODY_ID } from "config"
import { isMyFood } from "utils"
import MyFoodIcon from "components/Icons/MyFood"

const actions: Action[] = [
  {
    key: "edit",
    text: (
      <FlexBox $alignItems="center" $gap={8}>
        <EditIcon /> Edit
      </FlexBox>
    ),
  },
  {
    key: "delete",
    text: (
      <FlexBox $alignItems="center" $gap={8}>
        <TrashIcon color="#FF4921" /> Delete
      </FlexBox>
    ),
  },
]

const FoodDetails = () => {
  const { foodDetails, getFoodDetailsLoading } = useFoodDetails()
  const { food, onPortionChange, onUnitChange } = useUpdateFood(foodDetails)
  const { mealType, setMealType } = useSetMealTypeParams()

  const [isPortionValid, setIsPortionValid] = useState(true)
  const handlePortionChange = (value: number) => {
    onPortionChange(value)
    setIsPortionValid(true)
  }

  const backToHome = useBackToHome()

  const { handleLogMealByFood, logMealLoading } = useLogMeal(backToHome)

  const onBack = useFoodDetailsOnBack()

  const navigate = useNavigate()

  const goToEdit = () => {
    if (foodDetails) {
      navigate(generatePath(PATHS.app.food.edit, { id: foodDetails.id }))
    }
  }

  const [isPopConfirmDeleteOpen, setIsPopConfirmDeleteOpen] = useState(false)
  const { handleDeleteFood, deleteFoodLoading } = useDeleteFood(onBack)
  const previewImageUrl = foodDetails?.previewImageUrl ?? foodDetails?.imageUrl

  return (
    <Fragment>
      {getFoodDetailsLoading || !food ? (
        <FullPageLoading />
      ) : (
        <StyledFoodDetails>
          <FoodDetailsContent>
            <FoodDetailsBody id={FOOD_DETAILS_BODY_ID}>
              <FoodImage previewImageUrl={previewImageUrl}>
                <TopNavWrapper>
                  <FoodDetailsHeader>
                    <BackButton onClick={onBack}>
                      <ChevronLeft />
                    </BackButton>
                    {isMyFood(food) && (
                      <FoodDetailsPopover
                        actions={actions}
                        placement="bottom-end"
                        onAction={({ key }) => {
                          if (key === "edit") {
                            goToEdit()
                          }

                          if (key === "delete") {
                            setIsPopConfirmDeleteOpen(true)
                          }
                        }}
                        trigger="click"
                      >
                        <VerticalSettings />
                      </FoodDetailsPopover>
                    )}
                  </FoodDetailsHeader>
                </TopNavWrapper>
              </FoodImage>

              <FoodDetailsInfo>
                <FoodNameBox>
                  <FoodName>{food.name}</FoodName>
                  {isMyFood(food) ? <MyFoodIcon /> : null}
                </FoodNameBox>

                <MealTypePickerFormItem
                  value={mealType}
                  onChange={(val) =>
                    setMealType(val.toLowerCase() as IMealType)
                  }
                />

                <PortionUnitPickerFormItem
                  unitValue={food.unit}
                  portionValue={food.portion}
                  onPortionChange={handlePortionChange}
                  onUnitChange={onUnitChange}
                  unitOptions={food.units}
                  isDisabledUnit={!food.isExternal}
                  setIsPortionValid={setIsPortionValid}
                />

                <NutrientInformation
                  calorie={food.calorie}
                  fat={food.fat}
                  protein={food.protein}
                  carbohydrate={food.carbohydrate}
                />
                {food.ingredients?.length ? (
                  <IngredientList>
                    {" "}
                    {food.ingredients.map((ingredient, index) => (
                      <div key={index}>
                        <FoodListItem
                          food={ingredient}
                          hideAddBtn
                          style={{ padding: "0 8px" }}
                        />
                        {index !== (food.ingredients?.length ?? 1) - 1 && (
                          <FoodListDivider />
                        )}
                      </div>
                    ))}
                  </IngredientList>
                ) : undefined}
              </FoodDetailsInfo>
            </FoodDetailsBody>
            <CtaBox>
              <Button
                loading={logMealLoading}
                $type="primary"
                style={{ width: "100%" }}
                disabled={!isPortionValid}
                onClick={() => handleLogMealByFood(food)}
              >
                Log this meal
              </Button>
            </CtaBox>
          </FoodDetailsContent>

          <PopConfirm
            isOpen={isPopConfirmDeleteOpen}
            title="Delete meal"
            onCancel={() => setIsPopConfirmDeleteOpen(false)}
            onConfirm={() => {
              handleDeleteFood(foodDetails?.id as string)
            }}
            type="danger"
            loading={deleteFoodLoading}
          >
            <div style={{ textAlign: "center" }}>
              You are about to delete{" "}
              <ConfirmEntry>{foodDetails?.name}</ConfirmEntry>. Are you sure?
            </div>
          </PopConfirm>
        </StyledFoodDetails>
      )}
    </Fragment>
  )
}

export default FoodDetails
