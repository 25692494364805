import { FlexBox } from "components/Common/FlexBox"
import styled from "styled-components"
import { getDisplayCalories, truncateByDecimalPlace } from "utils"

const Title = styled.div`
  font-size: 14px;
  font-style: normal;
  line-height: 160%;
  font-weight: 500;
`

const StyledInfo = styled.div`
  min-width: 40px;
  flex-shrink: 0;
`

const InfoName = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 160%;
  color: var(--Schemes-On-Surface-Variant, #69757d);
`

const InfoValue = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 160%;
  min-width: 40px;
  width: max-content;
`

const Operator = styled.div`
  font-weight: 500;
`

const InfoItem = ({ name, value }: { name: string; value: number }) => {
  return (
    <StyledInfo>
      <InfoValue>{getDisplayCalories(value)}</InfoValue>
      <InfoName>{name}</InfoName>
    </StyledInfo>
  )
}

const CaloriesRemainingContainer = styled.div`
  border-top: 1px solid var(--Schemes-Outline-Variant, rgba(0, 38, 58, 0.14));
  background: #fff;
`

const CaloriesRemainingInner = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin: 0 auto;
  padding: 6px 0;
  width: 345px;
`

const CaloriesRemaining = ({
  caloriesPerDay,
  gainedCalories,
}: {
  caloriesPerDay: number
  gainedCalories: number
}) => {
  const roundedCaloriesPerDay = truncateByDecimalPlace(caloriesPerDay, 0)
  const roundedGainedCalories = truncateByDecimalPlace(gainedCalories, 0)
  const roundedRemaining = roundedCaloriesPerDay - roundedGainedCalories
  return (
    <CaloriesRemainingContainer>
      <CaloriesRemainingInner>
        <Title>Calories Remaining (cal)</Title>

        <FlexBox $gap={18}>
          <InfoItem name="Goal" value={roundedCaloriesPerDay} />
          <Operator>{" - "}</Operator>
          <InfoItem name="Food" value={roundedGainedCalories} />
          <Operator>{" = "}</Operator>
          <InfoItem name="Remaining" value={roundedRemaining} />
        </FlexBox>
      </CaloriesRemainingInner>
    </CaloriesRemainingContainer>
  )
}

export default CaloriesRemaining
