import styled from "styled-components"

const StyledStepper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
`

const StepItem = styled.div<{ $isActive: boolean }>`
  width: 24px;
  height: 4px;
  border-radius: 99px;
  background: ${(props) =>
    props.$isActive
      ? "var(--Schemes-Primary, #0DA3BA)"
      : "var(--Schemes-Outline, rgba(0, 38, 58, 0.14))"};
`

const Stepper = ({ currentStep }: { currentStep: number }) => {
  return (
    <StyledStepper>
      {[0, 1, 2].map((step) => (
        <StepItem $isActive={currentStep >= step} key={step} />
      ))}
    </StyledStepper>
  )
}

export default Stepper
