import { IFood, IIngredientFood, IMealIngredient } from "features/food/foodTypes"
import { IMeal } from "features/meal/types"

export const updateIngredientsWhenPortionChange = (
  food: IFood | IMeal,
  portion: number,
) => {
  if (!food.ingredients) return undefined

  const percent = portion / food.portion

  return food.ingredients.map((ingredient) => {
    const fat = ingredient.fat * percent
    const protein = ingredient.protein * percent
    const carbohydrate = ingredient.carbohydrate * percent
    const calorie = ingredient.calorie * percent
    const foodPortion = ingredient.portion * percent

    return {
      ...ingredient,
      calorie,
      fat,
      protein,
      carbohydrate,
      portion: foodPortion,
    } as IIngredientFood | IMealIngredient
  })
}
