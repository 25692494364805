import { FlexBox } from "components/Common/FlexBox"
import styled from "styled-components"

export const Header = styled(FlexBox)`
  height: 44px;
  padding: 0 8px;
  background: var(--Schemes-Surface-Container-Low, #f5f7f7);
`

export const Body = styled.div`
  padding: 24px 16px 64px 16px;

  .adm-list-body {
    background: transparent;
    overflow: visible;
  }

  .adm-list-body-inner {
    margin-top: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  .adm-form-footer {
    padding: 24px 0px 0px 0;
  }
`

export const Title = styled.div`
  font-family: sf-pro;
  font-size: 17px;
  font-weight: 590;
  line-height: 22px;
  letter-spacing: -0.43px;
  text-align: center;
`
export const ForgotPassword = styled.div`
  padding: 9px 0px;
  font-size: 14px;
  font-weight: 700;
  line-height: 160%;
  color: var(--color-navy);
`

export const OrDivider = styled.div`
  padding: 32px 0;
  font-size: 18px;
  font-weight: 700;
  line-height: 140%;
  text-align: center;
  color: #69757d;
`
