import { useNavigate } from "react-router-dom"
import { useSetPageTitle } from "libs/ga"
import SignInWithAppleButton from "./SignInWithApple"
import {
  AuthWrapper,
  BottomOverlay,
  CTABox,
  Description,
  Footer,
  FooterText,
  FooterTextSpan,
  StyledAuthLayout,
  Title,
  TitleBox,
  HeaderWrapper,
  TopOverlay,
  Header,
  HeaderTitle,
  HeaderSubTitle,
  SignUpBtn,
  SignUpText,
} from "./styled"
import Button from "components/Buttons/Button"
import EmailIcon from "components/Icons/EmailIcon"
import PATHS from "router/paths"
import ZozoFitIcon from "components/Icons/ZozoFitIcon"
import banner from "../../../../../banner-1.png"
import styled from "styled-components"
import { useAppSelector } from "app/hooks"
import { FlexBox } from "components/Common/FlexBox"
import { getSignUpUrl } from "utils"

const StyledImage = styled.img`
  position: absolute;
  inset: 0;
  object-fit: cover;
  width: 100%;
  height: 100%;
`

const Auth = () => {
  useSetPageTitle("Login")
  const navigate = useNavigate()
  const loginWithAppleLoading = useAppSelector(
    (state) => state.auth.loginWithAppleLoading,
  )

  return (
    <StyledAuthLayout>
      <StyledImage src={banner} />
      <HeaderWrapper>
        <TopOverlay />
        <Header>
          <ZozoFitIcon />
          <HeaderTitle>Food Journal</HeaderTitle>
          <HeaderSubTitle>Beta</HeaderSubTitle>
        </Header>
      </HeaderWrapper>

      <AuthWrapper>
        <BottomOverlay />

        <TitleBox>
          <Title>Track Your Diet & Macros</Title>

          <Description>
            Introducing our exciting new feature, ZOZOFIT's Food Journal. Dive
            in, explore, and let us know what you think as we keep developing
            this feature.
          </Description>

          <Description>Please log in using your ZOZOFIT account.</Description>
        </TitleBox>

        <CTABox>
          <SignInWithAppleButton />
          <Button
            onClick={() => {
              if (!loginWithAppleLoading) {
                navigate(PATHS.auth.loginWithEmail)
              }
            }}
            disabled={loginWithAppleLoading}
            shape="rounded"
            style={{ width: "100%" }}
          >
            <EmailIcon /> Login with Email
          </Button>
        </CTABox>

        <FlexBox
          $gap={12}
          $direction="column"
          style={{ marginTop: 24, width: "100%", zIndex: 1 }}
        >
          <SignUpText style={{ marginTop: 12 }}>
            Don't have an account?
          </SignUpText>

          <SignUpBtn href={getSignUpUrl()}>Sign up</SignUpBtn>
        </FlexBox>

        <Footer>
          <FooterText>
            By continuing, you agree to ZOZOFIT's <span></span>
            <FooterTextSpan to={PATHS.auth.termOfUse}>
              Terms of Use
            </FooterTextSpan>
          </FooterText>
        </Footer>
      </AuthWrapper>
    </StyledAuthLayout>
  )
}

export default Auth
